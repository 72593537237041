export var SignupTypes;
(function (SignupTypes) {
    SignupTypes["RESEND_CONFIRM_EMAIL_REQUEST"] = "RESEND_CONFIRM_EMAIL_REQUEST";
    SignupTypes["RESEND_CONFIRM_EMAIL_SUCCESS"] = "RESEND_CONFIRM_EMAIL_SUCCESS";
    SignupTypes["CONFIRM_SIGNUP_REQUEST"] = "CONFIRM_SIGNUP_REQUEST";
    SignupTypes["CONFIRM_SIGNUP_SUCCESS"] = "CONFIRM_SIGNUP_SUCCESS";
    SignupTypes["CONFIRM_RESET_PASSWORD_REQUEST"] = "CONFIRM_RESET_PASSWORD_REQUEST";
    SignupTypes["CONFIRM_RESET_PASSWORD_SUCCESS"] = "CONFIRM_RESET_PASSWORD_SUCCESS";
    SignupTypes["CONFIRM_RESET_PASSWORD_FAILURE"] = "CONFIRM_RESET_PASSWORD_FAILURE";
})(SignupTypes || (SignupTypes = {}));
