export const GET_QUEUE_ORDERS = "GET_QUEUE_ORDERS";
export const GET_PROGRESS_ORDERS = "GET_PROGRESS_ORDERS";
export const GET_INCOMPLETE_ORDERS = "GET_INCOMPLETE_ORDERS";
export const GET_DRAFT_ORDERS = "GET_DRAFT_ORDERS";
export const GET_CUSTOMER_DRAFT_ORDERS = "GET_CUSTOMER_DRAFT_ORDERS";
export const GET_DONE_ORDERS = "GET_DONE_ORDERS";
export const GET_ARCHIVED_ORDERS = "GET_ARCHIVED_ORDERS";

export const SEARCH_DRAFT_ORDERS = "SEARCH_DRAFT_ORDERS";
export const SEARCH_CUSTOMER_DRAFT_ORDERS = "SEARCH_CUSTOMER_DRAFT_ORDERS";

export const QUEUE = "queue";
export const PROGRESS = "progress";
export const EDIT = "edit";
export const INCOMPLETE = "incomplete";
export const DRAFT = "draft";
export const CUSTOMER_DRAFT = "customer_draft";
export const PRIORITY = "priority";
export const REVISION = "revision";
export const REVISION_PROGRESS = "revision_progress";
export const DONE = "done";
export const EDITOR_PROGRESS = "editor_progress";
export const ARCHIVED = "archived"; //статус не настояший, вместо него есть в заказе булевая переменная archieved

//bytes
export const limitImageSize = 31456000;
export const limitStringsSize = 31456000;
