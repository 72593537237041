import { formatCurrency } from "./formatCurrency";
import { USD } from "../constants/Currency";

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export const formatRate = (value, withSymbol) => {
  const contentRoundToTwo = roundToTwo(value);
  const contentTmp = contentRoundToTwo.toString().split(".");
  const rate = {
    integer: contentTmp[0],
    decimal: contentTmp[1] ? (contentTmp[1].length > 1 ? contentTmp[1] : contentTmp[1] + "0") : "00",
  };

  return formatCurrency({ value: rate, currency: withSymbol && USD });
};
