import React, { Component, Fragment } from "react";
import _ from "lodash";

import Loading from "../../../../Loading";
import LanguageCheckbox from "../LanguageCheckbox/LanguageCheckbox";
// eslint-disable-next-line no-unused-vars
import table from "./css/table.css";
import { I18n } from "react-redux-i18n";
import iconSet from "../../../../../images/selection.json";
import IcomoonReact from "icomoon-react";

const COLUMN_WIDTH = 190;
const LANGUAGE_CONTAINER_HEIGHT = 31;

export default class TableLanguagesBlock extends Component {
  state = {
    findValue: "",
    languages: [],
    tableBlock: {},
    languageCheckboxWidth: "",
    isFocusFind: false,
  };

  getBlockStyle = (languageBlockWidth, languages, value) => {
    const width = window.innerWidth < 640 ? window.innerWidth : languageBlockWidth;
    const columnCount = width && Math.floor(width / COLUMN_WIDTH);
    const columnHeight = Math.ceil(languages.length / columnCount) * LANGUAGE_CONTAINER_HEIGHT + 25;
    const tableBlock =
      window.innerWidth > 640 && columnCount === 2
        ? {
            height: columnHeight ? (value ? "auto" : columnHeight) : 32,
            width: "50%",
            flexDirection: value ? "row" : "column",
          }
        : {
            height: columnHeight ? (value ? "auto" : columnHeight) : 32,
            flexDirection: value ? "row" : "column",
          };
    const languageCheckboxWidth = window.innerWidth < 640 && columnCount < 3 ? "100%" : 199;

    return { tableBlock, languageCheckboxWidth };
  };

  calculateSize = () => {
    const blockStyle = this.getBlockStyle(this.props.languageBlockWidth, this.props.languages);

    this.setState({
      tableBlock: blockStyle.tableBlock,
      languageCheckboxWidth: blockStyle.languageCheckboxWidth,
      languages: this.props.languages,
    });
  };

  componentDidMount() {
    this.calculateSize();
    window.addEventListener("resize", this.calculateSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateSize);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.languages.length <= 0 && this.props.languages.length > 0) ||
      prevProps.sourceLanguage.value !== this.props.sourceLanguage.value
    ) {
      this.calculateSize();
    }
  }

  onChangeFind = e => {
    const value = e.target.value;
    const foundedLanguages = _.filter(this.props.languages, function (lang) {
      const isIndexOf = lang.label.toLowerCase().indexOf(value.toLowerCase());

      return isIndexOf >= 0;
    });

    const languagesTemp = value.length > 0 ? foundedLanguages : this.props.languages;
    const blockStyle = this.getBlockStyle(594, languagesTemp, value);

    this.setState({
      findValue: value,
      languages: languagesTemp,
      tableBlock: blockStyle.tableBlock,
      languageCheckboxWidth: blockStyle.languageCheckboxWidth,
    });
  };

  onResetFind = () => {
    this.onChangeFind({ target: { value: "" } });
  };

  onFocusFind = () => {
    this.setState({
      isFocusFind: true,
    });
  };

  onBlurFind = () => {
    this.setState({
      isFocusFind: false,
    });
  };

  render() {
    const {
      handleClick,
      sourceLanguage,
      selectedTargetLanguage,
      recentPairsList,
      handlerRemoveRecentLanguage,
      isLoadingMe,
      clickDisabledLanguage,
    } = this.props;
    const { languages, languageCheckboxWidth, tableBlock } = this.state;
    const isLanguagesLoading = _.isEmpty(this.props.languages) || isLoadingMe;
    if (isLanguagesLoading) {
      return (
        <div styleName={"table.loading"}>
          <Loading display={true} />
        </div>
      );
    }
    const languagesHeight = languages.length <= 0 ? { height: "auto" } : tableBlock;
    const selectLanguage = this.props.languages.find(item => item.value === sourceLanguage.value);

    return (
      <Fragment>
        <div styleName={"table.find"} style={{ width: languageCheckboxWidth }}>
          <input
            styleName={"table.inputFind table.inputBlock"}
            onFocus={this.onFocusFind}
            onBlur={this.onBlurFind}
            placeholder={I18n.t("SEARCH_PLACEHOLDER")}
            value={this.state.findValue}
            onChange={this.onChangeFind}
          />
          {this.state.findValue ? (
            <div styleName={"table.iconBox"} onClick={this.onResetFind}>
              <IcomoonReact iconSet={iconSet} color={"#BDBDBD"} size={14} icon={"close"} />
            </div>
          ) : (
            <div styleName={"table.iconBox"}>
              <IcomoonReact iconSet={iconSet} color={"#BDBDBD"} size={16} icon={"search"} />
            </div>
          )}
        </div>
        <div styleName={"table.block"} style={languagesHeight}>
          {languages.length > 0 ? (
            languages.map((item, i) => {
              const isRecent = recentPairsList && recentPairsList[item.value];
              return (
                <LanguageCheckbox
                  key={i}
                  boxWidth={languageCheckboxWidth}
                  language={item}
                  sourceLanguage={sourceLanguage}
                  isDisabled={!item.sourcePairs[sourceLanguage.value]}
                  selected={selectedTargetLanguage[item.value]}
                  selectLanguage={selectLanguage}
                  isRecent={isRecent}
                  handleClick={handleClick}
                  handlerRemoveRecentLanguage={handlerRemoveRecentLanguage}
                  clickDisabledLanguage={clickDisabledLanguage}
                />
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 260,
              }}
            >
              {I18n.t("EMPTY.SEARCH_CONTENT")}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
