import React, { Component } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import faqList from "../../locales/en/FAQ-customer";
import moment from "moment";
import UnderHeader from "../../components/refactored/UnderHeader";
import ScrollNavigator from "../../components/navigation/ScrollNavigator";
import SupportGeneralIssues from "../../components/refactored/SupportGeneralIssues";
import HtmlTranslate from "../../components/HtmlTranslate";
import { scrollToItem } from "../../actions/customer/Orders";
import { setTitle } from "../../actions/App";
import { showNewMessageChat } from "../../helpers/intercomChat";
// eslint-disable-next-line no-unused-vars
import baseContainer from "../../css/container.css";
import { changeTableState } from "../../actions/customer/Support";
import SupportFAQ from "../../components/refactored/SupportFAQ/SupportFAQ";
import { blocksArray } from "../../settings/translator/SupportFAQ";

class CustomerSupport extends Component {
  componentDidMount() {
    const { setTitle, i18n } = this.props;

    setTitle(I18n.t("SUPPORT_PAGE.PAGE_TITLE"));
    moment.locale(i18n.locale);
  }

  componentWillUnmount() {
    this.saveScrollPosition();
    this.props.scrollToItem("");
  }

  saveScrollPosition = () => {
    this.props.changeTableStateSupport("", {
      scrollPosition: {
        x: window.scrollX,
        y: window.scrollY,
      },
    });
  };

  onOpenChat = template => {
    const { scrollToItem } = this.props;
    scrollToItem("");
    showNewMessageChat(template ? template : " ");
  };

  render() {
    const { auth, itemId, tableState, location } = this.props;
    const issuesSection = {
      generalIssuesText: <HtmlTranslate value="SUPPORT_PAGE.GENERAL_ISSUES.GENERAL_ISSUES_TEXT" />,
      buttonTitle: I18n.t("SUPPORT_PAGE.GENERAL_ISSUES.BUTTON_OPEN_CHAT"),
    };
    const scrollNavigatorConst = {
      title: I18n.t("HEADER.SUPPORT_LINK"),
      widthScrollNavigator: 644,
    };
    const buttonSendEmailTitle = I18n.t("SUPPORT_PAGE.GENERAL_ISSUES.BUTTON_SEND_EMAIL");
    const supportedFormatsTextAfterTitle = I18n.t("FAQ_CUSTOMER.SUPPROTED_FORMATS.TEXT_BLOCK");
    const supportedFormatsTextSuggestions = I18n.t("FAQ_CUSTOMER.SUPPROTED_FORMATS.SUGGESTIONS");
    const sendEmailHref = "mailto:nitro@alconost.com";

    let hashLinkArray;
    if (location) {
      hashLinkArray = location.hash && location.hash.substring(1).split("_");
    } else {
      hashLinkArray = window.location.hash && window.location.hash.substring(1).split("_");
    }
    const scrollTo = itemId && !hashLinkArray ? itemId : hashLinkArray && hashLinkArray[0];
    const openBlock = hashLinkArray ? Number(hashLinkArray[1]) : undefined;

    const supportBlocks = [
      {
        id: "faq",
        text: I18n.t("SUPPORT_PAGE.FAQ.SECTION_TITLE"),
        component: () => (
          <SupportFAQ blocks={blocksArray("CUSTOMER", Object.keys(faqList.CUSTOMER))} firstOpen={true} />
        ),
        sectionWrapper: true,
        width: 644,
      },
      {
        id: "supportedFormats",
        text: I18n.t("FAQ_CUSTOMER.SUPPROTED_FORMATS.SECTION_TITLE"),
        component: () => (
          <SupportFAQ
            blocks={blocksArray("SUPPROTED_FORMATS", Object.keys(faqList.SUPPROTED_FORMATS))}
            styleData={"supportedFormats"}
            textAfterTitle={supportedFormatsTextAfterTitle}
            textSuggestions={supportedFormatsTextSuggestions}
            openIndex={openBlock}
          />
        ),
        sectionWrapper: true,
        width: 644,
      },
      {
        id: "issues",
        text: I18n.t("FAQ_CUSTOMER.GENERAL_ISSUES.SECTION_TITLE"),
        component: () => (
          <SupportGeneralIssues
            generalIssuesText={issuesSection.generalIssuesText}
            buttonTitle={issuesSection.buttonTitle}
            buttonSendEmailTitle={buttonSendEmailTitle}
            onOpenChat={() => this.onOpenChat()}
            href={sendEmailHref}
          />
        ),
        sectionWrapper: true,
        width: 644,
      },
    ];

    return (
      <div styleName={"baseContainer.full-width"}>
        <UnderHeader title={scrollNavigatorConst.title} auth={auth} />
        <ScrollNavigator
          navigationBlocks={supportBlocks}
          maxWidth={scrollNavigatorConst.widthScrollNavigator}
          scrollTo={scrollTo}
          scrollToPosition={tableState.scrollPosition}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: title => dispatch(setTitle(title)),
    scrollToItem: itemId => dispatch(scrollToItem(itemId)),
    changeTableStateSupport: (status, state) => dispatch(changeTableState(status, state)),
  };
}

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    tableState: state.supportCustomer.tableState,
    itemId: state.ordersTemp.itemId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
