import reduceReducers from "reduce-reducers";
import { DESC } from "../../constants/Sort";

const initialState = {
  tablesState: {
    images: [],
    search: "",
    editingBlocks: {
      isOpen: false,
      order: {},
    },
    scrollPosition: {
      x: 0,
      y: 0,
    },
    queue: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    progress: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    done: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    incomplete: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    priority: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    draft: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
    },
    focusPositionSettings: false,
  },
};

function orders(state = initialState, action) {
  const statusIsEmpty = !action.status && action.state;
  switch (action.type) {
    case "CHANGE_ORDERS_TABLE_STATE":
      return {
        ...state,
        tablesState: {
          ...state.tablesState,
          ...statusIsEmpty,
          [action.status]: {
            ...state.tablesState[action.status],
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}

export const translatorOrdersReducer = reduceReducers(orders);
