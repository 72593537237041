import React, { Component } from "react";
import { Provider } from "react-redux";
import { closePopovers } from "../helpers/common";
import NewOrderApp from "./NewOrderApp";
import Register from "./forms/Register";
import SignupSuccess from "./forms/SignupSuccess/SignupSuccess";
import Forgot from "./forms/Forgot";
import ForgotReset from "./forms/ForgotReset";
import Login from "./forms/Login";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import {
  getCouponFromUrl,
  getQueryValueFromUrl,
  getNavigateURLForCouponDiscountQueries,
} from "../helpers/getCouponFromUrl";
import WebsocketProvider from "../components/refactored/Websocket";

const styleForm = { border: "none", padding: 0 };
const styleFormAlt = {
  paddingBottom: 0,
  paddingTop: 24,
  textAlign: "left",
};
const styleSignUpSuccess = { border: "none", padding: 0, width: "auto" };
const styleFormAltSignUpSuccess = { display: "none" };
const styleDescriptionSignUpSuccess = { marginBottom: -16 };

const coupon = getCouponFromUrl();
const discount = getQueryValueFromUrl(null, "discount");

const navURL = getNavigateURLForCouponDiscountQueries("/new", coupon, discount);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={navURL} replace={true} />,
  },
  {
    path: "/new/*",
    element: <NewOrderApp />,
    children: [
      {
        index: true,
        element: <Login styleForm={styleForm} styleFormAlt={styleFormAlt} isNotSetTitle={true} relativePath={".."} />,
      },
      {
        path: "login",
        element: <Login styleForm={styleForm} styleFormAlt={styleFormAlt} isNotSetTitle={true} relativePath={".."} />,
      },
      {
        path: "signup",
        element: (
          <Register styleForm={styleForm} styleFormAlt={styleFormAlt} isNotSetTitle={true} relativePath={".."} />
        ),
      },
      {
        path: "signup/confirm",
        element: (
          <SignupSuccess
            styleFormAltSignUpSuccess={styleFormAltSignUpSuccess}
            styleSignUpSuccess={styleSignUpSuccess}
            styleDescriptionSignUpSuccess={styleDescriptionSignUpSuccess}
            isNotSetTitle={true}
            relativePath={".."}
          />
        ),
      },
      {
        path: "forgot-password",
        element: <Forgot styleForm={styleForm} styleFormAlt={styleFormAlt} isNotSetTitle={true} relativePath={".."} />,
      },
      {
        path: "forgot-password/reset",
        element: (
          <ForgotReset styleForm={styleForm} styleFormAlt={styleFormAlt} isNotSetTitle={true} relativePath={".."} />
        ),
      },
    ],
  },
]);
export default class NewOrderRoot extends Component {
  componentDidMount() {
    const body = document.getElementsByTagName("body")[0];
    body.onresize = closePopovers;
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <WebsocketProvider>
          <RouterProvider router={router} />
        </WebsocketProvider>
      </Provider>
    );
  }
}
