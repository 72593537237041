import * as types from "../constants/AuthorizedActionTypes";

export function toggleSideMenu(isOpened) {
  return {
    type: types.TOGGLE_SIDE_MENU,
    isOpened,
  };
}

export function requestOrder() {
  return {
    type: types.REQUEST_ORDER,
  };
}

export function succeedOrder(order) {
  return {
    type: types.SUCCEED_ORDER,
    order,
  };
}

export function failOrder(error) {
  return {
    type: types.FAIL_ORDER,
    error,
  };
}

export function unsetOrder() {
  return {
    type: types.UNSET_ORDER,
  };
}

//  settings
export function succeedSettings(settings) {
  return {
    type: types.SUCCEED_SETTINGS,
    settings,
  };
}

export function failSettings(error, message) {
  return {
    type: types.FAIL_SETTINGS,
    error,
    message,
  };
}

export function failPollingConnection(error, message) {
  return {
    type: types.FAIL_POLLING_OPEN,
    error,
    message,
  };
}

export function successPollingConnection(error, message) {
  return {
    type: types.FAIL_POLLING_CLOSE,
    error,
    message,
  };
}

export function failConnection(error, message) {
  return {
    type: types.FAIL_CONNECTION_OPEN,
    error,
    message,
  };
}

export function successConnection(error, message) {
  return {
    type: types.FAIL_CONNECTION_CLOSE,
    error,
    message,
  };
}

export function updatedSettings(settings) {
  return {
    type: types.UPDATED_SETTINGS,
    settings,
  };
}

export function hideNotificationsSettings() {
  return {
    type: types.HIDE_NOTIFICATIONS_SETTINGS,
  };
}

export function succeedPassword(error) {
  return {
    type: types.SUCCEEED_PASSWORD,
    error,
  };
}

export function failPassword(error) {
  return {
    type: types.FAIL_PASSWORD,
    error,
  };
}

export function succeedOrderStatus(status) {
  if (status.count === 0) {
    return {
      type: types.SUCCEED_NO_ORDER_STATUS,
      status,
    };
  }
  return {
    type: types.SUCCEED_ORDER_STATUS,
    status,
  };
}

export function loadingOrderStatus() {
  return {
    type: types.LOADING_ORDER_STATUS,
  };
}

export function failOrderStatus() {
  return {
    type: types.FAIL_ORDER_STATUS,
  };
}

export function succeedNextOrder(nextOrder) {
  if (nextOrder.acceptedTime) {
    return {
      type: types.SUCCEED_NEXT_ORDER,
      nextOrder,
    };
  }
  return {
    type: types.SUCCEED_NEXT_ORDER,
    nextOrder,
  };
}

export function failNextOrder() {
  return {
    type: types.FAIL_NEXT_ORDER,
  };
}

export function succeedSkipOrder() {
  return {
    type: types.SUCCEED_SKIP_ORDER,
  };
}

export function failSkipOrder() {
  return {
    type: types.FAIL_SKIP_ORDER,
  };
}

export function succeedCompleteOrder() {
  return {
    type: types.SUCCEED_COMPLETE_ORDER,
  };
}

export function failCompleteOrder() {
  return {
    type: types.FAIL_COMPLETE_ORDER,
  };
}

export function succeedAcceptedOrder(acceptedOrder) {
  return {
    type: types.SUCCEED_ACCEPTED_ORDER,
    acceptedOrder,
  };
}

export function loadingOrder() {
  return {
    type: types.LOADING_ORDER,
  };
}

export function completeWithNote(isAccepted) {
  return {
    type: types.COMPLETE_WITH_NOTE_ORDER,
    isAccepted,
  };
}

export function updateCompleteStatus() {
  return {
    type: types.UPDATE_COMPLETE_STATUS,
  };
}

export function updateResult(resultText) {
  return {
    type: types.UPDATE_RESULT,
    resultText,
  };
}

// export const OPEN_DESKTOP_POPUP = 'OPEN_DESKTOP_POPUP';
// export const CLOSE_DESKTOP_POPUP = 'CLOSE_DESKTOP_POPUP';

export function acceptOrderSkip() {
  return {
    type: types.ACCEPT_ORDER_SKIP,
  };
}

export function acceptOrderDone() {
  return {
    type: types.ACCEPT_ORDER_DONE,
  };
}

export function openDesktopPopup(popupName) {
  return {
    type: types.OPEN_DESKTOP_POPUP,
    popupName,
  };
}

export function closeDesktopPopup() {
  return {
    type: types.CLOSE_DESKTOP_POPUP,
  };
}

export function resetDesktopPopup() {
  return {
    type: types.RESET_DESKTOP_POPUP,
  };
}

export function hideDesktopNotifications() {
  return {
    type: types.HIDE_NOTIFICATIONS_DESKTOP,
  };
}

export function validateOrder(origin, result) {
  let { unvalidObj, isValid } = getValidOrder(origin, result);

  return {
    type: types.VALIDATE_ORDER,
    unvalidObj,
    isValid,
  };
}

function getValidOrder(origin, result) {
  let unvalidObj = {
    additionalLine: [],
    emptyResult: [],
    emptySource: [],
  };

  let originArr = origin.split(/\r?\n/);
  let resultArr = result.split(/\r?\n/);
  let isValid = true;
  resultArr.forEach((resultText, i) => {
    // additional unneccessary lines
    if (i >= originArr.length) {
      isValid = false;
      unvalidObj.additionalLine.push(i);
    } else if (resultText === "" && originArr[i] !== "") {
      isValid = false;
      unvalidObj.emptyResult.push(i);
    } else if (originArr[i] === "" && resultText !== "") {
      isValid = false;
      unvalidObj.emptySource.push(i);
    }
  });
  return { unvalidObj, isValid };
}
