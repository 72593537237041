// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-dialog__container__q45Qs1 {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 544px;
  padding: 24px;
  font-family: Roboto;

  /*::-webkit-scrollbar {
    display: none;
  }*/
}

.popup-dialog__title__abj2tt {
  color: #212121;
  margin-top: 0;
  font-size: 22px;
  line-height: 33px;
}

.popup-dialog__text__FF2i_9 {
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.popup-dialog__btn-container__G8Fz5t {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.popup-dialog__btn__UZADqc {
  font-style: normal;
  font-weight: 400;
  text-align: center !important;
  font-size: 14px;
  border-radius: 3px;
  line-height: 16px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  padding: 4px 15px !important;
}

.popup-dialog__btn__UZADqc:focus {
    outline: 1px solid #0075ff;
    box-shadow: none !important;
  }

.popup-dialog__green__ojviEH {
  border: 1px solid #43a047 !important;
  background-color: #ffffff !important;
  color: #43a047 !important;
}

.popup-dialog__green__ojviEH:hover {
    background-color: #43a047 !important;
    color: #ffffff !important;
  }

.popup-dialog__green__ojviEH:disabled {
    cursor: default !important;
    border: 1px solid #43a047 !important;
    background-color: #ffffff !important;
    color: #43a047 !important;
  }

.popup-dialog__blue__OojfUQ {
  border: 1px solid #1e88e5 !important;
  background-color: #1e88e5 !important;
  color: #ffffff !important;
  padding: 8px 24px !important;
}

.popup-dialog__blue__OojfUQ:hover {
    background-color: #1976d2 !important;
    color: #ffffff !important;
  }

.popup-dialog__blue__OojfUQ:disabled {
    cursor: default !important;
    border: 1px solid #1e88e5 !important;
    background-color: #1e88e5 !important;
    color: #ffffff !important;
    opacity: 0.3;
  }

.popup-dialog__greenAdditional__VRtqDC {
  margin-left: 16px;
  border: 1px solid #43a047 !important;
  background-color: #ffffff !important;
  color: #43a047 !important;
}

.popup-dialog__greenAdditional__VRtqDC:focus {
    outline: 1px solid #0075ff;
    box-shadow: none !important;
  }

.popup-dialog__greenAdditional__VRtqDC:hover {
    background-color: #43a047 !important;
    color: #ffffff !important;
  }

.popup-dialog__greenFilled__hpBNHy {
  min-width: 80px;
  height: 32px;
  padding: 7px 20px !important;
  border-radius: 4px;
  border: none;
  background-color: #43a047;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}

.popup-dialog__greenFilled__hpBNHy:hover {
    background-color: #388e3c !important;
    border-color: #388e3c !important;
  }

.popup-dialog__red__vaynNa {
  border: 1px solid #ef5350 !important;
  background-color: #ffffff !important;
  color: #ef5350 !important;
}

.popup-dialog__red__vaynNa:disabled {
    cursor: default !important;
  }

.popup-dialog__red__vaynNa:hover {
    background-color: #ef5350 !important;
    border-color: #ef5350 !important;
    color: #ffffff !important;
  }

.popup-dialog__close__K65dt0 {
  outline: none !important;
}

.popup-dialog__iconClose__o6hPLP {
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.popup-dialog__arrowContainer__C6J_Jn {
  position: absolute;
  bottom: 90px;
  width: 272px;
  display: flex;
  justify-content: center;
}

.popup-dialog__arrowDown__QpBAyv {
  height: 31px;
  width: 31px;
  min-width: 31px;
  min-height: 31px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-dialog__btnWithLoading__isZVG6 {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `popup-dialog__container__q45Qs1`,
	"title": `popup-dialog__title__abj2tt`,
	"text": `popup-dialog__text__FF2i_9`,
	"btn-container": `popup-dialog__btn-container__G8Fz5t`,
	"btn": `popup-dialog__btn__UZADqc`,
	"green": `popup-dialog__green__ojviEH popup-dialog__btn__UZADqc`,
	"blue": `popup-dialog__blue__OojfUQ popup-dialog__btn__UZADqc`,
	"greenAdditional": `popup-dialog__greenAdditional__VRtqDC popup-dialog__btn__UZADqc`,
	"greenFilled": `popup-dialog__greenFilled__hpBNHy`,
	"red": `popup-dialog__red__vaynNa popup-dialog__btn__UZADqc`,
	"close": `popup-dialog__close__K65dt0`,
	"iconClose": `popup-dialog__iconClose__o6hPLP`,
	"arrowContainer": `popup-dialog__arrowContainer__C6J_Jn`,
	"arrowDown": `popup-dialog__arrowDown__QpBAyv`,
	"btnWithLoading": `popup-dialog__btnWithLoading__isZVG6`
};
export default ___CSS_LOADER_EXPORT___;
