import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  clearErrors,
  getParameterByName,
  setFocusOnFirst,
  showErrors,
  validate,
  handleTabFocus,
} from "../../helpers/common";

import HtmlTranslate from "../HtmlTranslate";
import { I18n } from "react-redux-i18n";
import FacebookLogin from "./FacebookLogin";
import GoogleLogin from "./GoogleLogin";
import { getCouponFromUrl } from "../../helpers/getCouponFromUrl";

export default class RegisterForm extends Component {
  static propTypes = {
    handler: PropTypes.func.isRequired,
    facebookHandler: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    disablingTrigger: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      locale: this.props.locale,
      email: props.data ? props.data.email : "",
      password: "",
      name: props.data ? props.data.name : getParameterByName("fallbackName") || "",
      terms: props.data ? props.data.terms : false,
      error: null,
      modalElements: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    setFocusOnFirst();

    const modalElements = document.querySelectorAll(
      "#register-form input#name, #register-form input#email, #register-form input#password, #register-form input#terms-label, #register-form button"
    );
    this.setState({ modalElements });
    document.addEventListener("keydown", e => handleTabFocus(e, modalElements));
  }

  componentWillUnmount() {
    const { modalElements } = this.state;
    document.removeEventListener("keydown", e => handleTabFocus(e, modalElements));
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  handleTabFocus = e => {
    const { modalElements } = this.state;

    if (e.key === "Tab") {
      e.preventDefault();
      const currentFocusIndex = Array.from(modalElements).indexOf(document.activeElement);
      const nextFocusIndex = (currentFocusIndex + 1) % modalElements.length;
      modalElements[nextFocusIndex].focus();
    }
  };

  handleKeyPressTerms = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState(prevState => ({
        terms: !prevState.terms,
      }));
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    this.props.validator();
    clearErrors();
    document.activeElement.blur();
    let form = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      terms: this.state.terms,
      locale: this.state.locale,
    };

    const errors = validate(form);

    const couponGetCookie = getCouponFromUrl();
    form = {
      ...form,
      coupon: couponGetCookie && couponGetCookie,
    };

    if (!errors) {
      if (!this.props.disabled) this.props.handler(form);
    } else {
      showErrors(errors);
    }
  }

  render() {
    const { data, styleForm, googleHandler } = this.props;
    const action = I18n._translate("SIGNUP_PAGE.SIGNUP_FORM.SIGNUP_ACTION");
    return (
      <form
        id="register-form"
        className="nitro-form"
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        style={styleForm}
      >
        <div className="form-group">
          <label htmlFor="name" style={{ fontSize: 14, fontWeight: 300 }}>
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.NAME_FIELD_LABEL" />
          </label>
          <input className="form-control" name="name" type="text" id="name" defaultValue={data ? data.name : ""} />
          <p className="error-message">
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.NAME_FIELD_ERROR" />
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="email" style={{ fontSize: 14, fontWeight: 300 }}>
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.EMAIL_FIELD_LABEL" />
          </label>
          <input
            className="form-control"
            name="email"
            type="text"
            id="email"
            value={this.state.email}
            defaultValue={data ? data.email : ""}
          />
          <p className="error-message">
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.EMAIL_FIELD_ERROR" />
          </p>
          <p className="error-message custom-error">
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.INCORRECT_EMAIL_ERROR" />
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="password" style={{ fontSize: 14, fontWeight: 300 }}>
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.PASSWORD_FIELD_LABEL" />
          </label>
          <input className="form-control" name="password" type="password" id="password" value={this.state.password} />
          <p className="error-message">
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.PASSWORD_FIELD_ERROR" />
          </p>
        </div>
        <div
          className="form-group"
          style={{ display: "grid", gridTemplateColumns: "30px 1fr", justifyContent: "start" }}
        >
          <input
            type="checkbox"
            id="terms-label"
            name="terms"
            checked={this.state.terms}
            className="regular-checkbox custom-checkbox"
            onKeyDown={this.handleKeyPressTerms}
          />
          <div className="tag">
            <HtmlTranslate
              value="SIGNUP_PAGE.SIGNUP_FORM.TERMS_WARNING"
              action={action}
              termsLink={I18n.t("SIGNUP_PAGE.SIGNUP_FORM.TERMS_LINK")}
              privacyLink={I18n.t("SIGNUP_PAGE.SIGNUP_FORM.PRIVACY_LINK")}
            />
          </div>
          <p className="error-message" style={{ gridColumn: "1/3" }}>
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.PASSWORD_FIELD_ERROR" />
          </p>
        </div>
        <div className="form-group button-group">
          <input type="hidden" name="locale" value={this.state.locale} />
          <button type="submit" className="btn btn-block btn-nitro">
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.SUBMIT_BUTTON_TEXT" />
          </button>
        </div>
        <div className="clearfix">
          <div style={{ marginBottom: 16 }}>
            <HtmlTranslate value="SIGNUP_PAGE.SIGNUP_FORM.SOCIAL_SIGNUP_LABEL" />
          </div>
          <FacebookLogin
            trigger={this.props.disablingTrigger}
            disabled={this.props.disabled}
            formIndex={6}
            handler={this.props.facebookHandler}
          />
          <GoogleLogin
            trigger={this.props.disablingTrigger}
            disabled={this.props.disabled}
            formIndex={7}
            googleHandler={googleHandler}
          />
        </div>
        {this.state.error && <p className="text-danger">{this.state.error}</p>}
      </form>
    );
  }
}
