import {
  REQUEST_REGISTRATION,
  SUCCEED_REQUEST_REGISTRATION,
  FAIL_REQUEST_REGISTRATION,
  INIT_REGISTRATION,
  REGISTRATION_REQUESTED,
  REGISTRATION_ERROR,
  SAVE_FORM,
} from "../constants/Signup";

import { VALIDATE_FORM } from "../constants/App";

const initialState = {
  status: null,
  error: null,
  form: null,
  authToken: null,
  authStatusCode: null,
  authMethod: null,
};

export default function signup(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_FORM:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SAVE_FORM:
      return {
        ...state,
        form: action.form,
      };
    case REQUEST_REGISTRATION:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_REQUEST_REGISTRATION:
      return {
        ...state,
        status: REGISTRATION_REQUESTED,
        form: action.form,
      };
    case FAIL_REQUEST_REGISTRATION:
      return {
        ...state,
        status: REGISTRATION_ERROR,
        error: action.error.message,
      };
    case INIT_REGISTRATION:
      return initialState;
    default:
      return state;
  }
}
