// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.area__input__wNBTfq {
  width: 100%;
  border: 1px solid #cecece;
  resize: none;
  box-shadow: none;
  outline-width: 0;
  padding-left: 0;
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  overflow: hidden;
}

.area__input__wNBTfq:focus {
    border: 1px solid #0075ff;
  }

textarea {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-clip: padding-box;
  border-width: 1px !important;
  -webkit-transform: translate3d(0, 0, 0);
}

.area__inputNewOrder__K3HZ1r {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid #e0e0e0;
  box-shadow: none !important;
  background-image: linear-gradient(transparent, transparent);
}

.area__inputNewOrder__K3HZ1r:focus {
    border-color: #1e88e5;
    border-radius: 0;
    box-shadow: none !important;
    background-image: linear-gradient(transparent, transparent);
  }

.area__input__wNBTfq::-webkit-scrollbar {
  width: 0 !important;
}

.area__input__wNBTfq::-webkit-input-placeholder {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  padding-left: 0;
  margin-left: 0;
}

.area__input2__zhF1jK {
  width: 100%;
  border: none;
  resize: none;
  box-shadow: none;
  outline: none 0;
  padding-left: 0;
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  overflow: hidden;
}

.area__input2__zhF1jK::-webkit-scrollbar {
  width: 0 !important;
}

.area__input2__zhF1jK::-webkit-input-placeholder {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  padding-left: 0;
  margin-left: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `area__input__wNBTfq`,
	"inputNewOrder": `area__inputNewOrder__K3HZ1r area__input__wNBTfq`,
	"input2": `area__input2__zhF1jK`
};
export default ___CSS_LOADER_EXPORT___;
