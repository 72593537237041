export const addFundsFormSelectStyle = menuIsOpen => {
  const controlBorder = menuIsOpen ? "1px solid #1E88E5" : "1px solid #CECECE";
  return {
    control: base => ({
      ...base,
      height: 32,
      minHeight: 32,
      maxWidth: 446,
      border: controlBorder,
      borderRadius: 1,
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #1E88E5",
      },
      "@media (maxWidth: 480px)": {
        width: "100% !important",
      },
    }),

    placeholder: base => ({
      ...base,
      color: "#9E9E9E",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 446,
      lineHeight: 16,
    }),

    input: base => ({
      ...base,
    }),

    valueContainer: base => ({
      ...base,
      height: 30,
    }),

    singleValue: base => ({
      ...base,
      fontFamily: "Roboto",
      color: "#424242",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "16px",
      marginLeft: 1,
    }),

    dropdownIndicator: () => ({
      paddingRight: 1,
      backgroundImage:
        "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDx0aXRsZT5CM0MyNTgzMC02RDk1LTQ2NzYtQUI3My02OTgxRjAxREZBNjI8L3RpdGxlPiA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9Im5vbmUiIGlkPSJoZWFkZXIiPiAgIDxnIGlkPSJwcm9maWxlLWFyZWEiIGZpbGw9IiNCREJEQkQiPiAgICA8ZyBpZD0ic3ZnXzEiPiAgICAgPGcgaWQ9InByb2ZpbGUtY29udGVudCI+ICAgICAgPGcgaWQ9IlRyaWFuZ2xlIj4gICAgICAgPHBvbHlnb24gZmlsbD0iIzYxNjE2MSIgaWQ9InN2Z18yIiBwb2ludHM9IjUsNiAwLDAgMTAsMCAiLz4gICAgICA8L2c+ICAgICA8L2c+ICAgIDwvZz4gICA8L2c+ICA8L2c+IDwvZz48L3N2Zz4=) center no-repeat",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    clearIndicator: base => ({
      ...base,
      display: "none",
    }),

    menu: base => ({
      ...base,
      // minHeight: 270,
      marginTop: 0,
      borderRadius: 1,
      maxWidth: 446,
      border: "1px solid #0075FF",
      borderTop: "none",
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "::-webkit-scrollbar": { width: "0 !important", height: "0 !important" },
      "@media (maxWidth: 480px)": {
        width: "100% !important",
        height: "0 !important",
      },
      zIndex: 4,
    }),

    menuList: base => ({
      ...base,
      // minHeight: 270,
      padding: 0,
      boxShadow: "0 3px 4px 0 rgba(0,0,0,0.19)",
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "::-webkit-scrollbar": { width: "0 !important", height: "0 !important" },
    }),

    option: base => ({
      ...base,
      width: 444,
      padding: 0,
      "@media (maxWidth: 480px)": {
        width: "100% !important",
      },
      backgroundColor: "#FFFFFF",
      color: "#424242",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E3F2FD",
        color: "#1E88E5",
      },
    }),
  };
};

export const addFundsFormSelectOption = props => {
  return {
    width: 444,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "normal",
    padding: props.data && props.data.item ? "0 8px 0 8px" : 8,
    "@media (maxWidth: 480px)": {
      width: "100% !important",
    },
    backgroundColor: props.isSelected && "#E3F2FD",
    color: props.isSelected && "#424242",
    cursor: !props.isSelected ? "pointer" : "default",
  };
};
