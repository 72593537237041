import { OrderTypes, } from "./orderActionTypes";
export function requestGetFileMetadata() {
    return {
        type: OrderTypes.GET_FILE_METADATA_REQUEST,
    };
}
export function successGetFileMetadata(payload) {
    return {
        type: OrderTypes.GET_FILE_METADATA_SUCCESS,
        payload: payload,
    };
}
export function requestGetOrderFile() {
    return {
        type: OrderTypes.GET_ORDER_FILE_REQUEST,
    };
}
export function successGetOrderFile(payload) {
    return {
        type: OrderTypes.GET_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestCreateTemplate() {
    return {
        type: OrderTypes.CREATE_TEMPLATE_REQUEST,
    };
}
export function successCreateTemplate(payload) {
    return {
        type: OrderTypes.CREATE_TEMPLATE_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteTemplate() {
    return {
        type: OrderTypes.DELETE_TEMPLATE_REQUEST,
    };
}
export function successDeleteTemplate(payload) {
    return {
        type: OrderTypes.DELETE_TEMPLATE_SUCCESS,
        payload: payload,
    };
}
export function requestSendMail() {
    return {
        type: OrderTypes.SEND_MAILS_REQUEST,
    };
}
export function successSendMail() {
    return {
        type: OrderTypes.SEND_MAILS_SUCCESS,
    };
}
export function requestGetTemplate() {
    return {
        type: OrderTypes.GET_TEMPLATES_REQUEST,
    };
}
export function successGetTemplate(payload) {
    return {
        type: OrderTypes.GET_TEMPLATES_SUCCESS,
        payload: payload,
    };
}
export function requestAddStar() {
    return {
        type: OrderTypes.ADD_STAR_REQUEST,
    };
}
export function successAddStar() {
    return {
        type: OrderTypes.ADD_STAR_SUCCESS,
    };
}
export function requestGetOrderAdmin() {
    return {
        type: OrderTypes.GET_ORDER_ADMIN_REQUEST,
    };
}
export function successGetOrderAdmin(payload) {
    return {
        type: OrderTypes.GET_ORDER_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestGetOrderTranslator() {
    return {
        type: OrderTypes.GET_ORDER_TRANSLATOR_REQUEST,
    };
}
export function successGetOrderTranslator(payload) {
    return {
        type: OrderTypes.GET_ORDER_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestGetOrderCustomer() {
    return {
        type: OrderTypes.GET_ORDER_CUSTOMER_REQUEST,
    };
}
export function successGetOrderCustomer(payload) {
    return {
        type: OrderTypes.GET_ORDER_CUSTOMER_SUCCESS,
        payload: payload,
    };
}
export function requestSetOrderUnreadByAdmin() {
    return {
        type: OrderTypes.SET_ORDER_UNREAD_BY_ADMIN_REQUEST,
    };
}
export function successSetOrderUnreadByAdmin(payload) {
    return {
        type: OrderTypes.SET_ORDER_UNREAD_BY_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestAcceptOrder() {
    return {
        type: OrderTypes.ACCEPT_ORDER_REQUEST,
    };
}
export function successAcceptOrder(payload) {
    return {
        type: OrderTypes.ACCEPT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestGetOrder() {
    return {
        type: OrderTypes.GET_ORDER_REQUEST,
    };
}
export function successGetOrder(payload) {
    return {
        type: OrderTypes.GET_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestContinueTranslatorOrderWithDraft() {
    return {
        type: OrderTypes.CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_REQUEST,
    };
}
export function successContinueTranslatorOrderWithDraft(payload) {
    return {
        type: OrderTypes.CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_SUCCESS,
        payload: payload,
    };
}
export function requestStartAdminEditDraftOrder() {
    return {
        type: OrderTypes.START_ADMIN_EDIT_DRAFT_ORDER_REQUEST,
    };
}
export function successStartAdminEditDraftOrder(payload) {
    return {
        type: OrderTypes.START_ADMIN_EDIT_DRAFT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestStartTranslatorEditOrder() {
    return {
        type: OrderTypes.START_TRANSLATOR_EDIT_ORDER_REQUEST,
    };
}
export function successStartTranslatorEditOrder(payload) {
    return {
        type: OrderTypes.START_TRANSLATOR_EDIT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestStartEditorEditOrder() {
    return {
        type: OrderTypes.START_EDITOR_EDIT_ORDER_REQUEST,
    };
}
export function successStartEditorEditOrder(payload) {
    return {
        type: OrderTypes.START_EDITOR_EDIT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestStartAdminEditOrder() {
    return {
        type: OrderTypes.START_ADMIN_EDIT_ORDER_REQUEST,
    };
}
export function successStartAdminEditOrder(payload) {
    return {
        type: OrderTypes.START_ADMIN_EDIT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestStartRevisionOrder() {
    return {
        type: OrderTypes.START_REVISION_ORDER_REQUEST,
    };
}
export function successStartRevisionOrder(payload) {
    return {
        type: OrderTypes.START_REVISION_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestOrderViewed() {
    return {
        type: OrderTypes.ORDER_VIEWED_REQUEST,
    };
}
export function successOrderViewed(payload) {
    return {
        type: OrderTypes.ORDER_VIEWED_SUCCESS,
        payload: payload,
    };
}
export function requestSkipProgressOrdersTranslator() {
    return {
        type: OrderTypes.SKIP_PROGRESS_ORDERS_TRANSLATOR_REQUEST,
    };
}
export function successSkipProgressOrdersTranslator(payload) {
    return {
        type: OrderTypes.SKIP_PROGRESS_ORDERS_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestSkipProgressOrdersAdmin() {
    return {
        type: OrderTypes.SKIP_PROGRESS_ORDERS_ADMIN_REQUEST,
    };
}
export function successSkipProgressOrdersAdmin(payload) {
    return {
        type: OrderTypes.SKIP_PROGRESS_ORDERS_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestCancelOrdersAdmin() {
    return {
        type: OrderTypes.CANCEL_ORDERS_ADMIN_REQUEST,
    };
}
export function successCancelOrdersAdmin(payload) {
    return {
        type: OrderTypes.CANCEL_ORDERS_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestCancelOrdersCustomer() {
    return {
        type: OrderTypes.CANCEL_ORDERS_CUSTOMER_REQUEST,
    };
}
export function successCancelOrdersCustomer(payload) {
    return {
        type: OrderTypes.CANCEL_ORDERS_CUSTOMER_SUCCESS,
        payload: payload,
    };
}
export function requestOrdersViewed() {
    return {
        type: OrderTypes.ORDERS_VIEWED_REQUEST,
    };
}
export function successOrdersViewed(payload) {
    return {
        type: OrderTypes.ORDERS_VIEWED_SUCCESS,
        payload: payload,
    };
}
export function requestExportOrdersCustomer() {
    return {
        type: OrderTypes.EXPORT_ORDERS_CUSTOMER_REQUEST,
    };
}
export function successExportOrdersCustomer(payload) {
    return {
        type: OrderTypes.EXPORT_ORDERS_CUSTOMER_SUCCESS,
        payload: payload,
    };
}
export function requestExportOrdersAdmin() {
    return {
        type: OrderTypes.EXPORT_ORDERS_ADMIN_REQUEST,
    };
}
export function successExportOrdersAdmin(payload) {
    return {
        type: OrderTypes.EXPORT_ORDERS_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestGetQueueStatus() {
    return {
        type: OrderTypes.GET_QUEUE_STATUS_REQUEST,
    };
}
export function successGetQueueStatus(payload) {
    return {
        type: OrderTypes.GET_QUEUE_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestGetAdminQueueStatus() {
    return {
        type: OrderTypes.GET_ADMIN_QUEUE_STATUS_REQUEST,
    };
}
export function successGetAdminQueueStatus(payload) {
    return {
        type: OrderTypes.GET_ADMIN_QUEUE_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestAddCommentAdmin() {
    return {
        type: OrderTypes.ADD_COMMENT_ADMIN_REQUEST,
    };
}
export function successAddCommentAdmin(payload) {
    return {
        type: OrderTypes.ADD_COMMENT_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestAddCommentTranslator() {
    return {
        type: OrderTypes.ADD_COMMENT_TRANSLATOR_REQUEST,
    };
}
export function successAddCommentTranslator(payload) {
    return {
        type: OrderTypes.ADD_COMMENT_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestAddCommentCustomer() {
    return {
        type: OrderTypes.ADD_COMMENT_CUSTOMER_REQUEST,
    };
}
export function successAddCommentCustomer(payload) {
    return {
        type: OrderTypes.ADD_COMMENT_CUSTOMER_SUCCESS,
        payload: payload,
    };
}
export function requestGetNextAdminOrderNew() {
    return {
        type: OrderTypes.GET_NEXT_ADMIN_ORDER_NEW_REQUEST,
    };
}
export function successGetNextAdminOrderNew(payload) {
    return {
        type: OrderTypes.GET_NEXT_ADMIN_ORDER_NEW_SUCCESS,
        payload: payload,
    };
}
export function requestGetNextCustomerOrderNew() {
    return {
        type: OrderTypes.GET_NEXT_CUSTOMER_ORDER_NEW_REQUEST,
    };
}
export function successGetNextCustomerOrderNew(payload) {
    return {
        type: OrderTypes.GET_NEXT_CUSTOMER_ORDER_NEW_SUCCESS,
        payload: payload,
    };
}
export function requestGetNextTranslatorOrderNew() {
    return {
        type: OrderTypes.GET_NEXT_TRANSLATOR_ORDER_NEW_REQUEST,
    };
}
export function successGetNextTranslatorOrderNew(payload) {
    return {
        type: OrderTypes.GET_NEXT_TRANSLATOR_ORDER_NEW_SUCCESS,
        payload: payload,
    };
}
export function requestGetNextDesktopOrder() {
    return {
        type: OrderTypes.GET_NEXT_DESKTOP_ORDER_REQUEST,
    };
}
export function successGetNextDesktopOrder(payload) {
    return {
        type: OrderTypes.GET_NEXT_DESKTOP_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestDetectLanguage() {
    return {
        type: OrderTypes.DETECT_LANGUAGE_REQUEST,
    };
}
export function successDetectLanguage(payload) {
    return {
        type: OrderTypes.DETECT_LANGUAGE_REQUEST,
        payload: payload,
    };
}
export function requestReadMessages() {
    return {
        type: OrderTypes.READ_MESSAGES_REQUEST,
    };
}
export function successReadMessages(payload) {
    return {
        type: OrderTypes.READ_MESSAGES_SUCCESS,
        payload: payload,
    };
}
export function requestProlongOrder() {
    return {
        type: OrderTypes.PROLONG_ORDER_REQUEST,
    };
}
export function successProlongOrder(payload) {
    return {
        type: OrderTypes.PROLONG_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestCompleteOrder() {
    return {
        type: OrderTypes.COMPLETE_ORDER_REQUEST,
    };
}
export function successCompleteOrder(payload) {
    return {
        type: OrderTypes.COMPLETE_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestCompleteOrderFile() {
    return {
        type: OrderTypes.COMPLETE_ORDER_FILE_REQUEST,
    };
}
export function successCompleteOrderFile(payload) {
    return {
        type: OrderTypes.COMPLETE_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateArchive() {
    return {
        type: OrderTypes.UPDATE_ARCHIVE_REQUEST,
    };
}
export function successUpdateArchive(payload) {
    return {
        type: OrderTypes.UPDATE_ARCHIVE_SUCCESS,
        payload: payload,
    };
}
export function requestAcceptGroup() {
    return {
        type: OrderTypes.ACCEPT_GROUP_REQUEST,
    };
}
export function successAcceptGroup(payload) {
    return {
        type: OrderTypes.ACCEPT_GROUP_SUCCESS,
        payload: payload,
    };
}
export function requestFinishEditOrder() {
    return {
        type: OrderTypes.FINISH_EDIT_ORDER_REQUEST,
    };
}
export function successFinishEditOrder(payload) {
    return {
        type: OrderTypes.FINISH_EDIT_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestFinishEditOrderFile() {
    return {
        type: OrderTypes.FINISH_EDIT_ORDER_FILE_REQUEST,
    };
}
export function successFinishEditOrderFile(payload) {
    return {
        type: OrderTypes.FINISH_EDIT_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestFinishEditorOrder() {
    return {
        type: OrderTypes.FINISH_EDITOR_ORDER_REQUEST,
    };
}
export function successFinishEditorOrder(payload) {
    return {
        type: OrderTypes.FINISH_EDITOR_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestFinishEditorOrderFile() {
    return {
        type: OrderTypes.FINISH_EDITOR_ORDER_FILE_REQUEST,
    };
}
export function successFinishEditorOrderFile(payload) {
    return {
        type: OrderTypes.FINISH_EDITOR_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestRevisionOrder() {
    return {
        type: OrderTypes.REVISION_ORDER_REQUEST,
    };
}
export function successRevisionOrder(payload) {
    return {
        type: OrderTypes.REVISION_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestRevisionOrderAdmin() {
    return {
        type: OrderTypes.REVISION_ORDER_ADMIN_REQUEST,
    };
}
export function successRevisionOrderAdmin(payload) {
    return {
        type: OrderTypes.REVISION_ORDER_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestFinishRevisionOrder() {
    return {
        type: OrderTypes.FINISH_REVISION_ORDER_REQUEST,
    };
}
export function successFinishRevisionOrder(payload) {
    return {
        type: OrderTypes.FINISH_REVISION_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestFinishRevisionOrderFile() {
    return {
        type: OrderTypes.FINISH_REVISION_ORDER_FILE_REQUEST,
    };
}
export function successFinishRevisionOrderFile(payload) {
    return {
        type: OrderTypes.FINISH_REVISION_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestUpdatePauseAdmin() {
    return {
        type: OrderTypes.UPDATE_PAUSE_ADMIN_REQUEST,
    };
}
export function successUpdatePauseAdmin(payload) {
    return {
        type: OrderTypes.UPDATE_PAUSE_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestSaveDraft() {
    return {
        type: OrderTypes.SAVE_DRAFT_REQUEST,
    };
}
export function successSaveDraft(payload) {
    return {
        type: OrderTypes.SAVE_DRAFT_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateAdvancedOptions() {
    return {
        type: OrderTypes.UPDATE_ADVANCED_OPTIONS_REQUEST,
    };
}
export function successUpdateAdvancedOptions(payload) {
    return {
        type: OrderTypes.UPDATE_ADVANCED_OPTIONS_SUCCESS,
        payload: payload,
    };
}
export function requestGetUnreadStatus() {
    return {
        type: OrderTypes.GET_UNREAD_STATUS_REQUEST,
    };
}
export function successGetUnreadStatus(payload) {
    return {
        type: OrderTypes.GET_UNREAD_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestListActionRequiredOrdersTranslator() {
    return {
        type: OrderTypes.LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_REQUEST,
    };
}
export function successListActionRequiredOrdersTranslator(payload) {
    return {
        type: OrderTypes.LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestCreateOrder() {
    return {
        type: OrderTypes.CREATE_ORDER_REQUEST,
    };
}
export function successCreateOrder(payload) {
    return {
        type: OrderTypes.CREATE_ORDER_SUCCESS,
        payload: payload,
    };
}
export function requestCreateOrderFile() {
    return {
        type: OrderTypes.CREATE_ORDER_FILE_REQUEST,
    };
}
export function successCreateOrderFile(payload) {
    return {
        type: OrderTypes.CREATE_ORDER_FILE_SUCCESS,
        payload: payload,
    };
}
export function requestListOrdersWithDraftAdmin() {
    return {
        type: OrderTypes.LIST_ORDERS_WITH_DRAFT_ADMIN_REQUEST,
    };
}
export function successListOrdersWithDraftAdmin(payload) {
    return {
        type: OrderTypes.LIST_ORDERS_WITH_DRAFT_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestListOrdersWithDraftTranslator() {
    return {
        type: OrderTypes.LIST_ORDERS_WITH_DRAFT_TRANSLATOR_REQUEST,
    };
}
export function successListOrdersWithDraftTranslator(payload) {
    return {
        type: OrderTypes.LIST_ORDERS_WITH_DRAFT_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestListDoneOrders() {
    return {
        type: OrderTypes.GET_DONE_ORDERS_REQUEST,
    };
}
export function successListDoneOrders(payload) {
    return {
        type: OrderTypes.GET_DONE_ORDERS_SUCCESS,
        payload: payload,
    };
}
export function requestListIncompleteOrders() {
    return {
        type: OrderTypes.GET_INCOMPLETE_ORDERS_REQUEST,
    };
}
export function successListIncompleteOrders(payload) {
    return {
        type: OrderTypes.GET_INCOMPLETE_ORDERS_SUCCESS,
        payload: payload,
    };
}
export function requestListQueueOrders() {
    return {
        type: OrderTypes.GET_QUEUE_ORDERS_REQUEST,
    };
}
export function successListQueueOrders(payload) {
    return {
        type: OrderTypes.GET_QUEUE_ORDERS_SUCCESS,
        payload: payload,
    };
}
export function requestListArchivedOrders() {
    return {
        type: OrderTypes.GET_ARCHIVED_ORDERS_REQUEST,
    };
}
export function successListArchivedOrders(payload) {
    return {
        type: OrderTypes.GET_ARCHIVED_ORDERS_SUCCESS,
        payload: payload,
    };
}
export function requestRestartFileBuild() {
    return {
        type: OrderTypes.RESTART_FILE_BUILD_REQUEST,
    };
}
export function successRestartFileBuild(payload) {
    return {
        type: OrderTypes.RESTART_FILE_BUILD_SUCCESS,
        payload: payload,
    };
}
export var requestGetLexiqa = function () { return ({ type: OrderTypes.GET_LEXIQA_REQUEST }); };
export var failedGetLexiqa = function () { return ({ type: OrderTypes.GET_LEXIQA_FAILED }); };
export var successGetLexiqa = function (payload) {
    return {
        type: OrderTypes.GET_LEXIQA_SUCCESS,
        payload: payload,
    };
};
