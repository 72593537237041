import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import styles from "./ImageLocal.css";

class ImageLocal extends Component {
  render() {
    const { image, onClick } = this.props;

    return (
      <div styleName={"styles.container"}>
        <img onClick={e => onClick(e)} styleName={"styles.img"} src={image} />
      </div>
    );
  }
}

export default ImageLocal;
