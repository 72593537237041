//docs https://developers.intercom.com/installing-intercom/docs/intercom-javascript
import { I18n } from "react-redux-i18n";

export const bootChat = intercomSettings => window.Intercom("boot", intercomSettings);

export const shutdownChat = () => window.Intercom("shutdown");

export const updateChat = updateObject => window.Intercom("update", updateObject);

export const hideDefaultLauncher = () => updateChat({ hide_default_launcher: true });

export const showDefaultLauncher = () => updateChat({ hide_default_launcher: false });

export const hideChat = () => window.Intercom("hide");

export const showChat = () => window.Intercom("show");

export const showMessagesChat = () => window.Intercom("showMessages");

export const showNewMessageChat = template => window.Intercom("showNewMessage", template);

export const onHideChat = onHideFunction => window.Intercom("onHide", onHideFunction);

export const onShowChat = onShowFunction => window.Intercom("onShow", onShowFunction);

export const onUnreadCountChangeChat = onUnreadCountChangeFunction =>
  window.Intercom("onUnreadCountChange", onUnreadCountChangeFunction);

export const trackEventChat = (event, metadata) => window.Intercom("trackEvent", event, metadata);

export const getVisitorIdChat = () => window.Intercom("getVisitorId");

export const orderTemplateToChat = order => {
  if (order) {
    const orderText = order.sourceText;
    const removeNewLine = orderText.replace(/(\r\n|\n|\r)/gm, " ");
    const removeManySpace = removeNewLine.replace(/\s+/g, " ");

    const id = order.id;
    const maxLengthTextWithoutEllipsis = 32;
    let snippet = removeManySpace;
    if (orderText && orderText.length > maxLengthTextWithoutEllipsis) {
      snippet = snippet.substring(0, 29) + "...";
    }
    return I18n.t("SUPPORT.REGARDING_ORDER_NEW_CHAT_TEMPLATE_MESSAGE", {
      id,
      snippet,
    });
  }
};
