import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { formatDate } from "../../../helpers/formatDate";

const parseInputValue = value => value.split(" - ");

const getDatePeriodAsString = (user, name) => {
  const { [`start${name}Period`]: startValue, [`end${name}Period`]: endValue } = user;
  const hasPeriod = startValue && endValue;

  if (!hasPeriod) return "";

  const start = formatDate(startValue).format("DD/MM/YYYY");
  const end = formatDate(endValue).format("DD/MM/YYYY");

  return start + " - " + end;
};

const parseDateToTimestamp = (stringDate, isEndDate = false) => {
  const timestamp = new Timestamp();
  let date = moment.utc(stringDate, "DD/MM/YYYY");

  if (isEndDate) {
    date = date.endOf("day");
  } else {
    date = date.startOf("day");
  }

  timestamp.fromDate(date.toDate());
  return timestamp;
};

export { parseInputValue, getDatePeriodAsString, parseDateToTimestamp };
