import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["coupons"]: { status: "", data: { hasmore: false } },
  ["users"]: { status: "", data: { hasmore: false } },
};

const getCouponsRequest = createThunkReducer("coupons", "GET_COUPONS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getCouponUsers = createThunkReducer("users", "GET_COUPON_USERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const couponsReducer = reduceReducers(initialReducer, getCouponsRequest, getCouponUsers);
