var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { getOnOpenHandler, getOnCloseHandler, getOnReconnectStopHandler, ECHO, PRICE, RECONNECT_INTERVAL_S, checkIsTokenValid, } from "./helpers";
var WebsocketContext = React.createContext({
    send: function (message) { },
    isWSPriceOpened: false,
    message: "",
    openWsPrice: function () { },
    closeWsPrice: function () { },
});
var WebsocketProvider = function (props) {
    var token = useSelector(function (state) { return state.app.token; });
    var isTokenLoading = useSelector(function (state) { return state.app.isTokenLoading; });
    var _a = useState(false), shouldConnectWSPrice = _a[0], setShouldConnectWSPrice = _a[1];
    var _b = useState(""), message = _b[0], setMessage = _b[1];
    var onMessage = function (event) {
        var message = JSON.parse(event.data);
        setMessage(message);
    };
    useWebSocket(token && !isTokenLoading ? process.env.WEBSOCKET_ECHO_URL : null, {
        protocols: ["bearer", token],
        onOpen: getOnOpenHandler(ECHO),
        onClose: getOnCloseHandler(ECHO),
        onMessage: onMessage,
        shouldReconnect: function () {
            if (!token)
                return false;
            var isTokenValid = checkIsTokenValid(token);
            return isTokenValid;
        },
        reconnectInterval: RECONNECT_INTERVAL_S * 1000,
        reconnectAttempts: 10,
        onReconnectStop: getOnReconnectStopHandler(ECHO),
    });
    var _c = useWebSocket(process.env.WEBSOCKET_PRICE_URL, {
        onOpen: getOnOpenHandler(PRICE),
        onClose: getOnCloseHandler(PRICE),
        onMessage: onMessage,
        shouldReconnect: function () { return true; },
        reconnectInterval: RECONNECT_INTERVAL_S * 1000,
        reconnectAttempts: 10,
        onReconnectStop: getOnReconnectStopHandler(PRICE),
    }, shouldConnectWSPrice), sendWSPrice = _c.sendMessage, WSPriceState = _c.readyState;
    var openWSPriceConnection = function () {
        setShouldConnectWSPrice(true);
    };
    var closeWSPriceConnection = function () {
        setShouldConnectWSPrice(false);
    };
    var sendPrice = function (message) {
        if (WSPriceState !== WebSocket.OPEN)
            return;
        var messageJSON = JSON.stringify(message);
        sendWSPrice(messageJSON);
    };
    return (_jsx(WebsocketContext.Provider, __assign({ value: {
            send: sendPrice,
            isWSPriceOpened: WSPriceState === WebSocket.OPEN,
            message: message,
            openWsPrice: openWSPriceConnection,
            closeWsPrice: closeWSPriceConnection,
        } }, props)));
};
function useWS() {
    var context = React.useContext(WebsocketContext);
    if (context === undefined) {
        throw new Error("useWS must be used within a WebsocketProvider");
    }
    return context;
}
function withWebsocket(Component) {
    function ComponentWithWebsocketProp(props) {
        var ws = useWS();
        //todo: remove after or during redesign
        return _jsx(Component, __assign({}, props, { ws: ws }));
    }
    return ComponentWithWebsocketProp;
}
export { WebsocketContext, useWS, withWebsocket };
export default WebsocketProvider;
