import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["translationMemories"]: { status: "", data: { hasmore: false } },
  ["translationMemoriesByUserId"]: { status: "", data: { hasmore: false } },
};

const getTranslationMemoriesRequest = createThunkReducer("translationMemories", "GET_TRANSLATION_MEMORIES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getTranslationMemoriesByUserIdRequest = createThunkReducer(
  "translationMemoriesByUserId",
  "GET_TRANSLATION_MEMORIES_BY_USER_ID",
  {
    handleSuccessAction: (state, payload) => {
      return { requestState: { data: payload } };
    },
  }
);

const updateTranslationUnitRequest = createThunkReducer("translationMemoriesByUserId", "UPDATE_TRANSLATION_UNIT", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const deleteTranslationUnitRequest = createThunkReducer("translationMemoriesByUserId", "DELETE_TRANSLATION_UNIT", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const translationMemoriesReducer = reduceReducers(
  initialReducer,
  getTranslationMemoriesRequest,
  getTranslationMemoriesByUserIdRequest,
  updateTranslationUnitRequest,
  deleteTranslationUnitRequest
);
