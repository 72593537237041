import { FAIL_BALANCE, SUCCEED_BALANCE, REQUEST_BALANCE, BALANCE_FETCHED, BALANCE_ERROR } from "../constants/User";

const initialState = {
  scrollPosition: {
    x: 0,
    y: 0,
  },
  status: null,
  balance: 0,
};

export default function balance(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BALANCE:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_BALANCE:
      return {
        ...state,
        status: BALANCE_FETCHED,
        balance: action.balance,
      };
    case FAIL_BALANCE:
      return {
        ...state,
        status: BALANCE_ERROR,
        error: action.error.message,
      };
    case "CHANGE_BALANCE_TABLE_STATE":
      return {
        ...state,
        scrollPosition: action.state.scrollPosition,
      };
    default:
      return state;
  }
}
