import { SignupTypes } from "./signupActionTypes";
export function requestResendConfirmEmail() {
    return {
        type: SignupTypes.RESEND_CONFIRM_EMAIL_REQUEST,
    };
}
export function successResendConfirmEmail(payload) {
    return {
        type: SignupTypes.RESEND_CONFIRM_EMAIL_SUCCESS,
        payload: payload,
    };
}
export function requestConfirmSignup() {
    return {
        type: SignupTypes.CONFIRM_SIGNUP_REQUEST,
    };
}
export function successConfirmSignup(payload) {
    return {
        type: SignupTypes.CONFIRM_SIGNUP_SUCCESS,
        payload: payload,
    };
}
export function requestConfirmResetPassword() {
    return {
        type: SignupTypes.CONFIRM_RESET_PASSWORD_REQUEST,
    };
}
export function successConfirmResetPassword(payload) {
    return {
        type: SignupTypes.CONFIRM_RESET_PASSWORD_SUCCESS,
        token: payload,
    };
}
export function failureConfirmResetPassword(error) {
    return {
        type: SignupTypes.CONFIRM_RESET_PASSWORD_FAILURE,
        error: error,
    };
}
