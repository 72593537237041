export function changeTableStateOrders(status, state) {
  return {
    type: "CHANGE_ORDERS_TABLE_STATE",
    status,
    state,
  };
}

export function scrollToItem(itemId) {
  return {
    type: "SCROLL_TO_ITEM",
    itemId,
  };
}
