import { createThunkReducer } from "../../helpers/createThunkReducer";
import { CREATE_CUSTOMER_RATE } from "../../constants/Rates";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["rates"]: { status: "", data: { hasmore: false } },
  ["allRates"]: { status: "", data: {} },
  ["createTranslatorRate"]: { status: "" },
};

const createCustomerRateRequest = createThunkReducer("createTranslatorRate", CREATE_CUSTOMER_RATE, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getCustomerRateRequest = createThunkReducer("rates", "GET_CUSTOMER_RATES_LIST", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getAllCustomerRateRequest = createThunkReducer("allRates", "GET_ALL_CUSTOMER_RATES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const customerRatesReducer = reduceReducers(
  initialReducer,
  createCustomerRateRequest,
  getCustomerRateRequest,
  getAllCustomerRateRequest
);
