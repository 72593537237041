export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const GET_PAYOUTS = "GET_PAYOUTS";
export const GET_PAYOUT_INCOMPLETE_COUNT = "GET_PAYOUT_INCOMPLETE_COUNT";
export const PROCESS_PAYOUT = "PROCESS_PAYOUT";
export const CREATE_PAYOUT = "CREATE_PAYOUT";
export const DELETE_PAYOUTS = "DELETE_PAYOUTS";
export const GET_PAYOUT_BY_ID = "GET_PAYOUT_BY_ID";
export const CONFIRM_PAYOUT = "CONFIRM_PAYOUT";
export const SEND_PAYOUTS = "SEND_PAYOUTS";
