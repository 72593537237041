import {
  REQUEST_TRANSACTIONS,
  SUCCEED_REFRESH_TRANSACTIONS,
  SUCCEED_TRANSACTIONS,
  FAIL_TRANSACTIONS,
  REFRESH_TRANSACTIONS,
} from "../constants/Transaction";

import {
  TRANSACTIONS_FETCHED,
  TRANSACTIONS_FETCHING,
  TRANSACTIONS_REFRESHING,
  TRANSACTIONS_ERROR,
} from "../constants/Transaction";

const initialState = {
  items: [],
  hasMore: false,
  status: "",
  lastTransactionTime: null,
  newOrdersCount: 0,
  error: null,
};

export default function transactions(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TRANSACTIONS:
      return {
        ...state,
        status: TRANSACTIONS_FETCHING,
        newOrdersCount: 0,
        error: null,
      };
    case REFRESH_TRANSACTIONS:
      return {
        ...state,
        status: TRANSACTIONS_REFRESHING,
        error: null,
      };
    case SUCCEED_TRANSACTIONS:
      if (state.items.length > 0) {
        action.transactions.forEach(value => {
          value.newItem = true;
        });
      }
      return {
        ...state,
        status: TRANSACTIONS_FETCHED,
        items: action.transactions,
        hasMore: action.hasMore,
        lastTransactionTime:
          action.transactions.length > 0 &&
          (action.transactions[0].time > state.lastTransactionTime || !state.lastTransactionTime)
            ? action.transactions[0].time
            : state.lastTransactionTime,
      };
    case SUCCEED_REFRESH_TRANSACTIONS:
      let newItems = 0;
      action.transactions.forEach(value => {
        if (value.time > state.lastTransactionTime) {
          value.newItem = true;
          newItems++;
        }
      });
      return {
        ...state,
        status: TRANSACTIONS_FETCHED,
        items: action.transactions,
        hasMore: action.hasMore,
        lastTransactionTime: action.transactions[0] ? action.transactions[0].time : null,
        newTransactionsCount: newItems,
      };
    case FAIL_TRANSACTIONS:
      return {
        ...state,
        status: TRANSACTIONS_ERROR,
        error: action.error.message,
      };
    default:
      return state;
  }
}
