// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageLocal__container__PEw5fF {
  margin: 12px 0;
  min-width: 48px;
  min-height: 48px;
}

.ImageLocal__img__eYtL_c {
  width: 50%;
  height: auto;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ImageLocal__container__PEw5fF`,
	"img": `ImageLocal__img__eYtL_c`
};
export default ___CSS_LOADER_EXPORT___;
