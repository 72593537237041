import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import toolbar from "./css/refresh.css";
import { I18n } from "react-redux-i18n";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

export default class Refresh extends Component {
  render() {
    const { onClick } = this.props;
    const refresh = I18n.t("HEADER.REFRESH_BUTTON");
    return (
      <a styleName={"toolbar.refresh-icon"} onClick={onClick} title={refresh}>
        <IcomoonReact iconSet={iconSet} color={"#1E88E5"} size={24} icon={"refresh"} />
      </a>
    );
  }
}
