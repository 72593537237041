// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewPopup__wrapper__Yd_qBS {
  position: relative;
  max-width: 575px;
  width: 100%;
}

.previewPopup__description__Abu_Gj {
	color: #212121;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 21px;
	padding-top: 2px;
}

.previewPopup__menuContainer__sZjSPC {
	flex-direction: column;
	align-items: flex-start;
}

.previewPopup__menuLabel__vMdcCN {
	margin-top: 15px;
	margin-bottom: 8px;
	color: #424242;
	font-family: Roboto, sans-serif;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
}

.previewPopup__button-close__mFN6sB {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  right: 12px;
  top: -12px;
  width: 10px;
  height: 10px;
}

.previewPopup__button-close__mFN6sB:hover {
  opacity: 0.8;
}

.previewPopup__button-close__mFN6sB:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #212121;
  transform: rotate(45deg);
  left: 4px;
  bottom: -2px;
}

.previewPopup__button-close__mFN6sB:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #212121;
  transform: rotate(135deg);
  right: 5px;
  top: -1px;
}

.previewPopup__fileTitle__DCT2Ca {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #212121;
  margin-bottom: 6px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.previewPopup__segmentList__GGNVyM {
  display: flex;
  flex-direction: column;
  max-height: 550px;
  border-top: none;
}

.previewPopup__segment__s0In7C {
  border-top: 1px solid #e0e0e0;
  display: flex;
    max-width: 575px;
}

.previewPopup__segmentId__n2wNIM {
  background-color: #f5f5f5;
  color: #9e9e9e;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  min-width: 24px;
  padding-top: 8px;
  text-align: center;
}

.previewPopup__segmentText__h_CBUj {
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  padding: 8px 16px;
  width: 100%;
  max-width: 550px;
  margin: 0;
  word-break: break-all;
}

.previewPopup__header__fnI8CO {
  min-width: 24px;
  padding: 8px 41px;
  background-color: #f5f5f5;
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  top: 0;
  left: 0;
}

.previewPopup__total__dJFr_r {
  margin: 0;
  padding-top: 8px;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0px;
  color: #9e9e9e;
}

.previewPopup__buttonsWrapper__dSCPX6 {
    padding: 16px 0 0 0;
}

.previewPopup__clearButton__HrPydn {
    cursor: pointer;
    height: 24px;
    width: 65px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 24px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    display: inline-block;
    margin-left: 5px;
    border: none;
    background-color: transparent;
    color: #9E9E9E
}

.previewPopup__clearButton__HrPydn:active {
    outline: none !important;
}

.previewPopup__clearButton__HrPydn:focus {
    box-shadow: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `previewPopup__wrapper__Yd_qBS`,
	"description": `previewPopup__description__Abu_Gj`,
	"menuContainer": `previewPopup__menuContainer__sZjSPC`,
	"menuLabel": `previewPopup__menuLabel__vMdcCN`,
	"button-close": `previewPopup__button-close__mFN6sB`,
	"fileTitle": `previewPopup__fileTitle__DCT2Ca`,
	"segmentList": `previewPopup__segmentList__GGNVyM`,
	"segment": `previewPopup__segment__s0In7C`,
	"segmentId": `previewPopup__segmentId__n2wNIM`,
	"segmentText": `previewPopup__segmentText__h_CBUj`,
	"header": `previewPopup__header__fnI8CO`,
	"total": `previewPopup__total__dJFr_r`,
	"buttonsWrapper": `previewPopup__buttonsWrapper__dSCPX6`,
	"clearButton": `previewPopup__clearButton__HrPydn`
};
export default ___CSS_LOADER_EXPORT___;
