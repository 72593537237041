import React, { Component } from "react";
import _ from "lodash";
import Textarea from "react-textarea-autosize";
// eslint-disable-next-line no-unused-vars
import area from "./css/area.css";

const getAreaStyleName = type => {
  const styleName = {
    inputNewOrder: "area.inputNewOrder",
    default: "area.input",
  };

  return styleName[type] || styleName["default"];
};

export default class TextareaAutoresize extends Component {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();
    this.state = {
      value: "",
      minRows: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { placeholder, value, borderColor, isUploadFilePopoverOpen } = this.props;
    return (
      placeholder !== nextProps.placeholder ||
      value !== nextProps.value ||
      borderColor !== nextProps.borderColor ||
      this.props.onFocus !== nextProps.onFocus ||
      this.state.value !== nextState.value ||
      this.props.initValue !== nextProps.initValue ||
      this.state.minRows !== nextState.minRows ||
      isUploadFilePopoverOpen !== nextProps.isUploadFilePopoverOpen
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initValue && this.props.initValue) {
      this.setState({
        value: this.props.initValue,
      });
    }
    if (this.props.onFocus && !prevProps.onFocus && this.props.onFocus !== prevProps.onFocus) {
      this.textarea.current.focus();
    }
  }

  updateAutosizeTextArea = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.autoFocus && this.textarea.current.focus();

    if (this.props.initValue) {
      this.setState({
        value: this.props.initValue,
      });
    }

    const ref = this.textarea.current;
    if (this.props.correctionHeight && ref) {
      setTimeout(() => {
        if ((!this.state.height || ref.scrollHeight > ref.clientHeight) && ref.scrollHeight) {
          this.setState({
            height: this.textarea.current.scrollHeight,
          });
        }
      }, 200);
    }
  }

  handleClickOutside(event) {
    if (this.textarea && !this.textarea.current.contains(event.target)) {
      this.textarea.current.blur();
    }
  }
  onFocus = () => {
    this.props.onFocusTextArea && this.props.onFocusTextArea(true);
  };

  onBlur = e => {
    const value = this.props.isBlurValue && e;
    this.props.onFocusTextArea && this.props.onFocusTextArea(false, value);
  };

  onChange = e => {
    this.props.onChange(e);
    this.setState({
      value: e.target.value,
    });
  };

  onKeyUp = e => {
    if (e.key === "Backspace") {
      this.onChange(e);
    }
  };

  render() {
    const {
      placeholder,
      value,
      textAreaStyle,
      backgroundColor,
      styleClass,
      styleContainer,
      areaStyleName,
      isOnValue,
      borderColor,
      initValue,
      customPlaceholder,
      correctionHeight,
      maxRows,
    } = this.props;
    const style = {
      minRows: 1,
      backgroundColor: backgroundColor && backgroundColor,
      borderColor: borderColor && borderColor,
    };
    let textArea = textAreaStyle ? _.assign(style, textAreaStyle) : style;
    if (correctionHeight && this.state.height) {
      textArea = { ...textArea, minRows: this.state.minRows };
      if (value && value.length === 0) {
        textArea = { ...textArea, maxRows: this.state.height };
      }
    }
    const styleName = areaStyleName ? getAreaStyleName(areaStyleName) : styleClass ? "area.input2" : "area.input";

    return (
      <div style={{ ...styleContainer, width: "100%" }}>
        {customPlaceholder && !this.state.value && <div>{customPlaceholder}</div>}
        <Textarea
          dir="auto"
          tabIndex={this.props.tabIndex}
          ref={this.textarea}
          styleName={styleName}
          style={textArea}
          placeholder={placeholder && placeholder}
          value={isOnValue && value}
          defaultValue={initValue && initValue}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          id="uploadImage"
          minRows={textArea.minRows}
          maxRows={maxRows || Infinity}
        />
      </div>
    );
  }
}
