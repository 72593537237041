import React, { memo, useState, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import preview from "./css/previewPopup.css";
import Popup from "../../../elements/Popup";
import { userFormSelectOption, userFormSelectStyle } from "../../Select/userForm";
import DefaultSelect from "../../Select";
import { uploadFileFromComputer } from "../../../../thunks/customer/Attachments";
import { useDispatch } from "react-redux";
import NitroButton from "../../GreenButton";

const stylePopup = {
  maxWidth: 607,
  width: "100%",
  padding: "0px 16px 24px",
  borderRadius: 6,
};

const confirmButtonStyles = {
  color: "#FFF",
  fontFamily: "Roboto, sans-serif",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  height: 32,
  minWidth: 98,
  padding: "8px 24px",
};

const containerStyle = isTXT => {
  return {
    marginTop: 18,
    border: "1px solid #E0E0E0",
    maxHeight: isTXT ? 375 : 500,
  };
};

const getOptionByFileEncoding = file => {
  return { value: file.encoding, label: file.encoding };
};

const DEFAULT_OPTIONS = [
  { value: "UTF-8", label: "UTF-8" },
  { value: "ISO-2022-JP", label: "ISO-2022-JP" },
  { value: "ISO-2022-CN", label: "ISO-2022-CN" },
  { value: "ISO-2022-KR", label: "ISO-2022-KR" },
  { value: "ISO-8859-1", label: "ISO-8859-1" },
  { value: "ISO-8859-5", label: "ISO-8859-5" },
  { value: "ISO-8859-7", label: "ISO-8859-7" },
  { value: "ISO-8859-8", label: "ISO-8859-8" },
  { value: "BIG5", label: "BIG5" },
  { value: "EUC-JP", label: "EUC-JP" },
  { value: "EUC-KR", label: "EUC-KR" },
  { value: "EUC-TW", label: "EUC-TW" },
  { value: "SHIFT_JIS", label: "SHIFT_JIS" },
  { value: "IBM855", label: "IBM855" },
  { value: "IBM866", label: "IBM866" },
  { value: "KOI8-R", label: "KOI8-R" },
  { value: "MACCYRILLIC", label: "MACCYRILLIC" },
  { value: "WINDOWS-1251", label: "WINDOWS-1251" },
  { value: "WINDOWS-1252", label: "WINDOWS-1252" },
  { value: "WINDOWS-1253", label: "WINDOWS-1253" },
  { value: "WINDOWS-1255", label: "WINDOWS-1255" },
  { value: "UTF-16BE", label: "UTF-16BE" },
  { value: "UTF-16LE", label: "UTF-16LE" },
  { value: "UTF-32BE", label: "UTF-32BE" },
  { value: "UTF-32LE", label: "UTF-32LE" },
];

export const PopupPreview = ({
  isOpen,
  onClose,
  sourceLanguage,
  file,
  setFile,
  setCharsAmount,
  handleChangeSourceLanguage,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isTXTFile, setIsTXTFile] = useState(false);
  const [lastConfirmedFile, setLastConfirmedFile] = useState(null);

  useEffect(() => {
    if (!lastConfirmedFile && file) setLastConfirmedFile(file);
  }, []);

  useEffect(() => {
    setIsTXTFile(file.type === "TXT");
    const defaultFileEncoding = options.find(option => option.value === file.encoding);
    setSelectedOption(defaultFileEncoding || getOptionByFileEncoding(file));
    if (!defaultFileEncoding) setOptions([getOptionByFileEncoding(file), ...DEFAULT_OPTIONS]);
  }, [file, setSelectedOption, setIsTXTFile, setOptions]);

  const updateFile = data => {
    const { content, encoding, uuid, volume, language } = data;

    const updatedFile = {
      ...file,
      content,
      encoding,
      uuid,
      volume,
      language,
    };

    setFile(updatedFile);
    setCharsAmount(volume);
    handleChangeSourceLanguage();
  };

  const getFileWithNewEncoding = payload => {
    return dispatch(uploadFileFromComputer(payload, null, false, true))
      .then(data => updateFile(JSON.parse(data)))
      .catch(e => {
        console.log("error", e);
        // e.error.text.then(errorText => {
        //   console.log({errorText})
        // })
      })
      .finally();
  };

  const getPayloadForFileWithNewEncoding = file => {
    const payload = {
      name: file.filename,
      content: file.filesAsFormattedText,
      type: file.type,
      uuid: file?.draftId ? undefined : file.uuid,
      encoding: file.encoding,
    };

    return payload;
  };

  const handleOptionChange = option => {
    setSelectedOption(option);

    const payload = getPayloadForFileWithNewEncoding({ ...file, encoding: option.value });

    getFileWithNewEncoding(payload);
  };

  const handleConfirmClick = () => {
    setLastConfirmedFile(file);
    onClose();
  };

  const handleCloseClick = async () => {
    if (!isTXTFile) {
      onClose();
      return;
    }

    if (lastConfirmedFile) {
      const payload = getPayloadForFileWithNewEncoding(lastConfirmedFile);

      await getFileWithNewEncoding(payload);
    }

    onClose();
  };

  const language =
    sourceLanguage && sourceLanguage.value
      ? I18n.t("LANGUAGES." + sourceLanguage.value)
      : I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.SOURCE");

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleCloseClick}
      popupTitle={file.filename}
      isHeader={
        <div>
          <p className={preview.fileTitle}>{file.filename}</p>
          <p className={preview.description}>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.DESCRIPTION")}</p>
          {isTXTFile && (
            <>
              <p className={preview.description}>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.EXPLANATION")}</p>
              <div className={preview.menuContainer}>
                <div className={preview.menuLabel}>
                  {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.ENCODING_FIELD_TITLE")}
                </div>
                <DefaultSelect
                  selectStyle={userFormSelectStyle}
                  selectOptionStyle={userFormSelectOption}
                  options={options}
                  value={selectedOption}
                  onChange={handleOptionChange}
                />
              </div>
            </>
          )}
        </div>
      }
      isFooter={
        <div>
          <p className={preview.total}>
            {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.TOTAL_AMOUNT_CHARS", {
              amount: file.volume,
            })}
          </p>
          {isTXTFile && (
            <div className={preview.buttonsWrapper}>
              <NitroButton
                clickHandler={handleConfirmClick}
                style={confirmButtonStyles}
                title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.CONFIRM")}
              />
              <button className={preview.clearButton} onClick={handleCloseClick}>
                {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PREVIEW_POPUP.CANCEL")}
              </button>
            </div>
          )}
        </div>
      }
      style={stylePopup}
      containerStyle={containerStyle(isTXTFile)}
    >
      <div className={preview.wrapper}>
        <div className={preview.header}>{language}</div>
        <div className={preview.segmentList}>
          {file.content.map((elem, index) => {
            return (
              <div key={elem + index} className={preview.segment}>
                <div className={preview.segmentId}>{index + 1}</div>
                <p className={preview.segmentText}>{elem}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default memo(PopupPreview);
