import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";

import classnames from "classnames";
import modal from "./css/review-modal.css";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

import NitroButton from "../GreenButton";
import { saveCustomerReviewAction, getMeAction } from "../../../thunks/newThunks/user/userAsyncActions";

const starOptions = [1, 2, 3, 4, 5];

export default function ReviewModal() {
  const needCustomerReview = useSelector(state => state.me.me.data.needCustomerReview);
  const dispatch = useDispatch();

  const [isReviewVisible, setReviewVisible] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [selectedStar, setSelectedStar] = useState(0);
  const [isStarHovered, setStarHovered] = useState(false);
  const [starHoverIndex, setStarHoverIndex] = useState(0);

  useEffect(() => {
    setReviewVisible(needCustomerReview);
  }, [needCustomerReview]);

  const handleCloseModal = () => {
    dispatch(saveCustomerReviewAction({ mark: 0, text: "" })).then(() => {
      dispatch(getMeAction());
    });
    setReviewVisible(false);
  };

  const handleConfirmModal = () => {
    let reviewData = null;

    if (selectedStar && !commentText) {
      reviewData = { mark: selectedStar, text: "" };
    } else if (commentText && !selectedStar) {
      reviewData = { mark: 0, text: commentText };
    } else if (commentText && selectedStar) {
      reviewData = { mark: selectedStar, text: commentText };
    }

    if (reviewData) {
      dispatch(saveCustomerReviewAction(reviewData)).then(() => {
        dispatch(getMeAction());
      });
    }

    setReviewVisible(false);
  };

  const handleCommentChange = e => {
    const value = e.target.value;
    setCommentText(value);
  };

  const handleStarClick = star => {
    setSelectedStar(star);
  };

  const handleMouseEnter = starHoverIndex => {
    setStarHovered(true);
    setStarHoverIndex(starHoverIndex);
  };

  const handleMouseLeave = () => {
    setStarHovered(false);
  };

  let disabledButton = commentText || selectedStar ? false : true;
  return (
    <div
      className={classnames(modal.containerModal, {
        [modal.slideInBottom]: isReviewVisible,
        [modal.slideOutBottom]: !isReviewVisible,
        [modal.hideModal]: !isReviewVisible,
      })}
    >
      <div className={modal.modalContent}>
        <div className={modal.closeModal} onClick={handleCloseModal}>
          <IcomoonReact iconSet={iconSet} color={"#616161"} size={10} icon={"close"} />
        </div>

        <h3 className={modal.title}>{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.REVIEW_MODAL.TITLE")}</h3>
        <div className={modal.rateContainer}>
          {starOptions.map((star, ind) => (
            <span key={`star-${star}`} className={modal.starPosition} onClick={() => handleStarClick(star)}>
              <IcomoonReact
                iconSet={iconSet}
                size={32}
                icon={"star_thin"}
                className={classnames(modal.notActive, {
                  [modal.activeStar]: star <= selectedStar,
                  [modal.hoveredStar]: isStarHovered && ind + 1 <= starHoverIndex,
                })}
                onMouseEnter={() => handleMouseEnter(ind + 1)}
                onMouseLeave={handleMouseLeave}
              />
            </span>
          ))}
        </div>
        <textarea
          style={{ resize: "none" }}
          className={modal.reviewTextarea}
          placeholder={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.REVIEW_MODAL.PLACEHOLDER")}
          value={commentText}
          onChange={handleCommentChange}
        />
        <NitroButton
          style={{ maxWidth: 98, fontSize: "14px" }}
          title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.REVIEW_MODAL.BUTTON")}
          buttonStyleName={"button.save-button"}
          clickHandler={handleConfirmModal}
          disabled={disabledButton}
        />
      </div>
    </div>
  );
}
