// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-success__formContainer__msDDbO {
  width: 308px;
  padding: 24px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  margin: 0 auto;
}

.signup-success__description__KCwQqO {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 16px;
}

.signup-success__codeInput__Jm6jGj {
  box-sizing: border-box;
  height: 32px;
  width: 260px;
  border: 1px solid #cecece;
  border-radius: 1px;
  background-color: #ffffff;
  padding: 8px;
  margin-bottom: 20px;
}

.signup-success__repeatCode__FrdErx {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 24px;
  display: flex;
}

.signup-success__repeatCode__FrdErx .signup-success__link__E14Yq5 {
    -webkit-text-decoration: underline;
    text-decoration: underline;
    border-bottom: none;
    color: #1e88e5;
  }

.signup-success__formAlt__khBDjK {
  padding: 32px 0 65px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `signup-success__formContainer__msDDbO`,
	"description": `signup-success__description__KCwQqO`,
	"codeInput": `signup-success__codeInput__Jm6jGj`,
	"repeatCode": `signup-success__repeatCode__FrdErx`,
	"link": `signup-success__link__E14Yq5`,
	"formAlt": `signup-success__formAlt__khBDjK`
};
export default ___CSS_LOADER_EXPORT___;
