import reduceReducers from "reduce-reducers";
import { DESC } from "../../constants/Sort";

const initialState = {
  tablesState: {
    images: [],
    search: "",
    editingBlocks: {
      isOpen: false,
      order: {},
    },
    scrollPosition: {
      x: 0,
      y: 0,
    },
    queue: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
      },
    },
    progress: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
      },
    },
    done: {
      loadOrdersLimit: 100,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 100,
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      showInputField: false,
      showButton: false,
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        createdTime: "",
        completedTime: "",
      },
    },
    archived: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      showInputField: false,
      showButton: false,
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        createdTime: "",
        completedTime: "",
      },
    },
    incomplete: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
      },
    },
    customer_draft: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      sortOrders: {
        id: "DRAFT_ID",
        type: DESC,
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
      },
    },
  },
  itemId: "",
};

function orders(state = initialState, action) {
  const statusIsEmpty = !action.status && action.state;
  switch (action.type) {
    case "CHANGE_ORDERS_TABLE_STATE":
      return {
        ...state,
        tablesState: {
          ...state.tablesState,
          ...statusIsEmpty,
          [action.status]: {
            ...state.tablesState[action.status],
            ...action.state,
          },
        },
      };
    case "SCROLL_TO_ITEM":
      return {
        ...state,
        itemId: action.itemId,
      };
    default:
      return state;
  }
}

export const customerOrdersReducer = reduceReducers(orders);
