import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import snackbar from "./css/snackbar.css";
import classnames from "classnames";
import { ERROR, NEW_SOURCE_VERSION, NOTIFICATION, WARNING, WARNING_ERROR } from "../../../constants/Notifications";
import HtmlTranslate from "../../HtmlTranslate";
import ClearCacheRefresh from "../../../helpers/ClearCacheRefresh";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

class Snackbar extends PureComponent {
  timerAutoHide = null;

  state = {
    open: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notification } = this.props;
    const { open } = this.state;

    if (!notification.stop && nextProps.notification.stop && open) {
      this.onClose();
      return;
    }

    if (nextProps.notification.id !== notification.id) {
      if (open) {
        this.timerAutoHide && clearTimeout(this.timerAutoHide);
        this.setAutoHideTimer(nextProps.notification.notificationType);
      } else {
        this.setState({ open: true }, () => {
          this.setAutoHideTimer();
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerAutoHide);
  }

  setAutoHideTimer = (customAutoHideDuration, newNotificationType) => {
    const { autoHideDuration } = this.props;
    const { notificationType, autoHide } = this.props.notification;
    const realNotificationType = newNotificationType != null ? newNotificationType : notificationType;
    const autoHideDurationBefore = customAutoHideDuration != null ? customAutoHideDuration : autoHideDuration;

    if (autoHideDurationBefore == null || (realNotificationType !== NOTIFICATION && !autoHide)) {
      return;
    }

    clearTimeout(this.timerAutoHide);

    this.timerAutoHide = setTimeout(() => {
      const autoHideDurationAfter = customAutoHideDuration != null ? customAutoHideDuration : autoHideDuration;

      if (autoHideDurationAfter == null || (realNotificationType !== NOTIFICATION && !autoHide)) {
        return;
      }
      this.setState({ open: false });
    }, autoHideDurationBefore);
  };

  onClose = () => {
    this.timerAutoHide && clearTimeout(this.timerAutoHide);
    this.setState({ open: false });
  };

  onRefreshClick = () => {
    window.location.reload();
  };

  render() {
    const { notificationType, message, withClose } = this.props.notification;
    //todo: refactor notification store in order to have common behavior for snackbar
    //      withClose
    //      withAutoHide
    //      notificationType
    //      etc.
    const mainClasses = classnames(
      { "snackbar.notification": notificationType === NOTIFICATION },
      {
        "snackbar.warning": notificationType === WARNING,
      },
      {
        "snackbar.error": notificationType === WARNING_ERROR,
      },
      {
        "snackbar.error": notificationType === ERROR,
      },
      {
        "snackbar.new-source-version": notificationType === NEW_SOURCE_VERSION,
      }
    );
    return (
      this.state.open && (
        <div styleName={"snackbar.error-message-handler"}>
          <div id="app-success" styleName={mainClasses}>
            <div styleName={"snackbar.error-container"}>
              <span styleName={"snackbar.error-text"}>{message}</span>
              {notificationType === ERROR && (
                <div styleName={"snackbar.error-refresh-link"} onClick={this.onRefreshClick}>
                  <HtmlTranslate value="ERRORS.COMMON_REFRESH_BUTTON" />
                </div>
              )}
              {notificationType === NEW_SOURCE_VERSION && <ClearCacheRefresh />}

              {(withClose || (notificationType !== NOTIFICATION && notificationType !== WARNING_ERROR)) && (
                <div styleName={"snackbar.error-close-container"}>
                  <a href="#" styleName={"snackbar.error-close-link"} onClick={this.onClose}>
                    <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={14} icon={"close"} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

Snackbar.propTypes = {};

export default Snackbar;
