// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table__block__yUHNCN {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 199px;
  padding-bottom: 24px;
}

.table__loading__kHTC77 {
  display: flex !important;
  margin-left: 50%;
  justify-self: start;
  align-items: center;
}

.table__inputBlock__Zwohxc {
  border: none !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
}

.table__find__Rxml_e {
  margin-bottom: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
}

.table__find__Rxml_e .table__inputFind__SP3SPP {
    width: 100%;
    padding: 6px 8px 5px;
    background: transparent;
    color: #9e9e9e;
  }

.table__find__Rxml_e .table__iconBox__FRCIkA {
    height: 16px;
    width: 18px;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width: 640px) {
  .table__block__yUHNCN {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    height: 100% !important;
  }

  .table__find__Rxml_e,
  .table__findWithout__Rrplpx {
    width: 199px;
  }

    .table__find__Rxml_e .table__inputFind__SP3SPP, .table__findWithout__Rrplpx .table__inputFind__SP3SPP {
      width: 100%;
      margin-right: 8px;
    }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `table__block__yUHNCN`,
	"loading": `table__loading__kHTC77`,
	"inputBlock": `table__inputBlock__Zwohxc`,
	"find": `table__find__Rxml_e`,
	"inputFind": `table__inputFind__SP3SPP`,
	"iconBox": `table__iconBox__FRCIkA`,
	"findWithout": `table__findWithout__Rrplpx`
};
export default ___CSS_LOADER_EXPORT___;
