import React, { Component } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import issues from "./css/support-general-issues.css";
import SaveBtn from "../../elements/SaveBtn";

export default class SupportGeneralIssues extends Component {
  static defaultProps = {
    generalIssuesText: "",
    buttonTitle: "",
    buttonSendEmailTitle: "",
  };

  static propTypes = {
    generalIssuesText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonTitle: PropTypes.string,
    onOpenChat: PropTypes.func,
  };

  render() {
    const { generalIssuesText, buttonTitle, onOpenChat, href, buttonSendEmailTitle } = this.props;

    return (
      <div styleName={"issues.container"}>
        <div styleName={"issues.text"}>{generalIssuesText}</div>
        <div styleName={"issues.container"}>
          <SaveBtn styleName={"issues.open-chat-btn"} text={buttonTitle} onClick={onOpenChat} />
          <a styleName={"issues.open-chat-btn"} href={href}>
            {buttonSendEmailTitle}
          </a>
        </div>
      </div>
    );
  }
}
