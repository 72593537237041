import { USD } from "../../constants/Currency";
import { calculateSum, formatCurrency, formatNumAsCurrency } from "../../helpers/formatCurrency";

const settingsBalance = ({ balance, reserved }) => {
  const balanceNum = formatCurrency({
    value: balance,
    currency: USD,
    showIsZero: true,
  });
  const reservedNum = formatCurrency({
    value: reserved,
    currency: USD,
    showIsZero: true,
  });
  const availableNum = formatNumAsCurrency({
    value: calculateSum(balance) - calculateSum(reserved),
    currency: USD,
    showIsZero: true,
  });
  const positiveValue = calculateSum(balance) - calculateSum(reserved) >= 0;

  return {
    balanceNum: balanceNum,
    reservedNum: reservedNum,
    availableNum: availableNum,
    positiveValue: positiveValue,
  };
};

export default settingsBalance;
