// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__container__s8kU6w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title__content__ASfOoH {
  display: flex;
  align-items: center;
}

.title__text__THuAHj {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  padding: 0 8px 0 0;
  margin: 0;
  text-transform: uppercase;
  line-height: 21px;
}

.title__counter__lU7WPo {
  display: flex;
  justify-content: flex-end;
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  flex: 1;
}

.title__toolbar__slSSz2 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title__questionHint__ni0uhp {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  align-self: center;
  box-shadow: none;
  padding: 8px;
}

.title__questionLink__bBDWlX {
  color: #1e88e5 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.title__questionLinkContainer__yDCX1Z {
  padding-top: 8px;
}

.title__refreshContainer__KLgIP3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -27px;
  margin-right: -25px;
  width: 42px;
  height: 42px;
}

.title__refreshIcon__Z2Lm6P {
  height: 16px;
  width: 16px;
  cursor: pointer;
  color: rgba(84, 84, 84, 1);
}

.title__refreshIcon__Z2Lm6P:hover {
    color: #1e88e5;
  }

.title__closeIconContainer__C1NLZG {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.title__load-more__z8kWg7 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  animation: title__load-animation__JYu32Q 1s infinite steps(12);
}

@keyframes title__load-animation__JYu32Q {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

.title__right-tooltip__sTaEFu {
  display: flex;
  align-items: center;
  margin-top: 4px;
  height: 0;
}

.title__right-tooltip__sTaEFu > div[data-placement^="bottom-end"] {
  left: 18px !important;
}

@media (max-width: 660px) {
  .title__toolbar__slSSz2 {
    display: none;
  }
  .title__refreshContainer__KLgIP3 {
    margin-top: -38px;
    margin-right: -16px;
    cursor: pointer;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `title__container__s8kU6w`,
	"content": `title__content__ASfOoH`,
	"text": `title__text__THuAHj`,
	"counter": `title__counter__lU7WPo`,
	"toolbar": `title__toolbar__slSSz2`,
	"questionHint": `title__questionHint__ni0uhp`,
	"questionLink": `title__questionLink__bBDWlX`,
	"questionLinkContainer": `title__questionLinkContainer__yDCX1Z`,
	"refreshContainer": `title__refreshContainer__KLgIP3`,
	"refreshIcon": `title__refreshIcon__Z2Lm6P`,
	"closeIconContainer": `title__closeIconContainer__C1NLZG`,
	"load-more": `title__load-more__z8kWg7`,
	"load-animation": `title__load-animation__JYu32Q`,
	"right-tooltip": `title__right-tooltip__sTaEFu`
};
export default ___CSS_LOADER_EXPORT___;
