import {
  SUCCEED_SETTINGS,
  UPDATED_SETTINGS,
  FAIL_SETTINGS,
  HIDE_NOTIFICATIONS_SETTINGS,
  FAIL_PASSWORD,
  SUCCEEED_PASSWORD,
} from "../constants/AuthorizedActionTypes";

import {
  SETTINGS_FETCHED,
  SETTINGS_UPDATED,
  SETTINGS_ERROR,
  SETTINGS_WRONG_PASSWORD,
} from "../constants/AuthorizedStatusTypes";

const initialState = {
  scrollPosition: {
    x: 0,
    y: 0,
  },
  fullName: "",
  login: "",
  paymentMethod: 0,
  paypalPaymentDetails: "",
  otherPaymentPetails: "",
  notifyLess: false,
  locale: "en",
  error: null,
  status: "",
  isWrongOldPassword: false,
  balance: null,
  reserved: null,
  balanceOverdraftAllowed: false,
  itemId: "",
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SUCCEED_SETTINGS:
      return {
        ...state,
        ...action.settings,
        status: SETTINGS_FETCHED,
        error: null,
      };
    case UPDATED_SETTINGS:
      return {
        ...state,
        ...action.settings,
        status: SETTINGS_UPDATED,
        error: null,
      };
    case FAIL_SETTINGS:
      return {
        ...state,
        status: SETTINGS_ERROR,
        error: action.error,
        errMessage: action.message,
      };
    case HIDE_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        status: SETTINGS_FETCHED,
        error: null,
      };
    case SUCCEEED_PASSWORD:
      return {
        ...state,
        status: SETTINGS_UPDATED,
        error: null,
        isWrongOldPassword: false,
      };
    case FAIL_PASSWORD:
      return {
        ...state,
        ...action.settings,
        status: SETTINGS_WRONG_PASSWORD,
        error: action.error,
        isWrongOldPassword: true,
      };
    case "CHANGE_BALANCE_TABLE_STATE":
      return {
        ...state,
        scrollPosition: action.state.scrollPosition,
      };
    case "SCROLL_TO_ITEM":
      return {
        ...state,
        itemId: action.itemId,
      };
    case "SCROLL_TO_ITEM_FAVOURITE_LANGUAGE":
      return {
        ...state,
        itemId: action.itemId,
      };
    default:
      return state;
  }
}
