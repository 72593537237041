import React, { Component } from "react";
import PropTypes from "prop-types";
import { HandySvg } from "handy-svg";
import title from "./css/title.css";
import Question from "./components/Question";
import Tutorial from "../Tutorial";
import refresh from "../../../images/svg/refresh.svg";
import { I18n } from "react-redux-i18n";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
import colorIconSet from "../../../images/color_icons.json";

export default class Title extends Component {
  state = {
    counter: 0,
  };

  setCounter = count => {
    const amount =
      count >= 0 ? I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SECTION_TITLES.AMOUNT_CHARS_ON_TEXT", { amount: count }) : count;
    this.setState({
      counter: amount,
    });
  };

  setTooltip() {
    const tooltip = this.props.rightTooltip;
    if (tooltip !== undefined) {
      return (
        <div styleName={"title.right-tooltip"}>
          <Question {...this.props} question={tooltip.tooltip} placement={"bottom-end"} title={tooltip.title} />
        </div>
      );
    }
    return null;
  }

  render() {
    const { titleColor, onRefreshBlock, onCloseBlock, titleRightBlock, isShowPopover, question, id } = this.props;
    const titleStyle = { color: titleColor };

    const renderRefreshIndicator =
      onRefreshBlock && onRefreshBlock.isShowLoading ? (
        <span styleName={"title.load-more"}>
          <IcomoonReact iconSet={colorIconSet} size={24} icon={"spinner"} />
        </span>
      ) : (
        <HandySvg src={refresh} styleName={"title.refreshIcon"} onClick={onRefreshBlock && onRefreshBlock.onRefresh} />
      );

    return (
      <div className={title.container}>
        <div styleName={"title.content"} ref={contentRef => (this.contentRef = contentRef)}>
          {isShowPopover && id === isShowPopover.id && <Tutorial content={isShowPopover} parentRef={this.contentRef} />}
          <h3 styleName={"title.text"} style={titleStyle}>
            {this.props.text && this.props.text}
          </h3>
          {question && <Question {...this.props} />}
        </div>
        {this.props.counter && <div styleName={"title.counter"}>{this.state.counter}</div>}
        {onRefreshBlock && <div styleName={"title.refreshContainer"}>{renderRefreshIndicator}</div>}
        {onCloseBlock && (
          <div styleName={"title.refreshContainer"}>
            <div styleName={"title.closeIconContainer"} onClick={onCloseBlock}>
              <IcomoonReact iconSet={iconSet} color={"#9E9E9E"} size={10} icon={"close"} />
            </div>
          </div>
        )}
        {titleRightBlock && titleRightBlock}
        {this.setTooltip()}
      </div>
    );
  }
}

Title.propTypes = {
  refreshBlock: PropTypes.object,
};
