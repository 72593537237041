// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-block__bar-container__fSZIfY {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: -24px;
  height: 16px;
  margin: -24px 104px 24px;
  position: sticky;
  position: -webkit-sticky;
  top: 118px;
  z-index: 1;
}

.table-block__toolbar__AXfIXL {
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar-container": `table-block__bar-container__fSZIfY`,
	"toolbar": `table-block__toolbar__AXfIXL`
};
export default ___CSS_LOADER_EXPORT___;
