const getCidCookie = name => {
  let matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getCid = () => {
  let cidLong = getCidCookie("_ga");
  let tmp = cidLong && cidLong.split(".");

  return tmp ? tmp[2] + "." + tmp[3] : null;
};
