import * as types from "../constants/Signup";

export function initRegistration() {
  return {
    type: types.INIT_REGISTRATION,
  };
}

export function saveForm(form) {
  return {
    type: types.SAVE_FORM,
    form,
  };
}

export function requestRegistration(form) {
  return {
    type: types.REQUEST_REGISTRATION,
    form,
  };
}

export function succeedRequestRegistration(form) {
  return {
    type: types.SUCCEED_REQUEST_REGISTRATION,
    form,
  };
}

export function failRequestRegistration(error) {
  return {
    type: types.FAIL_REQUEST_REGISTRATION,
    error,
  };
}

// Reset password

export function initPasswordReset() {
  return {
    type: types.INIT_PASSWORD_RESET,
  };
}

export function requestPasswordReset(form) {
  return {
    type: types.REQUEST_PASSWORD_RESET,
    email: form.email,
  };
}

export function succeedRequestPasswordReset() {
  return {
    type: types.SUCCEED_REQUEST_PASSWORD_RESET,
  };
}

export function failRequestPasswordReset(error) {
  return {
    type: types.FAIL_REQUEST_PASSWORD_RESET,
    error,
  };
}

export function setUserNotActiveError(error) {
  return {
    type: types.PASSWORD_RESET_USER_NOT_ACTIVE_ERROR,
    error,
  };
}

export function setStatus(status) {
  return {
    type: types.SET_STATUS,
    status,
  };
}

export function confirmPasswordReset(form) {
  return {
    type: types.CONFIRM_PASSWORD_RESET,
    form,
  };
}

export function succeedConfirmPasswordReset() {
  return {
    type: types.SUCCEED_CONFIRM_PASSWORD_RESET,
  };
}

export function failConfirmPasswordReset(error) {
  return {
    type: types.FAIL_CONFIRM_PASSWORD_RESET,
    error,
  };
}

export function resetPassword(form) {
  return {
    type: types.RESET_PASSWORD,
    form,
  };
}

export function succeedResetPassword(token) {
  return {
    type: types.SUCCEED_RESET_PASSWORD,
    token,
  };
}

export function failResetPassword(error) {
  return {
    type: types.FAIL_RESET_PASSWORD,
    error,
  };
}
