import React from "react";
import { I18n } from "react-redux-i18n";
// eslint-disable-next-line no-unused-vars
import priceTable from "./css/priceTable.css";
import { useSelector } from "react-redux";

const countAverageDeliveryTime = (deliveryTime, charactersCount) => {
  const hourLabel = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.DELIVERY_TIME");
  const fixedTime = `160 - 230 ${hourLabel}`;

  let timeInHours = deliveryTime === 0 ? charactersCount / 100 : charactersCount / deliveryTime;
  timeInHours = Math.max(1, Math.ceil(timeInHours));

  const secondTimeInHours = timeInHours * 2.0;
  const shouldShowZero = secondTimeInHours % 1 !== 0;
  const formattedSecondTimeInHours = shouldShowZero ? secondTimeInHours.toFixed(1) : secondTimeInHours.toFixed(0);

  return timeInHours >= 160 ? fixedTime : `${timeInHours.toFixed(0)} - ${formattedSecondTimeInHours} ${hourLabel}`;
};

export default function DeliveryTimeTable({ files, sourceLanguage, orderType, selectedTargetLanguage, sourceText }) {
  const i18n = useSelector(state => state.i18n);
  const customerRatesList = useSelector(state => state.domain.customerRates.allRates.data);

  let isNotEmptyTable = !_.isEmpty(sourceLanguage) && sourceLanguage.value && !_.isEmpty(selectedTargetLanguage);
  if (orderType === "text" && _.isEmpty(sourceText)) isNotEmptyTable = false;

  let charactersCount =
    (orderType === "file" || orderType === "googleFile") && !_.isEmpty(files) && !_.isEmpty(files[0])
      ? files[0].volume
      : !_.isEmpty(sourceText)
        ? sourceText.volume
        : 0;

  if (!_.isEmpty(customerRatesList)) {
    const list = customerRatesList.ratesList;
    for (const key in list) {
      const item = list[key];
      if (item.sourceLanguage === sourceLanguage.value && selectedTargetLanguage[item.targetLanguage]) {
        selectedTargetLanguage[item.targetLanguage].deliveryTime = item.deliveryTime;
      }
    }
  }

  return (
    <div styleName={"priceTable.tableContainer"}>
      <div styleName={"priceTable.tableRow priceTable.source"}>
        <div
          styleName={"priceTable.tableHeader"}
          style={{
            minWidth: i18n.locale === "ru" ? "105px" : "inherit",
          }}
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.SOURCE")}
        </div>
        {isNotEmptyTable && <div styleName={"priceTable.tableText"}>{I18n.t("LANGUAGES." + sourceLanguage.value)}</div>}
      </div>

      <div styleName={"priceTable.tableRow priceTable.target"}>
        <div styleName={"priceTable.tableHeader"}>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.TARGET")}
        </div>
        {isNotEmptyTable &&
          Object.values(selectedTargetLanguage).map((target, index) => (
            <div key={index} styleName="priceTable.tableText">
              {target.label}
            </div>
          ))}
      </div>

      <div styleName={"priceTable.tableRow priceTable.price"}>
        <div
          styleName={
            "priceTable.tableHeader priceTable.tablePriceHeader " +
            (i18n.locale === "ru" && orderType === "file" ? " priceTable.tableHeaderFileRus" : "")
          }
        >
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.AVERAGE_DELIVERY_TIME")}
        </div>
        {isNotEmptyTable &&
          Object.values(selectedTargetLanguage).map(target => {
            return (
              <div styleName={"priceTable.tableText priceTable.priceText"} style={{ textAlign: "right" }}>
                {countAverageDeliveryTime(target.deliveryTime, charactersCount)}
              </div>
            );
          })}
      </div>

      {!isNotEmptyTable && (
        <div styleName={"priceTable.tableEmptyText"}>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.EMPTY_TABLE_DELIVERY")}
        </div>
      )}
    </div>
  );
}
