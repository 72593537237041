// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiSettingSection__container__TJxxsd {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.multiSettingSection__containerLanguages__LuiBWh {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
}

.multiSettingSection__itemLanguages__kfDTyG {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 24px;
}

.multiSettingSection__nextItemLanguages__OGXQ_8 {
  width: 100%;
  background-color: #ffffff;
  padding: 24px;
  border-left: none;
}

.multiSettingSection__hiddenContainer__ahlRq7 {
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-top: none;
  background-color: #ffffff;
}

.multiSettingSection__item__uauwXI {
  width: 100%;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 24px;
}

.multiSettingSection__nextItem__sCC4W5 {
  width: 100%;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 24px;
  border-left: none;
}

@media (max-width: 660px) {
  .multiSettingSection__container__TJxxsd {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
  }

  .multiSettingSection__containerLanguages__LuiBWh {
    margin-top: 8px;
  }

  .multiSettingSection__hiddenContainer__ahlRq7 {
    padding: 24px 16px;
    border: 1px solid #e0e0e0;
    border-top: none;
    background-color: #ffffff;
  }

  .multiSettingSection__item__uauwXI {
    width: 100%;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    padding: 24px 16px;
  }

  .multiSettingSection__nextItem__sCC4W5 {
    width: 100%;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    padding: 24px 16px;
    border-top: none;
  }

  .multiSettingSection__nextItemLanguages__OGXQ_8 {
    padding: 16px;
  }

  .multiSettingSection__itemLanguages__kfDTyG {
    padding: 16px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `multiSettingSection__container__TJxxsd`,
	"containerLanguages": `multiSettingSection__containerLanguages__LuiBWh`,
	"itemLanguages": `multiSettingSection__itemLanguages__kfDTyG`,
	"nextItemLanguages": `multiSettingSection__nextItemLanguages__OGXQ_8`,
	"hiddenContainer": `multiSettingSection__hiddenContainer__ahlRq7`,
	"item": `multiSettingSection__item__uauwXI`,
	"nextItem": `multiSettingSection__nextItem__sCC4W5`
};
export default ___CSS_LOADER_EXPORT___;
