import React from "react";
import Loading from "./Loading";
// eslint-disable-next-line no-unused-vars
import loadingBlock from "../css/deprecated/loading-block.css";

export default function LoadingBlock(props) {
  return (
    <div className="loading-block" style={props.style}>
      <Loading display={true} />
    </div>
  );
}
