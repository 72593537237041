import React from "react";
import { createRoot } from "react-dom/client";
import { configureCustomerStore } from "./store/configureStore";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";

import setupTrackers from "./helpers/setupTrackers";
import setupLanguage from "./helpers/setupLanguage";

import { customerResources } from "./locales";
import NewOrderRoot from "./containers/NewOrderRoot";

const language = setupLanguage();
const store = configureCustomerStore();

setupTrackers();
syncTranslationWithStore(store);
store.dispatch(loadTranslations(customerResources));
store.dispatch(setLocale(language));

const container = document.getElementById("app-container");
const root = createRoot(container);
root.render(<NewOrderRoot store={store} />);
