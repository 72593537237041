import { getCookie, setCookie } from "./common";

const setupLanguage = function () {
  const browserLanguage = navigator.browserLanguage || navigator.language || navigator.userLanguage;
  const shortLanguage = browserLanguage.substr(0, 2);
  const browserLocale = getLanguageForPage(shortLanguage);
  const language = getCookie("nitro_lang") ? getCookie("nitro_lang") : browserLocale;

  if (!getCookie("nitro_lang")) setCookie("nitro_lang", language, 3650);

  return language;
};

export const setupLanguageRuEn = () => {
  const languages = {
    ru: "ru",
    en: "en",
  };
  const browserLanguage = navigator.browserLanguage || navigator.language || navigator.userLanguage;
  const shortLanguage = browserLanguage.substr(0, 2);
  const cookieLocale = getCookie("nitro_lang");
  const browserLocale = getLanguageRuEnForPage(shortLanguage);
  const language = languages[cookieLocale]
    ? cookieLocale
    : cookieLocale === browserLocale
      ? cookieLocale
      : browserLocale;

  if (!getCookie("nitro_lang")) setCookie("nitro_lang", language, 3650);

  return language;
};

export const getLanguageRuEnForPage = language => {
  const languages = {
    ru: "ru",
    en: "en",
    default: "en",
  };

  return languages[language] || languages["default"];
};

export const getLanguageEnForPage = language => {
  const languages = {
    en: "en",
    default: "en",
  };

  return languages[language] || languages["default"];
};

const getLanguageForPage = language => {
  const languages = {
    ru: "ru",
    ja: "ja",
    en: "en",
    default: "en",
  };

  return languages[language] || languages["default"];
};

export default setupLanguage;
