// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.priceTable__tableContainer__PqF7dD {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
  grid-template-areas: "source volume target price";
}

.priceTable__tableContainerFile__JewUZc {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 16px;

  grid-template-areas:
    "source volume volumeFake volumeFake volumeFake"
    "target pm tm discount price";
}

.priceTable__tableContainerFileRus__uhL0WM {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 24px;

  grid-template-areas:
    "source volume volumeFake volumeFake volumeFake"
    "target pm tm discount price";
}

.priceTable__tableContainerGoogleFile__uacY2o {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
  grid-template-areas:
    "source volume volumeFake volumeFake"
    "target tm discount price";
}

.priceTable__source__eTSzwC {
  grid-area: source;
}

.priceTable__volume__EFXX17 {
  grid-area: volume;
}

.priceTable__volumeFake__JNas_t {
  grid-area: volumeFake;
  color: transparent;
}

.priceTable__volumeFake__JNas_t .priceTable__volumeTitle__E_qK5Q {
  color: transparent;
}
.priceTable__target__KiN1pS {
  grid-area: target;
}

.priceTable__price__up6Eqz {
  grid-area: price;
}

.priceTable__pm__W3a5hH {
  grid-area: pm;
}

.priceTable__tm__yFRUnj {
  grid-area: tm;
}

.priceTable__emptyCell__DOh6G_ {
  grid-area: emptyCell;
}

.priceTable__target2__gGL9HY {
  grid-area: target2;
}

.priceTable__discount__ffStn9 {
  grid-area: discount;
}

.priceTable__tableHeaderQuestion__RGvGwB {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.priceTable__tableHeader__NWmmbB {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.priceTable__tableTmHeader__iH4q3y {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.priceTable__tableRow__K1Z20g {
  color: #212121;
  min-width: 115px;
}

.priceTable__tableRowVolumeFile__z8QNMv {
  grid-column: 2/6;
}

.priceTable__tableRowVolumeGoogle__c4Hz5j {
  grid-column: 2/5;
}

.priceTable__pmTitle__uSAska {
  text-align: right;
}

.priceTable__questionCell__tv5fkW {
  margin: 0;
  padding-right: 20px;
  text-align: right;
}

.priceTable__volumeTitle__E_qK5Q {
  margin: 0;
  /*width: 148px;*/
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableHeaderFile__O9G6nw.priceTable__tableHeaderFileRus__Dc8vqb {
  height: 72px;
}

.priceTable__tableHeaderFile__O9G6nw {
  height: 51px;
  display: flex;
  align-items: flex-end;
}

.priceTable__tableHeaderFileRight__pT7Nl9 {
  justify-content: flex-end;
}

.priceTable__tablePriceHeader__tLoPKR {
  text-align: right;
  justify-content: flex-end;
}

.priceTable__tableEmptyText__MY1yr3 {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  grid-column: 1/5;
  padding: 44px 0 32px 0;
}

.priceTable__tableContainerFile__JewUZc .priceTable__tableEmptyText__MY1yr3 {
  grid-column: 1/6;
}

.priceTable__tableContainerFile__JewUZc .priceTable__totalPrice__Ab7SWN {
  grid-column: 1/6;
}

.priceTable__tableContainerFileRus__uhL0WM .priceTable__tableEmptyText__MY1yr3 {
  grid-column: 1/6;
}

.priceTable__tableContainerFileRus__uhL0WM .priceTable__totalPrice__Ab7SWN {
  grid-column: 1/6;
}

.priceTable__tableText__i2AHy9 {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.priceTable__tableContainerFile__JewUZc .priceTable__tableText__i2AHy9 {
  /*width: 118px;*/
}

.priceTable__tableRowVolumeGoogle__c4Hz5j .priceTable__volumeTitle__E_qK5Q,
.priceTable__tableRowVolumeGoogle__c4Hz5j .priceTable__tableVolumeText__Q4QZGH {
  width: 121px;
}

.priceTable__tableContainerFile__JewUZc .priceTable__volumeTitle__E_qK5Q {
  margin: 0;
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableContainerFile__JewUZc .priceTable__tableVolumeText__Q4QZGH {
  text-align: right;
  padding-right: 20px;
}
.priceTable__tableContainerFileRus__uhL0WM .priceTable__volumeTitle__E_qK5Q {
  margin: 0;
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableContainerFileRus__uhL0WM .priceTable__tableVolumeText__Q4QZGH {
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableContainerGoogleFile__uacY2o .priceTable__tableVolumeText__Q4QZGH {
  text-align: right;
  padding-right: 20px;
}

.priceTable__priceText__Xxv_9n {
  display: flex;
  justify-content: flex-end;
}

.priceTable__tableVolumeText__Q4QZGH {
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableTMText__RjZzru {
  text-align: right;
  vertical-align: middle;
  min-height: 25px;
  max-height: 25px;
}

.priceTable__totalPrice__Ab7SWN {
  grid-column: 1/5;
  font-weight: bold;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #212121;
}

.priceTable__spinnerContainer__zv05KH {
  padding-top: 3px;
}

.priceTable__tableTragetLangRowMobile__tP3Ow5 {
  display: none;
}

.priceTable__pmQuestion__AF1R1e {
  margin-bottom: 3px;
}

.priceTable__pmQuestionUp__cXPbUy {
  position: relative;
  top: -23px;
}

.priceTable__discountBtn__v035UB{
  font-style: normal;
  font-weight: 400;
  text-align: center !important;
  font-size: 14px;
  border-radius: 3px;
  line-height: 14px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  padding: 4px 15px !important;
  border: 1px solid #43a047 !important;
  background-color: #ffffff !important;
  color: #43a047 !important;
}

@media (max-width: 725px) {
  .priceTable__tableContainer__PqF7dD {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-template-areas:
      "source volume"
      "target price";
  }

  .priceTable__tableContainerFile__JewUZc {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "source volume volumeFake"
      "target pm tm"
      "target2 discount price";
  }
  .priceTable__tableContainerFileRus__uhL0WM {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "source volume volumeFake"
      "target pm tm"
      "target2 discount price";
  }


  .priceTable__tableContainerGoogleFile__uacY2o {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "source volume volumeFake"
      "target tm emptyCell"
      "target2 discount price";
  }

  .priceTable__tableHeaderFile__O9G6nw.priceTable__tableHeaderFileRus__Dc8vqb {
    height: 51px;
  }

  .priceTable__tableTragetLangRowMobile__tP3Ow5 {
    display: block;
  }

  .priceTable__tableRowVolumeFile__z8QNMv {
    grid-column: 2/4;
  }
  .priceTable__tableRowVolumeGoogle__c4Hz5j {
    grid-column: 2/4;
  }

  .priceTable__volumeTitle__E_qK5Q {
    padding-right: 0;
  }

  .priceTable__tableContainerFile__JewUZc .priceTable__volumeTitle__E_qK5Q {
    margin: 0;
    text-align: right;
    padding-right: 20px;
  }
  .priceTable__tableContainerFileRus__uhL0WM .priceTable__volumeTitle__E_qK5Q {
    margin: 0;
    text-align: right;
    padding-right: 20px;
  }

  .priceTable__tableContainerGoogleFile__uacY2o .priceTable__volumeTitle__E_qK5Q {
    margin: 0;
    text-align: right;
    padding-right: 20px;
  }

  .priceTable__tableVolumeText__Q4QZGH {
    padding-right: 0;
  }

  .priceTable__tableVolumeRow__VFuoq0 {
    grid-column: 2/4;
  }

  .priceTable__tableTargetRow__Lz_nR_ .priceTable__tableText__i2AHy9 {
    /*  */
    max-width: 88px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .priceTable__tableText__i2AHy9 {
    width: auto;
  }

  .priceTable__tableContainerFile__JewUZc .priceTable__tableEmptyText__MY1yr3 {
    grid-column: 1/4;
  }
  .priceTable__tableContainerFileRus__uhL0WM .priceTable__tableEmptyText__MY1yr3 {
    grid-column: 1/4;
  }

  .priceTable__tableEmptyText__MY1yr3 {
    grid-column: 1/3;
  }

  .priceTable__tableAuthEmptyText___9Nrs9 {
    grid-column: 1/4;
  }

  .priceTable__tableTargetText__A6gBVW {
    padding-left: 0;
  }

  .priceTable__totalPrice__Ab7SWN {
    grid-column: 1/3;
  }

  .priceTable__tableContainerFile__JewUZc .priceTable__totalPrice__Ab7SWN {
    grid-column: 1/4;
  }
  .priceTable__tableContainerFileRus__uhL0WM .priceTable__totalPrice__Ab7SWN {
    grid-column: 1/4;
  }

  .priceTable__tableTMText__RjZzru {
    /*padding-right: 33px;*/
  }
}

@media (max-width: 425px) {
  .priceTable__tableTMText__RjZzru {
    /*padding-right: 20px;*/
  }
}

@media screen and (max-width: 380px) {
    .priceTable__tableContainerFile__JewUZc{
        grid-template-columns: 28% 44% 28%;
    }
    .priceTable__tableRow__K1Z20g{
        min-width: auto;
    }
}

@media (max-width: 325px) {
  .priceTable__tableTMText__RjZzru {
    /*padding-right: 18px;*/
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `priceTable__tableContainer__PqF7dD`,
	"tableContainerFile": `priceTable__tableContainerFile__JewUZc`,
	"tableContainerFileRus": `priceTable__tableContainerFileRus__uhL0WM`,
	"tableContainerGoogleFile": `priceTable__tableContainerGoogleFile__uacY2o`,
	"source": `priceTable__source__eTSzwC`,
	"volume": `priceTable__volume__EFXX17`,
	"volumeFake": `priceTable__volumeFake__JNas_t`,
	"volumeTitle": `priceTable__volumeTitle__E_qK5Q`,
	"target": `priceTable__target__KiN1pS`,
	"price": `priceTable__price__up6Eqz`,
	"pm": `priceTable__pm__W3a5hH`,
	"tm": `priceTable__tm__yFRUnj`,
	"emptyCell": `priceTable__emptyCell__DOh6G_`,
	"target2": `priceTable__target2__gGL9HY`,
	"discount": `priceTable__discount__ffStn9`,
	"tableHeaderQuestion": `priceTable__tableHeaderQuestion__RGvGwB`,
	"tableHeader": `priceTable__tableHeader__NWmmbB`,
	"tableTmHeader": `priceTable__tableTmHeader__iH4q3y`,
	"tableRow": `priceTable__tableRow__K1Z20g`,
	"tableRowVolumeFile": `priceTable__tableRowVolumeFile__z8QNMv`,
	"tableRowVolumeGoogle": `priceTable__tableRowVolumeGoogle__c4Hz5j`,
	"pmTitle": `priceTable__pmTitle__uSAska`,
	"questionCell": `priceTable__questionCell__tv5fkW`,
	"tableHeaderFile": `priceTable__tableHeaderFile__O9G6nw`,
	"tableHeaderFileRus": `priceTable__tableHeaderFileRus__Dc8vqb`,
	"tableHeaderFileRight": `priceTable__tableHeaderFileRight__pT7Nl9`,
	"tablePriceHeader": `priceTable__tablePriceHeader__tLoPKR`,
	"tableEmptyText": `priceTable__tableEmptyText__MY1yr3`,
	"totalPrice": `priceTable__totalPrice__Ab7SWN`,
	"tableText": `priceTable__tableText__i2AHy9`,
	"tableVolumeText": `priceTable__tableVolumeText__Q4QZGH`,
	"priceText": `priceTable__priceText__Xxv_9n`,
	"tableTMText": `priceTable__tableTMText__RjZzru`,
	"spinnerContainer": `priceTable__spinnerContainer__zv05KH`,
	"tableTragetLangRowMobile": `priceTable__tableTragetLangRowMobile__tP3Ow5`,
	"pmQuestion": `priceTable__pmQuestion__AF1R1e`,
	"pmQuestionUp": `priceTable__pmQuestionUp__cXPbUy`,
	"discountBtn": `priceTable__discountBtn__v035UB`,
	"tableVolumeRow": `priceTable__tableVolumeRow__VFuoq0`,
	"tableTargetRow": `priceTable__tableTargetRow__Lz_nR_`,
	"tableAuthEmptyText": `priceTable__tableAuthEmptyText___9Nrs9`,
	"tableTargetText": `priceTable__tableTargetText__A6gBVW`
};
export default ___CSS_LOADER_EXPORT___;
