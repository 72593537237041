import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class SettingsToolbar extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="settings-toolbar" style={this.props.style}>
        <div className="settings-toolbar__title" style={this.props.titleStyle}>
          {this.props.title}
        </div>
        {this.props.children}
      </div>
    );
  }
}
