import React, { Component } from "react";
import Lightbox from "react-18-image-lightbox";
import Loading from "../../Loading";
// eslint-disable-next-line no-unused-vars
import view from "./css/image-view.css";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

class UploadingImageView extends Component {
  state = {
    imageOpened: {},
    isOpenImage: false,
    showErrorMessage: true,
  };

  onClickImage = () => {
    const { image } = this.props;
    const imageSrc = image.file;

    this.setState({
      isOpenImage: true,
      imageOpened: imageSrc,
    });
  };

  handleRetry = () => {
    const { handleRetry, image, arrayCount } = this.props;
    this.closeErrorMessage();
    handleRetry(image.selectedFile, arrayCount);
  };

  closeErrorMessage = image => {
    this.setState({
      showErrorMessage: false,
    });
    this.props.handleDeleteImage(image);
  };

  closeImage = () => {
    this.setState({
      isOpenImage: false,
    });
  };

  handleDeleteImage = e => {
    e.stopPropagation();
    this.props.handleDeleteImage(this.props.image);
  };

  render() {
    const { image, displayLoading, handleDeleteImage, messageContainer } = this.props;
    const { isOpenImage, imageOpened } = this.state;
    const loading = !image.uuid && displayLoading && !image.uploadImageError;
    const error = !!(displayLoading && image.uploadImageError);
    const imageSrc = !image.uuid ? image.file : process.env.FRONTEND_URL + "/attachments/" + image.uuid;
    const imageContainer = messageContainer ? "view.imageMessageContainer" : "view.imageContainer";

    const customStyles = {
      content: {
        cursor: "zoom-out",
        backgroundColor: "rgba(0,0,0,0.3)",
      },
    };

    return (
      <div styleName={imageContainer} style={image.containerStyle}>
        {!error && (
          <div styleName={"view.uploadedImage"}>
            {loading && (
              <div styleName={"view.imageLoading"}>
                <Loading styleName={"view.loadingIndicator"} display={loading} />
              </div>
            )}
            {!loading && <img styleName={"view.image"} src={imageSrc} />}
          </div>
        )}
        {handleDeleteImage && !error && (
          <div styleName={"view.hoverCover"} onClick={this.onClickImage}>
            <div styleName={"view.deleteImage"} onClick={e => this.handleDeleteImage(e)}>
              <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={10} icon={"close"} />
            </div>
          </div>
        )}
        {isOpenImage && (
          <div onClick={this.closeImage}>
            <Lightbox
              mainSrc={imageOpened}
              enableZoom={false}
              onCloseRequest={this.closeImage}
              reactModalStyle={customStyles}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UploadingImageView;
