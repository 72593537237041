// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-view__imageContainer__tJWDbt {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `image-view__imageContainer__tJWDbt`
};
export default ___CSS_LOADER_EXPORT___;
