import React, { Component } from "react";
import ErrorMessage from "../refactored/ErrorMessage/ErrorMessage";

export default class FormError extends Component {
  componentDidUpdate() {
    const { display } = this.props;
    display && window.scroll(0, 0);
  }

  render() {
    const { display } = this.props;
    if (!display) {
      return null;
    }
    return <ErrorMessage onClose={this.props.onClose} message={this.props.message} />;
  }
}
