// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA1MS4zICg1NzU0NCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+NTNGNzRBNTAtREMwOS00NjNBLUI5QTMtQkE5Nzk1MkJFNUQxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJuaXRyby1hZG1pbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9InVzZXJzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM1Ni4wMDAwMDAsIC04MzkuMDAwMDAwKSIgZmlsbD0iI0JEQkRCRCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMzYwLjYsODQ1IEwxMzU2LDg0MC40IEwxMzU3LjQsODM5IEwxMzYyLDg0My42IEwxMzY2LjYsODM5IEwxMzY4LDg0MC40IEwxMzYzLjQsODQ1IEwxMzY4LDg0OS42IEwxMzY2LjYsODUxIEwxMzYyLDg0Ni40IEwxMzU3LjQsODUxIEwxMzU2LDg0OS42IEwxMzYwLjYsODQ1IFoiIGlkPSJleGl0LWljb24iPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input__box__cxTLVt {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 520px) {
  .search-input__box__cxTLVt {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
  }
}

.search-input__search__VX4du0 {
  height: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: #fafafa;
  width: 268px;
}

.search-input__icon-container__XzV6n5 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  height: 32px;
}

.search-input__input-container__xlQ8Ta {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search-input__icon-base__LV5L01 {
  height: 16px;
  width: 16px;
}

.search-input__icon-unfocus__nUNWHr {
  color: #9e9e9e;
}

.search-input__icon-focus__z3o4yL {
  color: #1e88e5;
  cursor: pointer;
}

.search-input__icon-unfocus-content__xTRhop {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 12px;
  height: 12px;
  top: 10px;
  cursor: pointer;
}

.search-input__input__ZXhBWQ {
  /*width: 224px;*/
  height: 32px;
  color: #212121;
  background-color: #fafafa;
  border-radius: 1px;
  padding: 8px;
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `search-input__box__cxTLVt`,
	"search": `search-input__search__VX4du0`,
	"icon-container": `search-input__icon-container__XzV6n5`,
	"input-container": `search-input__input-container__xlQ8Ta`,
	"icon-base": `search-input__icon-base__LV5L01`,
	"icon-unfocus": `search-input__icon-unfocus__nUNWHr search-input__icon-base__LV5L01 glyphicon glyphicon-search`,
	"icon-focus": `search-input__icon-focus__z3o4yL search-input__icon-base__LV5L01 glyphicon glyphicon-search`,
	"icon-unfocus-content": `search-input__icon-unfocus-content__xTRhop search-input__icon-base__LV5L01`,
	"input": `search-input__input__ZXhBWQ form-control`
};
export default ___CSS_LOADER_EXPORT___;
