import PropTypes from "prop-types";
import React, { Component } from "react";
import ellipsize from "ellipsize";
import { I18n } from "react-redux-i18n";
import { closePopovers } from "../helpers/common";
import onTabClose from "../helpers/onTabClose";
import { ACCEPTED_ORDER } from "./../constants/AuthorizedStatusTypes";
import HtmlTranslate from "./HtmlTranslate";
import { Link } from "react-router-dom";
import "../css/deprecated/popover.css";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import iconSet from "../images/selection.json";
import IcomoonReact from "icomoon-react";
import { withAuthProvider } from "./refactored/AuthProvider";
class UserMenu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    feedback: PropTypes.bool,
    appName: PropTypes.string,
  };

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
    this.handleUserMenuLinkClick = this.handleUserMenuLinkClick.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", function () {
      const userMenu = document.getElementById("user-menu");
      const userMenuTrigger = document.getElementById("user-menu-trigger");

      if (userMenu) {
        userMenuTrigger.classList.remove("active");
      }
    });
  }

  handleUserMenuClick() {
    const userMenu = document.getElementById("user-menu");
    const userMenuTrigger = document.getElementById("user-menu-trigger");

    if (!userMenu) {
      userMenuTrigger.classList.add("active");
    } else {
      userMenuTrigger.classList.remove("active");
    }
  }

  handleUserMenuLinkClick() {
    closePopovers();
  }

  handleClick() {
    if (this.props.desktopStatus === ACCEPTED_ORDER) {
      let message = I18n._translate("TRANSLATOR_APP.DESKTOP_PAGE.CONFIRM_LOGOUT");
      closePopovers();
      setTimeout(() => {
        if (confirm(message)) {
          window.removeEventListener("beforeunload", onTabClose);

          this.props?.auth?.logout();
        }
      }, 0);
    } else {
      this.props?.auth?.logout();
    }
  }

  render() {
    const { user, feedback } = this.props;
    const supportLinkDisplay = feedback ? "block" : "none";
    const appName = this.props.appName ? this.props.appName : "customer";
    const userName = user.fullName ? user.fullName : user.name;
    let langAppName = appName.toUpperCase() + "_APP";
    // {`${langAppName}.HEADER.SUPPORT_LINK`}
    const shouldShowStarsForTranslator =
      user &&
      user.rolesList &&
      Array.isArray(user.rolesList) &&
      user.rolesList.includes(3) &&
      window.location.href.includes("/translator/");

    const popover = (
      <Popover className="user-popover" id="user-menu">
        <ul className="user-list">
          <li>
            <span title={userName} className="menu-span">
              {ellipsize(userName, 20)}
              <span title={user.email} className="header-small">
                {ellipsize(user.email, 30)}
              </span>
            </span>
            {shouldShowStarsForTranslator && (
              <span className="popover-star-container">
                <IcomoonReact iconSet={iconSet} size={18} icon={"star_icon"} />
                <span className="popover-star-count">{user.starState.available}</span>
              </span>
            )}
          </li>
          <li>
            <Link to={`/${appName}/settings`} onClick={this.handleUserMenuLinkClick}>
              <HtmlTranslate value={`${langAppName}.SETTINGS_PAGE.PAGE_HEADING`} />
            </Link>
          </li>
          <li style={{ display: supportLinkDisplay }}>
            <Link to={`/${appName}/support`} onClick={this.handleUserMenuLinkClick}>
              <HtmlTranslate value={`SUPPORT_PAGE.PAGE_HEADING`} />
            </Link>
          </li>
          <li>
            <a href="#" onClick={this.handleClick} className="logout-link">
              <span className="logout-icon">
                <IcomoonReact iconSet={iconSet} color={"#a4a4a4"} size={18} icon={"logout"} />
              </span>
              <HtmlTranslate value={`HEADER.LOGOUT_LINK`} />
            </a>
          </li>
        </ul>
      </Popover>
    );

    return (
      <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
        <a onClick={this.handleUserMenuClick} id="user-menu-trigger" className="header-user">
          <span className="user-icon">&nbsp;</span>
          <span title={userName} className="user-name">
            {userName}
          </span>
          <span className="drop-icon">&nbsp;</span>
        </a>
      </OverlayTrigger>
    );
  }
}

export default withAuthProvider(UserMenu);
