export var DiscountTypes;
(function (DiscountTypes) {
    DiscountTypes["GENERATE_DISCOUNTS_REQUEST"] = "GENERATE_DISCOUNTS_REQUEST";
    DiscountTypes["GENERATE_DISCOUNTS_SUCCESS"] = "GENERATE_DISCOUNTS_SUCCESS";
    DiscountTypes["GET_DISCOUNT_INFO_REQUEST"] = "GET_DISCOUNT_INFO_REQUEST";
    DiscountTypes["GET_DISCOUNT_INFO_SUCCESS"] = "GET_DISCOUNT_INFO_SUCCESS";
    DiscountTypes["GET_CUSTOMER_DISCOUNT_INFO_REQUEST"] = "GET_CUSTOMER_DISCOUNT_INFO_REQUEST";
    DiscountTypes["GET_CUSTOMER_DISCOUNT_INFO_SUCCESS"] = "GET_CUSTOMER_DISCOUNT_INFO_SUCCESS";
    DiscountTypes["GET_ADMIN_DISCOUNT_INFO_REQUEST"] = "GET_ADMIN_DISCOUNT_INFO_REQUEST";
    DiscountTypes["GET_ADMIN_DISCOUNT_INFO_SUCCESS"] = "GET_ADMIN_DISCOUNT_INFO_SUCCESS";
    DiscountTypes["LIST_DISCOUNTS_REQUEST"] = "LIST_DISCOUNTS_REQUEST";
    DiscountTypes["LIST_DISCOUNTS_SUCCESS"] = "LIST_DISCOUNTS_SUCCESS";
    DiscountTypes["LIST_DISCOUNT_USERS_REQUEST"] = "LIST_DISCOUNT_USERS_REQUEST";
    DiscountTypes["LIST_DISCOUNT_USERS_SUCCESS"] = "LIST_DISCOUNT_USERS_SUCCESS";
    DiscountTypes["UPDATE_DISCOUNT_REQUEST"] = "UPDATE_DISCOUNT_REQUEST";
    DiscountTypes["UPDATE_DISCOUNT_SUCCESS"] = "UPDATE_DISCOUNT_SUCCESS";
    DiscountTypes["UPDATE_DISCOUNT_BLOCKED_REQUEST"] = "UPDATE_DISCOUNT_BLOCKED_REQUEST";
    DiscountTypes["UPDATE_DISCOUNT_BLOCKED_SUCCESS"] = "UPDATE_DISCOUNT_BLOCKED_SUCCESS";
    DiscountTypes["DELETE_DISCOUNT_REQUEST"] = "DELETE_DISCOUNT_REQUEST";
    DiscountTypes["DELETE_DISCOUNT_SUCCESS"] = "DELETE_DISCOUNT_SUCCESS";
})(DiscountTypes || (DiscountTypes = {}));
