var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, memo } from "react";
import styles from "./css/styles.css";
import Popup from "../../../elements/Popup";
import FormCheckbox from "../../FormCheckbox";
import NitroButton from "../../GreenButton";
import { I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import { changeTableState } from "../../../../actions/customer/NewOrder";
import { formattedNumberWithSpaces, getElementWithUpdatedIsSelectFlag, getSelectedValues, getTotalOfCharsInSelectedElements, getValueForSelectAllCheckbox, } from "./helpers";
var Header = function (_a) {
    var translateFirstRow = _a.translateFirstRow, handleTranslateFirstRowChange = _a.handleTranslateFirstRowChange, _b = _a.filename, filename = _b === void 0 ? "" : _b;
    return (_jsxs("div", { children: [_jsx("h1", { className: styles.title, children: I18n.t("CSV_SELECTOR_POPUP.TITLE") }), _jsx("div", { children: _jsx("p", { className: styles.description, children: I18n.t("CSV_SELECTOR_POPUP.DESCRIPTION") }) }), _jsx("div", { children: _jsx(FormCheckbox, { id: "translateFirstRow", value: translateFirstRow, onChange: handleTranslateFirstRowChange, label: I18n.t("CSV_SELECTOR_POPUP.CHECKBOX_DESCRIPTION") }) }), _jsx("p", { className: styles.filename, children: filename })] }));
};
var Footer = function (_a) {
    var totalCharsAmount = _a.totalCharsAmount, selectAllValue = _a.selectAllValue, handleSubmitClick = _a.handleSubmitClick, onClose = _a.onClose, loadingView = _a.loadingView, btnApply = _a.btnApply;
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.totalAmountOfCharacters, children: I18n.t("CSV_SELECTOR_POPUP.AMOUNT_OF_CHARS_TOTAL", { amount: totalCharsAmount }) }), _jsxs("div", { className: styles.buttonsWrapper, children: [_jsx(NitroButton, { clickHandler: handleSubmitClick, style: { minWidth: 83, height: 32, fontSize: 14, fontWeight: 300 }, title: I18n.t("CSV_SELECTOR_POPUP.APPLY"), disabled: !totalCharsAmount || !selectAllValue, loadingView: loadingView, btnApply: btnApply }), _jsx("button", { className: styles.clearButton, onClick: onClose, children: I18n.t("CSV_SELECTOR_POPUP.CANCEL") })] })] }));
};
var PopupCSVSelector = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, file = _a.file, fileElements = _a.fileElements, setFileElements = _a.setFileElements, parserInfo = _a.parserInfo, setParserInfo = _a.setParserInfo, onCreateOrder = _a.onCreateOrder, isUploadCSVLoading = _a.isUploadCSVLoading;
    var dispatch = useDispatch();
    var storedSelectedFileSegments = useSelector(function (state) { return state.newOrder.uploadedFileSegmentsData; });
    var _b = useState([]), elements = _b[0], setElements = _b[1];
    var _c = useState(false), selectAllValue = _c[0], setSelectAllValue = _c[1];
    var _d = useState(0), totalCharsAmount = _d[0], setTotalCharsAmount = _d[1];
    var _e = useState(false), translateFirstRow = _e[0], setTranslateFirstRow = _e[1];
    var _f = useState(""), errorText = _f[0], setErrorText = _f[1];
    useEffect(function () {
        var _a;
        var hasStoredFileElements = (_a = storedSelectedFileSegments === null || storedSelectedFileSegments === void 0 ? void 0 : storedSelectedFileSegments.fileElements) === null || _a === void 0 ? void 0 : _a.length;
        var areInitialElementsEmpty = !(fileElements === null || fileElements === void 0 ? void 0 : fileElements.length);
        if (storedSelectedFileSegments && hasStoredFileElements && areInitialElementsEmpty) {
            setFileElements(storedSelectedFileSegments.fileElements);
            console.log("selector is invoked");
        }
    }, [storedSelectedFileSegments, fileElements, setFileElements]);
    useEffect(function () {
        if (!parserInfo.hasOwnProperty("skipHeader"))
            return;
        setTranslateFirstRow(!parserInfo.skipHeader);
    }, [parserInfo, setTranslateFirstRow]);
    useEffect(function () {
        var elems = [];
        if (fileElements) {
            elems = getElementWithUpdatedIsSelectFlag(fileElements);
        }
        setElements(elems);
    }, [setElements, fileElements, storedSelectedFileSegments]);
    useEffect(function () {
        var selectAllCheckboxValue = getValueForSelectAllCheckbox(elements);
        var total = getTotalOfCharsInSelectedElements(elements, translateFirstRow);
        setSelectAllValue(selectAllCheckboxValue);
        setTotalCharsAmount(total);
    }, [elements, translateFirstRow, setSelectAllValue, setTotalCharsAmount]);
    var uncheckEmptyColumns = function () {
        var nextElements = elements.map(function (element) {
            var isSelected = element.isSelected, countChars = element.countChars, countCharsOnFirstRow = element.countCharsOnFirstRow;
            var shouldUncheckColumn = isSelected && countChars - countCharsOnFirstRow === 0;
            if (shouldUncheckColumn)
                return __assign(__assign({}, element), { isSelected: false });
            return element;
        });
        setElements(nextElements);
    };
    var handleTranslateFirstRowChange = function () {
        if (translateFirstRow) {
            uncheckEmptyColumns();
        }
        setTranslateFirstRow(function (prev) { return !prev; });
    };
    var handleSelectItemChange = function (index) { return function () {
        var nextState = elements.map(function (elem, i) {
            if (i === index) {
                return __assign(__assign({}, elem), { isSelected: !elem.isSelected });
            }
            return elem;
        });
        setElements(nextState);
    }; };
    var handleSelectAllChange = function () {
        var nextFlag = false;
        if (selectAllValue === "intermediate" || !selectAllValue)
            nextFlag = true;
        var updatedElements = getElementWithUpdatedIsSelectFlag(elements, nextFlag);
        setElements(updatedElements);
    };
    var getPayload = function () {
        var values = getSelectedValues(elements);
        var payload = {
            text: file.filesAsFormattedText,
            fileType: file.type,
            fileName: file.filename,
            parserInfo: __assign(__assign({}, parserInfo), { skipHeader: !translateFirstRow }),
            selected: { values: values },
        };
        return payload;
    };
    var validateSelectedValuesPayload = function (payload) {
        var isValid = payload.selected.values.length;
        if (isValid) {
            setErrorText("");
        }
        else {
            setErrorText("Select Columns to translate(add translation)");
        }
        return isValid;
    };
    var handleSubmitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = getPayload();
                    isValid = validateSelectedValuesPayload(payload);
                    if (!isValid)
                        return [2 /*return*/];
                    return [4 /*yield*/, setFileElements(elements)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, setParserInfo(payload.parserInfo)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, dispatch(changeTableState({
                            uploadedFileSegmentsData: {
                                selectedValues: payload.selected.values,
                                parserInfo: payload.parserInfo,
                                fileElements: elements,
                                filesAsFormattedText: file.filesAsFormattedText,
                            },
                        }))];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, onCreateOrder(payload)];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCloseClick = function () {
        var shouldRemoveFile = !(file === null || file === void 0 ? void 0 : file.uuid);
        if (!shouldRemoveFile) {
            if (storedSelectedFileSegments === null || storedSelectedFileSegments === void 0 ? void 0 : storedSelectedFileSegments.fileElements) {
                setElements(storedSelectedFileSegments.fileElements);
            }
            if (storedSelectedFileSegments === null || storedSelectedFileSegments === void 0 ? void 0 : storedSelectedFileSegments.parserInfo) {
                setParserInfo(storedSelectedFileSegments.parserInfo);
                setTranslateFirstRow(!storedSelectedFileSegments.parserInfo.skipHeader);
            }
        }
        onClose(shouldRemoveFile);
    };
    return (_jsx(Popup, { isOpen: isOpen, onClose: handleCloseClick, className: styles.container, stylePopup: { overflow: "scroll", padding: "120px 0 50px" }, style: { bottom: "initial" }, containerStyle: { overflowY: "initial" }, isHeader: _jsx(Header, { translateFirstRow: translateFirstRow, handleTranslateFirstRowChange: handleTranslateFirstRowChange, filename: file.filename }), isFooter: _jsxs(_Fragment, { children: [_jsx(Footer, { totalCharsAmount: totalCharsAmount, selectAllValue: selectAllValue, handleSubmitClick: handleSubmitClick, onClose: handleCloseClick, loadingView: isUploadCSVLoading, btnApply: true }), errorText && _jsx("p", { className: styles.errorText, children: errorText })] }), children: _jsxs("div", { className: styles.selectorWrapper, children: [_jsx("div", { className: styles.selectorItem, children: _jsx(FormCheckbox, { id: "selectAll", value: selectAllValue, label: _jsx("span", { className: styles.selectorItemName, children: I18n.t("CSV_SELECTOR_POPUP.SELECT_ALL") }), onChange: handleSelectAllChange }) }), elements &&
                    elements.map(function (item, index) {
                        var countChars = item.countChars, countCharsOnFirstRow = item.countCharsOnFirstRow;
                        var chars = translateFirstRow ? countChars : countChars - countCharsOnFirstRow;
                        if (chars === 0)
                            return null;
                        return (_jsx("div", { className: styles.selectorItem, children: _jsx(FormCheckbox, { id: item.nameColumn, value: item.isSelected, onChange: handleSelectItemChange(index), label: _jsxs(_Fragment, { children: [_jsx("span", { className: styles.selectorItemName, children: I18n.t("CSV_SELECTOR_POPUP.COLUMN_NAME", { name: item.nameColumn }) }), _jsx("span", { className: styles.selectorItemChars, children: I18n.t("CSV_SELECTOR_POPUP.AMOUNT_OF_CHARS", {
                                                amount: formattedNumberWithSpaces(chars.toString()),
                                            }) })] }) }) }, item.nameColumn));
                    })] }) }));
};
export default memo(PopupCSVSelector);
