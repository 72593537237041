var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { UserServiceClient } from "@alconost/nitro-services-api/src/proto/user_service_grpc_web_pb";
import { AcceptTranslatorPopupRequest, CreateTranslatorRequest, DeleteApiKeyRequest, DeleteMeRequest, DeleteUserRequest, DisableEmailNotifications, GenerateApiKeyRequest, GetUserRequest, ListTranslatorsRequest, ListUsersRequest, PaymentMethodRequest, RemoveRecentPairRequest, SaveCustomerReviewRequest, SaveVacationPeriodAdminRequest, SaveVacationPeriodTranslatorRequest, SortUserField, SpellCheckerStatusRequest, SubscribeStatusRequest, UpdateApiKeyRequest, UpdateBillToRequest, UpdatePasswordRequest, UpdatePersonalSettingsRequest, UpdateUserRequest, UserId, Vacation, UserRole, ListLiteUsersRequest, UserFilter, SetCustomerCategoryRequest, CustomerCategory, RemoveRoleRequest, } from "@alconost/nitro-services-api/src/proto/user_service_pb";
import { Empty, LanguagePair, Page, SortOrder, } from "@alconost/nitro-services-api/src/proto/base_pb";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
export var createTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, createTranslatorRequest, paymentMethodRequest;
    var token = _b.token, fullname = _b.fullname, name = _b.name, email = _b.email, password = _b.password, paymentMethod = _b.paymentMethod, paymentDetails = _b.paymentDetails;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                createTranslatorRequest = new CreateTranslatorRequest();
                paymentMethodRequest = new PaymentMethodRequest();
                paymentMethodRequest.setPaymentDetails(paymentDetails);
                paymentMethodRequest.setPaymentMethod(paymentMethod);
                createTranslatorRequest.setFullname(fullname);
                createTranslatorRequest.setName(name);
                createTranslatorRequest.setEmail(email);
                createTranslatorRequest.setPassword(password);
                createTranslatorRequest.setPayment(paymentMethodRequest);
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "createTranslator",
                        protoRequest: createTranslatorRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getMeRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new Empty();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "me",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getApiKeysRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new Empty();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "getApiKeys",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var generateApiKeyRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request;
    var token = _b.token, apiName = _b.apiName;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new GenerateApiKeyRequest();
                request.setApiName(apiName);
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "generateApiKey",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateApiKeyNameRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var oldName = _b.oldName, newName = _b.newName, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateApiKeyRequest();
                request.setOldName(oldName);
                request.setNewName(newName);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updateApiKeyName",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteApiKeyRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var apiNamesList = _b.apiNamesList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DeleteApiKeyRequest();
                request.setApiNamesList(apiNamesList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "deleteApiKey",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteMeRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var reasonsList = _b.reasonsList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DeleteMeRequest();
                request.setReasonsList(reasonsList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "deleteMe",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getUserRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var id = _b.id, role = _b.role, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetUserRequest();
                request.setId(id);
                request.setRole(role);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "getUser",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updatePasswordRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var oldpassword = _b.oldpassword, newpassword = _b.newpassword, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdatePasswordRequest();
                request.setOldpassword(oldpassword);
                request.setNewpassword(newpassword);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updatePassword",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var removeRecentPairRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var pairList = _b.pairList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RemoveRecentPairRequest();
                request.setPairList(pairList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "removeRecentPair",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var setCustomerCategoryRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var category = _b.category, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SetCustomerCategoryRequest();
                request.setCustomerCategory(CustomerCategory[category]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "setCustomerCategory",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteUserRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var useridsList = _b.useridsList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DeleteUserRequest();
                request.setUseridsList(useridsList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "deleteUser",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var removeRoleUserRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var userId = _b.userId, role = _b.role, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RemoveRoleRequest();
                request.setUserId(userId);
                request.setRole(UserRole[role]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "removeRole",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getSubscribeStatusRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "getSubscribeStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var setSubscribeStatusRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var subscribed = _b.subscribed, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SubscribeStatusRequest();
                request.setSubscribed(subscribed);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "setSubscribeStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listUsersRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request, userFilter, page;
    var offset = _b.offset, limit = _b.limit, search = _b.search, role = _b.role, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token, filters = _b.filters;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new ListUsersRequest();
                userFilter = new UserFilter();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                if (filters === null || filters === void 0 ? void 0 : filters.credit)
                    userFilter.setCredit(filters.credit.value);
                if (filters === null || filters === void 0 ? void 0 : filters.paused)
                    userFilter.setPaused(filters.paused.value);
                if (filters === null || filters === void 0 ? void 0 : filters.ping)
                    userFilter.setPing(filters.ping.value);
                if (filters === null || filters === void 0 ? void 0 : filters.method)
                    userFilter.setPaymentmethodsList(filters.method.value);
                if (filters === null || filters === void 0 ? void 0 : filters.roles)
                    userFilter.setRolesList(filters.roles.value);
                if (filters === null || filters === void 0 ? void 0 : filters.active)
                    userFilter.setActive(filters.active.value);
                request.setPage(page);
                request.setSearch(search);
                request.setRole(UserRole[role]);
                request.setField(SortUserField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setUserfilter(userFilter);
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "listUsers",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listTranslatorsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, pair, metadata;
    var source = _b.source, target = _b.target, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListTranslatorsRequest();
                pair = new LanguagePair();
                pair.setSource(source);
                pair.setTarget(target);
                request.setPair(pair);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "listTranslators",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getLanguagePairStatisticRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var userId = _b.userId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UserId();
                request.setUserId(userId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "getLanguagePairStatistic",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getSendersEmailsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "getSendersEmails",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateUserRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request, paymentMethodRequest, billTo;
    var token = _b.token, fullName = _b.fullName, name = _b.name, id = _b.id, email = _b.email, billToDetails = _b.billToDetails, notes = _b.notes, active = _b.active, balanceOverdraftAllowed = _b.balanceOverdraftAllowed, paused = _b.paused, subscribed = _b.subscribed, timeZone = _b.timeZone, sender = _b.sender, partnerId = _b.partnerId, startPartnerPeriod = _b.startPartnerPeriod, endPartnerPeriod = _b.endPartnerPeriod, rolesList = _b.rolesList, paymentDetails = _b.paymentDetails, paymentMethod = _b.paymentMethod;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new UpdateUserRequest();
                paymentMethodRequest = new PaymentMethodRequest();
                request.setFullName(fullName);
                request.setName(name);
                request.setUserId(id);
                request.setEmail(email);
                billTo = new StringValue();
                billTo.setValue(billToDetails);
                request.setBillto(billTo);
                request.setNotes(notes);
                request.setActive(active);
                request.setBalanceOverdraftAllowed(balanceOverdraftAllowed);
                request.setPaused(paused);
                request.setSubscribed(subscribed);
                request.setTimeZone(timeZone);
                request.setSender(sender);
                request.setPartnerId(partnerId);
                request.setStartPartnerPeriod(startPartnerPeriod);
                request.setEndPartnerPeriod(endPartnerPeriod);
                request.setRolesList(rolesList);
                paymentMethodRequest.setPaymentMethod(paymentMethod);
                paymentMethodRequest.setPaymentDetails(paymentDetails);
                request.setPayment(paymentMethodRequest);
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updateUser",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updatePaymentMethodRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentMethod = _b.paymentMethod, paymentDetails = _b.paymentDetails, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentMethodRequest();
                request.setPaymentMethod(paymentMethod);
                request.setPaymentDetails(paymentDetails);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updatePaymentMethod",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateEmailNotificationsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, cancelledCustomer = _b.cancelledCustomer, completedCustomer = _b.completedCustomer, editedCustomer = _b.editedCustomer, revisedCustomer = _b.revisedCustomer, completedTranslator = _b.completedTranslator, editedTranslator = _b.editedTranslator, revisedTranslator = _b.revisedTranslator, nightTranslator = _b.nightTranslator;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DisableEmailNotifications();
                request.setCancelledCustomer(cancelledCustomer);
                request.setCompletedCustomer(completedCustomer);
                request.setEditedCustomer(editedCustomer);
                request.setRevisedCustomer(revisedCustomer);
                request.setCompletedTranslator(completedTranslator);
                request.setEditedTranslator(editedTranslator);
                request.setRevisedTranslator(revisedTranslator);
                request.setNightTranslator(nightTranslator);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                console.log("Request", request.toObject());
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updateEmailNotifications",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var setSpellCheckerStatusRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var spellChecker = _b.spellChecker, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SpellCheckerStatusRequest();
                request.setSpellChecker(spellChecker);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "setSpellCheckerStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updatePersonalSettingsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, fullName = _b.fullName, name = _b.name, locale = _b.locale, currency = _b.currency, timeZone = _b.timeZone;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdatePersonalSettingsRequest();
                request.setFullname(fullName);
                request.setName(name);
                request.setLanguage(locale);
                request.setCurrency(currency);
                request.setTimeZone(timeZone);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updatePersonalSettings",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateBillToRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var billto = _b.billto, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateBillToRequest();
                request.setBillto(billto);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "updateBillTo",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var acceptTranslatorPopupRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, fullName = _b.fullName, name = _b.name, email = _b.email, paymentMethod = _b.paymentMethod, paymentDetails = _b.paymentDetails, timeZone = _b.timeZone;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new AcceptTranslatorPopupRequest();
                request.setFullname(fullName);
                request.setName(name);
                request.setEmail(email);
                request.setPaymentMethod(paymentMethod);
                request.setPaymentDetails(paymentDetails);
                request.setTimeZone(timeZone);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "acceptTranslatorPopup",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var saveCustomerReviewRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var mark = _b.mark, text = _b.text, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SaveCustomerReviewRequest();
                request.setMark(mark);
                request.setText(text);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "saveCustomerReview",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var saveVacationPeriodTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, vacationData, metadata;
    var startVacationPeriod = _b.startVacationPeriod, endVacationPeriod = _b.endVacationPeriod, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SaveVacationPeriodTranslatorRequest();
                vacationData = new Vacation();
                vacationData.setStartVacationPeriod(startVacationPeriod);
                vacationData.setEndVacationPeriod(endVacationPeriod);
                request.setVacation(vacationData);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "saveVacationPeriodTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var saveVacationPeriodAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, vacation, metadata;
    var userId = _b.userId, startVacationPeriod = _b.startVacationPeriod, endVacationPeriod = _b.endVacationPeriod, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SaveVacationPeriodAdminRequest();
                vacation = new Vacation();
                vacation.setStartVacationPeriod(startVacationPeriod);
                vacation.setEndVacationPeriod(endVacationPeriod);
                request.setUserId(userId);
                request.setVacation(vacation);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "saveVacationPeriodAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listLiteUsersRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var search = _b.search, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListLiteUsersRequest();
                request.setSearch(search);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: UserServiceClient,
                        methodName: "listLiteUsers",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
