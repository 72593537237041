export const INIT_AUTH = "INIT_AUTH";
export const REQUEST_AUTH = "REQUEST_AUTH";
export const SUCCEED_AUTH = "SUCCEED_AUTH";
export const FAIL_AUTH = "FAIL_AUTH";
export const UNCONFIRMED_AUTH = "UNCONFIRMED_AUTH";

export const REQUEST_FACEBOOK_AUTH = "REQUEST_FACEBOOK_AUTH";
export const SUCCEED_REQUEST_FACEBOOK_AUTH = "SUCCEED_REQUEST_FACEBOOK_AUTH";
export const FAIL_REQUEST_FACEBOOK_AUTH = "FAIL_REQUEST_FACEBOOK_AUTH";

export const VALIDATE_FACEBOOK_AUTH = "VALIDATE_FACEBOOK_AUTH";
export const SUCCEED_VALIDATE_FACEBOOK_AUTH = "SUCCEED_VALIDATE_FACEBOOK_AUTH";
export const FAIL_VALIDATE_FACEBOOK_AUTH = "FAIL_VALIDATE_FACEBOOK_AUTH";

export const REQUEST_GOOGLE_AUTH = "REQUEST_GOOGLE_AUTH";
export const SUCCEED_REQUEST_GOOGLE_AUTH = "SUCCEED_REQUEST_GOOGLE_AUTH";
export const FAIL_REQUEST_GOOGLE_AUTH = "FAIL_REQUEST_GOOGLE_AUTH";
export const REQUEST_GOOGLE_AUTH_WITH_CODE = "REQUEST_GOOGLE_AUTH_WITH_CODE";
export const SUCCEED_REQUEST_GOOGLE_AUTH_WITH_CODE = "SUCCEED_REQUEST_GOOGLE_AUTH_WITH_CODE";
export const FAIL_REQUEST_GOOGLE_AUTH_WITH_CODE = "FAIL_REQUEST_GOOGLE_AUTH_WITH_CODE";

export const VALIDATE_GOOGLE_AUTH = "VALIDATE_GOOGLE_AUTH";
export const SUCCEED_VALIDATE_GOOGLE_AUTH = "SUCCEED_VALIDATE_GOOGLE_AUTH";
export const FAIL_VALIDATE_GOOGLE_AUTH = "FAIL_VALIDATE_GOOGLE_AUTH";

export const FALLBACK_TO_EMAIL_SIGNUP = "FALLBACK_TO_EMAIL_SIGNUP";

export const SOCIAL_SIGNIN_ERROR = "SOCIAL_SIGNIN_ERROR";

export const EMAIL_FALLBACK = "EMAIL_FALLBACK";

export const FACEBOOK_AUTH_REQUESTED = "FACEBOOK_AUTH_REQUESTED";
export const FACEBOOK_AUTH_VALIDATED = "FACEBOOK_AUTH_VALIDATED";

export const GOOGLE_AUTH_REQUESTED = "GOOGLE_AUTH_REQUESTED";
export const GOOGLE_AUTH_WITH_CODE_REQUESTED = "GOOGLE_AUTH_WITH_CODE_REQUESTED";
export const GOOGLE_AUTH_VALIDATED = "GOOGLE_AUTH_VALIDATED";

export const AUTH_COMPLETED = "AUTH_COMPLETED";
export const AUTH_ERROR = "AUTH_ERROR";
