import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["apiKeys"]: { status: "", data: {} },
};

const getApiKeysReducer = createThunkReducer("apiKeys", "GET_API_KEYS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

export default function addNewApiKeysReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_NEW_API_KEY":
      const newArray = [...state.apiKeys.data.keysList];
      newArray.unshift(action.apiKey);
      return {
        ...state,
        apiKeys: {
          data: {
            keysList: newArray,
          },
        },
      };
    default:
      return state;
  }
}

function initialReducer(state = initialState) {
  return state;
}

export const apiKeysReducer = reduceReducers(initialReducer, getApiKeysReducer, addNewApiKeysReducer);
