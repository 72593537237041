import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import footer from "./css/footer.css";
import LanguageSwitcher from "../../LanguageSwitcher";

class Footer extends Component {
  render() {
    const { locale, german, japanese, russian, handleSaveLocale, style } = this.props;
    return (
      <div styleName={"footer.container"} style={style}>
        <div styleName={"footer.content"}>
          <LanguageSwitcher
            locale={locale}
            german={german}
            japanese={japanese}
            russian={russian}
            handleSaveLocale={handleSaveLocale}
          />
        </div>
      </div>
    );
  }
}

Footer.propTypes = {};

export default Footer;
