// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ1LjIgKDQzNTE0KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5Hcm91cDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJuaXRyby1sb2dpbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9InNpZ24tdXAtZmFjZWJvb2srZ29vZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzkwLjAwMDAwMCwgLTQ5Ni4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9InNpZ24tdXAtZm9ybSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzkwLjAwMDAwMCwgMjAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMjk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtNC1Db3B5IiBmaWxsPSIjMUU4OEU1IiB4PSIwIiB5PSIwIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHJ4PSIzIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgtMiIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIxNiA2IDE0IDYgOCAxMiA2IDEwIDQgMTAgNCAxMSA4IDE1IDE2IDciPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 2%27%3e%3cpath fill=%27white%27 d=%27M0 0H10V2H0V0Z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA1Mi42ICg2NzQ5MSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+OUQ1QTg1MDQtNjc1QS00NTA1LTk0NzgtM0M2M0E0Qjk3M0E0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxnIGlkPSJuaXRyby10cmFuc2xhdG9yIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iVUktS0lULURFTU8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNjQuMDAwMDAwLCAtMjAwNS4wMDAwMDApIiBmaWxsPSIjMUU4OEU1IiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMTc2LDIwMjcuOCBDMTgxLjk2NDY3NSwyMDI3LjggMTg2LjgsMjAyMi45NjQ2OCAxODYuOCwyMDE3IEMxODYuOCwyMDExLjAzNTMyIDE4MS45NjQ2NzUsMjAwNi4yIDE3NiwyMDA2LjIgQzE3MC4wMzUzMjUsMjAwNi4yIDE2NS4yLDIwMTEuMDM1MzIgMTY1LjIsMjAxNyBDMTY1LjIsMjAyMi45NjQ2OCAxNzAuMDM1MzI1LDIwMjcuOCAxNzYsMjAyNy44IFogTTE3NiwyMDI5IEMxNjkuMzcyNTgzLDIwMjkgMTY0LDIwMjMuNjI3NDIgMTY0LDIwMTcgQzE2NCwyMDEwLjM3MjU4IDE2OS4zNzI1ODMsMjAwNSAxNzYsMjAwNSBDMTgyLjYyNzQxNywyMDA1IDE4OCwyMDEwLjM3MjU4IDE4OCwyMDE3IEMxODgsMjAyMy42Mjc0MiAxODIuNjI3NDE3LDIwMjkgMTc2LDIwMjkgWiBNMTc2LDIwMjQgQzE3Mi4xMzQwMDcsMjAyNCAxNjksMjAyMC44NjU5OSAxNjksMjAxNyBDMTY5LDIwMTMuMTM0MDEgMTcyLjEzNDAwNywyMDEwIDE3NiwyMDEwIEMxNzkuODY1OTkzLDIwMTAgMTgzLDIwMTMuMTM0MDEgMTgzLDIwMTcgQzE4MywyMDIwLjg2NTk5IDE3OS44NjU5OTMsMjAyNCAxNzYsMjAyNCBaIiBpZD0iYWN0aXZlLXJhZGlvYnV0dG9uIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-checkbox__input__W8HTpV {
  position: absolute;
  opacity: 0;
}

.form-checkbox__input__W8HTpV:checked + label {
    border: 1px solid #1e88e5;
    color: #99a1a7;
    padding: 9px;
    border-radius: 1px;
    background: #1e88e5
      url(${___CSS_LOADER_URL_REPLACEMENT_0___})
      no-repeat;
    background-size: cover;
    height: 20px;
    width: 20px;
  }

.form-checkbox__input__W8HTpV:focus + label {
    outline: 1px solid #0075ff;
  }

.form-checkbox__input__W8HTpV:focus {
    outline: 1px solid #0075ff;
  }

.form-checkbox__input__W8HTpV[value^="intermediate"] + label {
  border-color: #1e88e5;
  background: #1e88e5;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}

.form-checkbox__inputCircle__bX7vaC {
  position: absolute;
  opacity: 0;
}

.form-checkbox__inputCircle__bX7vaC:checked + label {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_2___})
      no-repeat;
    padding: 9px;
    border-radius: 1px;
    background-size: cover;
    height: 20px;
    width: 20px;
  }

.form-checkbox__inputCircle__bX7vaC:focus + label {
    outline: 1px solid #0075ff;
  }

.form-checkbox__inputCircle__bX7vaC:focus {
    outline: 1px solid #0075ff;
  }

.form-checkbox__label__KGpZbr {
  border: 1px solid #cacece;
  padding: 9px;
  border-radius: 1px;
  display: inline-block;
  position: relative;
  float: left;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}

.form-checkbox__label__KGpZbr:focus {
    outline: none;
  }

.form-checkbox__control-label__VaTBsV {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  min-height: 2.4rem;
  line-height: 21px;
  margin-bottom: 0;
}

.form-checkbox__invalid__Dd3rv_ {
  border: 1px solid red;
}

.form-checkbox__validation-message__Pqxbkd {
  margin-top: 8px;
  color: #ef5350;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  line-height: 16px;
}

.form-checkbox__container__qp6JD0 {
  display: flex;
  flex-direction: column;
}

.form-checkbox__container__qp6JD0 > div {
  display: flex;
  height: 20px;
}

`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `form-checkbox__input__W8HTpV`,
	"inputCircle": `form-checkbox__inputCircle__bX7vaC`,
	"label": `form-checkbox__label__KGpZbr`,
	"control-label": `form-checkbox__control-label__VaTBsV`,
	"invalid": `form-checkbox__invalid__Dd3rv_ form-checkbox__label__KGpZbr`,
	"validation-message": `form-checkbox__validation-message__Pqxbkd`,
	"container": `form-checkbox__container__qp6JD0`
};
export default ___CSS_LOADER_EXPORT___;
