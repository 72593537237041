import { Component } from "react";
import PropTypes from "prop-types";

export default class TitlePage extends Component {
  static propTypes = {
    title: PropTypes.any.isRequired,
  };

  state = {
    title: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.title !== prevState.title) {
      document.title = nextProps.title;
    }
    return null;
  }

  render() {
    const { children } = this.props;
    return children ? children : null;
  }
}
