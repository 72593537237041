export const INIT_REGISTRATION = "INIT_REGISTRATION";
export const REQUEST_REGISTRATION = "REQUEST_REGISTRATION";
export const SUCCEED_REQUEST_REGISTRATION = "SUCCEED_REQUEST_REGISTRATION";
export const FAIL_REQUEST_REGISTRATION = "FAIL_REQUEST_REGISTRATION";

export const SAVE_FORM = "SAVE_FORM";
export const SET_STATUS = "SET_STATUS";

export const CONFIRM_REGISTRATION = "CONFIRM_REGISTRATION";
export const SUCCEED_CONFIRM_REGISTRATION = "SUCCEED_CONFIRM_REGISTRATION";
export const FAIL_CONFIRM_REGISTRATION = "FAIL_CONFIRM_REGISTRATION";

export const INIT_PASSWORD_RESET = "INIT_PASSWORD_RESET";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const SUCCEED_REQUEST_PASSWORD_RESET = "SUCCEED_REQUEST_PASSWORD_RESET";
export const FAIL_REQUEST_PASSWORD_RESET = "FAIL_REQUEST_PASSWORD_RESET";

export const CONFIRM_PASSWORD_RESET = "CONFIRM_PASSWORD_RESET";
export const SUCCEED_CONFIRM_PASSWORD_RESET = "SUCCEED_CONFIRM_PASSWORD_RESET";
export const FAIL_CONFIRM_PASSWORD_RESET = "FAIL_CONFIRM_PASSWORD_RESET";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const SUCCEED_RESET_PASSWORD = "SUCCEED_RESET_PASSWORD";
export const FAIL_RESET_PASSWORD = "FAIL_RESET_PASSWORD";

export const REGISTRATION_REQUESTED = "REGISTRATION_REQUESTED";
export const REGISTRATION_CONFIRMED = "REGISTRATION_CONFIRMED";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_CONFIRMED = "PASSWORD_RESET_CONFIRMED";
export const PASSWORD_RESET_COMPLETED = "PASSWORD_RESET_COMPLETED";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const PASSWORD_RESET_USER_NOT_ACTIVE_ERROR = "PASSWORD_RESET_USER_NOT_ACTIVE_ERROR";

//reset password statuses
export const PASSWORD_RESET_USER_NOT_ACTIVE_STATUS = "PASSWORD_RESET_USER_NOT_ACTIVE";
export const PASSWORD_RESET_USER_NOT_FOUND_STATUS = "PASSWORD_RESET_USER_NOT_FOUND";
