import React, { Component } from "react";
import {
  // Modifier,
  Popper,
} from "react-popper";
import { findDOMNode } from "react-dom";
// eslint-disable-next-line no-unused-vars
import popover from "./css/popover.css";
// eslint-disable-next-line no-unused-vars
import orders from "./css/something-wrong-order.css";
// eslint-disable-next-line no-unused-vars
import greenArrow from "./css/greenArrow.css";
import onClickOutside from "react-onclickoutside";

const getPopoverStyle = name => {
  const customPopoverStyle = {
    ordersDetailButton: "orders.detail-button-container-popover",
    estimatedTimeButton: "popover.estimated-time-container-popover",
    detailButton: "popover.detail-button-container-popover",
    green: "greenArrow.popper",
    customerOrderContext: "popover.popper-customerOrderContext",
    translatorOrderContext: "popover.popper-translatorOrderContext",
    orderContext: "popover.popper-orderContext",
    addFiles: "popover.popper-addFiles",
    default: "popover.popper",
    tableMenu: "popover.popper-table-menu",
    underHeaderTitle: "popover.underheader-title",
  };

  return customPopoverStyle[name] ? customPopoverStyle[name] : customPopoverStyle.default;
};

const getPopoverQuestionHintStyle = name => {
  const customPopoverStyle = {
    green: "greenArrow.questionHint",
    default: "popover.questionHint",
  };

  return customPopoverStyle[name] ? customPopoverStyle[name] : customPopoverStyle.default;
};

class Popover extends Component {
  state = {
    leftPosition: null,
    deferredRendering: true,
  };

  static defaultProps = {
    popperStyleName: "default",
    popperArrowStyleName: "default",
  };

  // Обязытельная функция для библиотеки onClickOutside
  handleClickOutside = evt => {
    const { handleBlur, target } = this.props;
    target && !target.contains(evt.target) && handleBlur && handleBlur(evt);
  };

  resetPosition() {
    this.props.fixedPosition &&
      setTimeout(() => {
        if (this.popper && this.popper.style) {
          let translate3dList = this.popper.style.transform.replace(/([\w]*\()|(\))+|(px)|(\s)/g, "").split(",");
          this.popper.style.transform = `translate3d(7px, ${Number(translate3dList[1])}px, 0px)`;

          const newVal = Number(this.arrow.style.left.replace("px", "")) + Number(translate3dList[0]) - 5;
          this.setState({
            leftPosition: `${newVal}px`,
          });
        }
      });
    this.state.deferredRendering &&
      setTimeout(() => {
        this.setState({ deferredRendering: false });
      });
  }

  render() {
    const {
      placement,
      onMouseEnter,
      onMouseLeave,
      target,
      onClick,
      mainText,
      popperStyleName,
      popperModifiers,
      style,
      notArrow,
      children,
      styleMain,
    } = this.props;
    const modifiers = popperModifiers
      ? popperModifiers
      : {
          preventOverflow: {
            enabled: true,
          },
        };
    const popperStyle = { ...style, opacity: this.state.deferredRendering ? 0 : 1 };

    return (
      <Popper
        key="popper"
        placement={placement}
        innerRef={c => {
          this.popper = findDOMNode(c);
          this.resetPosition();
        }}
        style={popperStyle}
        styleName={getPopoverStyle(popperStyleName)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        modifiers={modifiers}
        onClick={onClick}
        target={target}
      >
        {mainText && (
          <div style={styleMain} styleName={getPopoverQuestionHintStyle(popperStyleName)}>
            {mainText}
          </div>
        )}
        {children}
        {!notArrow && (
          <span>[WIP] Arrow removed</span>
          // <Arrow
          //   styleName={getPopoverArrowStyle(popperArrowStyleName)}
          //   style={arrowStyle}
          //   innerRef={(c) => {
          //     this.arrow = findDOMNode(c);
          //   }}
          // />
        )}
      </Popper>
    );
  }
}

export default onClickOutside(Popover);
