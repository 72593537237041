import { REQUEST_ME, SUCCEED_ME, FAIL_ME, ME_FETCHED, ME_ERROR } from "../constants/User";

const initialState = {
  id: null,
  status: null,
  name: null,
  email: null,
  locale: null,
  balanceOverdraftAllowed: null,
  reserved: null,
  error: null,
  roles: null,
  balance: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ME:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_ME:
      return {
        ...state,
        status: ME_FETCHED,
        id: action.me.id,
        createdTime: action.me.createdTime,
        name: action.me.fullName,
        email: action.me.email,
        locale: action.me.locale,
        balanceOverdraftAllowed: action.me.balanceOverdraftAllowed,
        reserved: action.me.reserved,
        roles: action.me.rolesList,
        balance: action.me.balance,
      };
    case FAIL_ME:
      return {
        ...state,
        status: ME_ERROR,
        error: action.error.message,
      };
    default:
      return state;
  }
}
