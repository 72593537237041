import React from "react";
import { Translate } from "react-redux-i18n";

import appsIcon from "../../../images/svg/category-menu/apps.svg";
import cryptoIcon from "../../../images/svg/category-menu/crypto.svg";
import casinoIcon from "../../../images/svg/category-menu/casino.svg";
import legalIcon from "../../../images/svg/category-menu/legal.svg";
import ecommerceIcon from "../../../images/svg/category-menu/e-commerce.svg";
import financeIcon from "../../../images/svg/category-menu/finance.svg";
import gamesIcon from "../../../images/svg/category-menu/games.svg";
import generalIcon from "../../../images/svg/category-menu/general.svg";
import mediaIcon from "../../../images/svg/category-menu/media.svg";
import medicalIcon from "../../../images/svg/category-menu/medical.svg";
import scienceIcon from "../../../images/svg/category-menu/science.svg";
import itIcon from "../../../images/svg/category-menu/it.svg";
import travelIcon from "../../../images/svg/category-menu/travel.svg";

export const advancedSettingsList = [
  {
    value: "FORMAL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU.FORMAL" />,
  },
  {
    value: "INFORMAL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU.INFORMAL" />,
  },
  {
    value: "TRANSLATOR_GUESS",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU.TRANSLATOR_GUESS" />,
  },
];

export const categorySettingsList = [
  {
    value: "ORDER_CATEGORY_APPS",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.APPS" />,
    icon: appsIcon,
  },
  {
    value: "ORDER_CATEGORY_BLOCKCHAIN_CRYPTO",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.CRYPTO" />,
    icon: cryptoIcon,
  },
  {
    value: "ORDER_CATEGORY_BOOKMAKER_CASINO",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.CASINO" />,
    icon: casinoIcon,
  },
  {
    value: "ORDER_CATEGORY_DOCUMENTS_LEGAL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.LEGAL" />,
    icon: legalIcon,
  },
  {
    value: "ORDER_CATEGORY_E_COMMERCE",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.E_COMMERCE" />,
    icon: ecommerceIcon,
  },
  {
    value: "ORDER_CATEGORY_ECONOMICS_FINANCE",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.FINANCE" />,
    icon: financeIcon,
  },
  {
    value: "ORDER_CATEGORY_GAMES",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.GAMES" />,
    icon: gamesIcon,
  },
  {
    value: "ORDER_CATEGORY_GENERAL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.GENERAL" />,
    icon: generalIcon,
  },
  {
    value: "ORDER_CATEGORY_MARKETING_MEDIA",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.MEDIA" />,
    icon: mediaIcon,
  },
  {
    value: "ORDER_CATEGORY_MEDICAL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.MEDICAL" />,
    icon: medicalIcon,
  },
  {
    value: "ORDER_CATEGORY_SCIENCE_EDUCATION",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.SCIENCE" />,
    icon: scienceIcon,
  },
  {
    value: "ORDER_CATEGORY_SOFTWARE_IT",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.IT" />,
    icon: itIcon,
  },
  {
    value: "ORDER_CATEGORY_TRAVEL",
    label: <Translate value="CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU.TRAVEL" />,
    icon: travelIcon,
  },
];
