// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading__load-more__i_1zkQ {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 24px;
  height: 24px; */
  animation: loading__load-animation__nN9LR4 1s infinite steps(12);
}

@keyframes loading__load-animation__nN9LR4 {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"load-more": `loading__load-more__i_1zkQ`,
	"load-animation": `loading__load-animation__nN9LR4`
};
export default ___CSS_LOADER_EXPORT___;
