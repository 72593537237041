// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 11 8%27%3e%3cpath fill=%27white%27 d=%27M11 0H9L4 5L2 3H0V4L4 8L11 1V0Z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 2%27%3e%3cpath fill=%27white%27 d=%27M0 0H10V2H0V0Z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
    margin: 0;
}
.uploadFilePopup__titleWrapper__boWsIa{
    padding: 0 24px;
    max-width: 575px;
    overflow: hidden;
}
.uploadFilePopup__title__lj36RJ{
    color: #212121;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 7px;
}
.uploadFilePopup__titleText__M1IPUB{
    color: #212121;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}
.uploadFilePopup__fileNameWrapper__gvPaG5{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 16px;
}
.uploadFilePopup__helpWrapper__nkZDfb{
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.uploadFilePopup__decorator__Uu_erN{
    color: #9E9E9E;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}
.uploadFilePopup__text__CCtod5{
    color: #424242;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.uploadFilePopup__totalChars__Dow8Ux{
    color: #9E9E9E;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
}
.uploadFilePopup__buttonsWrapper__BA4JDg{
    display: flex;
    gap: 16px;
    margin-bottom: 18px;
}
.uploadFilePopup__buttonSubmit__O57jAX {
    background: #43a047;
    border-radius: 3px;
    padding: 8px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border: none;
}
.uploadFilePopup__buttonSubmit__O57jAX:disabled{
    opacity: 0.5;
}
.uploadFilePopup__buttonCancel__FIfq_9 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #9e9e9e;
    background: transparent;
    border: none;
    padding: 0;
}
.uploadFilePopup__fileAttributeList__zwQOC5{
    margin-left: 29px;
}
.uploadFilePopup__checkBox__fh8it6 {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 0;
    padding: 6px 0;
  }
.uploadFilePopup__checkBoxTitle__DQxsFp {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #424242;
}
.uploadFilePopup__checkBox__fh8it6 > .uploadFilePopup__checkBoxInput__Ib46QU {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.uploadFilePopup__checkBox__fh8it6 > .uploadFilePopup__checkBoxTitle__DQxsFp {
    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}

.uploadFilePopup__checkBox__fh8it6 > .uploadFilePopup__checkBoxTitle__DQxsFp::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #bdbdbd;
    border-radius: 1px;
    margin-right: 16px;
}

.uploadFilePopup__checkBox__fh8it6 > .uploadFilePopup__checkBoxInput__Ib46QU[value^=checked] + .uploadFilePopup__checkBoxTitle__DQxsFp::before {
    border-color: #1e88e5;
    background: #1e88e5;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70% 70%;
  }

.uploadFilePopup__checkBox__fh8it6 > .uploadFilePopup__checkBoxInput__Ib46QU[value^=partial] + .uploadFilePopup__checkBoxTitle__DQxsFp::before {
    border-color: #1e88e5;
    background: #1e88e5;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70% 70%;
}
.uploadFilePopup__checkBoxTitle__DQxsFp .uploadFilePopup__decorator__Uu_erN{
    margin-right: 5px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": `uploadFilePopup__titleWrapper__boWsIa`,
	"title": `uploadFilePopup__title__lj36RJ`,
	"titleText": `uploadFilePopup__titleText__M1IPUB`,
	"fileNameWrapper": `uploadFilePopup__fileNameWrapper__gvPaG5`,
	"helpWrapper": `uploadFilePopup__helpWrapper__nkZDfb`,
	"decorator": `uploadFilePopup__decorator__Uu_erN`,
	"text": `uploadFilePopup__text__CCtod5`,
	"totalChars": `uploadFilePopup__totalChars__Dow8Ux`,
	"buttonsWrapper": `uploadFilePopup__buttonsWrapper__BA4JDg`,
	"buttonSubmit": `uploadFilePopup__buttonSubmit__O57jAX`,
	"buttonCancel": `uploadFilePopup__buttonCancel__FIfq_9`,
	"fileAttributeList": `uploadFilePopup__fileAttributeList__zwQOC5`,
	"checkBox": `uploadFilePopup__checkBox__fh8it6`,
	"checkBoxTitle": `uploadFilePopup__checkBoxTitle__DQxsFp`,
	"checkBoxInput": `uploadFilePopup__checkBoxInput__Ib46QU`
};
export default ___CSS_LOADER_EXPORT___;
