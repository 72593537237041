import MobileDetect from "mobile-detect";

export function handleLogout(triggered = false) {
  setCookie("auth_token", "", -10);
  setCookie("refresh_token", "", -10);
  setCookie("fblo_" + process.env.FACEBOOK_APP_ID, "", -10);
  if (!triggered) window.location = "/login?continue=" + location.pathname + location.search;
  else window.location = "/login";
}

export function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + "; path=/; " + expires;
}

export function setCookiePromise(name, value, days) {
  return new Promise((resolve, reject) => {
    setCookie(name, value, days);
    const cookie = getCookie(name);

    if (cookie) {
      resolve(cookie);
    } else {
      console.log("Can't get cookie! Check the name!");
      reject(0);
    }
  });
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function handleFail(error) {
  if (error.response && error.response.status === 401) handleLogout();
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2]);
}

const checkIsEmailValid = (email = "") => {
  return String(email)
    .toLowerCase()
    .match(
      /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/
    );
};

export function validate(form) {
  let errors = {};

  for (let key in form) {
    if (key === "email" && !checkIsEmailValid(form[key])) {
      errors[key] = "invalid";
    }
    if (form[key] === "") errors[key] = "empty";
    if (key === "terms" && !form[key]) errors[key] = "unchecked";
  }

  if (Object.keys(errors).length === 0 && errors.constructor === Object) return false;
  else return errors;
}

export function clearErrors() {
  let elements = document.querySelectorAll(".has-error");
  for (let element of elements) {
    element.classList.remove("has-error");
    element.classList.remove("custom-error");
  }
}

export function showErrors(errors) {
  let first;
  for (let key in errors) {
    let element;
    if (key === "terms") element = document.getElementById(key + "-label");
    else element = document.getElementById(key);

    if (!first) first = element;

    let parent = element.parentNode;
    if (errors[key] === "custom" || errors[key] === "invalid") parent.classList.add("custom-error");
    parent.classList.add("has-error");
  }
  first.focus();
}

export function setFocusOnFirst(fieldType = "input") {
  const md = new MobileDetect(window.navigator.userAgent);
  const fields = document.querySelectorAll(fieldType);
  if (!md.mobile()) fields[0].focus();
}

export function closePopovers() {
  const body = document.getElementsByTagName("body")[0];
  const click = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  body.dispatchEvent(click);
}

export function clearFadeIns() {
  const elements = document.getElementsByClassName("with-fade");
  while (elements.length > 0) {
    elements[0].classList.remove("with-fade");
  }
}

export function checkIfNumber(id) {
  const regexp = new RegExp("^[0-9]*$");
  return regexp.test(id);
}

export function isLocalStorageSupported(storage) {
  if (!storage) {
    return false;
  } else {
    try {
      const key = "__some_random_key_you_are_not_going_to_use__";
      storage.setItem(key, key);
      storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export const handleTabFocus = (e, modalElements) => {
  if (e.key === "Tab") {
    e.preventDefault();

    const currentFocusIndex = Array.from(modalElements).indexOf(document.activeElement);
    const nextFocusIndex = (currentFocusIndex + 1) % modalElements.length;
    modalElements[nextFocusIndex].focus();
  }
};
