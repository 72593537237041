import PropTypes from "prop-types";
import React, { Component } from "react";
import ResetForm from "../../components/forms/ResetForm";
import FormError from "../../components/errors/FormError";
import { setTitle, validateForm } from "../../actions/App";
import { setCookie } from "../../helpers/common";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { confirmResetPasswordAction, resetPasswordAction } from "../../thunks/newThunks/signup/signupAsyncActions";
import { APP_DISABLED } from "../../constants/App";
import { PASSWORD_RESET_COMPLETED, PASSWORD_RESET_ERROR } from "../../constants/Signup";
import { withRouter } from "../CustomerApp";

export class ForgotReset extends Component {
  static propTypes = {
    confirmResetPassword: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    resetPassword: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
  };

  state = {
    closeError: false,
    resetPasswordStatus: this.props.resetPassword.status,
    shouldNavigateToPrevResetPasswordStep: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.resetPassword.status === PASSWORD_RESET_COMPLETED &&
      nextProps.resetPassword.status !== prevState.resetPasswordStatus
    ) {
      setCookie("auth_token", nextProps.resetPassword.authToken, 3650);
      woopra.track("signed in", {
        method: "Auto",
        env: process.env.SERVER_NAME,
      });
      window.location = "/";
      return {
        resetPasswordStatus: nextProps.resetPassword.status,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (!this.props.resetPassword.email) {
      this.setState({ shouldNavigateToPrevResetPasswordStep: true });
    } else {
      this.props.setTitle(I18n.t("RESET_PASSWORD_PAGE.PAGE_TITLE"));
      this.setState({ shouldNavigateToPrevResetPasswordStep: false });
    }
  }

  componentDidUpdate() {
    if (this.state.shouldNavigateToPrevResetPasswordStep) {
      this.props.router.navigate("/forgot-password");
    }
  }

  closeErrorMessage = () => {
    this.setState({ closeError: true });
  };

  render() {
    const { styleForm } = this.props;
    const displayErrorMessage = this.props.resetPassword.status === PASSWORD_RESET_ERROR && !this.state.closeError;
    return (
      <div>
        <FormError
          onClose={this.closeErrorMessage}
          message={this.props.resetPassword.error}
          display={displayErrorMessage}
        />
        <ResetForm
          disabled={this.props.app.status === APP_DISABLED}
          validator={this.props.validateForm}
          handler={this.props.confirmResetPassword}
          reSendConfirmEmail={this.props.fetchResetPassword}
          email={this.props.resetPassword.email}
          styleForm={styleForm ? styleForm : {}}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resetPassword: state.resetPassword,
    app: state.app,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchResetPassword: form => {
      dispatch(resetPasswordAction({ email: form.email }));
    },
    confirmResetPassword: form => {
      dispatch(confirmResetPasswordAction({ email: form.email, code: form.passwordCode, password: form.password }));
    },
    validateForm: () => {
      dispatch(validateForm());
    },
    setTitle: title => {
      dispatch(setTitle(title));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotReset));
