// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-view__imageContainer__tRC4ON {
  position: relative;
  width: 188px;
  height: 188px;
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
}

.image-view__imageMessageContainer__suaAiJ {
  position: relative;
  width: 118px;
  height: 118px;
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
}

.image-view__uploadedImage__xJDFUN {
  width: 100%;
  height: 100%;
}

.image-view__image__Dt3dFq {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

.image-view__imageLoading__DEV1wZ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*-ms-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
}

.image-view__imageContainer__tRC4ON:hover .image-view__hoverCover__HuHP8i,
.image-view__imageMessageContainer__suaAiJ:hover .image-view__hoverCover__HuHP8i {
  display: block;
}

.image-view__hoverCover__HuHP8i {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #21212166;
  left: 0;
  top: 0;
  display: none;
  cursor: zoom-in;
}

.image-view__loadingIndicator__ugeZGT {
}

.image-view__deleteImage__n9Vrbc {
  right: 8px;
  top: 8px;
  position: absolute;
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 142px;
  cursor: pointer;
  display: flex;
}

@media screen and (max-width: 480px) {
  .image-view__hoverCover__HuHP8i {
    display: block;
  }
  .image-view__imageContainer__tRC4ON {
    width: 136px;
    height: 136px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `image-view__imageContainer__tRC4ON`,
	"imageMessageContainer": `image-view__imageMessageContainer__suaAiJ`,
	"uploadedImage": `image-view__uploadedImage__xJDFUN`,
	"image": `image-view__image__Dt3dFq`,
	"imageLoading": `image-view__imageLoading__DEV1wZ`,
	"hoverCover": `image-view__hoverCover__HuHP8i`,
	"loadingIndicator": `image-view__loadingIndicator__ugeZGT`,
	"deleteImage": `image-view__deleteImage__n9Vrbc`
};
export default ___CSS_LOADER_EXPORT___;
