import { SignInTypes } from "./signinActionTypes";
export function requestEmail() {
    return {
        type: SignInTypes.EMAIL_REQUEST,
    };
}
export function successEmail(payload) {
    return {
        type: SignInTypes.EMAIL_SUCCESS,
        payload: payload,
    };
}
