import * as types from "../constants/Signin";

export function requestFacebookAuth() {
  return {
    type: types.REQUEST_FACEBOOK_AUTH,
  };
}

export function succeedRequestFacebookAuth(facebookToken) {
  return {
    type: types.SUCCEED_REQUEST_FACEBOOK_AUTH,
    facebookToken,
  };
}

export function failRequestFacebookAuth(error) {
  return {
    type: types.FAIL_REQUEST_FACEBOOK_AUTH,
    error,
  };
}
