import { CouponsTypes, } from "./couponsActionTypes";
export function requestGetCoupons() {
    return {
        type: CouponsTypes.GET_COUPONS_REQUEST,
    };
}
export function successGetCoupons(payload) {
    return {
        type: CouponsTypes.GET_COUPONS_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteCoupon() {
    return {
        type: CouponsTypes.DELETE_COUPONS_REQUEST,
    };
}
export function successDeleteCoupon(payload) {
    return {
        type: CouponsTypes.DELETE_COUPONS_SUCCESS,
        payload: payload,
    };
}
export function requestBlockCoupon() {
    return {
        type: CouponsTypes.DELETE_COUPONS_REQUEST,
    };
}
export function successBlockCoupon(payload) {
    return {
        type: CouponsTypes.DELETE_COUPONS_SUCCESS,
        payload: payload,
    };
}
export function requestGetCouponById() {
    return {
        type: CouponsTypes.GET_COUPON_BY_ID_REQUEST,
    };
}
export function successGetCouponById(payload) {
    return {
        type: CouponsTypes.GET_COUPON_BY_ID_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateCoupon() {
    return {
        type: CouponsTypes.GET_COUPON_BY_ID_REQUEST,
    };
}
export function successUpdateCoupon(payload) {
    return {
        type: CouponsTypes.GET_COUPON_BY_ID_SUCCESS,
        payload: payload,
    };
}
export function requestGenerateCoupons() {
    return {
        type: CouponsTypes.GENERATE_COUPONS_REQUEST,
    };
}
export function successGenerateCoupons(payload) {
    return {
        type: CouponsTypes.GENERATE_COUPONS_SUCCESS,
        payload: payload,
    };
}
export function requestGetListCouponUsers() {
    return {
        type: CouponsTypes.GET_COUPON_USERS_REQUEST,
    };
}
export function successGetListCouponUsers(payload) {
    return {
        type: CouponsTypes.GET_COUPON_USERS_SUCCESS,
        payload: payload,
    };
}
export function requestGetCouponInfo() {
    return {
        type: CouponsTypes.GET_COUPON_INFO_REQUEST,
    };
}
export function successGetCouponInfo(payload) {
    return {
        type: CouponsTypes.GET_COUPON_INFO_SUCCESS,
        payload: payload,
    };
}
