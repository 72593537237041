import moment from "moment";
import { I18n } from "react-redux-i18n";

const formatDateForToday = (date, todayTime, editBlock) => {
  const formattedDate = todayTime
    ? moment(date).format("HH:mm:ss")
    : moment().calendar(null, {
        lastDay: `[${I18n.t("CALENDAR.YESTERDAY")}]`,
        sameDay: `[${I18n.t("CALENDAR.TODAY")}]`,
      });

  return editBlock ? moment(date).format(I18n.t("ORDER_PAGE.DATE_FORMAT")) : formattedDate;
};

const formatDateForPast = (date, editBlock) => {
  return editBlock
    ? moment(date).format(I18n.t("ORDER_PAGE.DATE_FORMAT"))
    : moment(date).format(I18n.t("CALENDAR.DATE_FORMAT_WITHOUT_TIME"));
};

const settingsFormatDate = (date, todayTime, editBlock) => {
  const today = moment(date).isSameOrAfter(moment(), "day");
  return today ? formatDateForToday(date, todayTime, editBlock) : formatDateForPast(date, editBlock);
};

export default settingsFormatDate;
