export const CREATE_TRANSLATOR_RATE = "CREATE_TRANSLATOR_RATE";
export const GET_TRANSLATOR_RATES = "GET_TRANSLATOR_RATES";
export const CREATE_CUSTOMER_RATE = "CREATE_CUSTOMER_RATE";
export const GET_CUSTOMER_RATES = "GET_CUSTOMER_RATES";
export const GET_ALL_CUSTOMER_RATES = "GET_ALL_CUSTOMER_RATES";
export const DELETE_TRANSLATOR_RATES = "DELETE_TRANSLATOR_RATES";
export const UPDATE_TRANSLATOR_RATES = "UPDATE_TRANSLATOR_RATES";
export const DELETE_CUSTOMER_RATES = "DELETE_CUSTOMER_RATES";
export const UPDATE_CUSTOMER_RATES = "UPDATE_CUSTOMER_RATES";
export const GET_COUNT_LOW_MARGIN_RATES = "GET_COUNT_LOW_MARGIN_RATES";
