import {
  DISABLE_APP,
  ENABLE_APP,
  SET_TITLE,
  SET_DESCRIPTION,
  SET_TOKEN,
  APP_DISABLED,
  SET_REFRESH_TOKEN,
  SET_IS_TOKEN_LOADING,
} from "../constants/App";

import { TOGGLE_SIDE_MENU } from "../constants/AuthorizedActionTypes";

const initialState = {
  status: null,
  title: "Nitro",
  showSideMenu: false,
  description: null,
  disablingTrigger: null,
  token: null,
  refreshToken: null,
  isTokenLoading: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_IS_TOKEN_LOADING:
      return {
        ...state,
        isTokenLoading: action.value,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        isTokenLoading: false,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.refreshToken,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        title: action.description,
      };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        showSideMenu: action.isOpened,
      };
    case DISABLE_APP:
      return {
        ...state,
        status: APP_DISABLED,
        disablingTrigger: action.trigger || "",
      };
    case ENABLE_APP:
      return {
        ...state,
        status: null,
        disablingTrigger: null,
      };
    default:
      return state;
  }
}
