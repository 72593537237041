const noop = () => {};

let mappedEvents = {};

export default {
  register: (messageName, handler) => {
    mappedEvents[messageName] = handler;
  },

  unregister: messageName => {
    delete mappedEvents[messageName];
  },

  getAll: () => mappedEvents,

  get: messageName => mappedEvents[messageName] || noop,
};
