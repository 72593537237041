import { DESC } from "../constants/Sort";

const initialState = {
  tableState: {
    search: "",
    scrollPosition: {
      x: 0,
      y: 0,
    },
    activeEditBlockRole: "",
    payments: {
      loadLimit: 10,
      selected: [],
      sort: {
        id: "PAYMENT_ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        payment: {},
      },
      filter: {
        method: "",
        status: "",
      },
    },
    payouts: {
      loadLimit: 10,
      selected: [],
      sort: {
        id: "PAYOUT_ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        payout: {},
      },
      filter: {
        method: "",
        status: {
          value: "2",
          label: "Incompleted",
        },
      },
    },
  },
};

export default function payments(state = initialState, action) {
  const typePaymentIsNotEmpty = !action.typePayment && action.state;
  let newState = {};

  !typePaymentIsNotEmpty
    ? (newState = {
        tableState: {
          ...state.tableState,
          [action.typePayment]: {
            ...state.tableState[action.typePayment],
            ...action.state,
          },
        },
      })
    : (newState = {
        tableState: {
          ...state.tableState,
          ...typePaymentIsNotEmpty,
        },
      });

  switch (action.type) {
    case "CHANGE_PAYMENTS_TABLE_STATE":
      return newState;
    default:
      return state;
  }
}
