export function changeBalanceTableState(state) {
  return {
    type: "CHANGE_BALANCE_TABLE_STATE",
    state,
  };
}

export function scrollToAddFunds(itemId, inputBalanceValue) {
  return {
    type: "SCROLL_TO_ADD_FUNDS",
    itemId,
    inputBalanceValue,
  };
}
