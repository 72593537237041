var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { CustomerRateServiceClient } from "@alconost/nitro-services-api/src/proto/customer_rate_service_grpc_web_pb";
import { AddCustomerRateRequest, DeleteCustomerRatesRequest, GetRateRequest, ListCustomerRatesRequest, } from "@alconost/nitro-services-api/src/proto/customer_rate_service_pb";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { Empty, Page, Rate, SortCustomerRateField, SortOrder, GetRatesRequest, } from "@alconost/nitro-services-api/src/proto/base_pb";
export var deleteCustomerRateRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, deleteRateRequest;
    var rateIdsList = _b.rateIdsList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                deleteRateRequest = new DeleteCustomerRatesRequest();
                deleteRateRequest.setIdsList(rateIdsList);
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "deleteRate",
                        protoRequest: deleteRateRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateCustomerRateRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, customerRateServiceUpdateRate;
    var quality = _b.quality, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, rate = _b.rate, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                customerRateServiceUpdateRate = new Rate();
                customerRateServiceUpdateRate.setSourceLanguage(sourceLanguage);
                customerRateServiceUpdateRate.setTargetLanguage(targetLanguage);
                customerRateServiceUpdateRate.setQuality(quality);
                customerRateServiceUpdateRate.setRate(rate);
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "updateRate",
                        protoRequest: customerRateServiceUpdateRate,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCustomerRateByIdRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, getRateRequest;
    var id = _b.id, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                getRateRequest = new GetRateRequest();
                getRateRequest.setId(id);
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "getRate",
                        protoRequest: getRateRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createCustomerRateRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, addCustomerRateRequest;
    var sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, rate = _b.rate, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                addCustomerRateRequest = new AddCustomerRateRequest();
                addCustomerRateRequest.setQuality(1);
                addCustomerRateRequest.setRate(rate);
                addCustomerRateRequest.setSourceLanguage(sourceLanguage);
                addCustomerRateRequest.setTargetLanguage(targetLanguage);
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "addRate",
                        protoRequest: addCustomerRateRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCustomerListRatesRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var sourceLanguageStringValue, targetLanguageStringValue, metadata, listCustomerRatesRequest, page;
    var offset = _b.offset, limit = _b.limit, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, locale = _b.locale, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                sourceLanguageStringValue = new StringValue();
                sourceLanguageStringValue.setValue(sourceLanguage);
                targetLanguageStringValue = new StringValue();
                targetLanguageStringValue.setValue(targetLanguage);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                listCustomerRatesRequest = new ListCustomerRatesRequest();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                listCustomerRatesRequest.setPage(page);
                listCustomerRatesRequest.setSearch(search);
                listCustomerRatesRequest.setField(SortCustomerRateField[sortField]);
                listCustomerRatesRequest.setOrder(SortOrder[sortOrder]);
                sourceLanguage && listCustomerRatesRequest.setSourceLanguage(sourceLanguageStringValue);
                targetLanguage && listCustomerRatesRequest.setTargetLanguage(targetLanguageStringValue);
                listCustomerRatesRequest.setLocale(locale);
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "listRates",
                        protoRequest: listCustomerRatesRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCustomerRatesRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new GetRatesRequest();
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "getRates",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getLanguagePairsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, empty;
    var token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                empty = new Empty();
                return [2 /*return*/, grpcRequest({
                        clientService: CustomerRateServiceClient,
                        methodName: "getLanguagePairs",
                        protoRequest: empty,
                        metadata: metadata,
                    })];
        }
    });
}); };
