import { createThunkReducer } from "../../helpers/createThunkReducer";
import { GET_PAYMENTS, GET_PAYOUT_INCOMPLETE_COUNT, GET_PAYOUTS } from "../../constants/Payment";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["payments"]: { status: "", data: { hasmore: false } },
  ["payouts"]: { status: "", payoutIncompleteCount: 0, data: { hasmore: false } },
  ["exchangeRate"]: { rate: "" },
  ["personalExchangeRate"]: { rate: "" },
  ["payment"]: {},
};

const getPayments = createThunkReducer("payments", GET_PAYMENTS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getPayouts = createThunkReducer("payouts", GET_PAYOUTS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { ...state.payouts, data: payload } };
  },
});

const getPayoutIncompleteCount = createThunkReducer("payouts", GET_PAYOUT_INCOMPLETE_COUNT, {
  handleSuccessAction: (state, { count }) => {
    return { requestState: { ...state.payouts, payoutIncompleteCount: count } };
  },
});

const confirmPaymentRequest = createThunkReducer("payments", "CONFIRM_PAYMENT", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getReceiptPaymentRequest = createThunkReducer("payment", "GET_PAYMENT_INVOICE_RECEIPT_BY_UUID", {
  handleSuccessAction: (state, payload) => {
    return { requestState: payload };
  },
});

const getInvoicePaymentRequest = createThunkReducer("payment", "GET_PAYMENT_INVOICE_RECEIPT_BY_UUID", {
  handleSuccessAction: (state, payload) => {
    return { requestState: payload };
  },
});

const getExchangeRateRequest = createThunkReducer("exchangeRate", "EXCHANGE_RATE", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { rate: payload } };
  },
});

const getPersonalExchangeRateRequest = createThunkReducer("personalExchangeRate", "PERSONAL_EXCHANGE_RATE", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { rate: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const paymentsReducer = reduceReducers(
  initialReducer,
  getPayments,
  confirmPaymentRequest,
  getReceiptPaymentRequest,
  getExchangeRateRequest,
  getPersonalExchangeRateRequest,
  getInvoicePaymentRequest,
  getPayouts,
  getPayoutIncompleteCount
);
