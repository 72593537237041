// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./search-input.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.under-header__base-under-header-container__IHeKsG {
  display: flex;
  background: #fff;
  min-width: 100%;
  max-width: 100%;
  position: fixed;
  -webkit-backface-visibility: hidden;
  top: 48px;
  left: 0;
  z-index: 15;
}

.under-header__container-fixed__p8e7ig {
  padding: 0 16px;
  align-items: center;
  border-radius: 1px;
  height: 48px;
  box-shadow: 0 1px 1px 0 #e0e0e0;
}

.under-header__title__LirSF0,
.under-header__input-title__emPIUi,
.under-header__input-title__emPIUi:disabled {
  margin-right: 8px;
  align-self: center;
  color: #000000;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  max-width: 49%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.under-header__input-title__emPIUi,
.under-header__input-title__emPIUi:active,
.under-header__input-title__emPIUi:hover,
.under-header__input-title__emPIUi:focus,
.under-header__input-title__emPIUi:disabled {
  background: inherit;
  padding: 0;
  border: 0 !important;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  max-width: 49%;
  flex: 2;
}

.under-header__input-title-error__bmGqkL {
  color: #dd4b39;
}

.under-header__base-refresh-trigger__O5XoUq {
  align-self: center;
  top: 10px;
  right: 5px;
}

.under-header__refresh-trigger__AXUfKw {
  text-align: right;
}

.under-header__block__DNJkYY {
  flex: 2;
  text-align: right;
}

.under-header__tableToolbar__W_Z00e {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
}

.under-header__search__izil4Y {
}

.under-header__box__aAx2hV {
}

.under-header__icon-container__Nh8R8c {
}

.under-header__input-container__fsVpbZ {
}

.under-header__icon-base__hpB4oC {
}

.under-header__icon-unfocus__lwCKf9 {
}

.under-header__icon-focus__tnnqPC {
}

.under-header__icon-unfocus-content__e8SsoT {
}

.under-header__input__wLWZj2 {
}

.under-header__backArrow__k2z5pM {
  display: flex;
  height: 18px;
  width: 10px;
  margin-right: 14px;
  cursor: pointer;
}

@media screen and (max-width: 890px) {
  .under-header__base-under-header-container__IHeKsG {
    top: 48px !important;
  }
}

@media screen and (max-width: 520px) {
  .under-header__title__LirSF0,
  .under-header__input-title__emPIUi {
    max-width: 100%;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base-under-header-container": `under-header__base-under-header-container__IHeKsG`,
	"container-fixed": `under-header__container-fixed__p8e7ig under-header__base-under-header-container__IHeKsG`,
	"title": `under-header__title__LirSF0`,
	"input-title": `under-header__input-title__emPIUi`,
	"input-title-error": `under-header__input-title-error__bmGqkL`,
	"base-refresh-trigger": `under-header__base-refresh-trigger__O5XoUq`,
	"refresh-trigger": `under-header__refresh-trigger__AXUfKw under-header__base-refresh-trigger__O5XoUq`,
	"block": `under-header__block__DNJkYY under-header__base-refresh-trigger__O5XoUq`,
	"tableToolbar": `under-header__tableToolbar__W_Z00e`,
	"search": `under-header__search__izil4Y ${___CSS_LOADER_ICSS_IMPORT_0___.locals["search"]}`,
	"box": `under-header__box__aAx2hV ${___CSS_LOADER_ICSS_IMPORT_0___.locals["box"]}`,
	"icon-container": `under-header__icon-container__Nh8R8c ${___CSS_LOADER_ICSS_IMPORT_0___.locals["icon-container"]}`,
	"input-container": `under-header__input-container__fsVpbZ ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input-container"]}`,
	"icon-base": `under-header__icon-base__hpB4oC ${___CSS_LOADER_ICSS_IMPORT_0___.locals["icon-base"]}`,
	"icon-unfocus": `under-header__icon-unfocus__lwCKf9 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["icon-unfocus"]}`,
	"icon-focus": `under-header__icon-focus__tnnqPC ${___CSS_LOADER_ICSS_IMPORT_0___.locals["icon-focus"]}`,
	"icon-unfocus-content": `under-header__icon-unfocus-content__e8SsoT ${___CSS_LOADER_ICSS_IMPORT_0___.locals["icon-unfocus-content"]}`,
	"input": `under-header__input__wLWZj2 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"backArrow": `under-header__backArrow__k2z5pM`
};
export default ___CSS_LOADER_EXPORT___;
