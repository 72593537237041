// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advanced-options__validationText__UHlHBu {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9e9e9e;
  position: absolute;
  left: 150px;
  bottom: 5px;
}

.advanced-options__advancedSettingsWrapper__QjdEew {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.advanced-options__advansedLabel__O85OB_ {
  color: #424242;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
}

.advanced-options__advansedWrapper__GaIWjS {
  margin-bottom: 15px;
}

@media (max-width: 340px) {
  .advanced-options__validationText__UHlHBu {
    bottom: -5px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validationText": `advanced-options__validationText__UHlHBu`,
	"advancedSettingsWrapper": `advanced-options__advancedSettingsWrapper__QjdEew`,
	"advansedLabel": `advanced-options__advansedLabel__O85OB_`,
	"advansedWrapper": `advanced-options__advansedWrapper__GaIWjS`
};
export default ___CSS_LOADER_EXPORT___;
