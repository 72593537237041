// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-wait #app-container * {
  cursor: progress !important;
}

.app-wait #globalWrapper * {
  pointer-events: none;
}

.local-wait #app-container * {
  cursor: progress !important;
}

.local-wait #globalWrapper * {
  pointer-events: none;
}

.local-none-event {
  pointer-events: none;
  cursor: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/deprecated/app-wait.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".app-wait #app-container * {\r\n  cursor: progress !important;\r\n}\r\n\r\n.app-wait #globalWrapper * {\r\n  pointer-events: none;\r\n}\r\n\r\n.local-wait #app-container * {\r\n  cursor: progress !important;\r\n}\r\n\r\n.local-wait #globalWrapper * {\r\n  pointer-events: none;\r\n}\r\n\r\n.local-none-event {\r\n  pointer-events: none;\r\n  cursor: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
