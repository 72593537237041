var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//@ts-nocheck
import { deleteCustomerRateRequest, updateCustomerRateRequest, getCustomerRateByIdRequest, createCustomerRateRequest, getCustomerListRatesRequest, getCustomerRatesRequest, getLanguagePairsRequest, } from "../../../services/CustomerRates/requests";
import { requestDeleteCustomerRates, successDeleteCustomerRates, requestUpdateCustomerRate, successUpdateCustomerRate, requestGetCustomerRateById, successGetCustomerRateById, requestCreateCustomerRate, successCreateCustomerRate, requestGetCustomerListRates, successGetCustomerListRates, requestAllGetCustomerListRates, successAllGetCustomerListRates, requestGetCustomerRates, successGetCustomerRates, requestGetLanguagePairs, successGetLanguagePairs, } from "./customerRateActionCreators";
import { showNotification } from "../../Notification";
import { I18n } from "react-redux-i18n";
var MAX_LIMIT = 5000;
export var deleteCustomerRateAction = function (_a) {
    var rateIdsList = _a.rateIdsList;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestDeleteCustomerRates());
                    return [4 /*yield*/, deleteCustomerRateRequest({ rateIdsList: rateIdsList, token: token })];
                case 1:
                    data = _a.sent();
                    dispatch(successDeleteCustomerRates(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var updateCustomerRateAction = function (_a) {
    var quality = _a.quality, sourceLanguage = _a.sourceLanguage, targetLanguage = _a.targetLanguage, rate = _a.rate;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestUpdateCustomerRate());
                    return [4 /*yield*/, updateCustomerRateRequest({
                            quality: quality,
                            sourceLanguage: sourceLanguage,
                            targetLanguage: targetLanguage,
                            rate: rate,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(showNotification(I18n.t("ADMIN_APP.RATES_PAGE.EDIT_RATE_SECTION.SUCCESS_MESSAGE")));
                    dispatch(successUpdateCustomerRate(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var getCustomerRateByIdAction = function (_a) {
    var id = _a.id;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGetCustomerRateById());
                    return [4 /*yield*/, getCustomerRateByIdRequest({ id: id, token: token })];
                case 1:
                    data = _a.sent();
                    dispatch(successGetCustomerRateById(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var createCustomerRateAction = function (_a) {
    var sourceLanguage = _a.sourceLanguage, targetLanguage = _a.targetLanguage, rate = _a.rate;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestCreateCustomerRate());
                    return [4 /*yield*/, createCustomerRateRequest({
                            sourceLanguage: sourceLanguage,
                            targetLanguage: targetLanguage,
                            rate: rate,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(showNotification(I18n.t("ADMIN_APP.RATES_PAGE.NEW_TRANSLATOR_RATES_SECTION.SUCCESS_MESSAGE")));
                    dispatch(successCreateCustomerRate(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var getCustomerListRatesAction = function (_a) {
    var offset = _a.offset, limit = _a.limit, search = _a.search, sortField = _a.sortField, sortOrder = _a.sortOrder, sourceLanguage = _a.sourceLanguage, targetLanguage = _a.targetLanguage, locale = _a.locale;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    if (!(limit === MAX_LIMIT)) return [3 /*break*/, 2];
                    dispatch(requestAllGetCustomerListRates());
                    return [4 /*yield*/, getCustomerListRatesRequest({
                            offset: offset,
                            limit: limit,
                            search: search,
                            sortField: sortField,
                            sortOrder: sortOrder,
                            sourceLanguage: sourceLanguage,
                            targetLanguage: targetLanguage,
                            locale: locale,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(successAllGetCustomerListRates(data));
                    return [3 /*break*/, 4];
                case 2:
                    dispatch(requestGetCustomerListRates());
                    return [4 /*yield*/, getCustomerListRatesRequest({
                            offset: offset,
                            limit: limit,
                            search: search,
                            sortField: sortField,
                            sortOrder: sortOrder,
                            sourceLanguage: sourceLanguage,
                            targetLanguage: targetLanguage,
                            locale: locale,
                            token: token,
                        })];
                case 3:
                    data = _a.sent();
                    dispatch(successGetCustomerListRates(data));
                    _a.label = 4;
                case 4: return [2 /*return*/, data];
            }
        });
    }); };
};
export var getCustomerRatesAction = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var token, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = getState().app.token;
                dispatch(requestGetCustomerRates());
                return [4 /*yield*/, getCustomerRatesRequest({ token: token })];
            case 1:
                data = _a.sent();
                dispatch(successGetCustomerRates(data));
                return [2 /*return*/, data];
        }
    });
}); }; };
export var getLanguagePairsAction = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var token, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = getState().app.token;
                dispatch(requestGetLanguagePairs());
                return [4 /*yield*/, getLanguagePairsRequest({ token: token })];
            case 1:
                data = _a.sent();
                dispatch(successGetLanguagePairs(data));
                return [2 /*return*/, data];
        }
    });
}); }; };
