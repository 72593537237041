import {
  EMAIL_FALLBACK,
  FACEBOOK_AUTH_REQUESTED,
  FACEBOOK_AUTH_VALIDATED,
  FAIL_VALIDATE_FACEBOOK_AUTH,
  FAIL_VALIDATE_GOOGLE_AUTH,
  FALLBACK_TO_EMAIL_SIGNUP,
  GOOGLE_AUTH_REQUESTED,
  GOOGLE_AUTH_VALIDATED,
  GOOGLE_AUTH_WITH_CODE_REQUESTED,
  REQUEST_FACEBOOK_AUTH,
  REQUEST_GOOGLE_AUTH,
  SOCIAL_SIGNIN_ERROR,
  SUCCEED_REQUEST_FACEBOOK_AUTH,
  SUCCEED_REQUEST_GOOGLE_AUTH,
  SUCCEED_REQUEST_GOOGLE_AUTH_WITH_CODE,
  SUCCEED_VALIDATE_FACEBOOK_AUTH,
  SUCCEED_VALIDATE_GOOGLE_AUTH,
  VALIDATE_FACEBOOK_AUTH,
  VALIDATE_GOOGLE_AUTH,
} from "../constants/Signin";

const initialState = {
  status: null,
  error: null,
  authToken: null,
  refreshToken: null,
  created: false,
  authMethod: null,
  facebookToken: null,
  googleToken: null,
  googleCode: null,
};

export default function socialSignin(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FACEBOOK_AUTH:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_REQUEST_FACEBOOK_AUTH:
      return {
        ...state,
        status: FACEBOOK_AUTH_REQUESTED,
        facebookToken: action.facebookToken,
        error: null,
      };
    case VALIDATE_FACEBOOK_AUTH:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_VALIDATE_FACEBOOK_AUTH:
      return {
        ...state,
        status: FACEBOOK_AUTH_VALIDATED,
        authToken: action.token,
        refreshToken: action.refreshToken,
        created: action.created,
        authMethod: "Facebook",
        error: null,
      };
    case FAIL_VALIDATE_FACEBOOK_AUTH:
      return {
        ...state,
        status: SOCIAL_SIGNIN_ERROR,
        error: action.error.message,
      };
    case REQUEST_GOOGLE_AUTH:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_REQUEST_GOOGLE_AUTH:
      return {
        ...state,
        status: GOOGLE_AUTH_REQUESTED,
        googleToken: action.googleToken,
        error: null,
      };
    case SUCCEED_REQUEST_GOOGLE_AUTH_WITH_CODE:
      return {
        ...state,
        status: GOOGLE_AUTH_WITH_CODE_REQUESTED,
        googleCode: action.googleCode,
        error: null,
      };
    case VALIDATE_GOOGLE_AUTH:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_VALIDATE_GOOGLE_AUTH:
      return {
        ...state,
        status: GOOGLE_AUTH_VALIDATED,
        authToken: action.token,
        refreshToken: action.refreshToken,
        created: action.created,
        authMethod: "Google",
        error: null,
      };
    case FAIL_VALIDATE_GOOGLE_AUTH:
      return {
        ...state,
        status: SOCIAL_SIGNIN_ERROR,
        error: action.error.message,
      };

    case FALLBACK_TO_EMAIL_SIGNUP:
      return {
        ...state,
        status: EMAIL_FALLBACK,
        error: action.error,
        fallbackName: action.name,
      };
    default:
      return state;
  }
}
