import { DESC } from "../constants/Sort";

const initialState = {
  tableState: {
    search: "",
    scrollPosition: {
      x: 0,
      y: 0,
    },
    translator: {
      translatorLowMarginRatesCount: 0,
      loadRatesLimit: 10,
      selectedRates: [],
      sortRates: {
        id: "RATE_ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        rate: {},
      },
      filter: {
        sourceLanguage: "",
        targetLanguage: "",
      },
    },
    customer: {
      loadRatesLimit: 10,
      selectedRates: [],
      sortRates: {
        id: "CUSTOMER_RATE_ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        rate: {},
      },
      filter: {
        sourceLanguage: "",
        targetLanguage: "",
      },
    },
  },
};

export default function translatorRates(state = initialState, action) {
  const roleIsEmpty = !action.role && action.state;
  switch (action.type) {
    case "CHANGE_RATES_TABLE_STATE":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...roleIsEmpty,
          [action.role]: {
            ...state.tableState[action.role],
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}
