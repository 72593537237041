import PropTypes from "prop-types";
import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import { validate, clearErrors, showErrors, setFocusOnFirst } from "../../helpers/common";

import HtmlTranslate from "../HtmlTranslate";
import { translatedTextWithLink } from "../../helpers/translatedTextWithLink";

export default class ResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordRepeat: "",
      passwordCode: "",
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    handler: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    setFocusOnFirst();
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.validator();
    clearErrors();

    const form = {
      email: this.props.email,
      password: e.target.password.value,
      passwordRepeat: e.target.passwordRepeat.value,
      passwordCode: e.target.passwordCode.value,
    };

    let errors = validate(form);

    if (!errors && e.target.password.value !== e.target.passwordRepeat.value) {
      errors = {
        passwordRepeat: "custom",
      };
    }

    if (!errors) {
      if (!this.props.disabled) this.props.handler(form);
    } else {
      showErrors(errors);
    }
  }

  reSendConfirmEmail = email => {
    this.props.reSendConfirmEmail({ email });
  };

  render() {
    const { styleForm } = this.props;
    const resendLink = translatedTextWithLink(
      I18n.t("SIGNUP_SUCCESS_PAGE.RESENT_CODE", { email: this.props.email }),
      true
    );
    const resendLinkElement = (
      <p>
        {" "}
        {resendLink.leftText}
        <a
          onClick={() => this.reSendConfirmEmail(this.props.email)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            borderBottom: "none",
          }}
        >
          {resendLink.link}
        </a>
        {resendLink.rightText}
      </p>
    );
    return (
      <form className="nitro-form" style={styleForm} onChange={this.handleChange} onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="password" style={{ fontSize: 14 }}>
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.NEW_PASSWORD_FIELD_LABEL" />
          </label>
          <input className="form-control" name="password" type="password" id="password" value={this.state.password} />
          <p className="error-message">
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.NEW_PASSWORD_FIELD_ERROR" />
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="passwordRepeat" style={{ fontSize: 14 }}>
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.REPEAT_PASSWORD_FIELD_LABEL" />
          </label>
          <input
            className="form-control"
            name="passwordRepeat"
            type="password"
            id="passwordRepeat"
            value={this.state.passwordRepeat}
          />
          <p className="error-message">
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.REPEAT_PASSWORD_FIELD_ERROR" />
          </p>
          <p className="error-message custom-error">
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.PASSWORDS_DO_NOT_MATCH_ERROR" />
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="passwordCode" style={{ marginBottom: 16, fontSize: 14 }}>
            {I18n.t("RESET_PASSWORD_PAGE.RESET_FORM.CODE_PASSWORD_FIELD_LABEL", { email: this.props.email })}
          </label>
          <input
            className="form-control"
            name="passwordCode"
            type="text"
            id="passwordCode"
            value={this.state.passwordCode}
          />
          <p className="error-message">
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.CODE_PASSWORD_FIELD_ERROR" />
          </p>
        </div>
        <div className="form-group button-group">
          <button type="submit" className="btn btn-block btn-nitro">
            <HtmlTranslate value="RESET_PASSWORD_PAGE.RESET_FORM.SUBMIT_BUTTON_TEXT" />
          </button>
        </div>
        {this.state.error && <p className="text-danger">{this.state.error}</p>}
        <div style={{ fontSize: 14 }}>{resendLinkElement}</div>
      </form>
    );
  }
}
