import { decodeJwt } from "jose";
export var RECONNECT_INTERVAL_S = 2;
export var ECHO = "ECHO";
export var PRICE = "PRICE";
var isDevMode = process.env.NODE_ENV === "development";
export var getOnOpenHandler = function (wsName) {
    if (wsName === void 0) { wsName = ""; }
    return function (event) {
        if (isDevMode)
            console.log("%c [WS ".concat(wsName, " opened]"), "color: #35E5BC");
    };
};
export var getOnCloseHandler = function (wsName) {
    if (wsName === void 0) { wsName = ""; }
    return function () {
        if (isDevMode)
            console.log("%c [WS ".concat(wsName, " closed] Reconnecting in ").concat(RECONNECT_INTERVAL_S, " seconds."), "color: #FF2467");
    };
};
export var getOnReconnectStopHandler = function (wsName) {
    if (wsName === void 0) { wsName = ""; }
    return function () {
        console.log("%c [WS ".concat(wsName, " closed] Fail to connect"), "color: #FF2467");
    };
};
export var checkIsTokenValid = function (token) {
    if (!token)
        return false;
    var claims = decodeJwt(token);
    var currentDate = Math.round(Date.now() / 1000);
    if (!(claims === null || claims === void 0 ? void 0 : claims.exp))
        return false;
    // console.log({claims, currentDate, isValid: claims.exp <= currentDate});
    return (claims.exp && claims.exp <= currentDate) || !claims.exp;
};
