// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileSignBtn__signBtnMobile__DLfPXm {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-bottom: none !important;
  cursor: pointer;
  display: none;
}

.MobileSignBtn__signBtnMobile__DLfPXm span {
    padding: 8px;
    line-height: 0;
    background: orange;
    border-radius: 3px;
  }

.MobileSignBtn__signBtnMobilePopover__pQ26CE {
  position: fixed !important;
  top: 55px !important;
  min-width: 104px;
  max-width: 300px;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  border-radius: 0;
  margin-top: 3px !important;
  left: auto !important;
  opacity: 1;
  right: 10px !important;
  transform: none !important;
}

.MobileSignBtn__signBtnMobilePopover__pQ26CE .MobileSignBtn__signBtnList__R1IXpK {
    list-style: none;
    padding: 0;
    margin: 0;
  }

.MobileSignBtn__signBtnMobilePopover__pQ26CE .MobileSignBtn__signBtnList__R1IXpK li {
      padding: 0;
      border-bottom: none;
    }

.MobileSignBtn__signLink__Cw0IqE {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  border: 0;
  display: block;
  padding: 12px 16px;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
}

.MobileSignBtn__signLink__Cw0IqE:hover {
    background-color: rgba(227, 242, 253, 1);
    color: rgba(30, 136, 229, 1);
  }

.MobileSignBtn__signLink__Cw0IqE:last-child {
    border-bottom: none;
  }

@media screen and (max-width: 890px) {
  .MobileSignBtn__signBtnMobile__DLfPXm {
    display: flex;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signBtnMobile": `MobileSignBtn__signBtnMobile__DLfPXm`,
	"signBtnMobilePopover": `MobileSignBtn__signBtnMobilePopover__pQ26CE`,
	"signBtnList": `MobileSignBtn__signBtnList__R1IXpK`,
	"signLink": `MobileSignBtn__signLink__Cw0IqE`
};
export default ___CSS_LOADER_EXPORT___;
