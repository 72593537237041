var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CheckBoxStatuses, } from "./models";
export var convertArray = function (data) {
    return data.map(function (elem) {
        var _a;
        return (__assign(__assign({}, elem), { isSelected: CheckBoxStatuses.Checked, charactersCount: (_a = elem.charactersCount) !== null && _a !== void 0 ? _a : 0, attributes: elem.attributes.map(function (elem) { return (__assign(__assign({}, elem), { isSelected: CheckBoxStatuses.Checked })); }), children: convertArray(elem.children) }));
    });
};
var calculateIsCheckedProperty = function (_a) {
    var attributes = _a.attributes, children = _a.children;
    var isAllAttributesChecked = (!attributes && true) || attributes.every(function (elem) { return elem.isSelected === CheckBoxStatuses.Checked; });
    var isAllChildrenChecked = (!children && true) || children.every(function (elem) { return elem.isSelected === CheckBoxStatuses.Checked; });
    if (isAllAttributesChecked && isAllChildrenChecked) {
        return CheckBoxStatuses.Checked;
    }
    var isSomeCheckedAttributes = !!(attributes && attributes.some(function (elem) { return elem.isSelected === CheckBoxStatuses.Checked; }));
    var isSomeCheckedChildren = !!(children &&
        children.some(function (elem) { return elem.isSelected === CheckBoxStatuses.Checked || elem.isSelected === CheckBoxStatuses.Partial; }));
    return isSomeCheckedAttributes || isSomeCheckedChildren ? CheckBoxStatuses.Partial : CheckBoxStatuses.UnChecked;
};
export var updateSegmentsData = function (hierarchyArray, triggedSegments, isDeselectLeafs) {
    var currentSegmentPath = triggedSegments[0];
    var currentSegmentIndex = hierarchyArray.findIndex(function (segment) { return segment.path === currentSegmentPath; });
    if (triggedSegments.length && currentSegmentIndex === -1) {
        return hierarchyArray;
    }
    var isModifyAllSegments = currentSegmentIndex === -1;
    var segmentsToChange = isModifyAllSegments ? hierarchyArray : [hierarchyArray[currentSegmentIndex]];
    var updatedSegments = segmentsToChange.map(function (segmentToChange) {
        var _a, _b;
        var isLeaf = !((_a = segmentToChange.children) === null || _a === void 0 ? void 0 : _a.length) && !((_b = segmentToChange.attributes) === null || _b === void 0 ? void 0 : _b.length);
        if (isLeaf && isDeselectLeafs) {
            return __assign(__assign({}, segmentToChange), { isSelected: CheckBoxStatuses.UnChecked });
        }
        if (isLeaf && (currentSegmentPath === segmentToChange.path || !currentSegmentPath)) {
            return __assign(__assign({}, segmentToChange), { isSelected: segmentToChange.isSelected === CheckBoxStatuses.Checked
                    ? CheckBoxStatuses.UnChecked
                    : CheckBoxStatuses.Checked });
        }
        var subTriggedSegment = triggedSegments.slice(1);
        var isDeselectChildrenLeafs = (segmentToChange.isSelected === CheckBoxStatuses.Partial &&
            currentSegmentPath === segmentToChange.path &&
            !subTriggedSegment.length) ||
            isDeselectLeafs;
        var updatedAttributes = segmentToChange.attributes &&
            updateSegmentsData(segmentToChange.attributes, subTriggedSegment, isDeselectChildrenLeafs);
        var updatedChildren = segmentToChange.children &&
            updateSegmentsData(segmentToChange.children, subTriggedSegment, isDeselectChildrenLeafs);
        var isSelected = calculateIsCheckedProperty({
            attributes: updatedAttributes !== null && updatedAttributes !== void 0 ? updatedAttributes : [],
            children: updatedChildren !== null && updatedChildren !== void 0 ? updatedChildren : [],
        });
        return __assign(__assign({}, segmentToChange), { isSelected: isSelected, children: updatedChildren, attributes: updatedAttributes });
    });
    if (isModifyAllSegments) {
        return updatedSegments;
    }
    var updatedHierarchy = __spreadArray([], hierarchyArray, true);
    updatedHierarchy[currentSegmentIndex] = updatedSegments[0];
    return updatedHierarchy;
};
export var getSelectedElements = function (data) {
    return data.reduce(function (acc, elem) {
        if (elem.isSelected === CheckBoxStatuses.Checked) {
            acc.push({ path: elem.path, charactersCount: elem.charactersCount });
        }
        if (elem.attributes) {
            acc.push.apply(acc, getSelectedElements(elem.attributes));
        }
        if (elem.children) {
            acc.push.apply(acc, getSelectedElements(elem.children));
        }
        return acc;
    }, []);
};
