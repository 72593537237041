// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.something-wrong-order__popper__z4O03e[data-x-out-of-boundaries] {
  display: none;
}

.something-wrong-order__popper__z4O03e {
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 15px;
  background: white;
  max-width: 270px;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  min-height: 30px !important;
}

.something-wrong-order__popper__z4O03e .something-wrong-order__popper__arrow___jWcwW {
  display: none;
}

.something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] {
  /*margin-top: 13px;*/
  min-width: 190px;
  text-align: center;
  /*left: 0 !important;*/
}

.something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW {
  content: "";
  /*top: -20px;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
  /*left: calc(85%) !important;*/
}

.something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW::before,
.something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #bdbdbd;
}

@-moz-document url-prefix() {
  .something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW::before,
  .something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    /*margin-top: -24px;*/
  }
}

.something-wrong-order__popper__z4O03e[data-placement^="bottom-end"] .something-wrong-order__popper__arrow___jWcwW::after {
  border-bottom: 10px solid white;
  /*bottom: -21px;*/
}

.something-wrong-order__popper__z4O03e[data-placement^="top-end"] {
  /*margin-bottom: 12px;*/
  min-width: 190px;
  text-align: center;
  /*left: 0 !important;*/
}

.something-wrong-order__popper__z4O03e[data-placement^="top-end"] .something-wrong-order__popper__arrow___jWcwW {
  content: "";
  bottom: 0;
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
  /*left: calc(86%) !important;*/
}

.something-wrong-order__detail-button-container-popover__P6dWAN {
  padding: 0;
  min-height: 30px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": `something-wrong-order__popper__z4O03e`,
	"popper__arrow": `something-wrong-order__popper__arrow___jWcwW`,
	"detail-button-container-popover": `something-wrong-order__detail-button-container-popover__P6dWAN something-wrong-order__popper__z4O03e`
};
export default ___CSS_LOADER_EXPORT___;
