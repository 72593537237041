// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  color: #363636;
  font-size: 14px;
  font-weight: 400;
}

.footer-block {
  /*margin: 0 0 30px;*/
  text-align: center;
  margin: 0px auto;
}

.switcher-container {
  text-align: center;
  display: inline-block;
  width: 150px;
  cursor: pointer;
  font-size: 14px;
}

.footer-copyright {
  font-weight: 300;
  color: #999999;
  display: inline-block;
  margin-top: 61px;
}

.earth-icon {
  cursor: pointer;
  vertical-align: middle;
  margin-top: -5px;
  display: inline-block;
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/deprecated/footer.deprecated.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd","sourcesContent":[".footer {\r\n  color: #363636;\r\n  font-size: 14px;\r\n  font-weight: 400;\r\n}\r\n\r\n.footer-block {\r\n  /*margin: 0 0 30px;*/\r\n  text-align: center;\r\n  margin: 0px auto;\r\n}\r\n\r\n.switcher-container {\r\n  text-align: center;\r\n  display: inline-block;\r\n  width: 150px;\r\n  cursor: pointer;\r\n  font-size: 14px;\r\n}\r\n\r\n.footer-copyright {\r\n  font-weight: 300;\r\n  color: #999999;\r\n  display: inline-block;\r\n  margin-top: 61px;\r\n}\r\n\r\n.earth-icon {\r\n  cursor: pointer;\r\n  vertical-align: middle;\r\n  margin-top: -5px;\r\n  display: inline-block;\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
