import { resolver } from "./resolver";

const ENDPOINTS = {
  getClientSecretStripe: () => `stripe/add`,
  updDefaultPaymentMethod: () => `stripe/update`,
  getCards: () => `stripe/cards`,
  pay: () => `stripe/pay`,
  deleteCard: () => `stripe/delete`,
  check: () => `stripe/check`,
};

export function updateDefaultPaymentMethod(payload) {
  return resolver(ENDPOINTS.updDefaultPaymentMethod(), {
    method: "post",
    body: JSON.stringify(payload),
  });
}

export function getSecret() {
  return (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    return resolver(ENDPOINTS.getClientSecretStripe(), { method: "post" }, { token, dispatch });
  };
}

export function getCards(payload = null) {
  return (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    return resolver(
      ENDPOINTS.getCards(),
      {
        method: "post",
        body: payload ? JSON.stringify(payload) : undefined,
      },
      { token, dispatch }
    );
  };
}

export function deleteCard(payload) {
  return (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    return resolver(ENDPOINTS.deleteCard(), { method: "post", body: JSON.stringify(payload) }, { token, dispatch });
  };
}

export function checkDuplicates(payload) {
  return resolver(ENDPOINTS.check(), {
    method: "post",
    body: JSON.stringify(payload),
  });
}

export function payWithPaymentMethod(payload) {
  return (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    return resolver(ENDPOINTS.pay(), { method: "post", body: JSON.stringify(payload) }, { token, dispatch });
  };
}
