// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar__error-message-handler__TftB2Q {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  width: 100%;
  transition: bottom 0.25s;
  margin-bottom: 16px;
  bottom: 0px;
  z-index: 1051;
  height: 0px;
  align-items: flex-end;
}

.snackbar__snackbar__c0YZoV {
  position: static !important;
  z-index: 3;
}

.snackbar__warning__Yi8J0l {
  background: #ff9800;
}

.snackbar__error__xvVJDO {
  background: #ef5350;
}
.snackbar__new-source-version__LqtI8Y {
  background: #1e88e5;
}

.snackbar__notification__mN6a8J {
  background: #43a047;
}

.snackbar__snackbar__c0YZoV {
  display: flex;
  z-index: 100;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-width: 320px;
  max-width: 640px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  border-radius: 1px;
}

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 {
    position: relative;
    padding: 16px;
    overflow: overlay;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 .snackbar__error-text__AX3Xdk {
      display: inline-block;
      overflow: hidden;
      white-space: break-spaces;
      line-height: 18px;
      vertical-align: middle;
      text-align: left;
    }

@media (max-width: 540px) {

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 .snackbar__error-text__AX3Xdk {
        max-width: calc(100% - 50px);
    }
      }

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 .snackbar__error-refresh-link__vyECpW {
      display: inline-block;
      cursor: pointer;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 4px;
      padding: 2px 10px;
      margin-left: 24px;
      margin-right: 16px;
    }

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 .snackbar__error-close-container___QeEfy {
      margin-left: 46px;
    }

.snackbar__snackbar__c0YZoV .snackbar__error-container__ryzA14 .snackbar__error-close-link__l3CvxJ {
      cursor: pointer;
      display: inline-block;
      width: 14px;
      height: 14px;
      border: none;
    }
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-message-handler": `snackbar__error-message-handler__TftB2Q`,
	"snackbar": `snackbar__snackbar__c0YZoV with-fade-in with-fade-out`,
	"warning": `snackbar__warning__Yi8J0l snackbar__snackbar__c0YZoV with-fade-in with-fade-out`,
	"error": `snackbar__error__xvVJDO snackbar__snackbar__c0YZoV with-fade-in with-fade-out`,
	"new-source-version": `snackbar__new-source-version__LqtI8Y snackbar__snackbar__c0YZoV with-fade-in with-fade-out`,
	"notification": `snackbar__notification__mN6a8J snackbar__snackbar__c0YZoV with-fade-in with-fade-out`,
	"error-container": `snackbar__error-container__ryzA14`,
	"error-text": `snackbar__error-text__AX3Xdk`,
	"error-refresh-link": `snackbar__error-refresh-link__vyECpW`,
	"error-close-container": `snackbar__error-close-container___QeEfy`,
	"error-close-link": `snackbar__error-close-link__l3CvxJ`
};
export default ___CSS_LOADER_EXPORT___;
