import { combineReducers } from "redux";
import { usersReducer } from "./domain/user";
import { ordersReducer } from "./domain/order";
import { transactionsReducer } from "./domain/transactions";
import { paymentsReducer } from "./domain/payment";
import { translatorRatesReducer } from "./domain/translatorRate";
import { customerRatesReducer } from "./domain/customerRate";
import { couponsReducer } from "./domain/coupons";
import { translationMemoriesReducer } from "./domain/translationMemories";
import { apiKeysReducer } from "./domain/apiKeys";
import { discountsReducer } from "./domain/discounts";

export const domain = combineReducers({
  user: usersReducer,
  order: ordersReducer,
  payment: paymentsReducer,
  translatorRates: translatorRatesReducer,
  customerRates: customerRatesReducer,
  transactions: transactionsReducer,
  coupons: couponsReducer,
  discounts: discountsReducer,
  translationMemories: translationMemoriesReducer,
  apiKeys: apiKeysReducer,
});

export default domain;
