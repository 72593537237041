import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Badge from "../../../../elements/Badge";
import HtmlTranslate from "../../../../HtmlTranslate";
// eslint-disable-next-line no-unused-vars
import menu from "./../../css/side-menu.css";
// eslint-disable-next-line no-unused-vars
import badge from "./css/badge.css";
export default class BadgeMenuItem extends Component {
  render() {
    const { link, pageHeading, content, bsStyle, active, handleAddFunds, unreadStatus } = this.props;

    return (
      <li styleName={active}>
        <NavLink to={link} style={{ display: "flex", justifyContent: "space-between" }}>
          <HtmlTranslate value={pageHeading} />
          <div style={{ display: "flex" }}>
            {!content && unreadStatus > 0 && <div styleName={"badge.messageIcon"}>{unreadStatus}</div>}
            {!!content && <Badge bsStyle={bsStyle} content={content} handleAddFunds={handleAddFunds} />}
            {!!content && unreadStatus > 0 && (
              <div style={{ marginLeft: 8 }} styleName={"badge.messageIcon"}>
                {unreadStatus}
              </div>
            )}
          </div>
        </NavLink>
      </li>
    );
  }
}
