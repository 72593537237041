export var CouponsTypes;
(function (CouponsTypes) {
    CouponsTypes["GET_COUPONS_REQUEST"] = "GET_COUPONS_REQUEST";
    CouponsTypes["GET_COUPONS_SUCCESS"] = "GET_COUPONS_SUCCESS";
    CouponsTypes["DELETE_COUPONS_REQUEST"] = "DELETE_COUPONS_REQUEST";
    CouponsTypes["DELETE_COUPONS_SUCCESS"] = "DELETE_COUPONS_SUCCESS";
    CouponsTypes["GENERATE_COUPONS_REQUEST"] = "GENERATE_COUPONS_REQUEST";
    CouponsTypes["GENERATE_COUPONS_SUCCESS"] = "GENERATE_COUPONS_SUCCESS";
    CouponsTypes["GET_COUPON_BY_ID_REQUEST"] = "GET_COUPON_BY_ID_REQUEST";
    CouponsTypes["GET_COUPON_BY_ID_SUCCESS"] = "GET_COUPON_BY_ID_SUCCESS";
    CouponsTypes["GET_COUPON_USERS_REQUEST"] = "GET_COUPON_USERS_REQUEST";
    CouponsTypes["GET_COUPON_USERS_SUCCESS"] = "GET_COUPON_USERS_SUCCESS";
    CouponsTypes["GET_COUPON_INFO_REQUEST"] = "GET_COUPON_INFO_REQUEST";
    CouponsTypes["GET_COUPON_INFO_SUCCESS"] = "GET_COUPON_INFO_SUCCESS";
})(CouponsTypes || (CouponsTypes = {}));
