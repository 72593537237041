export const ORDER_FETCHED = "ORDER_FETCHED";
export const ORDER_FETCHING = "ORDER_FETCHING";
export const ORDER_ERROR = "ORDER_ERROR";

// export const SETTINGS_SUCCEED = 'SETTINGS_SUCCEED';
// export const SETTINGS_FAIL = 'SETTINGS_FAIL';
// export const SETTINGS_HIDE_NOTIFICATIONS = 'SETTINGS_HIDE_NOTIFICATIONS';

export const SOCIAL_SIGNIN_ERROR = "[WIP] FIX IT";

export const SETTINGS_FETCHED = "SETTINGS_FETCHED";
export const SETTINGS_UPDATED = "SETTINGS_UPDATED";
export const SETTINGS_FETCHING = "SETTINGS_FETCHING";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const SETTINGS_WRONG_PASSWORD = "SETTINGS_WRONG_PASSWORD";

export const LOADING_ORDER = "LOADING_ORDER";
export const NO_ORDER = "NO_ORDER";
export const NEW_ORDER = "NEW_ORDER";
export const ACCEPTED_ORDER = "ACCEPTED_ORDER";
export const COMPLETED_ORDER = "COMPLETED_ORDER";
export const FAILED_COMPLETE_ORDER = "FAILED_COMPLETE_ORDER";

// popup names
export const SKIP_DESKTOP_POPUP = "SKIP_DESKTOP_POPUP";
export const DONE_DESKTOP_POPUP = "DONE_DESKTOP_POPUP";
