import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import sidebar from "./css/navigation-sidebar.css";
import SidebarItem from "./components/SidebarItem";
import _ from "lodash";

export default class NavigationSidebar extends Component {
  state = {
    activeSectionId: "",
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
    this.props.itemList[0] &&
      this.props.itemList[0].getSidebarInfo &&
      this.props.itemList[0].getSidebarInfo(this.sidebarInfoSource);
    this.props.itemList[5] &&
      this.props.itemList[5].getSidebarInfo &&
      this.props.itemList[5].getSidebarInfo(this.sidebarInfoPrice);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleClick = (id, closeBlock) => {
    this.props.editBlockIsOpen && closeBlock();
    this.props.onChange(id);
  };

  handleScroll = _.throttle(() => {
    const { itemList } = this.props;
    const lastElementIndex = itemList.length - 1;
    const lastElementId = itemList[lastElementIndex].id;
    const { activeSectionId } = this.state;
    let currentActiveSection;
    this.props.itemList.forEach(block => {
      const posY =
        this.props.blockRefList[block.id] &&
        window.pageYOffset + this.props.blockRefList[block.id].getBoundingClientRect().y;
      const height = this.sidebar && this.sidebar.getBoundingClientRect().top;
      if (posY <= window.pageYOffset + height + 1) {
        currentActiveSection = block;
      }
    });

    if (currentActiveSection) {
      currentActiveSection.id !== activeSectionId &&
        this.setState({
          activeSectionId: currentActiveSection.id,
        });
    } else if (this.state.activeSectionId != lastElementId) {
      this.setState({
        activeSectionId: lastElementId,
      });
    }
  }, 50);

  render() {
    const { additionalSidebarBlock, isAddNumberBlockToSidebar, sidebarTop } = this.props;
    const active = this.state.activeSectionId ? this.state.activeSectionId : this.props.itemList[0].id;

    return (
      <div
        ref={node => {
          this.sidebar = node;
        }}
        styleName={this.props.sideBarMobile ? "sidebar.fixedMobile" : "sidebar.fixed"}
        style={{ top: sidebarTop ? sidebarTop : 150 }}
      >
        <div styleName={"sidebar.item-block"}>
          {this.props.itemList.map((item, i) => {
            return (
              !item.notSidebar && (
                <SidebarItem
                  ref={sidebarInfo => {
                    if (i === 0) {
                      this.sidebarInfoSource = sidebarInfo;
                    } else if (i === 5) {
                      this.sidebarInfoPrice = sidebarInfo;
                    }
                  }}
                  key={item.id}
                  numberItem={i + 1}
                  data={item}
                  active={item.id === (this.props.editBlockIsOpen ? item.activeEditBlock : active)}
                  isAddNumberBlockToSidebar={isAddNumberBlockToSidebar}
                  onSelect={this.handleClick.bind(null, item.id, item.closeBlock)}
                />
              )
            );
          })}
        </div>
        {additionalSidebarBlock && <div styleName={"sidebar.additional-block"}>{additionalSidebarBlock}</div>}
      </div>
    );
  }
}
