// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-edit-block__container__MINDrN {
  padding: 24px;
  min-height: 362px;
  width: 1064px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  margin-top: 16px;
}
.payment-edit-block__container__MINDrN:first-child {
  margin-top: 0;
}

.payment-edit-block__spinner__u7xoFx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.payment-edit-block__details__lobWCw {
  width: 596px;
  border: 1px solid #e0e0e0;
  border-radius: 1px;
  margin-top: 2rem;
}

.payment-edit-block__details-item__EYv5VL {
  display: flex;
  min-height: 32px;
  border-top: 1px solid #e0e0e0;
  padding: 5px 10px;
  justify-content: space-between;
}
.payment-edit-block__details-item__EYv5VL:first-child {
  border-top: none;
}

.payment-edit-block__details-value__yBOCPL {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  margin: 0;
  white-space: initial;
}

.payment-edit-block__details-value-link__bNgShi,
.payment-edit-block__details-value-link__bNgShi:hover {
  cursor: pointer;
}

.payment-edit-block__user__FouN3M {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  margin: 0;
  width: 25%;
}

.payment-edit-block__save-button__xAtcd1 {
  width: 115px;
  height: 32px;
  padding: 7px 20px;
  border-radius: 3px;
  border: none;
  background-color: #43a047;
  color: #ffffff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.payment-edit-block__save-button__xAtcd1:focus,
.payment-edit-block__save-button__xAtcd1:hover,
.payment-edit-block__save-button__xAtcd1:active:hover,
.payment-edit-block__save-button__xAtcd1:active,
.payment-edit-block__save-button__xAtcd1:active:focus {
  color: #ffffff;
  background-color: #388e3c;
  border-color: #388e3c;
}

.payment-edit-block__clear-button__UjPgZC {
  cursor: pointer;
  height: 24px;
  width: 65px;
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  display: inline-block;
  margin-left: 16px;
}

.payment-edit-block__clear-button__UjPgZC:active {
  outline: none !important;
}
.payment-edit-block__clear-button__UjPgZC:focus {
  outline: 1px solid #0075ff !important;
  box-shadow: none;
}

.payment-edit-block__button-container__eLEYRL {
  margin-top: 24px;
}

.payment-edit-block__btn__C7HoDP {
  text-align: center !important;
  font-size: 14px;
  margin-right: 8px;
  border-radius: 3px;
  border: 1px solid #43a047 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  background-color: #ffffff !important;
  color: #43a047 !important;
  line-height: 16px !important;
  padding: 4px 15px !important;
}
.payment-edit-block__btn__C7HoDP:disabled {
  cursor: default !important;
}
.payment-edit-block__btn__C7HoDP:hover {
  background-color: #43a047 !important;
  color: #ffffff !important;
}

.payment-edit-block__btn__C7HoDP:active {
  outline: none !important;
}

.payment-edit-block__btn-disabled__JegE3R {
  opacity: 0.5;
}
.payment-edit-block__btn-disabled__JegE3R:hover {
  background-color: #ffffff !important;
  color: #43a047 !important;
}

.payment-edit-block__btn-red__LYNJ_s {
  border: 1px solid rgba(239, 83, 80, 1) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  background-color: #ffffff !important;
  color: rgba(239, 83, 80, 1) !important;
}
.payment-edit-block__btn-red__LYNJ_s:hover {
  background-color: rgba(239, 83, 80, 1) !important;
  color: #ffffff !important;
}

.payment-edit-block__btn-disabled-red__l_OaY5 {
  opacity: 0.5;
  border: 1px solid rgba(239, 83, 80, 1) !important;
  color: rgba(239, 83, 80, 1) !important;
}
.payment-edit-block__btn-disabled-red__l_OaY5:hover {
  background-color: #ffffff !important;
  color: rgba(239, 83, 80, 1) !important;
}

.payment-edit-block__non-user__VgLK0J {
  display: flex;
  align-items: flex-end;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `payment-edit-block__container__MINDrN`,
	"spinner": `payment-edit-block__spinner__u7xoFx`,
	"details": `payment-edit-block__details__lobWCw`,
	"details-item": `payment-edit-block__details-item__EYv5VL`,
	"details-value": `payment-edit-block__details-value__yBOCPL`,
	"details-value-link": `payment-edit-block__details-value-link__bNgShi`,
	"user": `payment-edit-block__user__FouN3M`,
	"save-button": `payment-edit-block__save-button__xAtcd1`,
	"clear-button": `payment-edit-block__clear-button__UjPgZC`,
	"button-container": `payment-edit-block__button-container__eLEYRL`,
	"btn": `payment-edit-block__btn__C7HoDP`,
	"btn-disabled": `payment-edit-block__btn-disabled__JegE3R payment-edit-block__btn__C7HoDP`,
	"btn-red": `payment-edit-block__btn-red__LYNJ_s`,
	"btn-disabled-red": `payment-edit-block__btn-disabled-red__l_OaY5 payment-edit-block__btn__C7HoDP`,
	"non-user": `payment-edit-block__non-user__VgLK0J`
};
export default ___CSS_LOADER_EXPORT___;
