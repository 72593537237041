import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import button from "./css/add-image-button.css";
import { I18n } from "react-redux-i18n";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
import IconView from "../IconView";

class AddImageButton extends Component {
  onInputClick = event => {
    event.target.value = "";
  };

  onLabelClick = () => {
    this.link.blur();
  };

  render() {
    const { handleAddImage, iconPlus, tabIndex } = this.props;
    return (
      <div styleName={"button.container"} title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADD_IMAGE_LABEL")}>
        <label
          ref={link => (this.link = link)}
          onClick={this.onLabelClick}
          styleName={"button.imageLabel"}
          tabIndex={tabIndex}
        >
          {iconPlus ? (
            <IcomoonReact styleName={"button.iconPlus"} iconSet={iconSet} color={"#1E88E5"} size={8} icon={"plus"} />
          ) : (
            <IconView colors={["#1E88E5", "#545454"]} size={20} icon={"image"} cursor={"pointer"} />
          )}
          <input
            alt={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADD_IMAGE_QUESTION")}
            type={"file"}
            accept={"image/*"}
            multiple
            onChange={handleAddImage}
            onClick={this.onInputClick}
            styleName={"button.fileInput"}
          />
        </label>
      </div>
    );
  }
}

export default AddImageButton;
