// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block__title__iWH0ex {
  /*height: 16px;*/
}

.block__content__tBsAn9 {
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 16px;
  flex-wrap: wrap;
  position: relative;
}

.block__content-focus__LWTvUU {
  border: 1px solid #1e88e5 !important;
}

.block__reverse-container__nN7JKE {
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 640px) {
  .block__content-focus__LWTvUU {
    margin-bottom: 24px !important;
  }
    .block__content-focus__LWTvUU:focus-within {
      border: 1px solid #1e88e5 !important;
    }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `block__title__iWH0ex`,
	"content": `block__content__tBsAn9`,
	"content-focus": `block__content-focus__LWTvUU block__content__tBsAn9 block__content__tBsAn9`,
	"reverse-container": `block__reverse-container__nN7JKE`
};
export default ___CSS_LOADER_EXPORT___;
