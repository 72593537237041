export const unicodeToString = mainText => {
  let text = mainText;

  if (mainText) {
    let list = text.match(/\\u([\d\w]{4})|\\n|\\"|\\r/gi);

    list &&
      list.map(item => {
        var r = /\\u([\d\w]{4})|\\n|\\"|\\r/gi;
        let x = item.replace(r, function (match, grp) {
          if (item === "\\r") {
            return "";
          } //JSON.parse doesn't work correctly, need to extract this code to separate error parser func

          if (item === '\\"') {
            return '"';
          }

          if (item === "\\n") {
            return "\n";
          }

          return String.fromCharCode(parseInt(grp, 16));
        });
        x = unescape(x);
        text = text.replace(item, x);
      });
  }
  return text;
};

// Убираем кавычки, квадратные скобки и подрезание до двоеточия
export const formatString = text => {
  let txt = deleteFirstLastSymbol(text, '"', '"');
  txt = deleteFirstLastSymbol(txt, "'", "'");
  txt = deleteFirstLastSymbol(txt, "[", "]");
  const listParts = txt.split(">: ", 2);
  txt = listParts.length > 1 ? listParts[1] : txt;
  return txt;
};

export const deleteFirstLastSymbol = (text, first, last) => {
  if (text[0] === first && text[text.length - 1] === last) {
    return text.slice(1, -1);
  }
  return text;
};
