import reduceReducers from "reduce-reducers";

const initialState = {
  tableState: {
    scrollPosition: {
      x: 0,
      y: 0,
    },
  },
};

function supportCustomer(state = initialState, action) {
  const statusIsEmpty = !action.status && action.state;
  switch (action.type) {
    case "CHANGE_SUPPORT_CUSTOMER_TABLE_STATE":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...statusIsEmpty,
          [action.status]: {
            ...state.tableState[action.status],
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}

export const supportCustomerReducer = reduceReducers(supportCustomer);
