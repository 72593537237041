import React, { Component, useState } from "react";
import { PopoverTest } from "../../Popover/PopoverTest";
import { findDOMNode } from "react-dom";
import { isEscape } from "../../../constants/KeyboardKeys";
// eslint-disable-next-line no-unused-vars
import detail from "./css/detail-button.css";
// eslint-disable-next-line no-unused-vars
import popover from "./css/popover.css";
import outy from "outy";
import OptionButton from "../OptionButton/OptionButton";

const EVENT_KEY_DOWN = "keydown";

export default class DetailButton extends Component {
  state = {
    isPopoverOpen: false,
    barDetailStyleName: "popover.detail",
    isFormatingPopoverOpen: false,
  };

  componentDidMount() {
    this.setOutsideTap();
    document.addEventListener(EVENT_KEY_DOWN, this.escapePressed, false);
  }

  componentWillUnmount() {
    this.outsideTap.remove();
    document.removeEventListener(EVENT_KEY_DOWN, this.escapePressed, false);
  }

  escapePressed = event => {
    if (isEscape(event)) {
      this.setState({
        barDetailStyleName: "popover.detail",
        isPopoverOpen: false,
      });
    }
  };

  setOutsideTap = () => {
    const elements = [];
    this.target && elements.push(this.target);
    this.popper && elements.push(this.popper);

    if (this.outsideTap) {
      this.outsideTap.remove();
    }

    this.outsideTap = outy(elements, ["click"], this.handleOutsideTap);
  };

  handleOutsideTap = () => {
    this.setState({
      barDetailStyleName: "popover.detail",
      isPopoverOpen: false,
    });
  };

  handlePopover = () => {
    const { barDetailStyleName } = this.state;
    this.setState(prevState => ({
      isPopoverOpen: !prevState.isPopoverOpen,
      barDetailStyleName: barDetailStyleName === "popover.detail" ? "popover.detailBlue" : "popover.detail",
    }));
  };

  handleBlur = () => {
    this.setState({
      barDetailStyleName: "popover.detail",
      isPopoverOpen: false,
    });
  };

  render() {
    const { detail, btnStyleName, btnContainerStyle, popoverName, style, isSelect } = this.props;
    const popperStyleName = popoverName ? popoverName : "detailButton";
    const { barDetailStyleName } = this.state;
    const ref = c => {
      this.target = findDOMNode(c);
    };
    const divStyleName = btnContainerStyle
      ? "popover." + btnContainerStyle
      : this.state.isPopoverOpen
        ? "popover.exit-container-hover"
        : "popover.exit-container";
    const svgStyleName = btnStyleName ? "popover." + btnStyleName : barDetailStyleName;
    return (
      <React.Fragment>
        <PopoverTest
          placement={"bottom-end"}
          onClick={this.handlePopover}
          popperStyleName={popperStyleName}
          target={this.target}
          style={style}
          referenceElement={
            <OptionButton
              ref={ref}
              divStyleName={divStyleName}
              svgStyleName={svgStyleName}
              onClick={this.handlePopover}
            />
          }
          popperElement={
            <React.Fragment>
              {detail && (
                <ul styleName={"popover.detailItem-container"} onBlur={this.handleBlur}>
                  {detail.items.map((item, i) => {
                    return <DetailButtonItem item={item} index={i} isSelect={isSelect} key={i} />;
                  })}
                </ul>
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const DetailButtonItem = ({ item, index, isSelect }) => {
  const [formatingPopover, setFormatingPopover] = useState(false);
  const formatinPopoverShow = () => {
    setFormatingPopover(true);
  };
  const formatingPopoverHide = () => {
    setFormatingPopover(false);
  };
  const isActive = item.disabled && item.fileFormationStatus;
  return (
    <div
      key={index}
      title={item.hint}
      onPointerLeave={() => isActive && formatingPopoverHide()}
      onPointerEnter={() => isActive && formatinPopoverShow()}
      style={{ position: "relative" }}
    >
      <div
        style={!item.disabled || isSelect ? {} : { pointerEvents: "none", cursor: "default", opacity: 0.6 }}
        styleName={"detail.item"}
        onClick={isSelect || item.isSelect ? item.itemClick : () => {}}
        // master -> onClick={(!item.disabled || isSelect) ? item.itemClick : ()=>{}}
      >
        {item.title}
      </div>
      {item.fileFormationStatus && item.popoverText && (
        <div styleName={"detail.popover-block"} style={{ display: formatingPopover ? "block" : "none" }}>
          {item.popoverText}
        </div>
      )}
    </div>
  );
};
