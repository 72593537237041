export function changeTableState(state) {
  return {
    type: "CHANGE_NEW_ORDER_TABLE_STATE",
    state,
  };
}

export function changeTableStateFileOrder(state) {
  return {
    type: "CHANGE_NEW_FILE_ORDER_TABLE_STATE",
    state,
  };
}
