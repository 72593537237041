import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import badge from "./css/badge.css";

import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

class CustomerBadge extends Component {
  render() {
    const { content, handler } = this.props;

    const color = content.positiveValue ? "#43A047" : "#FFFFFF";
    const colorContent = content.positiveValue ? "#212121" : "#FFFFFF";
    const backgroundContent = content.positiveValue ? "#D6ECD6" : "#EF5350";

    return (
      <div styleName={"badge.container"} style={{ color: colorContent, background: backgroundContent }}>
        <div styleName={"badge.content"}>{content.value}</div>
        {handler && (
          <div styleName={"badge.icon-green"} onClick={handler}>
            <IcomoonReact iconSet={iconSet} color={color} size={15} icon={"add_circle"} onClick={this.handleNext} />
          </div>
        )}
      </div>
    );
  }
}

export default CustomerBadge;
