import PropTypes from "prop-types";
import React, { Fragment, Component } from "react";
import _ from "lodash";
// eslint-disable-next-line no-unused-vars
import toolbar from "./css/under-header.css";
import Refresh from "../Refresh/Refresh";
import Loading from "../Loading/Loading";
import Question from "../Title/components";
import onClickOutside from "react-onclickoutside";
import Popover from "../../Popover";
import { findDOMNode } from "react-dom";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

const popoverErrorStyles = {
  padding: "8px",
  background: "#FFFFFF",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 15px",
  borderRadius: "6px",
  border: "none",
  color: "#DD4B39",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "15px",
  whiteSpace: "initial",
  maxWidth: "280px",
};

class UnderHeader extends Component {
  static propTypes = {
    isShowingLoading: PropTypes.bool,
    onClickHandler: PropTypes.func,
    title: PropTypes.string,
    searchBlockStyle: PropTypes.string,
  };

  static defaultProps = {
    searchBlockStyle: "toolbar.block",
    tableToolbarBlockStyle: "toolbar.block",
  };

  state = {
    focused: false,
    content: "",
    lastSearch: "",
    isMobile: document.documentElement.clientWidth < 520,
    titleName: "",
    changeName: false,
    showTitlePopover: false,
  };

  detectMobile = _.debounce(() => {
    this.setState({
      isMobile: document.documentElement.clientWidth < 520,
    });
  }, 300);

  componentDidMount() {
    this.setState({
      content: this.props.searchInitPlaceholder ? this.props.searchInitPlaceholder : "",
      lastSearch: this.props.searchInitContent ? this.props.searchInitContent : "",
      isMobile: document.documentElement.clientWidth < 520,
      titleName: this.props.title,
    });
    window.addEventListener("resize", this.detectMobile);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.insertContent !== this.props.insertContent) {
      this.insertContent();
    }
    if (prevProps.searchInitContent !== this.props.searchInitContent) {
      this.setState({
        content: this.props.searchInitContent,
        lastSearch: this.props.searchInitContent,
      });
    }
    if (prevProps.title !== this.props.title) {
      this.setState({
        titleName: this.props.title,
      });
    }

    if (prevProps.titleError && !this.props.titleError) {
      this.setState({ changeName: false });
    }

    if (this.props.titleError) {
      this.nameTitleClick();
    }
  }

  componentWillUnmount() {
    this.props.handleSearchPlaceholder && this.props.handleSearchPlaceholder(this.state.content);
    window.removeEventListener("resize", this.detectMobile);
  }

  focus = () => {
    this.setState({
      focused: true,
    });
  };

  insertContent = () => {
    this.setState({
      content: this.props.insertContent,
      lastSearch: this.props.insertContent,
    });
  };

  handleChange = event => {
    this.setState({
      content: event.target.value,
    });
  };

  handleClickSearch = () => {
    const { content, focused, lastSearch } = this.state;

    if (content && lastSearch === content) {
      this.setState({
        content: "",
        focused: true,
        lastSearch: "",
      });
      this.props.resetSearch && this.props.resetSearch();
    } else if (focused && lastSearch !== content) {
      this.setState({
        lastSearch: content,
        focused: true,
      });
      this.props.handleSearch(content);
    }
    this.input && this.input.focus();
  };

  searchFocus = () => {
    this.setState(
      {
        focused: true,
      },
      () => {
        this.input && this.input.focus();
      }
    );
  };

  handleKeyDown = e => {
    const { content, focused, lastSearch } = this.state;

    if (e.key === "Enter" && content) {
      if (focused && lastSearch !== content) {
        this.setState({
          lastSearch: content,
          focused: true,
        });
      }

      this.props.handleSearch(content);
    } else if (e.key === "Enter" && content === "") {
      this.setState({
        lastSearch: content,
        focused: true,
      });
      this.props.resetSearch && this.props.resetSearch();
    } else if (focused && e.key === "Escape" && lastSearch !== "") {
      e.preventDefault();
      this.setState({
        content: lastSearch,
      });
    }
  };

  getIcon = () => {
    const { content, focused, lastSearch, isMobile } = this.state;

    if (!content && (!isMobile || (isMobile && !focused))) {
      return "icon-unfocus";
    } else if (lastSearch === content || (focused && !content)) {
      return "icon-unfocus-content";
    } else {
      return "icon-focus";
    }
  };

  nameTitleClick = () => {
    this.props.onSaveName && !this.state.changeName && this.setState({ changeName: true });
  };

  onTitleInputBlur = () => {
    // this.state.changeName && this.setState({changeName: false});
  };

  changeTitleName = e => {
    this.setState({
      titleName: e.target.value,
    });
  };

  onEnterKey = e => {
    if (e.keyCode === 13) {
      e.target.blur();
      this.saveName();
    }
  };

  // Обязытельная функция для библиотеки onClickOutside
  handleClickOutside = () => {
    this.state.changeName && this.saveName();
  };

  saveName = () => {
    const { titleName } = this.state;

    if (this.state.titleName && this.state.titleName.trim() === this.props.title) {
      this.props.setTitleError && this.props.setTitleError(false);
      this.state.changeName && this.setState({ changeName: false });
      return;
    }

    if (titleName.length > 0) {
      this.props.setTitleError && this.props.setTitleError(false);
      this.setState({ changeName: false });
      this.props.onSaveName(titleName);
    }
  };

  render() {
    const {
      isShowingLoading,
      onClick,
      title,
      isSearch,
      toolBar,
      searchBlockStyle,
      tableToolbarBlockStyle,
      placeholder,
      info,
      onBack,
      auth,
      onSaveName,
      children,
    } = this.props;
    const { content, focused, isMobile, changeName, titleName } = this.state;
    let inputStyle = this.getIcon();
    const renderRefreshIndicator = isShowingLoading ? <Refresh onClick={onClick} /> : <Loading />;
    const isVisibleTitle = !isMobile || (isMobile && !(focused || content));
    const inputWidth = isVisibleTitle ? { width: "268px" } : { width: "100%" };

    const renderHeaderWithRefreshAndLoader = (
      <Fragment>
        {onBack && (
          <div
            styleName={"toolbar.backArrow"}
            onClick={onBack}
            ref={c => {
              this.target = findDOMNode(c);
            }}
          >
            <IcomoonReact iconSet={iconSet} color={"#1E88E5"} size={18} icon={"left_arrow"} />
          </div>
        )}
        {!onSaveName || !changeName ? (
          title &&
          isVisibleTitle && (
            <div styleName={"toolbar.title"} onClick={this.nameTitleClick}>
              {titleName}
            </div>
          )
        ) : (
          <Fragment>
            <input
              autoFocus
              value={titleName}
              styleName={`toolbar.input-title ${this.props.titleError ? "toolbar.input-title-error" : ""}`}
              disabled={!changeName}
              onChange={this.changeTitleName}
              onKeyDown={this.onEnterKey}
              onBlur={this.onTitleInputBlur}
              maxLength="255"
            />
            {this.props.titleError && this.target && this.props.titleErrorText && (
              <Popover
                target={this.target}
                popperStyleName={"underHeaderTitle"}
                style={popoverErrorStyles}
                placement={"auto-start"}
                popperArrowStyleName={"underHeaderTitleArrow"}
              >
                {this.props.titleErrorText}
              </Popover>
            )}
          </Fragment>
        )}
        {info && !changeName && (
          <Question
            questionStyle={{ marginRight: 16 }}
            style={{ maxWidth: 304 }}
            styleMain={{ maxWidth: 304 }}
            info
            question={<div>{info}</div>}
            isNotShowQuestionIcon={true}
          />
        )}
        {isVisibleTitle && <div styleName={tableToolbarBlockStyle}>{toolBar && toolBar}</div>}
        <div styleName={searchBlockStyle}>
          {isSearch &&
            (!isMobile || focused || content ? (
              <div styleName={"toolbar.box"}>
                <div onKeyDown={this.handleKeyDown} styleName={"toolbar.search"} style={inputWidth}>
                  <div styleName={"toolbar.input-container"}>
                    <input
                      onFocus={this.focus}
                      onChange={this.handleChange}
                      placeholder={placeholder}
                      value={content}
                      styleName={"toolbar.input"}
                      type="search"
                      ref={input => (this.input = input)}
                    />
                  </div>
                  <div styleName={"toolbar.icon-container"} onMouseDown={this.handleClickSearch}>
                    <span styleName={inputStyle} />
                  </div>
                </div>
              </div>
            ) : (
              <div styleName={"toolbar.icon-container"}>
                <span onClick={this.searchFocus} styleName={inputStyle} />
              </div>
            ))}
        </div>
        {onClick && <div styleName={"toolbar.refresh-trigger"}>{renderRefreshIndicator}</div>}
      </Fragment>
    );

    const isNotCustomToolbar = !children;
    return (
      <div styleName={"toolbar.container-fixed"} style={{ top: auth && 64 }}>
        {isNotCustomToolbar && renderHeaderWithRefreshAndLoader}
        {children}
      </div>
    );
  }
}

export default onClickOutside(UnderHeader);
