export const GET_TERMS = "GET_TERMS";
export const GET_ORDER_GLOSSARY = "GET_ORDER_GLOSSARY";
export const SEARCH_TERMS = "SEARCH_TERMS";
export const DELETE_TERM_ENTRY = "DELETE_TERM_ENTRY";
export const GET_LIST_GLOSSARY_LANGUAGES = "GET_LIST_GLOSSARY_LANGUAGES";
export const GET_STATISTICS = "GET_GLOSSARY_STATISTICS";

export const SEARCH_GLOSSARY_ADMIN = "SEARCH_GLOSSARY_ADMIN";

export const SORT_FIELD = "RATE_ID";

export const OFFSET = 0;

export const DEFAULT_LANGUAGE_VALUE = "en";
