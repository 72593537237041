// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.inputStyles__container__SgcKRY {
    max-width: 288px;
    margin-top: 10px;
}

.inputStyles__label__g7Y4_7 {
    position: relative;
    display: block;
    color: #424242;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    margin: 0 0 5px 0;
}

.inputStyles__inputWrapper__HGbAc_ {
    position: relative;
}

.inputStyles__input__WQlT63 {
    width: 100%;
    height: 32px;
    outline: none;
    border: 1px solid #CECECE;
    padding: 0 25px 0 9px;
    font-size: 14px;
}

.inputStyles__input__WQlT63:focus {

}

.inputStyles__errorField__NBo_lO {
    border: 1px solid #ef5350;
}

.inputStyles__errorText__cxHG5V {
    margin-top: 4px;
    margin-bottom: 0;
    color: #ef5350;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
}

.inputStyles__triangleIcon__sMZurK {
    width: 10px;
    height: 6px;

    position: absolute;
    top: -2px;
    right: 8px;
}

.inputStyles__optionsWrapper__r5pc08 {
    position: absolute;
    top: 26px;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
    max-height: 140px;
    overflow-y: auto;
    background-color: white;
    border: none;
}

.inputStyles__optionsWrapper__r5pc08::-webkit-scrollbar {
    display: none !important;
}

.inputStyles__listIsOpened__yVDY43 {
    z-index: 1;
    border: 1px solid #1e88e5;
    border-top: transparent;
}

.inputStyles__option__CEFj1D {
    box-sizing: border-box;
    width: 100%;
    max-height: 32px;
    outline: none;
    background-color: white;
    border: none;
    padding: 0 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.inputStyles__option__CEFj1D:hover {
    background-color: #E3F2FD;
}

.inputStyles__isOptionSelected__t2JV8v {
    background-color: #E3F2FD;
    cursor: initial;
}




`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `inputStyles__container__SgcKRY`,
	"label": `inputStyles__label__g7Y4_7`,
	"inputWrapper": `inputStyles__inputWrapper__HGbAc_`,
	"input": `inputStyles__input__WQlT63`,
	"errorField": `inputStyles__errorField__NBo_lO`,
	"errorText": `inputStyles__errorText__cxHG5V`,
	"triangleIcon": `inputStyles__triangleIcon__sMZurK`,
	"optionsWrapper": `inputStyles__optionsWrapper__r5pc08`,
	"listIsOpened": `inputStyles__listIsOpened__yVDY43`,
	"option": `inputStyles__option__CEFj1D`,
	"isOptionSelected": `inputStyles__isOptionSelected__t2JV8v`
};
export default ___CSS_LOADER_EXPORT___;
