import PropTypes from "prop-types";
import React, { Component } from "react";
import HtmlTranslate from "./HtmlTranslate";
import Loading from "./Loading";
// eslint-disable-next-line no-unused-vars
import more from "../css/deprecated/more.css";

export default class More extends Component {
  static propTypes = {
    display: PropTypes.bool,
    handler: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    display: false,
    isLoading: false,
    title: <HtmlTranslate innerStyleName={"translate.moreTitle"} value="ORDERS_PAGE.MORE_LINK" />,
  };

  state = {
    left: "0%",
  };

  componentDidMount() {
    this.props.sticky && window.addEventListener("scroll", this.leftPosition);
  }

  setLeft = () => {
    const windowWidth = window.innerWidth;
    const percent = (windowWidth - 286) / 2 / (windowWidth * 0.01);
    let left = ((window.pageXOffset - 0) / (360 - 0)) * (percent - 50.5) + 50.5;
    left = left < percent ? percent : left;
    left = left + "%";

    this.setState({ left });
  };

  leftPosition = () => {
    if (!this.props.isStickyLeft) {
      this.setLeft();
    } else {
      if (window.pageXOffset > 0) {
        this.setLeft();
      } else {
        this.setState({ left: "0" });
      }
    }
  };

  render() {
    const {
      isLoading,
      title,
      display: displayState,
      handler,
      padding,
      styleContainer,
      sticky,
      isStickyLeft,
    } = this.props;
    const display = displayState ? "flex" : "none";
    const style = { display: display, padding: padding };
    const className = sticky ? (isLoading ? "more-sticky-loading" : "more-sticky") : "more";
    const stickyStyle = { ...style, left: this.state.left };
    const isShowMoreBlock = !!isLoading || !!displayState;

    if (isShowMoreBlock) {
      return sticky ? (
        <div
          className={isStickyLeft ? "moreWrapper sticky-left" : "moreWrapper"}
          id={isStickyLeft ? "sticky-left" : ""}
          style={styleContainer}
        >
          <div className={className} onClick={handler} style={stickyStyle}>
            {isLoading && <Loading display={true} />}
            {!isLoading && title}
          </div>
        </div>
      ) : (
        <div className="moreWrapper" style={styleContainer}>
          {isLoading && <Loading display={true} />}
          {!isLoading && (
            <div className={className} onClick={handler} style={style}>
              {title}
            </div>
          )}
        </div>
      );
    } else return null;
  }
}
