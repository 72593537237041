import React, { Component } from "react";
import SaveBtn from "../../../elements/SaveBtn";
import LeaveBtn from "../../../elements/LeaveBtn";
import Popup from "../../../elements/Popup";
// eslint-disable-next-line no-unused-vars
import popup from "./css/popup-dialog.css";
import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../images/selection.json";

export default class PopupDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.any,
    textAcceptAddition: PropTypes.any,
    textAccept: PropTypes.string,
    textClose: PropTypes.string,
    onAccept: PropTypes.func,
    onAcceptAddition: PropTypes.func,
    onClose: PropTypes.func,
  };

  render() {
    const {
      anotherButtons,
      title,
      text,
      children,
      styleContainer,
      isOpen,
      onClose,
      onAccept,
      textAccept,
      onAcceptAddition,
      textAcceptAddition,
      textClose,
      stylePopup,
      isIconClose,
      styleTitle,
      acceptDisabled,
      arrowDown,
      handlerArrowDown,
      closeButtonStyle,
      containerStyle,
      loadingView,
      setAutoFocus,
      btnAdd,
    } = this.props;
    const acceptBtnStyle = this.props.acceptBtnStyle && isOpen ? `popup.${this.props.acceptBtnStyle}` : "popup.green";

    return (
      <Popup
        styleName={"popup.container"}
        stylePopup={stylePopup}
        style={styleContainer}
        containerStyle={containerStyle}
        isOpen={isOpen}
        onClose={onClose}
      >
        <h3 styleName={"popup.title"} style={styleTitle}>
          {title}
        </h3>
        {isIconClose && (
          <div styleName={"popup.iconClose"} onClick={onClose}>
            <IcomoonReact iconSet={iconSet} color={"#616161"} size={10} icon={"close"} />
          </div>
        )}
        <div styleName={"popup.text"}>
          {text}
          {arrowDown && (
            <div styleName={"popup.arrowContainer"}>
              <div onClick={handlerArrowDown} styleName={"popup.arrowDown"}>
                <IcomoonReact iconSet={iconSet} color={"#212121"} size={11} icon={"arrow_down"} />
              </div>
            </div>
          )}
        </div>

        {(onAccept || onAcceptAddition || anotherButtons) && (
          <div styleName={"popup.btn-container"}>
            {onAccept && (
              <SaveBtn
                disabled={acceptDisabled}
                styleName={`${acceptBtnStyle} btnWithLoading`}
                text={textAccept}
                onClick={onAccept}
                loadingView={loadingView}
                setAutoFocus={setAutoFocus}
                btnAdd={btnAdd}
              />
            )}
            {onAcceptAddition && (
              <SaveBtn styleName={"popup.greenAdditional"} text={textAcceptAddition} onClick={onAcceptAddition} />
            )}
            {anotherButtons && anotherButtons}
            {textClose && (
              <LeaveBtn styleName={"popup.close"} text={textClose} onClick={onClose} style={closeButtonStyle} />
            )}
          </div>
        )}
        {children && children}
      </Popup>
    );
  }
}
