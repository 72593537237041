import reduceReducers from "reduce-reducers";

const initialState = {
  startedAt: undefined,
  stoppedAt: undefined,
  baseTime: undefined,
  refreshCounter: 0,
};

function timer(state = initialState, action) {
  switch (action.type) {
    case "RESET_TIMER":
      return {
        ...state,
        baseTime: 11000,
        startedAt: state.startedAt ? action.now : undefined,
        stoppedAt: state.stoppedAt ? action.now : undefined,
      };
    case "START_TIMER":
      return {
        ...state,
        baseTime: action.baseTime,
        startedAt: action.now,
        stoppedAt: undefined,
      };
    case "STOP_TIMER":
      return {
        ...state,
        stoppedAt: action.now,
      };
    case "RESET_INTERVAL":
      return {
        ...state,
        refreshCounter: state.refreshCounter + 1,
      };
    default:
      return state;
  }
}

export const timerReducer = reduceReducers(timer);
