// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__input__iWO9LI {
  border-width: 0;
  box-shadow: none;
  padding: 0;
  height: 21px;
  color: #1e88e5;
}

.select__input__iWO9LI:focus {
    box-shadow: none;
  }

option {
  color: #212121;
}

.select__icon__xKxA0B {
  margin-left: 12px;
  position: relative;
  box-sizing: border-box;
}

.select__detectLanguageIconStyle__PmGLHv {
  cursor: pointer;
  color: rgba(84, 84, 84, 1);
}

.select__detectLanguageIconStyle__PmGLHv:hover {
    color: #1e88e5;
  }

.select__detectLanguageDisableIconStyle__dldah_ {
  cursor: default;
  color: #bdbdbd;
}

.select__detectStickWrapper__y0LUMm {
  position: relative;
  padding-top: 8px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `select__input__iWO9LI`,
	"icon": `select__icon__xKxA0B`,
	"detectLanguageIconStyle": `select__detectLanguageIconStyle__PmGLHv select__icon__xKxA0B`,
	"detectLanguageDisableIconStyle": `select__detectLanguageDisableIconStyle__dldah_ select__icon__xKxA0B`,
	"detectStickWrapper": `select__detectStickWrapper__y0LUMm`
};
export default ___CSS_LOADER_EXPORT___;
