import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import HtmlTranslate from "../../components/HtmlTranslate";
import { setTitle, validateForm } from "../../actions/App";
import { initPasswordReset } from "../../actions/Signup";

import { PASSWORD_RESET_ERROR, PASSWORD_RESET_USER_NOT_ACTIVE_STATUS } from "../../constants/Signup";
import { APP_DISABLED } from "../../constants/App";

import EmailForm from "../../components/forms/EmailForm";
import FormError from "../../components/errors/FormError";

import { I18n } from "react-redux-i18n";
import { withRouter } from "../CustomerApp";
import { resetPasswordAction } from "../../thunks/newThunks/signup/signupAsyncActions";

export class Forgot extends Component {
  static propTypes = {
    fetchResetPassword: PropTypes.func.isRequired,
    initPasswordReset: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    resetPassword: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired,
  };
  static defaultProps = {
    relativePath: "",
  };
  state = {
    closeError: false,
  };

  componentDidMount() {
    this.props.initPasswordReset();
    !this.props.isNotSetTitle && this.props.setTitle(I18n._translate("FORGOT_PASSWORD_PAGE.PAGE_TITLE"));
  }

  componentDidUpdate(prevProps) {
    const { relativePath } = this.props;
    if (prevProps.resetPassword.status !== this.props.resetPassword.status) {
      if (
        this.props.resetPassword.status === PASSWORD_RESET_USER_NOT_ACTIVE_STATUS ||
        this.props.resetPassword.status === null
      ) {
        return;
      }

      this.props.router.navigate(`${relativePath}/forgot-password/reset`);
    }
  }

  closeErrorMessage = () => {
    this.setState({ closeError: true });
  };

  handlerResetPassword = form => {
    this.props.fetchResetPassword(form);

    woopra.track('Clicked the "Forgot the password" button', {
      email: form.email,
      env: process.env.SERVER_NAME,
    });
  };

  render() {
    const { relativePath } = this.props;
    const formErrorDisplay =
      (this.props.resetPassword.status === PASSWORD_RESET_ERROR ||
        this.props.resetPassword.status === PASSWORD_RESET_USER_NOT_ACTIVE_STATUS) &&
      !this.state.closeError;
    return (
      <div>
        <FormError
          onClose={this.closeErrorMessage}
          message={this.props.resetPassword.error}
          display={formErrorDisplay}
        />
        <EmailForm
          disabled={this.props.app.status === APP_DISABLED}
          validator={this.props.validateForm}
          handler={this.handlerResetPassword}
          styleForm={this.props.styleForm}
        />
        <p className="form-alt" style={{ ...this.props.styleFormAlt, fontSize: 14, fontWeight: 300 }}>
          <HtmlTranslate value="FORGOT_PASSWORD_PAGE.SIGNUP_TEXT" />{" "}
          <Link to={`${relativePath}/signup`}>
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.SIGNUP_LINK" />
          </Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resetPassword: state.resetPassword,
    app: state.app,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchResetPassword: form => {
      dispatch(resetPasswordAction({ email: form.email }));
    },
    initPasswordReset: () => {
      dispatch(initPasswordReset());
    },
    validateForm: () => {
      dispatch(validateForm());
    },
    setTitle: title => {
      dispatch(setTitle(title));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Forgot));
