import * as _ from "lodash";
import { I18n } from "react-redux-i18n";

const ENGLISH_LANG_VALUE = "en";

export const getWarningMessage = (sourceLanguage, selectedTargetLanguage, detectedCommentsLanguage, isNotNote) => {
  const oneTargetLangError = isOneTargetLangWarning(selectedTargetLanguage, detectedCommentsLanguage);
  const multipleTargetLanguage = isMultipleTargetLanguage(selectedTargetLanguage);
  if (!isNotNote && oneTargetLangError) {
    return I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ONE_TARGET_LANGUAGE_ERROR", {
      sourceLanguage: I18n.t("LANGUAGES." + sourceLanguage.value),
      targetLanguage: I18n.t("LANGUAGES." + Object.values(selectedTargetLanguage)[0].value),
      universalLanguage: I18n.t("LANGUAGES." + ENGLISH_LANG_VALUE),
    });
  }
  if (!isNotNote && multipleTargetLanguage) {
    return I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.MULTIPLE_TARGET_LANGUAGE_ERROR", {
      sourceLanguage: I18n.t("LANGUAGES." + sourceLanguage.value),
      universalLanguage: I18n.t("LANGUAGES." + ENGLISH_LANG_VALUE),
    });
  }
};

const isOneTargetLangWarning = (selectedTargetLanguage, detectedCommentsLanguage) => {
  const singleTargetLanguage = _.keys(selectedTargetLanguage).length === 1;
  return singleTargetLanguage && !_.keys(selectedTargetLanguage).includes(detectedCommentsLanguage);
};

const isMultipleTargetLanguage = selectedTargetLanguage => {
  return _.keys(selectedTargetLanguage).length > 1;
};
