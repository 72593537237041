import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";
import * as ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Loading from "../../../components/refactored/Loading";
// eslint-disable-next-line no-unused-vars
import button from "./css/green-button.css";

class NitroButton extends PureComponent {
  static propTypes = {
    tabIndex: PropTypes.number,
    buttonStyleName: PropTypes.string,
    title: PropTypes.string,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    loadingView: PropTypes.bool,
  };

  static defaultProps = {
    buttonStyleName: "button.save-button",
    tabIndex: 0,
    title: "Save",
    disabled: false,
    btnApply: false,
  };

  handleClick = e => {
    const { clickHandler } = this.props;
    const button = ReactDOM.findDOMNode(this.button);
    button && button.blur();
    clickHandler(e);
  };

  render() {
    const {
      tabIndex,
      buttonStyleName,
      title,
      titleTooltip,
      disabled,
      style,
      buttonIcon,
      disabledTitle,
      loadingView,
      btnApply,
      type,
    } = this.props;
    return (
      <Button
        tabIndex={tabIndex}
        disabled={disabled}
        onClick={e => this.handleClick(e)}
        styleName={buttonStyleName}
        style={style}
        ref={button => (this.button = button)}
        type={type}
      >
        <div styleName={"button.container"} title={disabled ? disabledTitle : titleTooltip}>
          {btnApply ? (
            loadingView ? (
              <div style={{ marginRight: 8, marginLeft: 8 }}>
                <Loading display={true} size={16} color={"white"} />
              </div>
            ) : (
              <span styleName={"button.title"}>{title}</span>
            )
          ) : (
            <span styleName={"button.title"}>{title}</span>
          )}
          {buttonIcon && buttonIcon}
        </div>
      </Button>
    );
  }
}

export default NitroButton;
