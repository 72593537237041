import { combineReducers } from "redux";
import user from "./user";
import balance from "./balance";
import payment from "./payment";
import translatorRates from "./translatorRates";
import order from "./order";
import settings from "./settings";
import desktop from "./desktop";
import transactions from "./transactions";
import login from "./login";
import signup from "./signup";
import resetPassword from "./reset-password";
import app from "./app";
import socialSignin from "./social-signin";
import errors from "./errors";
import { domain } from "./domain";

import { i18nReducer as i18n } from "react-redux-i18n";
import notification from "./notification";
import { usersReducer } from "./users";
import { adminOrdersReducer } from "./admin/orders";
import { customerOrdersReducer } from "./customer/orders";
import customerBalance from "./customer/balance";
import { translatorOrdersReducer } from "./translator/orders";
import { meReducer } from "./me";

import { newOrderReducer } from "./customer/newOrder";
import { newFileOrderReducer } from "./customer/newFileOrder";
import { supportCustomerReducer } from "./customer/support";
import { supportTranslatorReducer } from "./translator/support";
import { timerReducer } from "./timer";
import { glossaryReducer } from "./customer/glossary";
import { adminGlossaryReducer } from "./admin/glossary";
import { translatorGlossaryReducer } from "./translator/glossary";
import { templatesReducer } from "./domain/mailsTemplates";
import { couponsReducer } from "./domain/coupons";
import couponsTableReducer from "./admin/coupons";
import { translationMemoriesTableReducer } from "./admin/translatorMemories";
import { translationMemoryReducer } from "./customer/translatorMemories";
import { couponReducer } from "./coupon";
import discountsTableReducer from "./admin/discounts";

export const rolesReducer = combineReducers({
  user,
  app,
  i18n,
  notification,
  me: meReducer,
});

export const formsReducer = combineReducers({
  login,
  signup,
  resetPassword,
  app,
  user,
  socialSignin,
  i18n,
  notification,
  coupon: couponReducer,
});

export const translatorReducer = combineReducers({
  app,
  user,
  i18n,
  order,
  settings,
  desktop,
  transactions,
  errors,
  balance,
  notification,
  domain,
  supportTranslator: supportTranslatorReducer,
  me: meReducer,
  orders: translatorOrdersReducer,
  timer: timerReducer,
  glossary: translatorGlossaryReducer,
});

export const adminReducer = combineReducers({
  app,
  i18n,
  desktop,
  balance,
  order,
  payment,
  transactions,
  errors,
  notification,
  domain,
  me: meReducer,
  users: usersReducer,
  translatorRates: translatorRates,
  orders: adminOrdersReducer,
  glossary: adminGlossaryReducer,
  templates: templatesReducer,
  coupons: couponsReducer,
  couponsTable: couponsTableReducer,
  discountsTable: discountsTableReducer,
  translationMemoriesTable: translationMemoriesTableReducer,
});

export const customerReducer = combineReducers({
  app,
  user,
  i18n,
  customerBalance,
  settings,
  payment,
  transactions,
  domain,
  desktop,
  notification,
  supportCustomer: supportCustomerReducer,
  ordersTemp: customerOrdersReducer,
  me: meReducer,
  newOrder: newOrderReducer,
  newFileOrder: newFileOrderReducer,
  glossary: glossaryReducer,
  translationMemoriesTable: translationMemoryReducer,
  coupon: couponReducer,
  login,
  signup,
  resetPassword,
  socialSignin,
});
