// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover__questionHint__O9D_LY {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  align-self: center;
  box-shadow: none;
  padding: 8px;
}

.popover__questionLink__c0ZseC {
  color: #1e88e5 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.popover__questionLinkContainer__hnPxPR {
  padding-top: 8px;
}

.popover__exit-container__wEqFC9 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.popover__exit-container-hover__QZSez8 {
  color: #1e88e5;
}

.popover__addTime-container__bEjLzo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.popover__detailBlue__mPN6FJ {
  color: rgba(84, 84, 84, 1);
}

.popover__detailBlue__mPN6FJ:hover {
  color: #1e88e5;
}

.popover__exit-container__wEqFC9 .popover__detailBlue__mPN6FJ {
  color: inherit;
}

.popover__detail__JnKZGq {
}

.popover__detailItem-container__gBVt_d {
  min-width: 135px;
  margin: 0;
  padding: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionHint": `popover__questionHint__O9D_LY`,
	"questionLink": `popover__questionLink__c0ZseC`,
	"questionLinkContainer": `popover__questionLinkContainer__hnPxPR`,
	"exit-container": `popover__exit-container__wEqFC9`,
	"exit-container-hover": `popover__exit-container-hover__QZSez8 popover__exit-container__wEqFC9`,
	"addTime-container": `popover__addTime-container__bEjLzo`,
	"detailBlue": `popover__detailBlue__mPN6FJ`,
	"detail": `popover__detail__JnKZGq`,
	"detailItem-container": `popover__detailItem-container__gBVt_d`
};
export default ___CSS_LOADER_EXPORT___;
