// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discount__container__s31GFc {
  display: flex;
  /*align-items: center;*/
}

.discount__input__BKq9l2 {
  resize: none;
  color: #424242;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  width: 146px;
  height: 3.2rem;
  border-radius: 1px;
  box-shadow: none;
  padding-left: 9px;
  border: 1px solid #cecece;
  margin-right: 4px;
}

.discount__input__BKq9l2:focus {
    border: 1px solid #0075ff;
    box-shadow: none;
  }

.discount__validation-message__oeD2hx {
  margin-top: 4px;
  margin-bottom: 0;
  color: #ef5350;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  line-height: 16px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `discount__container__s31GFc`,
	"input": `discount__input__BKq9l2`,
	"validation-message": `discount__validation-message__oeD2hx`
};
export default ___CSS_LOADER_EXPORT___;
