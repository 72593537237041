// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9IjAgMCAxMiA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPjgxMDA4NTU5LTkxNTMtNENCNC1CQTFGLTVDMEJCNDU0NUFCODwvdGl0bGU+CiAgICA8ZyBpZD0i8J+Rt/Cfj7wtbml0cm8tdHJhbnNsYXRvciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9InN1cHBvcnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00OTMuMDAwMDAwLCAtNDA5LjAwMDAwMCkiIGZpbGw9IiM2MTYxNjEiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iZG93bi1hcnJvdyIgcG9pbnRzPSI1MDMuNTkgNDA5IDQ5OSA0MTMuNTggNDk0LjQxIDQwOSA0OTMgNDEwLjQxIDQ5OSA0MTYgNTA1IDQxMC40MSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `details {
  margin-bottom: 24px;
}

details summary {
    outline: none;
    cursor: pointer;
    color: #424242;
    font-weight: 300;
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
  }

details summary:before {
      position: relative;
      top: -2px;
      margin-right: 12px;
      display: inline-block;
      transform: rotate(-90deg);
      content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    }

details[open] summary:before {
      transform: rotate(0deg);
      content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    }

.support-faq__textAfterTitle__QaB6WU {
  margin-bottom: 24px;
}

.support-faq__textSuggestion__FHliln p {
    margin-bottom: 0 !important;
  }

.support-faq__textSuggestion__FHliln a {
    cursor: pointer;
  }

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

details:last-child,
details:last-child p:last-child {
  margin-bottom: 0;
}

.support-faq__supportedFormats__IMvP42,
.support-faq__container__o20Zbk {
  padding: 16px 0 0 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  white-space: pre-wrap;
}

ol {
  padding-left: 14px;
  list-style-type: decimal;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.support-faq__description__SZrdXK {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
}

.support-faq__description__SZrdXK a {
    color: #1e88e5;
    cursor: pointer;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    border-bottom: none;
  }

.support-faq__description__SZrdXK a:hover {
      color: #1e88e5;
      -webkit-text-decoration: underline;
      text-decoration: underline;
      border-bottom: none;
    }

.support-faq__description__SZrdXK span p {
  margin-bottom: 16px;
}

.support-faq__description__SZrdXK p img {
  width: 100%;
  height: 100%;
}

.support-faq__description__SZrdXK p {
  margin-bottom: 8px;
}

.support-faq__description__SZrdXK ol:last-child {
  margin-bottom: 0;
}

.support-faq__supportedFormats__IMvP42 ul {
  padding: 0 0 0 16px;
  margin-bottom: 16px;
}

.support-faq__supportedFormats__IMvP42 ol li::marker {
  font-weight: 400;
}

.support-faq__supportedFormats__IMvP42 ul li {
  list-style-type: square;
  margin-bottom: 8px;
}

.support-faq__supportedFormats__IMvP42 p {
  margin-bottom: 16px;
}

.support-faq__supportedFormats__IMvP42 pre {
  margin-bottom: 16px;
  padding: 16px 20px;
  border: none;
  border-radius: 0;
  font-family: "Roboto";
}

.support-faq__supportedFormats__IMvP42 section {
  margin-bottom: 16px;
  margin-top: 8px;
  padding: 16px 20px;
  background: #fff3e0;
  font-family: "Roboto";
}

.support-faq__supportedFormats__IMvP42 h5 {
  font-weight: 400;
  margin-bottom: 8px;
}

.support-faq__container__o20Zbk ol,
.support-faq__supportedFormats__IMvP42 ol {
  padding-left: 16px;
  margin-bottom: -21px;
}

.support-faq__supportedFormats__IMvP42 ol > p {
  margin: 0 0 8px -16px;
}

.support-faq__container__o20Zbk ol li,
.support-faq__supportedFormats__IMvP42 ol li {
  margin-bottom: 8px;
  padding-left: 12px;
}

.support-faq__supportedFormats__IMvP42 p span,
.support-faq__supportedFormats__IMvP42 pre span,
.support-faq__supportedFormats__IMvP42 ol li span {
  font-weight: 400;
  color: #1e88e5;
}

.support-faq__supportedFormats__IMvP42 p b {
  font-weight: 400;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textAfterTitle": `support-faq__textAfterTitle__QaB6WU`,
	"textSuggestion": `support-faq__textSuggestion__FHliln`,
	"supportedFormats": `support-faq__supportedFormats__IMvP42`,
	"container": `support-faq__container__o20Zbk`,
	"description": `support-faq__description__SZrdXK`
};
export default ___CSS_LOADER_EXPORT___;
