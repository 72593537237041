// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-button__item__jXTpLl {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #424242;
  height: 36px;
  border: 0;
  display: block;
  text-align: left;
  padding: 8px 11px 8px 8px !important;
  cursor: pointer;
}

.detail-button__item__jXTpLl:hover {
    background-color: #e3f2fd;
    color: #1e88e5;
  }

.detail-button__popover-block__geJLhO {
  position: absolute;
  z-index: 10;
  padding: 10px;
  background: white;
  width: 255px;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 19%);
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  margin-top: 13px;
  white-space: pre-line;
}

.detail-button__popover-block__geJLhO::before,
.detail-button__popover-block__geJLhO::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #bdbdbd;
  left: 15px;
  top: -20px;
}

.detail-button__popover-block__geJLhO::after {
  border-bottom: 10px solid white;
  left: 15px;
  top: -19px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `detail-button__item__jXTpLl`,
	"popover-block": `detail-button__popover-block__geJLhO`
};
export default ___CSS_LOADER_EXPORT___;
