var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState, useEffect, useCallback, useMemo } from "react";
import Popup from "../../../elements/Popup";
import styles from "./css/styles.css";
import { I18n } from "react-redux-i18n";
import NitroButton from "../../GreenButton";
import { InputWithOptions } from "./InputWithOptions";
import { useSelector } from "react-redux";
import ErrorMessage from "../../ErrorMessage";
import { SUBMIT_BUTTON_STYLES, ERROR_MESSAGE_STYLES, ERROR_MESSAGE_TEXT_STYLES } from "./helpers";
var INVALID_VALUE = "INPUT_VALIDATIONS.INVALID_VALUE";
var INVALID_CHARACTER = "INPUT_VALIDATIONS.INVALID_CHARACTER";
var EMPTY_STRING_VALUES = {
    delimiter: "",
    lineSeparator: "",
    quote: "",
};
var EMPTY_OPTION = { value: "", label: "" };
var escapeNewLines = function (inputString) {
    if (inputString === void 0) { inputString = ""; }
    var replacedString = inputString.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
    return replacedString;
};
var removeEscaping = function (string) {
    if (string === void 0) { string = ""; }
    var replacedString = string.replace(/\\n/g, "\n").replace(/\\r/g, "\r");
    return replacedString;
};
var Header = function (_a) {
    var _b = _a.isErrorWithDefaultParserInfo, isErrorWithDefaultParserInfo = _b === void 0 ? false : _b;
    return (_jsxs("div", { children: [_jsx("h1", { className: styles.title, children: I18n.t("CSV_SETTINGS_POPUP.TITLE") }), _jsx("div", { children: _jsx("p", { className: styles.description, children: isErrorWithDefaultParserInfo
                        ? I18n.t("CSV_SETTINGS_POPUP.DESCRIPTION_ERROR")
                        : I18n.t("CSV_SETTINGS_POPUP.DESCRIPTION") }) })] }));
};
var Footer = function (_a) {
    var handleSubmitClick = _a.handleSubmitClick, handleOnClose = _a.handleOnClose, submitError = _a.submitError;
    var _b = useState(false), shouldShowError = _b[0], setShouldShowError = _b[1];
    useEffect(function () {
        setShouldShowError(!!submitError);
    }, [submitError]);
    var handleOnSubmitErrorClose = function () {
        setShouldShowError(false);
    };
    return (_jsxs(_Fragment, { children: [shouldShowError && (_jsx("div", { style: { paddingTop: 24 }, children: _jsx(ErrorMessage, { style: ERROR_MESSAGE_STYLES, messageTextStyle: ERROR_MESSAGE_TEXT_STYLES, onClose: handleOnSubmitErrorClose, message: submitError }) })), _jsxs("div", { className: styles.buttonsWrapper, children: [_jsx(NitroButton, { clickHandler: handleSubmitClick, style: SUBMIT_BUTTON_STYLES, title: I18n.t("CSV_SETTINGS_POPUP.SUBMIT") }), _jsx("button", { className: styles.clearButton, onClick: handleOnClose, children: I18n.t("CSV_SETTINGS_POPUP.CANCEL") })] })] }));
};
var NEW_LINE_ELEMENTS = ["\n", "\r", "\\n", "\\r", "\r\n", "\\r\\n", "\n\r", "\\n\\r"];
var PopupCSVSettings = function (_a) {
    var file = _a.file, isOpen = _a.isOpen, onClose = _a.onClose, isErrorWithDefaultParserInfo = _a.isErrorWithDefaultParserInfo, parserInfo = _a.parserInfo, setParserInfo = _a.setParserInfo, setFileElements = _a.setFileElements, uploadFileFromPopup = _a.uploadFileFromPopup, openCSVSelectorPopup = _a.openCSVSelectorPopup;
    var storedSelectedFileSegments = useSelector(function (state) { return state.newOrder.uploadedFileSegmentsData; });
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var _c = useState(function () { return ({
        delimiter: EMPTY_OPTION,
        lineSeparator: EMPTY_OPTION,
        quote: EMPTY_OPTION,
    }); }), selected = _c[0], setSelected = _c[1];
    var _d = useState({
        delimiter: "",
        lineSeparator: "",
        quote: "",
    }), fieldsErrors = _d[0], setFieldsErrors = _d[1];
    var _e = useState(""), submitError = _e[0], setSubmitError = _e[1];
    var NEW_LINE = useMemo(function () { return I18n.t("CSV_SETTINGS_POPUP.NEW_LINE", { char: "\\n" }); }, [I18n]);
    var createOptionFromValue = function (value) {
        if (value === void 0) { value = ""; }
        var hasNewLineElement = NEW_LINE_ELEMENTS.some(function (v) { return value === v; });
        return { value: value, label: hasNewLineElement ? NEW_LINE : value };
    };
    var getDelimitersAsOptions = function (delimiters) {
        return delimiters.map(function (delimiter) {
            return createOptionFromValue(delimiter);
        });
    };
    useEffect(function () {
        var hasStoredParserInfo = storedSelectedFileSegments === null || storedSelectedFileSegments === void 0 ? void 0 : storedSelectedFileSegments.parserInfo;
        var isInitialParserInfoEmpty = parserInfo && !Object.keys(parserInfo).length;
        if (hasStoredParserInfo && isInitialParserInfoEmpty) {
            setParserInfo(hasStoredParserInfo);
        }
    }, [storedSelectedFileSegments === null || storedSelectedFileSegments === void 0 ? void 0 : storedSelectedFileSegments.parserInfo, parserInfo, setParserInfo]);
    var setInitialParserInfo = useCallback(function () {
        var hasValues = parserInfo && Object.keys(parserInfo).length;
        if (!hasValues)
            return;
        var delimiter = parserInfo.delimiter, lineSeparator = parserInfo.lineSeparator, quote = parserInfo.quote, availableDelimiters = parserInfo.availableDelimiters;
        var options = getDelimitersAsOptions(availableDelimiters);
        setOptions(options);
        setSelected({
            delimiter: createOptionFromValue(escapeNewLines(delimiter)),
            lineSeparator: createOptionFromValue(escapeNewLines(lineSeparator)),
            quote: createOptionFromValue(escapeNewLines(quote)),
        });
    }, [parserInfo, setSelected, setOptions]);
    useEffect(function () {
        setInitialParserInfo();
    }, [setInitialParserInfo]);
    var handleSelectorChange = function (name) { return function (value) {
        var isOption = typeof value !== "string";
        setSelected(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = isOption ? value : createOptionFromValue(escapeNewLines(value)), _a)));
        });
    }; };
    var checkAndSetErrors = function () {
        var errors = {};
        Object.keys(selected).reduce(function (acc, currentKey) {
            var _a = selected[currentKey], value = _a.value, label = _a.label;
            if (!removeEscaping(value) || acc.includes(removeEscaping(value)) || acc.includes(label)) {
                errors[currentKey] = I18n.t(INVALID_VALUE);
            }
            return !!value ? __spreadArray(__spreadArray([], acc, true), [removeEscaping(value), label], false) : acc;
        }, []);
        setFieldsErrors(function (prev) {
            return __assign(__assign({}, EMPTY_STRING_VALUES), errors);
        });
        return Object.keys(errors).length;
    };
    var validateValues = function () {
        var hasErrors = checkAndSetErrors();
        if (hasErrors)
            return false;
        setFieldsErrors(EMPTY_STRING_VALUES);
        return true;
    };
    var checkAreValuesChanged = function () {
        var delimiter = selected.delimiter, lineSeparator = selected.lineSeparator, quote = selected.quote;
        var initDelimiter = parserInfo.delimiter, initLineSeparator = parserInfo.lineSeparator, initQuote = parserInfo.quote;
        var areChanged = removeEscaping(delimiter.value) !== initDelimiter ||
            removeEscaping(lineSeparator.value) !== initLineSeparator ||
            removeEscaping(quote.value) !== initQuote;
        return areChanged;
    };
    var getPayload = function () {
        var delimiter = selected.delimiter, lineSeparator = selected.lineSeparator, quote = selected.quote;
        var payload = __assign(__assign(__assign({}, parserInfo), selected), { skipHeader: false, delimiter: removeEscaping(delimiter.value), lineSeparator: removeEscaping(lineSeparator.value), quote: removeEscaping(quote.value), comment: "#" });
        return payload;
    };
    var handleSubmitError = function (error) {
        if (!error)
            return;
        var fieldsNamesByErrorFieldsKeys = {
            DELIMITER_CHAR: "delimiter",
            SEPARATOR_CHAR: "lineSeparator",
            QUOTE_CHAR: "quote",
        };
        var ERROR_FIELDS_KEY = [
            "DELIMITER_CHAR",
            "SEPARATOR_CHAR",
            "QUOTE_CHAR",
        ];
        Object.keys(error).forEach(function (key) {
            if (key === "missingChar" && (error === null || error === void 0 ? void 0 : error.missingChar)) {
                var _a = error.missingChar, line = _a.line, position = _a.position;
                var errorText = I18n.t("CSV_SETTINGS_POPUP.ERROR_MISSING_QUOTE_CHAR", {
                    line: line,
                    position: position,
                    quoteChar: selected.quote.label,
                });
                setSubmitError(errorText);
            }
            if (ERROR_FIELDS_KEY.some(function (field) { return field === key; })) {
                var field_1 = fieldsNamesByErrorFieldsKeys[key];
                var err_1 = I18n.t(INVALID_CHARACTER, {
                    character: selected[field_1].label,
                });
                setFieldsErrors(function (prev) {
                    var _a;
                    return __assign(__assign({}, prev), (_a = {}, _a[field_1] = err_1, _a));
                });
            }
        });
    };
    var handleSubmitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValid, areChanged, payload, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isValid = validateValues();
                    areChanged = checkAreValuesChanged();
                    if (!isValid)
                        return [2 /*return*/];
                    if (!areChanged && !submitError) {
                        onClose();
                        openCSVSelectorPopup();
                        return [2 /*return*/];
                    }
                    payload = getPayload();
                    setFileElements([]);
                    setSubmitError("");
                    return [4 /*yield*/, uploadFileFromPopup({
                            fileName: file.filename,
                            fileText: file.filesAsFormattedText,
                            parserInfo: payload,
                        })];
                case 1:
                    result = _a.sent();
                    if (result === null || result === void 0 ? void 0 : result.error) {
                        handleSubmitError(result === null || result === void 0 ? void 0 : result.error);
                    }
                    else {
                        setParserInfo(payload);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleOnClose = function () {
        var shouldRemoveFile = !(file === null || file === void 0 ? void 0 : file.uuid);
        if (!shouldRemoveFile) {
            setInitialParserInfo();
        }
        setSubmitError("");
        setFieldsErrors(EMPTY_STRING_VALUES);
        onClose(shouldRemoveFile);
    };
    return (_jsxs(Popup, { className: styles.container, stylePopup: { overflow: "scroll", padding: "120px 0 50px" }, style: { bottom: "initial" }, containerStyle: { overflowY: "initial" }, isOpen: isOpen, onClose: handleOnClose, hideContainerScroll: true, isHeader: _jsx(Header, { isErrorWithDefaultParserInfo: isErrorWithDefaultParserInfo }), children: [_jsxs("div", { children: [_jsx("div", { className: styles.fieldWrapper, children: _jsx(InputWithOptions, { id: "delimiter", label: I18n.t("CSV_SETTINGS_POPUP.SPLIT_COLUMNS_TITLE"), options: options, onChange: handleSelectorChange("delimiter"), value: selected.delimiter.label, errorText: fieldsErrors.delimiter }) }), _jsx("div", { className: styles.fieldWrapper, children: _jsx(InputWithOptions, { 
                            //todo: lineSeparator can not be more than 2 symbols, max length 2 symb
                            id: "lineSeparator", label: I18n.t("CSV_SETTINGS_POPUP.SPLIT_ROWS_TITLE"), options: options, onChange: handleSelectorChange("lineSeparator"), value: selected.lineSeparator.label, errorText: fieldsErrors.lineSeparator }) }), _jsx("div", { className: styles.fieldWrapper, children: _jsx(InputWithOptions, { id: "quote", label: I18n.t("CSV_SETTINGS_POPUP.DONT_SPLIT_TITLE"), options: options, onChange: handleSelectorChange("quote"), value: selected.quote.label, errorText: fieldsErrors.quote }) })] }), _jsx(Footer, { submitError: submitError, handleOnClose: handleOnClose, handleSubmitClick: handleSubmitClick })] }));
};
export default memo(PopupCSVSettings);
