import React from "react";
import * as _ from "lodash";

import { createLocalFn } from "../getLocalizedString";

import ErrorMessage from "../../components/refactored/ErrorMessage/ErrorMessage";
import { I18n } from "react-redux-i18n";

const newOrderPageLocal = createLocalFn("CUSTOMER_APP.NEW_ORDER_PAGE");

const validateNewOrder = (orderData, closeErrorMessage, notSourceText, files) => {
  const { selectedTargetLanguage, sourceLanguage, sourceText, sourceHint, images } = orderData;
  const sourceLanguageValue = sourceLanguage && sourceLanguage.value;
  const isSourceLanguageValueNotExist = isNullOrUndefinedItem(sourceLanguageValue);
  const isSourceTextNotExist = isNullOrUndefinedItem(sourceText);
  const isNoSelectedTargetLanguage = _.values(selectedTargetLanguage).length === 0;
  const isNoContextOrImages = !sourceHint && _.isEmpty(images);

  if (notSourceText && files.length > 0 && files[0].isError) {
    return {
      blockId: "source",
      isSourceTextNotExist: (
        <ErrorMessage
          onClose={() => closeErrorMessage("isSourceTextNotExist")}
          message={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.INVALID_FILE")}
        />
      ),
    };
  }
  if (!notSourceText && isSourceTextNotExist) {
    return {
      blockId: "source",
      isSourceTextNotExist: (
        <ErrorMessage
          onClose={() => closeErrorMessage("isSourceTextNotExist")}
          message={newOrderPageLocal("DONT_HAVE_SOURCE_TEXT")}
        />
      ),
    };
  }
  if (isSourceLanguageValueNotExist) {
    return {
      blockId: "selectSourceLang",
      isSourceLanguageValueNotExist: (
        <ErrorMessage
          onClose={() => closeErrorMessage("isSourceLanguageValueNotExist")}
          message={newOrderPageLocal("DONT_SELECT_SOURCE_LANGUAGE")}
        />
      ),
    };
  }
  if (isNoSelectedTargetLanguage) {
    return {
      blockId: "target",
      isNoSelectedTargetLanguage: (
        <ErrorMessage
          onClose={() => closeErrorMessage("isNoSelectedTargetLanguage")}
          message={newOrderPageLocal("DONT_SELECT_TARGET_LANGUAGE")}
        />
      ),
    };
  }
  if (isNoContextOrImages) {
    return {
      blockId: "context",
      isNoContextOrImages: (
        <ErrorMessage
          onClose={() => closeErrorMessage("isNoContextOrImages")}
          message={newOrderPageLocal("DONT_HAVE_CONTEXT_OR_COMMENT")}
        />
      ),
    };
  }
  return {};
};
export const validateDraftOrder = (orderData, files) => {
  const {
    selectedTargetLanguage,
    sourceLanguage,
    sourceText,
    sourceHint,
    images,
    maxTranslationsLength,
    advancedCategoryValue,
    advancedSettingsValue,
  } = orderData;
  const sourceLanguageValue = sourceLanguage && sourceLanguage.value;
  const isSourceLanguageValueNotExist = isNullOrUndefinedItem(sourceLanguageValue);
  const isSourceTextNotExist = isNullOrUndefinedItem(sourceText);
  const isNoSelectedTargetLanguage = _.values(selectedTargetLanguage).length === 0;
  const isImages = _.values(images).length === 0;
  const isSourceHint = isNullOrUndefinedItem(sourceHint);
  const isMaxTranslationsLength = isNullOrUndefinedItem(maxTranslationsLength);
  const isAdvancedCategoryValue = advancedCategoryValue === 0;
  const isAdvancedSettingsValue = advancedSettingsValue === "TRANSLATOR_GUESS";

  return (
    (files.length > 0 && !files[0].isError) ||
    (!files.length > 0 && !isSourceTextNotExist) ||
    !isSourceLanguageValueNotExist ||
    !isNoSelectedTargetLanguage ||
    !isImages ||
    !isSourceHint ||
    !isMaxTranslationsLength ||
    !isAdvancedCategoryValue ||
    !isAdvancedSettingsValue
  );
};

const isNullOrUndefinedItem = item => {
  return typeof item === "string" ? !item.trim() : !item;
};

export default validateNewOrder;
