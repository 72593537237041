import React, { Component } from "react";
import Title from "../../../Title";
// eslint-disable-next-line no-unused-vars
import block from "./css/block.css";
import smoothscroll from "smoothscroll-polyfill";
export default class BlockContent extends Component {
  state = {
    isFocusOnContent: false,
  };

  componentDidMount() {
    smoothscroll.polyfill();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isFocusOnContent } = this.state;
    const { content, counter, errors } = this.props;

    return (
      errors !== nextProps.errors ||
      content !== nextProps.content ||
      counter !== nextProps.counter ||
      isFocusOnContent !== nextState.isFocusOnContent
    );
  }

  render() {
    const { isFocusOnContent } = this.state;
    const {
      title,
      titleColor,
      question,
      counter,
      content,
      questionLink,
      contentStyle,
      titleStyle,
      errors,
      isReverseBlocks,
    } = this.props;

    return (
      <React.Fragment>
        {errors && <span ref={errors => (this.errors = errors)}>{errors}</span>}
        <div styleName={isReverseBlocks ? "block.reverse-container" : ""}>
          {title && (
            <div styleName={"block.title"} style={titleStyle}>
              <Title
                text={title}
                titleColor={titleColor}
                question={question}
                questionLink={questionLink}
                counter={counter}
                container={this}
              />
            </div>
          )}
          <div styleName={isFocusOnContent ? "block.content-focus" : "block.content"} style={contentStyle}>
            {content}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
