import * as types from "../constants/App";

export function setIsTokenLoading(value = false) {
  return {
    type: types.SET_IS_TOKEN_LOADING,
    value,
  };
}

export function setToken(token) {
  return {
    type: types.SET_TOKEN,
    token,
  };
}
export function setRefreshToken(refreshToken) {
  return {
    type: types.SET_REFRESH_TOKEN,
    refreshToken,
  };
}
export function disableApp(trigger) {
  return {
    type: types.DISABLE_APP,
    trigger,
  };
}

export function enableApp() {
  return {
    type: types.ENABLE_APP,
  };
}

export function setTitle(title) {
  return {
    type: types.SET_TITLE,
    title,
  };
}

export function validateForm() {
  return {
    type: types.VALIDATE_FORM,
  };
}
