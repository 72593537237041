// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+NEU2MTBCNjktMkIwNy00QTFGLTlEMjMtRkY1QTU3NUYxNDc3PC90aXRsZT4KICAgIDxnIGlkPSJVSS1LSVQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJVSS1LSVQtREVNTyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk4NC4wMDAwMDAsIC0xMjI1LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iYWN0aXZlLWNoZWNrLWljb24tMTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk4NC4wMDAwMDAsIDEyMjUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjMzQ5M0U3IiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC0yIiBmaWxsPSIjRkZGRkZGIiBwb2ludHM9IjEzIDUgMTEgNSA3IDkgNSA3IDMgNyAzIDggNyAxMiAxMyA2Ij48L3BvbHlnb24+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox__container__WBwwZk {
  position: relative;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.checkbox__input__Q5Keef {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox__checkmark__pMsHPz {
  top: 0;
  left: 0;
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 1px;
  border: 1px solid #bdbdbd;
  background: #ffffff;
}

.checkbox__checkmark__pMsHPz:focus {
  outline: 1px solid #449ce9 !important;
  outline-offset: -1px !important;
}

.checkbox__checkmark__pMsHPz:active {
  outline: none !important;
}

.checkbox__container__WBwwZk .checkbox__input__Q5Keef:checked ~ .checkbox__checkmark__pMsHPz {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    center;
  border: none;
}

.checkbox__containerKeyBoardFocus__hz0GUO {
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `checkbox__container__WBwwZk`,
	"input": `checkbox__input__Q5Keef`,
	"checkmark": `checkbox__checkmark__pMsHPz`,
	"containerKeyBoardFocus": `checkbox__containerKeyBoardFocus__hz0GUO checkbox__container__WBwwZk`
};
export default ___CSS_LOADER_EXPORT___;
