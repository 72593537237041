export const REQUEST_ORDERS = "REQUEST_ORDERS";
export const REFRESH_ORDERS = "REFRESH_ORDERS";
export const SUCCEED_ORDERS = "SUCCEED_ORDERS";
export const SUCCEED_REFRESH = "SUCCEED_REFRESH";
export const FAIL_ORDERS = "FAIL_ORDERS";
export const CLEANUP_ORDERS = "CLEANUP_ORDERS";

export const ORDERS_FETCHED = "ORDERS_FETCHED";
export const ORDERS_FETCHING = "ORDERS_FETCHING";
export const ORDERS_REFRESHING = "ORDERS_REFRESHING";
export const ORDERS_ERROR = "ORDERS_ERROR";

export const ORDER_FORMATS_STATUS = {
  0: "ORDER_FILE_FORMATION_UNSPECIFIED",
  1: "ORDER_FILE_FORMATION_QUEUE",
  2: "ORDER_FILE_FORMATION_IN_PROGRESS",
  3: "ORDER_FILE_FORMATION_COMPLETED_WITH_SUCCESS",
  4: "ORDER_FILE_FORMATION_COMPLETED_WITH_FAILURE",
};

export const ORDER_STATUS_LIST = {
  UNDEFINED: 0,
  QUEUE: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  ARCHIVED: 4,
  DRAFT: 5,
};

export const TEXT_FORMAT = "Text";
//copied from fileLinksHelper.js <- combine
const GOOGLE_DOC = "Google docs";
const GOOGLE_DOC_UPPERCASE = "GOOGLE_DOC";
export const GOOGLEDOCS = [GOOGLE_DOC, GOOGLE_DOC_UPPERCASE];

const GOOGLE_SHEETS = "Google sheets";
const GOOGLE_SHEETS_UPPERCASE = "GOOGLE_SPREADSHEET";
export const GOOGLESHEETS = [GOOGLE_SHEETS, GOOGLE_SHEETS_UPPERCASE];

export const ORDER_FORMATS = {
  STRINGS: "strings",
  HTML: "html",
  JSON: "json",
  TXT: "txt",
  XML: "xml",
  CSV: "csv",
  SRT: "srt",
};

export const ORDER_FORMATS_WITH_POPUP = [ORDER_FORMATS.XML, ORDER_FORMATS.CSV];
export const FORMATS_WITH_DEFAULT_DOWNLOAD_LINK = [
  ORDER_FORMATS.HTML,
  ORDER_FORMATS.JSON,
  ORDER_FORMATS.TXT,
  ORDER_FORMATS.XML,
  ORDER_FORMATS.CSV,
  ORDER_FORMATS.STRINGS,
  ORDER_FORMATS.SRT,
];
