import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import input from "./css/search-input.css";
import _ from "lodash";

class SearchInput extends Component {
  state = {
    focused: false,
    content: "",
    lastSearch: "",
    isMobile: document.documentElement.clientWidth < 520,
  };

  detectMobile = _.debounce(() => {
    this.setState({
      isMobile: document.documentElement.clientWidth < 520,
    });
  }, 300);

  componentDidMount() {
    this.props.searchInitContent &&
      this.setState({
        content: this.props.searchInitContent,
        lastSearch: this.props.searchInitContent,
        isMobile: document.documentElement.clientWidth < 520,
      });
    window.addEventListener("resize", this.detectMobile);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.insertContent !== this.props.insertContent) {
      this.insertContent();
    }
    if (prevProps.searchInitContent !== this.props.searchInitContent) {
      this.setState({
        content: this.props.searchInitContent,
        focused: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectMobile);
  }

  focus = () => {
    this.setState({
      focused: true,
    });
  };

  resetFocus = () => {
    this.setState({
      focused: false,
      content: this.state.lastSearch,
    });
  };

  insertContent = () => {
    this.setState({
      content: this.props.insertContent,
      lastSearch: this.props.insertContent,
    });
  };

  handleChange = event => {
    this.setState({
      content: event.target.value,
    });
  };

  handleClickSearch = () => {
    const { content, focused, lastSearch } = this.state;

    if (content && lastSearch === content) {
      this.setState({
        content: "",
        focused: true,
        lastSearch: "",
      });
      this.props.resetSearch && this.props.resetSearch();
    } else if (focused && lastSearch !== content) {
      this.setState({
        lastSearch: content,
        focused: true,
      });
      this.props.handleSearch(content);
    }
    this.input && this.input.focus();
  };

  searchFocus = () => {
    this.setState(
      {
        focused: true,
      },
      () => {
        this.input && this.input.focus();
      }
    );
  };

  handleKeyDown = e => {
    const { content, focused, lastSearch } = this.state;

    if (e.key === "Enter" && content) {
      if (focused && lastSearch !== content) {
        this.setState({
          lastSearch: content,
          focused: true,
        });
      }

      this.props.handleSearch(content);
    } else if (e.key === "Enter" && content === "") {
      this.setState({
        lastSearch: content,
        focused: true,
      });
      this.props.resetSearch && this.props.resetSearch();
    } else if (focused && e.key === "Escape" && lastSearch !== "") {
      e.preventDefault();
      this.setState({
        content: lastSearch,
      });
    }
  };

  getIcon = () => {
    const { content, focused, lastSearch } = this.state;

    if (!content) {
      return "icon-unfocus";
    } else if (lastSearch === content || !focused) {
      return "icon-unfocus-content";
    } else {
      return "icon-focus";
    }
  };

  render() {
    const { content, focused, isMobile } = this.state;
    const { placeholder } = this.props;
    let inputStyle = this.getIcon();

    return !isMobile || focused || content ? (
      <div styleName={"input.box"}>
        <div onKeyDown={this.handleKeyDown} styleName={"input.search"}>
          <div styleName={"input.input-container"}>
            <input
              onFocus={this.focus}
              onBlur={this.resetFocus}
              onChange={this.handleChange}
              placeholder={placeholder}
              value={content}
              styleName={"input.input"}
              type="search"
              ref={input => (this.input = input)}
            />
          </div>
          <div styleName={"input.icon-container"} onMouseDown={this.handleClickSearch}>
            <span styleName={inputStyle} />
          </div>
        </div>
      </div>
    ) : (
      <div styleName={"input.icon-container"} onClick={this.searchFocus}>
        <span styleName={inputStyle} />
      </div>
    );
  }
}

export default SearchInput;
