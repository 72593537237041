// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-modal__containerModal__IZxiZg{
    position: fixed;
    bottom: 0;
    margin: 0 0 16px 16px;

    width: 334px;
    height: 292px;
    z-index: 5;
}
.review-modal__hideModal__cYOel1{
    height: 0;
    opacity: 0;
    transition: height 1.2s ease-out, opacity 1.5s ease-out;
}

.review-modal__modalContent__c2w3og{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #FFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.26);
}

.review-modal__closeModal__wj8v9c{
    position: absolute;
    top: 8px;
    right: 16px;
    cursor: pointer;
}

.review-modal__title__M132T2{
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;

    margin: 0 0 12px 0;
}
.review-modal__rateContainer__FgK3uL{
    display: flex;
    margin: 0 0 16px 0;
}

.review-modal__reviewTextarea__xdjRdW{
    padding: 16px;
    height: 95px;
    border-width: 1px;
    border-color: #E0E0E0;
    margin: 0 0 24px 0;

    color: #212121;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.review-modal__reviewTextarea__xdjRdW::placeholder {
    color: #9E9E9E;
}

.review-modal__starPosition__qIynZO{
    margin-right: 8px;
    cursor: pointer;
}
.review-modal__notActiveStar__WBa8bV {
    stroke: #545454;
    fill: transparent;
}

.review-modal__activeStar__zL8Z0y,
.review-modal__activeStar__zL8Z0y path{
    stroke: #ffb300;
    fill: #ffb300;
}
.review-modal__hoveredStar__RdOZjS path{
    stroke: #ffb300;
}

/* animation */
.review-modal__slideInBottom__orJN_R{
    animation: review-modal__slideInBottom__orJN_R 1s ease-out;
}
.review-modal__slideOutBottom__i70LsJ {
    animation: review-modal__slideOutBottom__i70LsJ 1s ease-in;
}

@keyframes review-modal__slideInBottom__orJN_R {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes review-modal__slideOutBottom__i70LsJ {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerModal": `review-modal__containerModal__IZxiZg`,
	"hideModal": `review-modal__hideModal__cYOel1`,
	"modalContent": `review-modal__modalContent__c2w3og`,
	"closeModal": `review-modal__closeModal__wj8v9c`,
	"title": `review-modal__title__M132T2`,
	"rateContainer": `review-modal__rateContainer__FgK3uL`,
	"reviewTextarea": `review-modal__reviewTextarea__xdjRdW`,
	"starPosition": `review-modal__starPosition__qIynZO`,
	"notActiveStar": `review-modal__notActiveStar__WBa8bV`,
	"activeStar": `review-modal__activeStar__zL8Z0y`,
	"hoveredStar": `review-modal__hoveredStar__RdOZjS`,
	"slideInBottom": `review-modal__slideInBottom__orJN_R`,
	"slideOutBottom": `review-modal__slideOutBottom__i70LsJ`
};
export default ___CSS_LOADER_EXPORT___;
