import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import popover from "./css/popover.css";
import iconSet from "../../../images/selection.json";
import IcomoonReact from "icomoon-react";

export default class Tutorial extends Component {
  state = {
    isResize: false,
    isVisible: false,
    isDone: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      isResize: true,
    });
  };

  onDone = () => {
    this.startAnimateStates(this.props.content.handlerDone, this.props.content.id);
  };

  onClose = () => {
    this.startAnimateStates(this.props.content.handlerClose);
  };

  startAnimateStates = (method, data) => {
    this.setState({
      isVisible: false,
      isDone: true,
    });
    setTimeout(() => {
      method(data);
    }, 300);
  };

  render() {
    const { content, style, parentRef } = this.props;
    const isMinWindow = window.innerWidth < 1280 && !content.onlyLeft;
    const isMobile = /Mobi/.test(navigator.userAgent);
    const containerStyleName =
      isMobile && window.innerWidth < 1280
        ? content.onlyLeft
          ? "popover.containerBottomLeft"
          : "popover.containerBottom"
        : isMinWindow
          ? "popover.containerRight"
          : "popover.container";
    const parentWidth = parentRef && isMinWindow && parentRef.clientWidth;
    const parentPosition = isMobile
      ? content.onlyLeft
        ? { top: parentRef.offsetTop + (window.innerWidth < 1280 ? 52 : 9) }
        : {}
      : { left: parentWidth };
    const containerStyle = !isMobile && style;
    const visibleStyle = this.state.isVisible ? "" : popover.containerVisible;
    if (!this.state.isVisible && !this.state.isDone) {
      setTimeout(
        () =>
          this.setState({
            isVisible: true,
          }),
        300
      );
    }

    return (
      <div
        styleName={containerStyleName}
        style={{ ...containerStyle, ...parentPosition }}
        className={visibleStyle}
        id="tutorialPopoverBlock"
      >
        {isMinWindow && <div styleName={"popover.popoverArrowInner"} />}
        <div styleName={"popover.body"}>
          <div styleName={"popover.close"} onClick={this.onClose}>
            <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={10} icon={"close"} />
          </div>
          <div styleName={"popover.content"}>{content && content.text}</div>
          <div styleName={"popover.button"} onClick={this.onDone}>
            {I18n.t("NEW_ORDER_PAGE.TUTORIAL.POPOVER.BUTTON")}
          </div>
        </div>
        {!isMinWindow && <div styleName={"popover.popoverArrowInner"} />}
      </div>
    );
  }
}
