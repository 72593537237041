import reduceReducers from "reduce-reducers";
import { createThunkReducer } from "../../helpers/createThunkReducer";
import { GET_STATISTICS } from "../../constants/Glossary";
import { DESC } from "../../constants/Sort";

const initialState = {
  tablesState: {
    search: "",
    name: "",
    termsLimit: 10,
    scrollPosition: {
      x: 0,
      y: 0,
    },

    terms: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      filter: {
        languages: "",
      },
    },
    glossary: {
      id: "",
      name: "",
      lastModified: undefined,
      terms: 0,
      languages: 0,
    },
    hasmore: false,
    languagesList: [],
  },
  glossaryInfo: {
    id: "",
    name: "",
    lastModified: undefined,
    terms: 0,
    languages: 0,
    languagesList: [],
  },
  sourceLanguages: {},
  actualLanguages: [],
  selectedRows: [],
  ["customerTerms"]: { status: "", termsentriesList: [], hasmore: false },
  ["statistic"]: { status: "", glossaries: "", termEntries: "", terms: "" },
  ["languagesList"]: { status: "", data: "" },
  scrollPosition: {
    x: 0,
    y: 0,
  },
  glossaries: {
    status: "",
    search: "",
    loadLimit: 10,
    glossariesList: [],
    hasmore: false,
    sort: {
      id: "GLOSSARY_LAST_MODIFIED",
      type: DESC,
    },
    selectedGlossaries: [],
    selectedGlossariesObj: [],
  },
};

const getListGlossaryLanguagesRequest = createThunkReducer("languagesList", "GET_LIST_GLOSSARY_LANGUAGES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const addGlossaryLanguageRequest = createThunkReducer("languagesList", "ADD_GLOSSARY_LANGUAGE", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const deleteGlossaryLanguageRequest = createThunkReducer("languagesList", "DELETE_GLOSSARY_LANGUAGE", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getTermsRequest = createThunkReducer("customerTerms", "GET_LIST_TERMS_CUSTOMER", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        termsentriesList: payload.termsentriesList,
        hasmore: payload.hasmore,
      },
    };
  },
});

const getGlossaryStatistic = createThunkReducer("statistic", GET_STATISTICS, {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        glossaries: payload.glossaries,
        termEntries: payload.termEntries,
        terms: payload.terms,
      },
    };
  },
});

function glossary(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_GLOSSARY_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    case "CHANGE_GLOSSARIES_TABLE_STATE":
      return {
        ...state,
        glossaries: {
          ...state.glossaries,
          ...action.state,
        },
      };
    case "CHANGE_TERMS_STATE":
      return {
        ...state,
        customerTerms: {
          ...state.customerTerms,
          ...action.state,
        },
      };
    case "CHANGE_GLOSSARY_INFO": //RC
      return {
        ...state,
        glossaryInfo: {
          ...state.glossaryInfo,
          ...action.state,
        },
      };
    case "GET_GLOSSARY_INFO_CUSTOMER_SUCCESS":
      return {
        ...state,
        glossaryInfo: {
          ...state.glossaryInfo,
          ...action.payload,
        },
      };
    case "GET_CUSTOMER_GLOSSARIES_REQUEST":
      return {
        ...state,
        glossaries: {
          ...state.glossaries,
          status: "GET_CUSTOMER_GLOSSARIES_REQUEST",
        },
      };
    case "GET_CUSTOMER_GLOSSARIES_SUCCESS":
      return {
        ...state,
        glossaries: {
          ...state.glossaries,
          ...action.payload,
          status: "GET_CUSTOMER_GLOSSARIES_SUCCESS",
        },
      };
    default:
      return state;
  }
}

export const glossaryReducer = reduceReducers(
  glossary,
  getListGlossaryLanguagesRequest,
  addGlossaryLanguageRequest,
  deleteGlossaryLanguageRequest,
  getTermsRequest,
  getGlossaryStatistic
);
