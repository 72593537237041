import * as types from "../constants/User";

export function requestMe() {
  return {
    type: types.REQUEST_ME,
  };
}

export function succeedMe(me) {
  return {
    type: types.SUCCEED_ME,
    me,
  };
}

export function failMe(error) {
  return {
    type: types.FAIL_ME,
    error,
  };
}

export function requestBalance() {
  return {
    type: types.REQUEST_BALANCE,
  };
}

export function succeedBalance(balance) {
  return {
    type: types.SUCCEED_BALANCE,
    balance,
  };
}

export function failBalance(error) {
  return {
    type: types.FAIL_BALANCE,
    error,
  };
}

export function requestUsers() {
  return {
    type: types.REQUEST_USERS,
  };
}

export function succeedUsers(users) {
  return {
    type: types.SUCCEED_USERS,
    users,
  };
}

export function failUsers(error) {
  return {
    type: types.FAIL_USERS,
    error,
  };
}
