var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//@ts-nocheck
import { getCouponsRequest, getListCouponUsersRequest, deleteCouponRequest, blockCouponRequest, updateCouponRequest, getCouponByIdRequest, getCouponInfoRequest, generateCouponsRequest, } from "../../../services/CouponServices/requests";
import { requestGetCoupons, successGetCoupons, requestDeleteCoupon, successDeleteCoupon, requestBlockCoupon, successBlockCoupon, requestGetCouponById, successGetCouponById, requestUpdateCoupon, successUpdateCoupon, requestGenerateCoupons, successGenerateCoupons, requestGetListCouponUsers, successGetListCouponUsers, requestGetCouponInfo, successGetCouponInfo, } from "./couponsActionCreators";
import { showNotification } from "../../Notification";
import { I18n } from "react-redux-i18n";
export var getCouponsAction = function (_a) {
    var offset = _a.offset, limit = _a.limit, search = _a.search, sortField = _a.sortField, sortOrder = _a.sortOrder;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGetCoupons());
                    return [4 /*yield*/, getCouponsRequest({
                            offset: offset,
                            limit: limit,
                            search: search,
                            sortField: sortField,
                            sortOrder: sortOrder,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(successGetCoupons(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var getListCouponUsersAction = function (_a) {
    var id = _a.id, offset = _a.offset, limit = _a.limit, sortField = _a.sortField, sortOrder = _a.sortOrder;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGetListCouponUsers());
                    return [4 /*yield*/, getListCouponUsersRequest({
                            id: id,
                            offset: offset,
                            limit: limit,
                            sortField: sortField,
                            sortOrder: sortOrder,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(successGetListCouponUsers(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var deleteCouponAction = function (_a) {
    var ids = _a.ids;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestDeleteCoupon());
                    return [4 /*yield*/, deleteCouponRequest({
                            ids: ids,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(showNotification(I18n.t("ADMIN_APP.COUPONS_PAGE.COUPONS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_DELETE")));
                    dispatch(successDeleteCoupon(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var blockCouponAction = function (_a) {
    var ids = _a.ids, isBlocked = _a.isBlocked;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data, successRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestBlockCoupon());
                    return [4 /*yield*/, blockCouponRequest({
                            ids: ids,
                            isBlocked: isBlocked,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    successRequest = isBlocked
                        ? I18n.t("ADMIN_APP.COUPONS_PAGE.COUPONS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_BLOCK")
                        : I18n.t("ADMIN_APP.COUPONS_PAGE.COUPONS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_UNBLOCK");
                    dispatch(showNotification(successRequest));
                    dispatch(successBlockCoupon(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var updateCouponAction = function (_a) {
    var id = _a.id, amount = _a.amount, code = _a.code, description = _a.description, expired = _a.expired, policy = _a.policy, blocked = _a.blocked;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestUpdateCoupon());
                    return [4 /*yield*/, updateCouponRequest({
                            id: id,
                            amount: amount,
                            code: code,
                            description: description,
                            expired: expired,
                            policy: policy,
                            blocked: blocked,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(showNotification(I18n.t("ADMIN_APP.COUPON_PAGE.NOTIFICATION_MESSAGE.SUCCESS_UPDATE")));
                    dispatch(successUpdateCoupon(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var getCouponByIdAction = function (_a) {
    var id = _a.id;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGetCouponById());
                    return [4 /*yield*/, getCouponByIdRequest({
                            id: id,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(successGetCouponById(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var getCouponInfoAction = function (_a) {
    var code = _a.code;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGetCouponInfo());
                    return [4 /*yield*/, getCouponInfoRequest({
                            code: code,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(successGetCouponInfo(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
export var generateCouponsAction = function (_a) {
    var description = _a.description, amount = _a.amount, policy = _a.policy, quantity = _a.quantity, expired = _a.expired;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = getState().app.token;
                    dispatch(requestGenerateCoupons());
                    return [4 /*yield*/, generateCouponsRequest({
                            description: description,
                            amount: amount,
                            policy: policy,
                            quantity: quantity,
                            expired: expired,
                            token: token,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(showNotification(I18n.t("ADMIN_APP.COUPONS_PAGE.COUPONS_GENERATOR.NOTIFICATION_MESSAGE.SUCCESS_GENERATE")));
                    dispatch(successGenerateCoupons(data));
                    return [2 /*return*/, data];
            }
        });
    }); };
};
