import reduceReducers from "reduce-reducers";

const initialState = {
  sourceText: "",
  permissionAutoDetectedLanguage: true,
  sourceTextCounter: 0,
  sourceHint: "",
  images: [],
  sourceLanguage: { value: null },
  sourceLanguages: {},
  targetLanguages: {},
  targetLanguagesIsPairs: {},
  selectedTargetLanguage: {},
  recentPairsList: {},
  price: 0.0,
  isShowAddFundsBlock: false,
  scrollPosition: {
    x: 0,
    y: 0,
  },
  focusPosition: false,
  focusPositionSettings: false,
  apiKeys: {
    selected: [],
  },
  advancedSettingsValue: "TRANSLATOR_GUESS",
  advancedCategoryValue: 0,
  orderType: "text",
  waitOrdersGroupToNotified: false,
  uploadedFileSegmentsData: null,
};

function newOrder(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_NEW_ORDER_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
}

export const newOrderReducer = reduceReducers(newOrder);
