// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input__container__fFWz9h {
  margin-top: 13px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.form-input__input-container__CYX4Gi {
  position: relative;
}

.form-input__input-container__CYX4Gi::before {
  position: absolute;
  content: "\$";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9e9e9e;
  right: 8px;
  top: 6px;
  z-index: 2;
}

.form-input__form-input-container__UpxDqE {
  display: flex;
  align-items: center;
}

.form-input__input-container--yen__Pu2VND::before {
  content: "¥";
}

.form-input__input-currency-exchange__a0LT94 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #9e9e9e;
  margin-bottom: 0;
}

.form-input__form-wrapper__WZU1hg {
  display: flex;
  gap: 8px;
  align-items: center;
}

.form-input__equivalent___2rFB6 {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.form-input__label__gfi2Nh {
  color: #424242;
  display: flex;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}

.form-input__label--text__jubJsT {
  color: #424242;
  display: flex;
  font-family: Roboto;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}

.form-input__label-text__nmrOyy {
  flex-direction: column;
}

.form-input__label__gfi2Nh > div {
  padding: 2px 8px;
}

.form-input__label__gfi2Nh div[data-placement^="bottom"] {
  left: -3px !important;
}

.form-input__base__AdHveN {
  color: #424242;
  font-family: Roboto;
  font-weight: 300;
  font-size: 14px;
  width: 30rem;
  max-width: 100%;
  height: 3.2rem;
  border-radius: 1px;
  box-shadow: none;
  padding-left: 9px;
}

.form-input__valid__zYQsP6 {
  resize: none;
  border: 1px solid #cecece;
}

.form-input__valid__zYQsP6:focus {
    border: 1px solid #0075ff;
    box-shadow: none;
    position: relative;
    z-index: 1;
  }

.form-input__invalid__Vo7asX {
  resize: none;
  border: 1px solid red;
}

.form-input__invalid__Vo7asX:focus {
    border: 1px solid red;
    box-shadow: none;
    position: relative;
    z-index: 1;
  }

.form-input__container__fFWz9h:first-child {
  margin-top: 24px;
}

.form-input__validation-message__S81K02 {
  margin-top: 4px;
  margin-bottom: 0;
  color: #ef5350;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  line-height: 16px;
}

/*удаляем тень сверху в iphone*/
input[type="text"] {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

/*удаляем тень сверху в iphone*/
input[type="password"] {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

/*удаляем тень сверху в iphone*/
input {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

.form-input__input--yen__VdW3mx {
  position: relative;
  left: -1px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `form-input__container__fFWz9h`,
	"input-container": `form-input__input-container__CYX4Gi`,
	"form-input-container": `form-input__form-input-container__UpxDqE`,
	"input-container--yen": `form-input__input-container--yen__Pu2VND`,
	"input-currency-exchange": `form-input__input-currency-exchange__a0LT94`,
	"form-wrapper": `form-input__form-wrapper__WZU1hg`,
	"equivalent": `form-input__equivalent___2rFB6`,
	"label": `form-input__label__gfi2Nh`,
	"label--text": `form-input__label--text__jubJsT`,
	"label-text": `form-input__label-text__nmrOyy`,
	"base": `form-input__base__AdHveN`,
	"valid": `form-input__valid__zYQsP6 form-input__base__AdHveN`,
	"invalid": `form-input__invalid__Vo7asX form-input__base__AdHveN`,
	"validation-message": `form-input__validation-message__S81K02`,
	"input--yen": `form-input__input--yen__VdW3mx`
};
export default ___CSS_LOADER_EXPORT___;
