import { RUN_NOTIFICATION, STOP_NOTIFICATION, STOP_NOTIFICATION_BY_ID } from "../constants/NotificationActionTypes";
import { NOTIFICATION } from "../constants/Notifications";

const initialState = {
  id: 0,
  message: "",
  notificationType: NOTIFICATION,
  notDisconnect: false,
  autoHide: false,
  stop: false,
  withClose: false,
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case RUN_NOTIFICATION:
      return {
        ...state,
        id: state.id + 1,
        message: action.message,
        notificationType: action.notificationType,
        notDisconnect: action.notDisconnect,
        autoHide: action.autoHide,
        withClose: action.withClose,
        stop: false,
      };
    case STOP_NOTIFICATION:
      return {
        ...state,
        stop: true,
      };

    case STOP_NOTIFICATION_BY_ID:
      return {
        ...state,
        stop: state.id === action.id,
      };
    default:
      return state;
  }
}
