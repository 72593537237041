export const DISABLE_APP = "DISABLE_APP";
export const ENABLE_APP = "ENABLE_APP";
export const SET_TITLE = "SET_TITLE";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_IS_TOKEN_LOADING = "SET_IS_TOKEN_LOADING";
export const VALIDATE_FORM = "VALIDATE_FORM";

export const APP_DISABLED = "APP_DISABLED";

export const TYPE_UNDEFINED = "undefined";
