// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 11 8%27%3e%3cpath fill=%27white%27 d=%27M11 0H9L4 5L2 3H0V4L4 8L11 1V0Z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 2%27%3e%3cpath fill=%27white%27 d=%27M0 0H10V2H0V0Z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-sheets__wrapper__aH8WFP {
  padding: 0 24px;
  max-width: 575px;
}

.style-sheets__footer__mKorcv {
  max-width: 575px;
}

.style-sheets__button-close__EzRhRG {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  right: 12px;
  top: -12px;
  width: 10px;
  height: 10px;
}

.style-sheets__button-close__EzRhRG:hover {
  opacity: 0.8;
}

.style-sheets__button-close__EzRhRG:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #212121;
  transform: rotate(45deg);
  left: 4px;
  bottom: -2px;
}

.style-sheets__button-close__EzRhRG:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #212121;
  transform: rotate(135deg);
  right: 5px;
  top: -1px;
}

.style-sheets__container__RLJojT {
    display: flex;
    flex-direction: column;
    max-width: 575px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    color: #212121;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    bottom: initial !important;
}

.style-sheets__title__v7CGro {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #212121;
  margin-bottom: 7px;
}

.style-sheets__text__PdZhyo {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  margin-bottom: 16px;
}

.style-sheets__file-name__j64u4k {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #424242;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style-sheets__checkbox-list__gp5dQP {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  width: 100%;
  max-width: 575px;
  min-height: 200px;
  max-height: 404px;
  overflow-y: auto;
}

.style-sheets__checkbox-item__yU5CIt {
  list-style-type: none;
}

.style-sheets__column-list__N1JNAn {
  display: flex;
  flex-direction: column;
}

.style-sheets__checkbox-item-text__xqZuVH {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #424242;
}

.style-sheets__checkbox-item-text-width__KlJ8xE {
  max-width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.style-sheets__checkboxLabel__NUrwiU {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #424242;
    max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 9px;
}

.style-sheets__checkbox-item-input__vQmAhC:checked ~ .style-sheets__checkbox-item-length__KMRDPY.style-sheets__activeShow__pn0sg5 {
  display: block;
}

.style-sheets__checkbox-item-label__WNEIql {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 0;
  padding: 6px 0;
}

.style-sheets__checkbox-item-label__WNEIql > .style-sheets__checkbox-item-input__vQmAhC {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.style-sheets__checkbox-item-label__WNEIql > .style-sheets__checkbox-item-text__xqZuVH {
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.style-sheets__checkbox-item-label__WNEIql > .style-sheets__checkbox-item-text__xqZuVH::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #bdbdbd;
  border-radius: 1px;
  margin-right: 16px;
}

.style-sheets__checkbox-item-label__WNEIql > .style-sheets__checkbox-item-input__vQmAhC:checked + .style-sheets__checkbox-item-text__xqZuVH::before {
  border-color: #1e88e5;
  background: #1e88e5;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}

.style-sheets__checkbox-item-label__WNEIql > .style-sheets__checkbox-item-input__vQmAhC + .style-sheets__checkbox-item-text--line__zS04cQ::before {
  border-color: #1e88e5;
  background: #1e88e5;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}

.style-sheets__checkbox-item-length__KMRDPY {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #9e9e9e;
  margin-left: 16px;
}

.style-sheets__checkbox-item-length__KMRDPY.style-sheets__activeShow__pn0sg5 {
  display: none;
}

.style-sheets__total-chars__dlMGY9 {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #9e9e9e;
  margin-bottom: 5px;
}

.style-sheets__buttons-list__L7rP_p {
  display: flex;
  align-items: center;
  gap: 16px;
}

.style-sheets__buttons-submit__a6A_9a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43a047;
  border-radius: 3px;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: none;
  min-width: 82px;
}

.style-sheets__buttons-cancel___b3i58 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #9e9e9e;
  background: transparent;
  border: none;
  padding: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style-sheets__wrapper__aH8WFP`,
	"footer": `style-sheets__footer__mKorcv`,
	"button-close": `style-sheets__button-close__EzRhRG`,
	"container": `style-sheets__container__RLJojT`,
	"title": `style-sheets__title__v7CGro`,
	"text": `style-sheets__text__PdZhyo`,
	"file-name": `style-sheets__file-name__j64u4k`,
	"checkbox-list": `style-sheets__checkbox-list__gp5dQP`,
	"checkbox-item": `style-sheets__checkbox-item__yU5CIt`,
	"column-list": `style-sheets__column-list__N1JNAn`,
	"checkbox-item-text": `style-sheets__checkbox-item-text__xqZuVH`,
	"checkbox-item-text-width": `style-sheets__checkbox-item-text-width__KlJ8xE`,
	"checkboxLabel": `style-sheets__checkboxLabel__NUrwiU`,
	"checkbox-item-input": `style-sheets__checkbox-item-input__vQmAhC`,
	"checkbox-item-length": `style-sheets__checkbox-item-length__KMRDPY`,
	"activeShow": `style-sheets__activeShow__pn0sg5`,
	"checkbox-item-label": `style-sheets__checkbox-item-label__WNEIql`,
	"checkbox-item-text--line": `style-sheets__checkbox-item-text--line__zS04cQ`,
	"total-chars": `style-sheets__total-chars__dlMGY9`,
	"buttons-list": `style-sheets__buttons-list__L7rP_p`,
	"buttons-submit": `style-sheets__buttons-submit__a6A_9a`,
	"buttons-cancel": `style-sheets__buttons-cancel___b3i58`
};
export default ___CSS_LOADER_EXPORT___;
