// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-menu__side-menu__z1mZXq {
  width: 235px;
  background: #424242;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.45);
  z-index: 100;
}

.side-menu__side-menu__z1mZXq .side-menu__close-icon__tLQdSl {
    cursor: pointer;
    width: 50px;
    border: 0;
    height: 48px;
    min-height: 48px;
    margin: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.side-menu__side-menu__z1mZXq .side-menu__close-icon__tLQdSl:hover,
    .side-menu__side-menu__z1mZXq .side-menu__close-icon__tLQdSl.side-menu__hover__Z2E7V2 {
      opacity: 1;
    }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj {
    margin: 0;
    padding: 0;
  }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li {
      height: 43px;
    }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:active,
      .side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:hover {
        background: #555555;
      }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:active .side-menu__green-badge__lzxEhv, .side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:hover .side-menu__green-badge__lzxEhv {
          background: #66bb6a;
        }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:active .side-menu__red-badge__IVoZsi, .side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li:hover .side-menu__red-badge__IVoZsi {
          background: #e57373;
        }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li a {
        display: block;
        /*padding: 12px 16px 12px 20px;*/
        padding: 10px 16px 10px 16px;
        color: #ffffff;
        font-size: 16px;
        border: 0;
        position: relative;
        align-items: center;
      }

.side-menu__side-menu__z1mZXq .side-menu__menu-list__T6iKcj li a .side-menu__menu-item__red__Ig9bm3 {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 17px;
          right: 16px;
          background-color: #ef5350;
          border-radius: 8px;
          color: #fff;
          font-size: 10px;
          min-height: 16px;
          min-width: 16px;
          text-align: center;
          line-height: 10px;
          padding: 3px;
        }

.side-menu__side-menu__z1mZXq .side-menu__balance-badge__B_7n9U {
    border-radius: 100px;
    float: right;
    font-size: 14px;
  }

.side-menu__side-menu__z1mZXq .side-menu__content-badge__d1wzzJ {
    display: inline-flex;
    padding-right: 5px;
    align-items: center;
    height: 25px;
  }

.side-menu__side-menu__z1mZXq .side-menu__balance-content__qlqHi6 {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 100;
  }

.side-menu__side-menu__z1mZXq .side-menu__round-badge-content__aRTkd2 {
    margin: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
  }

.side-menu__side-menu__z1mZXq .side-menu__link-add__HOjbYq {
    padding: 0 !important;
  }

.side-menu__active__GIKV7P {
  background: #616161;
}

.side-menu__active__GIKV7P .side-menu__green-badge__lzxEhv {
    background: #66bb6a;
  }

.side-menu__active__GIKV7P .side-menu__red-badge__IVoZsi {
    background: #e57373;
  }

.side-menu__red-round-badge__e17IGw {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background: #ef5350;
  border-radius: 50%;
  margin-left: 8px;
  font-weight: 500;
}

.side-menu__white-round-badge__RUgNtN {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 8px;
  color: #212121;
  background: #ffffff;
  font-weight: 500;
}

.side-menu__green-badge__lzxEhv {
  float: right;
  background: #43a047;
}

.side-menu__red-badge__IVoZsi {
  background: #ef5350;
}

.side-menu__hash-container__JMVNr6 {
  height: 100%;
  display: flex;
}

.side-menu__hash-version__a80tED {
  align-items: flex-end;
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  padding: 16px 16px 42px;
  word-break: break-word;
}

.side-menu__hash-date__pvjFm2 {
}

.side-menu__directory__PfkGZy {
  width: 234px;
  height: 59px;
  padding: 20px 17px 20px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.side-menu__directory__PfkGZy .side-menu__directoryName__DZfwpz {
  }

.side-menu__directory__PfkGZy .side-menu__arrowUp__Ih9jVL {
    width: 11px;
    height: 7px;
 }

.side-menu__directory__PfkGZy .side-menu__arrowDown__jR6BCx {
    width: 11px;
    height: 7px;
  }

.side-menu__directory__PfkGZy:hover {
    background: #555555;
  }

.side-menu__directoryContainer__WUe8yq > li > a {
  padding: 10px 16px 10px 40px !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side-menu": `side-menu__side-menu__z1mZXq`,
	"close-icon": `side-menu__close-icon__tLQdSl`,
	"hover": `side-menu__hover__Z2E7V2`,
	"menu-list": `side-menu__menu-list__T6iKcj`,
	"green-badge": `side-menu__green-badge__lzxEhv side-menu__balance-badge__B_7n9U side-menu__content-badge__d1wzzJ`,
	"red-badge": `side-menu__red-badge__IVoZsi side-menu__balance-badge__B_7n9U side-menu__content-badge__d1wzzJ`,
	"menu-item__red": `side-menu__menu-item__red__Ig9bm3`,
	"balance-badge": `side-menu__balance-badge__B_7n9U`,
	"content-badge": `side-menu__content-badge__d1wzzJ`,
	"balance-content": `side-menu__balance-content__qlqHi6`,
	"round-badge-content": `side-menu__round-badge-content__aRTkd2`,
	"link-add": `side-menu__link-add__HOjbYq`,
	"active": `side-menu__active__GIKV7P`,
	"red-round-badge": `side-menu__red-round-badge__e17IGw side-menu__balance-badge__B_7n9U`,
	"white-round-badge": `side-menu__white-round-badge__RUgNtN side-menu__balance-badge__B_7n9U`,
	"hash-container": `side-menu__hash-container__JMVNr6`,
	"hash-version": `side-menu__hash-version__a80tED`,
	"hash-date": `side-menu__hash-date__pvjFm2`,
	"directory": `side-menu__directory__PfkGZy`,
	"directoryName": `side-menu__directoryName__DZfwpz`,
	"arrowUp": `side-menu__arrowUp__Ih9jVL`,
	"arrowDown": `side-menu__arrowDown__jR6BCx`,
	"directoryContainer": `side-menu__directoryContainer__WUe8yq`
};
export default ___CSS_LOADER_EXPORT___;
