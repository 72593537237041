import { I18n } from "react-redux-i18n";

export const mapRatesToLanguages = ratesList => {
  let sourceLanguages = {};
  let targetLanguages = {};
  ratesList.map(item => {
    const { sourceLanguage, targetLanguage, quality, rate } = item;
    const sourceLabel = I18n.t("LANGUAGES." + sourceLanguage);
    const targetLabel = I18n.t("LANGUAGES." + targetLanguage);

    sourceLanguages[sourceLanguage] = {
      value: sourceLanguage,
      label: sourceLabel,
    };

    targetLanguages[targetLanguage] = {
      id: targetLanguage,
      value: targetLanguage,
      label: targetLabel,
      sourcePairs: {
        ...(targetLanguages[targetLanguage] && targetLanguages[targetLanguage].sourcePairs),
        [sourceLanguage]: {
          ...(targetLanguages[targetLanguage] && targetLanguages[targetLanguage].sourcePairs[sourceLanguage]),
          quality,
          rate,
        },
      },
    };
  });
  return { sourceLanguages, targetLanguages };
};
