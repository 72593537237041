import commonRu from "./ru/common";
import commonEn from "./en/common";
import commonBe from "./be/common";
import commonDe from "./de/common";
import commonIt from "./it/common";
import commonJa from "./ja/common";
import customerFaqRu from "./ru/FAQ-customer";
import customerFaqEn from "./en/FAQ-customer";
import customerFaqDe from "./de/FAQ-customer";
import customerFaqJa from "./ja/FAQ-customer";
import translatorFaqRu from "./ru/FAQ-translator";
import translatorFaqEn from "./en/FAQ-translator";
import translatorFaqDe from "./de/FAQ-translator";
import translatorFaqJa from "./ja/FAQ-translator";
import languagesRu from "../../languages/ru/languages";
import languagesEn from "../../languages/en/languages";
import languagesJa from "../../languages/ja/languages";
import languagesDe from "../../languages/de/languages";

export const languagesTranslations = {
  ru: languagesRu,
  en: languagesEn,
  ja: languagesJa,
  de: languagesDe,
};

export const commonTranslations = {
  ru: Object.assign(commonRu, languagesRu),
  en: Object.assign(commonEn, languagesEn),
  de: Object.assign(commonDe, languagesDe),
  ja: Object.assign(commonJa, languagesJa),
};

import adminRu from "./ru/admin";
import adminEn from "./en/admin";
import adminBe from "./be/admin";
import adminDe from "./de/admin";
import adminIt from "./it/admin";
import adminJa from "./ja/admin";
const adminResourcesRu = Object.assign(commonRu, adminRu, languagesRu);
const adminResourcesEn = Object.assign(commonEn, adminEn, languagesEn);
const adminResourcesBe = Object.assign(commonBe, adminBe);
const adminResourcesDe = Object.assign(commonDe, adminDe, languagesDe);
const adminResourcesIt = Object.assign(commonIt, adminIt);
const adminResourcesJa = Object.assign(commonJa, adminJa, languagesJa);

export const adminResources = {
  ru: adminResourcesRu,
  en: adminResourcesEn,
  be: adminResourcesBe,
  de: adminResourcesDe,
  it: adminResourcesIt,
  ja: adminResourcesJa,
};

import customerRu from "./ru/customer";
import customerEn from "./en/customer";
import customerBe from "./be/customer";
import customerDe from "./de/customer";
import customerIt from "./it/customer";
import customerJa from "./ja/customer";
const customerResourcesRu = Object.assign(commonRu, customerRu, customerFaqRu, languagesRu);
const customerResourcesEn = Object.assign(commonEn, customerEn, customerFaqEn, languagesEn);
const customerResourcesBe = Object.assign(commonBe, customerBe);
const customerResourcesDe = Object.assign(commonDe, customerDe, customerFaqDe, languagesDe);
const customerResourcesIt = Object.assign(commonIt, customerIt);
const customerResourcesJa = Object.assign(commonJa, customerJa, customerFaqJa, languagesJa);
export const customerResources = {
  ru: customerResourcesRu,
  en: customerResourcesEn,
  be: customerResourcesBe,
  de: customerResourcesDe,
  it: customerResourcesIt,
  ja: customerResourcesJa,
};

import translatorRu from "./ru/translator";
import translatorEn from "./en/translator";
import translatorBe from "./be/translator";
import translatorDe from "./de/translator";
import translatorIt from "./it/translator";
import translatorJa from "./ja/translator";
const translatorResourcesRu = Object.assign(commonRu, translatorRu, languagesRu, translatorFaqRu);
const translatorResourcesEn = Object.assign(commonEn, translatorEn, languagesEn, translatorFaqEn);
const translatorResourcesBe = Object.assign(commonBe, translatorBe);
const translatorResourcesDe = Object.assign(commonDe, translatorDe, languagesDe, translatorFaqDe);
const translatorResourcesIt = Object.assign(commonIt, translatorIt);
const translatorResourcesJa = Object.assign(commonJa, translatorJa, languagesJa, translatorFaqJa);
export const translatorResources = {
  ru: translatorResourcesRu,
  en: translatorResourcesEn,
  be: translatorResourcesBe,
  de: translatorResourcesDe,
  it: translatorResourcesIt,
  ja: translatorResourcesJa,
};
