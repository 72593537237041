// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 60px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/deprecated/loading-block.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".loading-block {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  margin: 0;\r\n  margin-top: 60px;\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
