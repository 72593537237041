import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from "react";
import styles from "./css/inputStyles.css";
import IconView from "../../IconView";
import classNames from "classnames";
function useOutsideClick(elementRef, onOutsideClick) {
    React.useEffect(function () {
        function handleClickOutside(event) {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [elementRef, onOutsideClick]);
}
var escapeNewLines = function (inputString) {
    if (inputString === void 0) { inputString = ""; }
    var replacedString = inputString.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
    return replacedString;
};
//TODO: [WIP] there are not requirements for component yet
var InputWithOptions = function (_a) {
    var _b, _c;
    var _d = _a.id, id = _d === void 0 ? "" : _d, _e = _a.label, label = _e === void 0 ? "" : _e, options = _a.options, value = _a.value, onChange = _a.onChange, errorText = _a.errorText;
    var optionsWrapperRef = useRef(null);
    var _f = useState(false), isFocused = _f[0], setIsFocused = _f[1];
    var outsideOptionsClick = function () {
        if (isFocused)
            setIsFocused(false);
    };
    useOutsideClick(optionsWrapperRef, outsideOptionsClick);
    var handleOptionClick = function (option) { return function () {
        onChange(option);
        setIsFocused(false);
    }; };
    var handleInputChange = function (e) {
        var value = e.target.value;
        onChange(value);
    };
    var handleFocus = function () {
        setIsFocused(true);
    };
    var handleInputKeyDown = function (e) {
        var isTab = e.code === "Tab";
        if (isTab)
            setIsFocused(false);
    };
    return (_jsxs("div", { className: styles.container, children: [_jsx("label", { htmlFor: id, className: styles.label, children: label }), _jsxs("span", { className: styles.inputWrapper, ref: optionsWrapperRef, children: [_jsx("input", { className: classNames(styles.input, (_b = {},
                            _b[styles.errorField] = !!errorText,
                            _b)), id: id, type: "text", value: value, onChange: handleInputChange, onFocus: handleFocus, onKeyDown: handleInputKeyDown }), _jsx(IconView, { icon: "triangle_open", colors: ["#9e9e9e", "#9e9e9e"], wrapperClassName: styles.triangleIcon }), errorText && _jsx("p", { className: styles.errorText, children: errorText }), _jsx("div", { className: classNames(styles.optionsWrapper, (_c = {},
                            _c[styles.listIsOpened] = isFocused,
                            _c)), children: isFocused &&
                            options.map(function (option, index) {
                                var _a;
                                return (_jsx("button", { className: classNames(styles.option, (_a = {},
                                        _a[styles.isOptionSelected] = option.value === value,
                                        _a)), onClick: handleOptionClick(option), children: escapeNewLines(option.label) }, index));
                            }) })] })] }));
};
export { InputWithOptions };
