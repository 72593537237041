// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar__container__X90Vf4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  min-width: 247px;
  max-width: 410px;
  position: relative;
  background-color: #e3f2fd;
  border: 1px solid #ffffff;
}

.toolbar__non-select-container__oDFtLB,
.toolbar__non-select-container__oDFtLB div {
  background-color: #fff !important;
  color: #bdbdbd !important;
}

.toolbar__target-language-toolbar-container__Ua40wt {
  width: fit-content;
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-fit-content; /* Firefox/Gecko */
  width: -webkit-fit-content; /* Chrome */
  min-width: auto;
  position: absolute;
  top: -32px;
  box-sizing: border-box;
  padding: 7px 12px 6px 0;
}

.toolbar__sticky-container__idrMRv {
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 140px;
  z-index: 1;
}

.toolbar__exit-container__vOqZBH {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.toolbar__non-select-container__oDFtLB .toolbar__exit-container__vOqZBH {
  cursor: initial;
}

.toolbar__btn-container__tdQjL6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 30px;
  border: none;
  background: inherit;
  cursor: pointer;
}


.toolbar__text-container__O0oDJM {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
  /*width: 314px;*/
  height: 32px;
  cursor: inherit;
}

.toolbar__basket-container__hrKKYy {
}

.toolbar__detail-container__X_vzSo {
}

.toolbar__text__Xsr6eF {
  font-weight: 400;
  height: 16px;
  color: #424242;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  padding-top: 1px;
}

.toolbar__history-clock-container__XH_BFZ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 3px;
}

.toolbar__detailContainer__Jqsz6l {
}

.toolbar__history-clock__hlbgQ6 {
  color: rgba(84, 84, 84, 1);
}

.toolbar__history-clock__hlbgQ6:hover {
    color: #1e88e5;
  }

.toolbar__container-text-clock__C2GZKU {
  display: flex;
}
@media (max-width: 660px) {
  .toolbar__sticky-container__idrMRv {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 35px;
  }
}

@media (max-width: 660px) {
  .toolbar__target-language-toolbar-container__Ua40wt {
    width: fit-content;
    width: intrinsic; /* Safari/WebKit uses a non-standard name */
    width: -moz-fit-content; /* Firefox/Gecko */
    width: -webkit-fit-content; /* Chrome */
    position: static;
    box-sizing: border-box;
    padding: 7px 12px 6px 0px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `toolbar__container__X90Vf4`,
	"non-select-container": `toolbar__non-select-container__oDFtLB`,
	"target-language-toolbar-container": `toolbar__target-language-toolbar-container__Ua40wt toolbar__container__X90Vf4 toolbar__container__X90Vf4`,
	"sticky-container": `toolbar__sticky-container__idrMRv`,
	"exit-container": `toolbar__exit-container__vOqZBH`,
	"btn-container": `toolbar__btn-container__tdQjL6`,
	"text-container": `toolbar__text-container__O0oDJM`,
	"basket-container": `toolbar__basket-container__hrKKYy toolbar__exit-container__vOqZBH`,
	"detail-container": `toolbar__detail-container__X_vzSo toolbar__exit-container__vOqZBH`,
	"text": `toolbar__text__Xsr6eF`,
	"history-clock-container": `toolbar__history-clock-container__XH_BFZ`,
	"detailContainer": `toolbar__detailContainer__Jqsz6l`,
	"history-clock": `toolbar__history-clock__hlbgQ6`,
	"container-text-clock": `toolbar__container-text-clock__C2GZKU`
};
export default ___CSS_LOADER_EXPORT___;
