export var CustomerRatesTypes;
(function (CustomerRatesTypes) {
    CustomerRatesTypes["DELETE_CUSTOMER_RATES_REQUEST"] = "DELETE_CUSTOMER_RATES_REQUEST";
    CustomerRatesTypes["DELETE_CUSTOMER_RATES_SUCCESS"] = "DELETE_CUSTOMER_RATES_SUCCESS";
    CustomerRatesTypes["UPDATE_CUSTOMER_RATE_REQUEST"] = "UPDATE_CUSTOMER_RATES_REQUEST";
    CustomerRatesTypes["UPDATE_CUSTOMER_RATE_SUCCESS"] = "UPDATE_CUSTOMER_RATES_SUCCESS";
    CustomerRatesTypes["GET_CUSTOMER_RATE_BY_ID_REQUEST"] = "GET_CUSTOMER_RATE_BY_ID_REQUEST";
    CustomerRatesTypes["GET_CUSTOMER_RATE_BY_ID_SUCCESS"] = "GET_CUSTOMER_RATE_BY_ID_SUCCESS";
    CustomerRatesTypes["CREATE_CUSTOMER_RATE_REQUEST"] = "CREATE_CUSTOMER_RATE_REQUEST";
    CustomerRatesTypes["CREATE_CUSTOMER_RATE_SUCCESS"] = "CREATE_CUSTOMER_RATE_SUCCESS";
    CustomerRatesTypes["GET_CUSTOMER_RATES_LIST_REQUEST"] = "GET_CUSTOMER_RATES_LIST_REQUEST";
    CustomerRatesTypes["GET_CUSTOMER_RATES_LIST_SUCCESS"] = "GET_CUSTOMER_RATES_LIST_SUCCESS";
    CustomerRatesTypes["GET_ALL_CUSTOMER_RATES_REQUEST"] = "GET_ALL_CUSTOMER_RATES_REQUEST";
    CustomerRatesTypes["GET_ALL_CUSTOMER_RATES_SUCCESS"] = "GET_ALL_CUSTOMER_RATES_SUCCESS";
    CustomerRatesTypes["GET_CUSTOMER_RATES_REQUEST"] = "GET_CUSTOMER_RATES_REQUEST";
    CustomerRatesTypes["GET_CUSTOMER_RATES_SUCCESS"] = "GET_CUSTOMER_RATES_SUCCESS";
    CustomerRatesTypes["GET_LANGUAGE_PAIRS_REQUEST"] = "GET_LANGUAGE_PAIRS_REQUEST";
    CustomerRatesTypes["GET_LANGUAGE_PAIRS_SUCCESS"] = "GET_LANGUAGE_PAIRS_SUCCESS";
})(CustomerRatesTypes || (CustomerRatesTypes = {}));
