import React, { useState } from "react";
import ErrorMessage from "../ErrorMessage";
import { I18n } from "react-redux-i18n";
// eslint-disable-next-line no-unused-vars
import view from "./css/image-view.css";

export default function ImageErrorMessage(props) {
  const { image } = props;
  const [showErrorMessage, setShowErrorMessage] = useState(true);

  const getDescription = () => {
    const size = humanFileSize(image.size);
    return (
      image.originalWidth &&
      image.originalHeight &&
      I18n.t("IMAGE_INFO", {
        name: image.name,
        width: image.originalWidth.toFixed(0),
        height: image.originalHeight.toFixed(0),
        size,
      })
    );
  };

  const humanFileSize = size => {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "KB", "MB", "GB", "TB"][i];
  };

  const retry = () => {
    const { handleRetry, arrayCount } = props;
    setShowErrorMessage(false);
    handleRetry(image.selectedFile, arrayCount);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
    props.handleDeleteImage(image);
  };

  return (
    <div styleName={"view.imageContainer"} style={image.containerStyle}>
      {showErrorMessage && (
        <ErrorMessage
          onClose={closeErrorMessage}
          textStyle={"message.text-line-break"}
          message={getDescription() + "\n" + image.uploadImageError}
          handleRetry={image.isRetry && retry}
        />
      )}
    </div>
  );
}
