// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-app__container__FsaBsk {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
}

.admin-app__globalWrapper___gBwSo {
  background: #eee;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*удаляем тень сверху в iphone*/
input[type="text"] {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

/*удаляем тень сверху в iphone*/
input[type="password"] {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

.admin-app__content__h0XlRv {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: auto;
}

--grey.admin-app__content__h0XlRv {
    background: #eee;
  }
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `admin-app__container__FsaBsk`,
	"globalWrapper": `admin-app__globalWrapper___gBwSo`,
	"content": `admin-app__content__h0XlRv`
};
export default ___CSS_LOADER_EXPORT___;
