// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles__container__jJVAih {
    display: flex;
    flex-direction: column;
    max-width: 575px;
    padding: 0 24px 24px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    color: #212121;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    bottom: initial !important;
}

.styles__title__kzFwBA {
    position: relative;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
}

.styles__description__C0W4Lq {
    padding: 8px 0 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.styles__filename__SI8Gft {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: #424242;
    margin: 9px 0 8px 0;
}

.styles__selectorWrapper__F4Vc3z {
    width: 527px;
    max-width: 527px;
    min-height: 200px;
    max-height: 404px;
    border: 1px solid #E0E0E0;
    padding: 10px 0;
    overflow-y: auto;
}

.styles__selectorItem__bImB3q {
    box-sizing: border-box;
    height: 32px;
    background-color: white;
    padding: 6px 15px 6px 44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;

}

.styles__selectorItem__bImB3q:first-child {
    padding: 6px 15px;
}

.styles__selectorItem__bImB3q:nth-child(2n - 1) {
    background-color: #F5F5F5;
}

.styles__selectorItemName__YHVL3Q {
    font-weight: 400;
    color: #424242;
    padding-left: 8px;
}

.styles__selectorItemChars__uEmA1y {
    font-weight: 300;
    color: #9E9E9E;
    padding-left: 15px;
}

.styles__totalAmountOfCharacters__DsRwC8 {
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0;
    color: #9E9E9E;
    margin-top: 8px;
}

.styles__buttonsWrapper__Synlkn {
    padding-top: 16px;
    font-size: 14px !important;
}


.styles__clearButton__NCW5_n {
    cursor: pointer;
    height: 24px;
    min-width: 65px;
    color: #9E9E9E;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    display: inline-block;
    margin-left: 5px;
    border: none;
    background-color: transparent;
}

.styles__clearButton__NCW5_n:active {
    outline: none !important;
}

.styles__clearButton__NCW5_n:focus {
    box-shadow: none;
}

.styles__errorText__zHpR2h {
    margin-top: 4px;
    margin-bottom: 0;
    color: #ef5350;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
}

`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container__jJVAih`,
	"title": `styles__title__kzFwBA`,
	"description": `styles__description__C0W4Lq`,
	"filename": `styles__filename__SI8Gft`,
	"selectorWrapper": `styles__selectorWrapper__F4Vc3z`,
	"selectorItem": `styles__selectorItem__bImB3q`,
	"selectorItemName": `styles__selectorItemName__YHVL3Q`,
	"selectorItemChars": `styles__selectorItemChars__uEmA1y`,
	"totalAmountOfCharacters": `styles__totalAmountOfCharacters__DsRwC8`,
	"buttonsWrapper": `styles__buttonsWrapper__Synlkn`,
	"clearButton": `styles__clearButton__NCW5_n`,
	"errorText": `styles__errorText__zHpR2h`
};
export default ___CSS_LOADER_EXPORT___;
