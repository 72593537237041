// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover__containerAnimation__jn5FGU {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transition: opacity 0.3s;
}

.popover__container__yDWSS6 {
  position: absolute;
  top: -4px;
  left: -176px;
  display: flex;
  z-index: 3;
}

.popover__container__yDWSS6 .popover__popoverArrowInner__bYnTQ0 {
    position: relative;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #8e24aa;
  }

.popover__container__yDWSS6 .popover__close__OBTEtt {
    position: absolute;
    right: 13px;
    top: 8px;
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

.popover__containerVisible__ySJlDA {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s, visibility 0.5s linear 0.5s;
}

.popover__containerRight__gLYas0 {
  position: absolute;
  top: -4px;
  left: 176px;
  display: flex;
  z-index: 4;
}

.popover__containerRight__gLYas0 .popover__popoverArrowInner__bYnTQ0 {
    position: relative;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #8e24aa;
  }

.popover__containerRight__gLYas0 .popover__close__OBTEtt {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

.popover__containerBottom__aIuZER {
  position: absolute;
  top: 28px;
  left: -10px;
  display: flex;
  z-index: 4;
}

.popover__containerBottom__aIuZER .popover__popoverArrowInner__bYnTQ0 {
    position: relative;
    top: -5px;
    width: 0;
    height: 0;
    left: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #8e24aa;
  }

.popover__containerBottom__aIuZER .popover__close__OBTEtt {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

.popover__containerBottomLeft__ZCOC3S {
  position: absolute;
  /*bottom: 102px;*/
  display: flex;
  z-index: 4;
}

.popover__containerBottomLeft__ZCOC3S .popover__popoverArrowInner__bYnTQ0 {
    position: relative;
    top: -5px;
    width: 0;
    height: 0;
    left: -153px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #8e24aa;
  }

.popover__containerBottomLeft__ZCOC3S .popover__close__OBTEtt {
    position: absolute;
    right: 18px;
    top: 8px;
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

.popover__body__tDeWvP {
  width: 160px;
  border-radius: 2px;
  background-color: #8e24aa;
  box-shadow: 0 24px 15px -16px rgba(142, 36, 170, 0.32);
  padding: 16px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.popover__body__tDeWvP .popover__content__HTi1VZ {
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 18px;
  }

.popover__body__tDeWvP .popover__button__mXT9Jg {
    height: 24px;
    padding: 4px 14px 4px 14px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
    color: #212121;
    font-family: Roboto;
    font-width: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAnimation": `popover__containerAnimation__jn5FGU`,
	"container": `popover__container__yDWSS6 popover__containerAnimation__jn5FGU`,
	"popoverArrowInner": `popover__popoverArrowInner__bYnTQ0`,
	"close": `popover__close__OBTEtt`,
	"containerVisible": `popover__containerVisible__ySJlDA`,
	"containerRight": `popover__containerRight__gLYas0 popover__containerAnimation__jn5FGU`,
	"containerBottom": `popover__containerBottom__aIuZER`,
	"containerBottomLeft": `popover__containerBottomLeft__ZCOC3S popover__containerAnimation__jn5FGU`,
	"body": `popover__body__tDeWvP`,
	"content": `popover__content__HTi1VZ`,
	"button": `popover__button__mXT9Jg`
};
export default ___CSS_LOADER_EXPORT___;
