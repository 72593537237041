import React, { Component } from "react";
import BadgeMenuItem from "../BadgeMenuItem/BadgeMenuItem";
import settingsBalance from "../../../../settings/settingsBalance";
// eslint-disable-next-line no-unused-vars
import badge from "../../css/side-menu.css";

export default class MenuItemBalance extends Component {
  handleAddFunds = () => {
    this.props.scrollToAddFunds("addFunds");
  };

  render() {
    const { link, pageHeading, content, active } = this.props;
    const bsStyle = {
      badgeContainer: settingsBalance(content).positiveValue ? "badge.green-badge" : "badge.red-badge",
      content: "badge.balance-content",
    };

    return (
      <BadgeMenuItem
        link={link}
        handleAddFunds={this.props.setAnchor && this.handleAddFunds}
        pageHeading={pageHeading}
        content={content.balance && settingsBalance(content).availableNum}
        bsStyle={bsStyle}
        active={active}
      />
    );
  }
}
