import React, { Component } from "react";
import { connect } from "react-redux";
import UnauthorizedHeader from "../components/headers/UnauthorizedHeader";
import "../css/deprecated/buttons.css";
import "../css/deprecated/common.css";
import "../css/deprecated/typography.css";
import "../css/deprecated/forms.css";
import "../css/deprecated/checkboxes.css";
import "../css/deprecated/app-wait.css";
import "../css/deprecated/popover.css";
import "../css/deprecated/light-box-image.css";
import { setLocale } from "react-redux-i18n";
import Footer from "../components/refactored/Footer";
import TitlePage from "../components/refactored/TitlePage";
import { changeTableState } from "../actions/customer/NewOrder";
import { getDiscountInfoAction } from "../thunks/newThunks/discount/discountAsyncActions";
import { getCustomerListRatesAction } from "../thunks/newThunks/customerRates/customerRateAsyncActions";
import { detectLanguageAction } from "../thunks/newThunks/order/orderAsyncActions";
import { getCouponInfoAction } from "../thunks/newThunks/coupons/couponsAsyncActions";
import { getCouponFromUrl, getDiscountFromUrl } from "../helpers/getCouponFromUrl";
import {
  deleteImage,
  uploadFileFromComputer,
  createOrderPopup,
  uploadGoogleFile,
  uploadImage,
  uploadGoogleSheets,
  uploadGoogleSheetsTable,
} from "../thunks/customer/Attachments";
import { bootChat, trackEventChat } from "../helpers/intercomChat";
import { setTitle } from "../actions/App";
import Snackbar from "../components/notification/Snackbar";
import NewOrderGeneralWithoutAuth from "./newOrder";
import { showNotification } from "../thunks/Notification";
import CustomerSupport from "./customer/CustomerSupport";
import { withRouter } from "./CustomerApp";
import { getExchangeRateAction } from "../thunks/newThunks/payment/paymentAsyncActions";

class NewOrderApp extends Component {
  state = {
    authClick: false,
    signUpClick: false,
  };

  componentDidMount() {
    this.activateStatistics();
  }

  activateStatistics = () => {
    bootChat({
      app_id: "x4sl7uku",
      language_override: this.props.i18n.locale,
    });

    let metadata = {};
    trackEventChat("Start customer app", metadata);
  };

  handlerAuth = () => {
    this.setState(
      {
        authClick: true,
      },
      () => {
        this.setState(
          {
            authClick: false,
          },
          () => {
            window.location.href = getCouponFromUrl()
              ? "/login?coupon=" + getCouponFromUrl()
              : getDiscountFromUrl()
                ? "/login?discount=" + getDiscountFromUrl()
                : "/login";
          }
        );
      }
    );
  };

  handlerSignUp = () => {
    this.setState(
      {
        signUpClick: true,
      },
      () => {
        this.setState(
          {
            signUpClick: false,
          },
          () => {
            window.location.href = getCouponFromUrl()
              ? "/signup?coupon=" + getCouponFromUrl()
              : getDiscountFromUrl()
                ? "/signup?discount=" + getDiscountFromUrl()
                : "/signup";
          }
        );
      }
    );
  };

  render() {
    const { authClick } = this.state;
    return (
      <div className="app">
        <TitlePage title={this.props.app.title}>
          <div className="global-wrapper" style={{ background: "#eee" }}>
            <UnauthorizedHeader
              newOrder={true}
              loginBtn={true}
              handlerAuth={this.handlerAuth}
              handlerSignUp={this.handlerSignUp}
              notInfo={true}
            />
            {window.location.pathname === "/new/support" ? (
              <CustomerSupport auth={true} />
            ) : (
              <NewOrderGeneralWithoutAuth {...this.props} authClick={authClick} />
            )}
            <Footer
              locale={this.props.i18n.locale}
              japanese={true}
              style={{
                margin: 0,
                paddingTop: 50,
                paddingBottom: 50,
                background: "#eee",
              }}
            />
          </div>
        </TitlePage>
        <Snackbar notification={this.props.notification} autoHideDuration={5000} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    i18n: state.i18n,
    tableState: state.newOrder,
    customerRates: state.domain.customerRates,
    coupon: state.coupon.coupon.data,
    notification: state.notification,
    exchangeRate: state.domain.payment.exchangeRate.rate.rate,
    personalExchangeRate: state.domain.payment.personalExchangeRate.rate.rate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLocale: locale => {
      dispatch(setLocale(locale));
    },
    setTitle: title => dispatch(setTitle(title)),
    changeTableState: state => {
      return dispatch(changeTableState(state));
    },
    getCustomerRates: ({ offset, limit, search, sortField, sortOrder, sourceLanguage, targetLanguage, locale }) =>
      dispatch(
        getCustomerListRatesAction({
          offset,
          limit,
          search,
          sortField,
          sortOrder,
          sourceLanguage,
          targetLanguage,
          locale,
        })
      ),
    detectLanguage: ({ text, fileUuid }) => dispatch(detectLanguageAction({ text, fileUuid })),
    getCouponInfo: code => dispatch(getCouponInfoAction({ code: code })),
    uploadImage: (image, type, name, width, height) => {
      return dispatch(uploadImage(image, type, name, width, height, false));
    },
    deleteImage: (id, notAuth) => {
      return dispatch(deleteImage(id, true, notAuth));
    },
    getExchangeRate: (currency, isPersonal) => {
      return dispatch(getExchangeRateAction({ to: currency, isPersonal }));
    },
    setDiscountInfo: code => {
      return dispatch(getDiscountInfoAction({ code }));
    },
    showNotification: message => {
      dispatch(showNotification(message));
    },
    uploadGoogleFile: url => {
      return dispatch(uploadGoogleFile(url));
    },
    uploadGoogleSheets: url => {
      return dispatch(uploadGoogleSheets(url));
    },
    uploadGoogleSheetsTable: (url, arrData, uuid) => {
      return dispatch(uploadGoogleSheetsTable(url, arrData, uuid));
    },
    uploadFileFromComputer: file => {
      return dispatch(uploadFileFromComputer(file));
    },
    createOrderPopup: payload => {
      return dispatch(createOrderPopup(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewOrderApp));
