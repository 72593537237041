// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-general-issues__container__qfMb2_ {
}
.support-general-issues__text__zuUnQd {
  padding: 24px 0 24px 0;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}
.support-general-issues__open-chat__IjetKZ {
}

.support-general-issues__open-chat-btn__Q4FrwQ {
  text-align: center !important;
  font-size: 14px !important;
  border-radius: 3px !important;
  border: 1px solid #1e88e5 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0) !important;
  background-color: #ffffff !important;
  color: #1e88e5 !important;
  line-height: 16px !important;
  padding: 4px 10px !important;
}

.support-general-issues__open-chat-btn__Q4FrwQ:disabled {
    cursor: default !important;
  }

.support-general-issues__open-chat-btn__Q4FrwQ:hover,
  .support-general-issues__open-chat-btn__Q4FrwQ:active:hover,
  .support-general-issues__open-chat-btn__Q4FrwQ:active,
  .support-general-issues__open-chat-btn__Q4FrwQ:active:focus {
    color: #ffffff !important;
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
    outline: none !important;
    cursor: pointer !important;
  }

.support-general-issues__open-chat-btn__Q4FrwQ:last-child {
    margin-left: 10px;
  }

.support-general-issues__open-chat-btn__Q4FrwQ:active,
.support-general-issues__open-chat-btn__Q4FrwQ:active:focus,
.support-general-issues__open-chat-btn__Q4FrwQ:active:hover {
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.support-general-issues__open-chat-btn__Q4FrwQ:hover {
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
  color: #ffffff !important;
  cursor: pointer !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `support-general-issues__container__qfMb2_`,
	"text": `support-general-issues__text__zuUnQd`,
	"open-chat": `support-general-issues__open-chat__IjetKZ`,
	"open-chat-btn": `support-general-issues__open-chat-btn__Q4FrwQ`
};
export default ___CSS_LOADER_EXPORT___;
