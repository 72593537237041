import PropTypes from "prop-types";
import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import empty from "./css/empty-label.css";

export default class Empty extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    display: PropTypes.bool,
  };
  static defaultProps = {
    display: false,
  };

  render() {
    const { text, display } = this.props;
    if (display) {
      return (
        <p styleName={"empty.container"}>
          <span>{text}</span>
        </p>
      );
    } else return null;
  }
}
