import moment from "moment";

export const formatDate = time => {
  return time && moment(time.seconds * 1000);
};

export const formatTimestampDate = timestamp => {
  if (!timestamp) return null;
  const seconds = timestamp.array[0];
  const nanos = timestamp.array[1];
  const millis = seconds * 1000 + nanos / 1000000;
  return moment.utc(millis);
};

export const getDatePeriodAsString = value => {
  if (!value) return "";

  const { start, end } = value;
  const startDate = formatTimestampDate(start).format("DD/MM/YYYY");
  const endDate = formatTimestampDate(end).format("DD/MM/YYYY");
  return `${startDate} - ${endDate}`;
};
