import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["templates"]: { status: "", data: {} },
};

const getTemplates = createThunkReducer("templates", "GET_TEMPLATES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const templatesReducer = reduceReducers(initialReducer, getTemplates);
