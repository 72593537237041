import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import LoadingBlock from "../navigation/ScrollNavigator/ScrollNavigator";
// eslint-disable-next-line no-unused-vars
import settingSection from "./css/multiSettingSection.css";
import Title from "../refactored/Title";

export default class MultiSettingSection extends Component {
  static propTypes = {
    components: PropTypes.array,
  };

  static defaultProps = {
    components: [],
    containerStyleName: "settingSection.container",
    itemsStyleNames: ["settingSection.item", "settingSection.nextItem"],
  };

  renderItem = item => {
    const {
      text,
      question,
      questionLink,
      titleColor,
      counter,
      toolbar,
      component,
      isLoading,
      onRefreshBlock,
      titleRightBlock,
      onCloseBlock,
      isShowPopover,
      id,
    } = item;
    return (
      <React.Fragment>
        <Title
          id={id}
          text={text}
          titleColor={titleColor}
          question={question}
          questionLink={questionLink}
          counter={counter}
          toolbar={toolbar}
          onRefreshBlock={onRefreshBlock}
          onCloseBlock={onCloseBlock}
          isShowPopover={isShowPopover}
          titleRightBlock={titleRightBlock}
        />
        {isLoading ? <LoadingBlock /> : component({})}
      </React.Fragment>
    );
  };

  render() {
    const {
      sectionRef,
      hiddenSectionRef,
      components,
      hiddenBlock,
      containerStyleName,
      itemsStyleNames,
      multiSettingSectionStyle,
    } = this.props;
    const style = this.props.width ? { width: this.props.width } : {};
    const sectionItems = _.map(components, (item, index) => {
      const { id } = item;

      const itemStyleName = index === 0 ? itemsStyleNames[0] : itemsStyleNames[1];

      const renderItem = this.renderItem(item);
      return (
        <div key={id} styleName={itemStyleName} style={{ ...multiSettingSectionStyle }}>
          {renderItem}
        </div>
      );
    });

    return (
      <React.Fragment>
        <section style={style} ref={sectionRef} styleName={containerStyleName}>
          {sectionItems}
        </section>
        {hiddenBlock && hiddenBlock.isShowBlock && (
          <section ref={hiddenSectionRef} styleName={"settingSection.hiddenContainer"}>
            {this.renderItem(hiddenBlock)}
          </section>
        )}
      </React.Fragment>
    );
  }
}
