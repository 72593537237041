import { Modifier, SelectionState } from "draft-js";
import EditorState from "draft-js/lib/EditorState";

export const LEXIQA_VALIDATION_MIN_VOLUME = 1000;
export const LEXIQA_NO_PAIR = "NO PAIR";

const LOADING = "loading";
const FAILED = "failed";
const SUCCESS = "success";

export const LEXIQA_STATUSES = {
  loading: LOADING,
  failed: FAILED,
  success: SUCCESS,
};

export const LEXIQA_ERROR_CATEGORIES = {
  inconsistencies: "inconsistencies",
};

export const removeLexiqaErrorById = (initialState, warningId) => {
  if (!initialState || !warningId) return;

  const filteredLexiqaWarnings = initialState?.lxqwarnings?.filter(warn => warn.errorid !== warningId);
  const newLexiqaState = { ...initialState, lxqwarnings: filteredLexiqaWarnings };

  return newLexiqaState;
};

export const removeLexiqaErrorAndShiftPositions = ({
  initialState,
  warningId,
  positionsShift,
  initialShiftPosition,
}) => {
  const updatedLexiWarnings = initialState?.lxqwarnings?.reduce((acc, warn) => {
    const { errorid, start: currWarnStart, end: currWarnEnd } = warn;
    const isCurrentWarningShouldBeRemoved = errorid === warningId;
    const isCurrentWarningPositionsShouldBeShifted = currWarnStart > initialShiftPosition;

    if (isCurrentWarningShouldBeRemoved) {
      return acc;
    }

    if (isCurrentWarningPositionsShouldBeShifted) {
      const updatedCurrentWarn = { ...warn, start: currWarnStart + positionsShift, end: currWarnEnd + positionsShift };
      return [...acc, updatedCurrentWarn];
    }

    return [...acc, warn];
  }, []);

  const newLexiqa = { ...initialState, lxqwarnings: updatedLexiWarnings };
  return newLexiqa;
};

export const getLexiqaStateAfterOnChange = ({ initialState, focusOffset, positionShift }) => {
  const updatedLexiWarnings = initialState?.lxqwarnings?.reduce((acc, warn) => {
    const { start: currWarnStart, end: currWarnEnd, category } = warn;

    const isCurrentWarningShouldBeRemoved = focusOffset >= currWarnStart && focusOffset <= currWarnEnd;
    const isCurrentWarningAfterRemovedOne = currWarnStart > focusOffset;
    const isInconsistencyError = category === LEXIQA_ERROR_CATEGORIES.inconsistencies;

    if (isCurrentWarningShouldBeRemoved && !isInconsistencyError) {
      return acc;
    }

    if (isCurrentWarningAfterRemovedOne) {
      const updatedCurrentWarn = { ...warn, start: currWarnStart + positionShift, end: currWarnEnd + positionShift };
      return [...acc, updatedCurrentWarn];
    }

    return [...acc, warn];
  }, []);

  const newLexiqa = { ...initialState, lxqwarnings: updatedLexiWarnings };

  return newLexiqa;
};

export const getEditorStateWithReplacedWord = ({ state, blockKey, start, end, word }) => {
  const contentState = state.getCurrentContent();
  const selectionState = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const newContentState = Modifier.replaceText(contentState, selectionState, word);
  const newState = EditorState.createWithContent(newContentState);

  return newState;
};
