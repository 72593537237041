import React from "react";
import TableColumn from "../TableColumn/TableColumn";

const TableHeader = ({ columns, sort, handleSortTable, idName }) => {
  return (
    <thead>
      <tr>
        {columns.map((item, i) => (
          <TableColumn
            key={i}
            id={item.id}
            idName={idName}
            column={item.name}
            style={item.style}
            thStyle={item.thStyle && item.thStyle}
            thClass={item.thClass && item.thClass}
            textStyle={item.textStyle}
            iconStyle={item.iconStyle}
            icon={item.sort && sort.id === item.sort && sort.type}
            filter={item.filter && item.filter}
            handleSortTable={item.sort && (() => handleSortTable(item.sort))}
            onHeaderClick={item.onHeaderClick}
          />
        ))}
      </tr>
    </thead>
  );
};

TableHeader.defaultProps = {
  columns: [],
  sort: {},
};

export default TableHeader;
