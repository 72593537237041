import { createThunkReducer } from "../../helpers/createThunkReducer";
import { CREATE_TRANSLATOR_RATE } from "../../constants/Rates";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["rates"]: { status: "", data: { hasmore: false } },
  ["createTranslatorRate"]: { status: "" },
  ["languagePairs"]: { status: "", data: {} },
};

const createTranslatorRateRequest = createThunkReducer("createTranslatorRate", CREATE_TRANSLATOR_RATE, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getTranslatorRateRequest = createThunkReducer("rates", "GET_TRANSLATOR_LIST_RATES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getLanguagePairsRequest = createThunkReducer("languagePairs", "GET_LANGUAGE_PAIRS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const translatorRatesReducer = reduceReducers(
  initialReducer,
  getLanguagePairsRequest,
  createTranslatorRateRequest,
  getTranslatorRateRequest
);
