import { I18n } from "react-redux-i18n";
import {
  deleteDiscountRequest,
  generateDiscountsRequest,
  getAdminDiscountInfoRequest,
  getCustomerDiscountInfoRequest,
  getDiscountInfoRequest,
  listDiscountUsersRequest,
  listDiscountsRequest,
  updateDiscountBlockedRequest,
  updateDiscountRequest,
} from "../../../services/DiscountServices/request";
import { showNotification } from "../../Notification";
import {
  requestDeleteDiscount,
  requestGenerateDiscounts,
  requestGetAdminDiscountInfo,
  requestGetCustomerDiscountInfo,
  requestGetDiscountInfo,
  requestListDiscountUsers,
  requestListDiscounts,
  requestUpdateDiscount,
  requestUpdateDiscountBlocked,
  successDeleteDiscount,
  successGenerateDiscounts,
  successGetAdminDiscountInfo,
  successGetCustomerDiscountInfo,
  successGetDiscountInfo,
  successListDiscountUsers,
  successListDiscounts,
  successUpdateDiscount,
  successUpdateDiscountBlocked,
} from "./discountActionCreators";

export const generateDiscountsAction =
  ({ count, percent, details, expires, policy }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGenerateDiscounts());

    const data = await generateDiscountsRequest({ count, percent, details, expires, policy, token });
    dispatch(successGenerateDiscounts(data));
    dispatch(
      showNotification(I18n.t("ADMIN_APP.DISCOUNTS_PAGE.DISCOUNTS_GENERATOR.NOTIFICATION_MESSAGE.SUCCESS_GENERATE"))
    );
    return data;
  };

export const getDiscountInfoAction =
  ({ code }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetDiscountInfo());

    const data = await getDiscountInfoRequest({ code, token });
    dispatch(successGetDiscountInfo(data));
    return data;
  };

export const getCustomerDiscountInfoAction =
  ({ code }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetCustomerDiscountInfo());

    const data = await getCustomerDiscountInfoRequest({ code, token });
    dispatch(successGetCustomerDiscountInfo(data));
    return data;
  };

export const getAdminDiscountInfoAction =
  ({ id }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetAdminDiscountInfo());

    const data = await getAdminDiscountInfoRequest({ id, token });
    dispatch(successGetAdminDiscountInfo(data));
    return data;
  };

export const listDiscountsAction =
  ({ limit, offset, search, sortField, sortOrder }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListDiscounts());

    const data = await listDiscountsRequest({ limit, offset, search, sortField, sortOrder, token });
    dispatch(successListDiscounts(data));
    return data;
  };

export const listDiscountUsersAction =
  ({ id, limit, offset, sortField, sortOrder }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListDiscountUsers());

    const data = await listDiscountUsersRequest({ id, limit, offset, sortField, sortOrder, token });
    dispatch(successListDiscountUsers(data));
    return data;
  };

export const updateDiscountAction =
  ({ id, percent, code, name, expires, policy, blocked }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateDiscount());

    const data = await updateDiscountRequest({
      id,
      percent,
      code,
      name,
      expires,
      policy,
      blocked,
      token,
    });
    dispatch(successUpdateDiscount(data));
    dispatch(showNotification(I18n.t("ADMIN_APP.DISCOUNT_PAGE.NOTIFICATION_MESSAGE.SUCCESS_UPDATE")));
    return data;
  };

export const updateDiscountBlockedAction =
  ({ ids, isBlocked }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    const successRequest = isBlocked
      ? I18n.t("ADMIN_APP.DISCOUNTS_PAGE.DISCOUNTS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_BLOCK")
      : I18n.t("ADMIN_APP.DISCOUNTS_PAGE.DISCOUNTS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_UNBLOCK");
    dispatch(requestUpdateDiscountBlocked());

    const data = await updateDiscountBlockedRequest({ ids, isBlocked, token });
    dispatch(successUpdateDiscountBlocked(data));
    dispatch(showNotification(successRequest));
    return data;
  };

export const deleteDiscountAction =
  ({ ids }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteDiscount());
    const data = await deleteDiscountRequest({ ids, token });
    dispatch(successDeleteDiscount(data));
    dispatch(
      showNotification(I18n.t("ADMIN_APP.DISCOUNTS_PAGE.DISCOUNTS_SECTION.NOTIFICATION_MESSAGE.SUCCESS_DELETE"))
    );
    return data;
  };
