// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT42Q0I1NUYyMS01RTBELTQ3MDMtOUZDRC02QkI3RTRDRTVEOUE8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9Im5pdHJvLWN1c3RvbWVycyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iVUktS0lULURFTU8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02ODguMDAwMDAwLCAtMTIyOC4wMDAwMDApIiBmaWxsPSIjRkY5ODAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxwYXRoIGQ9Ik02OTYsMTI0MyBDNjk5Ljg2NTk5MywxMjQzIDcwMywxMjM5Ljg2NTk5IDcwMywxMjM2IEM3MDMsMTIzMi4xMzQwMSA2OTkuODY1OTkzLDEyMjkgNjk2LDEyMjkgQzY5Mi4xMzQwMDcsMTIyOSA2ODksMTIzMi4xMzQwMSA2ODksMTIzNiBDNjg5LDEyMzkuODY1OTkgNjkyLjEzNDAwNywxMjQzIDY5NiwxMjQzIFogTTY5NiwxMjQ0IEM2OTEuNTgxNzIyLDEyNDQgNjg4LDEyNDAuNDE4MjggNjg4LDEyMzYgQzY4OCwxMjMxLjU4MTcyIDY5MS41ODE3MjIsMTIyOCA2OTYsMTIyOCBDNzAwLjQxODI3OCwxMjI4IDcwNCwxMjMxLjU4MTcyIDcwNCwxMjM2IEM3MDQsMTI0MC40MTgyOCA3MDAuNDE4Mjc4LDEyNDQgNjk2LDEyNDQgWiBNNjk1LDEyMzEgTDY5NywxMjMxIEw2OTcsMTIzNyBMNjk1LDEyMzcgTDY5NSwxMjMxIFogTTY5NSwxMjM5IEw2OTcsMTIzOSBMNjk3LDEyNDEgTDY5NSwxMjQxIEw2OTUsMTIzOSBaIiBpZD0iRXhjbGFtYXRpb24tcG9pbnQiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik04IDE1QzExLjg2NiAxNSAxNSAxMS44NjYgMTUgOEMxNSA0LjEzNDAxIDExLjg2NiAxIDggMUM0LjEzNDAxIDEgMSA0LjEzNDAxIDEgOEMxIDExLjg2NiA0LjEzNDAxIDE1IDggMTVaTTggMTZDMy41ODE3MiAxNiAwIDEyLjQxODMgMCA4QzAgMy41ODE3MiAzLjU4MTcyIDAgOCAwQzEyLjQxODMgMCAxNiAzLjU4MTcyIDE2IDhDMTYgMTIuNDE4MyAxMi40MTgzIDE2IDggMTZaTTcgM0g5VjlIN1YzWk03IDExSDlWMTNIN1YxMVoiIGZpbGw9IiNFRjUzNTAiLz4NCjwvc3ZnPg0K", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question__question__EKmO4M {
  height: 15px;
  width: 15px;
  min-width: 15px;
  display: flex;
  cursor: pointer;
  z-index: 0;
  color: #bdbdbd;
}
.question__iconColor__NbjqnC {
  height: 15px;
  width: 15px;
  min-width: 15px;
  display: flex;
  cursor: pointer;
  z-index: 0;
  color: #ffcc80;
}

.question__questionWhite__phjWXx {
  height: 15px;
  width: 15px;
  min-width: 15px;
  display: flex;
  cursor: pointer;
  z-index: 0;
  color: #ffffff;
}

.question__questionBlack__uyjSiH {
  height: 15px;
  width: 15px;
  min-width: 15px;
  display: flex;
  cursor: pointer;
  z-index: 0;
  color: #212121;
  margin-left: 6px;
}

.question__questionHint__PM_FZZ {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  align-self: center;
  box-shadow: none;
  text-align: left;
  padding: 16px;
}

.question__questionLink__daGCBH {
  color: #1e88e5 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.question__questionLinkContainer__Aaz0Rx {
  /*padding: 0 16px 16px;*/
}

.question__label__YkV224 {
  display: initial;
}

.question__title__Zz2c8A {
  width: auto;
}

.question__title__Zz2c8A span {
  padding: 0 8px;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 15px;
}

.question__circle__MU2D4n {
  cursor: pointer;
  position: absolute;
  right: 0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.question__circleRed__i2XF9H {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@media (max-width: 520px) {}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `question__question__EKmO4M`,
	"iconColor": `question__iconColor__NbjqnC`,
	"questionWhite": `question__questionWhite__phjWXx`,
	"questionBlack": `question__questionBlack__uyjSiH`,
	"questionHint": `question__questionHint__PM_FZZ`,
	"questionLink": `question__questionLink__daGCBH`,
	"questionLinkContainer": `question__questionLinkContainer__Aaz0Rx`,
	"label": `question__label__YkV224`,
	"title": `question__title__Zz2c8A`,
	"circle": `question__circle__MU2D4n`,
	"circleRed": `question__circleRed__i2XF9H`
};
export default ___CSS_LOADER_EXPORT___;
