import { ActionTypes } from "./paymentOrderTypes";
export function requestCreatePaymentAdmin() {
    return {
        type: ActionTypes.CREATE_PAYMENT_ADMIN_REQUEST,
    };
}
export function successCreatePaymentAdmin(payload) {
    return {
        type: ActionTypes.CREATE_PAYMENT_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestCreatePaymentUser() {
    return {
        type: ActionTypes.CREATE_PAYMENT_USER_REQUEST,
    };
}
export function successCreatePaymentUser(payload) {
    return {
        type: ActionTypes.CREATE_PAYMENT_USER_SUCCESS,
        payload: payload,
    };
}
export function requestListPayments() {
    return {
        type: ActionTypes.GET_PAYMENTS_REQUEST,
    };
}
export function successListPayments(payload) {
    return {
        type: ActionTypes.GET_PAYMENTS_SUCCESS,
        payload: payload,
    };
}
export function requestGetPaymentById() {
    return {
        type: ActionTypes.GET_PAYMENT_BY_ID_REQUEST,
    };
}
export function successGetPaymentById(payload) {
    return {
        type: ActionTypes.GET_PAYMENT_BY_ID_SUCCESS,
        payload: payload,
    };
}
export function requestCreatePaymentInvoice() {
    return {
        type: ActionTypes.CREATE_PAYMENT_INVOICE_REQUEST,
    };
}
export function successCreatePaymentInvoice(payload) {
    return {
        type: ActionTypes.CREATE_PAYMENT_INVOICE_SUCCESS,
        payload: payload,
    };
}
export function requestGetPaymentInvoice() {
    return {
        type: ActionTypes.GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_REQUEST,
    };
}
export function successGetPaymentInvoice(payload) {
    return {
        type: ActionTypes.GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_SUCCESS,
        payload: payload,
    };
}
export function requestProcessPayPal() {
    return {
        type: ActionTypes.PROCESS_PAYPAL_REQUEST,
    };
}
export function successProcessPayPal(payload) {
    return {
        type: ActionTypes.PROCESS_PAYPAL_SUCCESS,
        payload: payload,
    };
}
export function requestConfirmPayment() {
    return {
        type: ActionTypes.CONFIRM_PAYMENT_REQUEST,
    };
}
export function successConfirmPayment(payload) {
    return {
        type: ActionTypes.CONFIRM_PAYMENT_SUCCESS,
        payload: payload,
    };
}
export function requestGetExchangeRate(isPersonal) {
    if (isPersonal === void 0) { isPersonal = false; }
    var type = isPersonal ? ActionTypes.PERSONAL_EXCHANGE_RATE_REQUEST : ActionTypes.EXCHANGE_RATE_REQUEST;
    return {
        type: type,
    };
}
export function successGetExchangeRate(payload, isPersonal) {
    if (isPersonal === void 0) { isPersonal = false; }
    var type = isPersonal ? ActionTypes.PERSONAL_EXCHANGE_RATE_SUCCESS : ActionTypes.EXCHANGE_RATE_SUCCESS;
    return {
        type: type,
        payload: payload,
    };
}
export function requestRedeemCoupon() {
    return {
        type: ActionTypes.REDEEM_COUPON_REQUEST,
    };
}
export function successRedeemCoupon(payload) {
    return {
        type: ActionTypes.REDEEM_COUPON_SUCCESS,
        payload: payload,
    };
}
