var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { changeTableState } from "../../actions/customer/NewOrder";
import uploadFilePopup from "./css/uploadFilePopup.css";
import Popup from "../elements/Popup";
import { useDispatch, useSelector } from "react-redux";
import { convertArray, getSelectedElements, updateSegmentsData } from "./helpers";
export var UploadFilePopup = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, fileElements = _a.fileElements, onCreateOrder = _a.onCreateOrder, fileName = _a.fileName, fileType = _a.fileType, fileText = _a.fileText, fileUrl = _a.fileUrl;
    var savedFilesData = useSelector(function (state) { return state.newOrder.uploadedFileSegmentsData; });
    var _b = useState(savedFilesData || convertArray([fileElements])[0]), newDataList = _b[0], setNewDataList = _b[1];
    var _c = useState([]), selectedPath = _c[0], setSelectedPath = _c[1];
    var _d = useState(0), totalChars = _d[0], setTotalChars = _d[1];
    var dispatch = useDispatch();
    var handleFieldSelection = function (data) {
        setNewDataList(updateSegmentsData([newDataList], __spreadArray([], data, true).reverse(), false)[0]);
    };
    useEffect(function () {
        var selectedElements = getSelectedElements([newDataList]);
        setTotalChars(getTotalChars(selectedElements));
        setSelectedPath(getSelectedPath(selectedElements));
    }, [newDataList]);
    var handleCreateOrder = function () {
        onCreateOrder({ selected: selectedPath, fileName: fileName, text: fileText, fileType: fileType, fileUrl: fileUrl });
        dispatch(changeTableState({ uploadedFileSegmentsData: __assign(__assign({}, newDataList), { filesAsFormattedText: fileText }) }));
    };
    var getTotalChars = function (data) {
        return data.reduce(function (accumulator, elem) {
            if (elem.charactersCount) {
                return accumulator + elem.charactersCount;
            }
            else {
                return accumulator;
            }
        }, 0);
    };
    var getSelectedPath = function (data) {
        var selectedPath = [];
        data.map(function (elem) { return selectedPath.push({ path: elem.path }); });
        return selectedPath;
    };
    return (_jsx(Popup, { isOpen: isOpen, onClose: onClose, isHeader: _jsx(UploadFilePopupHeader, { fileName: fileName }), isFooter: _jsx(UploadFilePopupFooter, { totalChars: totalChars, onClose: onClose, onCreateOrder: handleCreateOrder }), isGoogleSheetContainer: true, children: _jsx("div", { className: uploadFilePopup.checkboxList, children: _jsx(FileList, { data: newDataList, onClickSegment: handleFieldSelection }) }) }));
};
var UploadFilePopupHeader = function (_a) {
    var fileName = _a.fileName;
    return (_jsxs("div", { className: uploadFilePopup.titleWrapper, children: [_jsx("p", { className: uploadFilePopup.title, children: I18n.t("SHEETS_POPUP.TITLE") }), _jsx("p", { className: uploadFilePopup.titleText, children: I18n.t("XML_POPUP.MAIN_TEXT") }), _jsxs("div", { className: uploadFilePopup.fileNameWrapper, children: [_jsx("p", { className: uploadFilePopup.text, children: fileName }), _jsxs("div", { className: uploadFilePopup.helpWrapper, children: [_jsxs("p", { className: uploadFilePopup.titleText, children: [_jsx("span", { className: uploadFilePopup.decorator, children: "</>" }), " Node"] }), _jsxs("p", { className: uploadFilePopup.titleText, children: [_jsx("span", { className: uploadFilePopup.decorator, children: "@" }), " Attributes"] })] })] })] }));
};
var UploadFilePopupFooter = function (_a) {
    var totalChars = _a.totalChars, onClose = _a.onClose, onCreateOrder = _a.onCreateOrder;
    return (_jsxs("div", { className: uploadFilePopup.titleWrapper, children: [_jsx("p", { className: uploadFilePopup.totalChars, children: I18n.t("SHEETS_POPUP.CURRENT_CHARS", { chars: totalChars }) }), _jsxs("div", { className: uploadFilePopup.buttonsWrapper, children: [_jsx("button", { className: uploadFilePopup.buttonSubmit, onClick: function () { return onCreateOrder(); }, disabled: !totalChars, children: I18n.t("SHEETS_POPUP.EDIT") }), _jsx("button", { className: uploadFilePopup.buttonCancel, onClick: onClose, children: I18n.t("SHEETS_POPUP.CANCEL") })] })] }));
};
var CheckBox = function (_a) {
    var label = _a.label, decorator = _a.decorator, value = _a.value, onClick = _a.onClick, path = _a.path;
    return (_jsxs("label", { className: uploadFilePopup.checkBox, children: [_jsx("input", { className: uploadFilePopup.checkBoxInput, type: "checkbox", value: value, onClick: function () { return onClick([path]); } }), _jsxs("span", { className: uploadFilePopup.checkBoxTitle, children: [_jsx("span", { className: uploadFilePopup.decorator, children: decorator }), label] })] }));
};
var FileList = function (_a) {
    var _b, _c, _d, _e;
    var data = _a.data, onClickSegment = _a.onClickSegment;
    var onCheckboxClick = function (data, paths) {
        paths.push(data.path);
        onClickSegment(paths);
    };
    return (_jsxs(_Fragment, { children: [_jsx(CheckBox, { label: data.name, value: data.isSelected, path: data.path, decorator: "</>", onClick: function (paths) { return onClickSegment(paths); } }, data.path), (((_c = (_b = data.attributes) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 0 || ((_e = (_d = data.children) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0) > 0) && (_jsxs("div", { className: uploadFilePopup.fileAttributeList, children: [data.attributes &&
                        data.attributes.map(function (elem, index) {
                            return (_jsx(CheckBox, { label: elem.name, decorator: "@", path: elem.path, value: elem.isSelected, onClick: function (paths) { return onCheckboxClick(data, paths); } }, elem.path + index));
                        }), data.children &&
                        data.children.map(function (elem, index) {
                            return (_jsx(FileList, { data: elem, onClickSegment: function (paths) { return onCheckboxClick(data, paths); } }, elem.path + index));
                        })] }))] }));
};
