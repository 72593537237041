import reduceReducers from "reduce-reducers";
import { DESC } from "../constants/Sort";

const initialState = {
  tablesState: {
    search: "",
    scrollPosition: {
      x: 0,
      y: 0,
    },
    activeEditBlockRole: "",
    isNotFound: false,
    customer: {
      loadUsersLimit: 25,
      selectedUsers: [],
      sortUsers: {
        id: "ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        user: {},
      },
      filters: {},
    },
    admin: {
      loadUsersLimit: 25,
      selectedUsers: [],
      sortUsers: {
        id: "ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        user: {},
      },
      filters: {},
    },
    translator: {
      loadUsersLimit: 25,
      selectedUsers: [],
      sortUsers: {
        id: "ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        user: {},
      },
      filters: {},
    },
    users: {
      loadUsersLimit: 25,
      selectedUsers: [],
      sortUsers: {
        id: "ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        user: {},
      },
      filters: {},
    },
    workspace: {
      loadUsersLimit: 25,
      selectedUsers: [],
      sortUsers: {
        id: "ID",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        user: {},
      },
    },
    focusPosition: false,
    focusPositionSettings: false,
  },
};

function users(state = initialState, action) {
  const roleIsEmpty = !action.role && action.state;
  switch (action.type) {
    case "CHANGE_USERS_TABLE_STATE":
      return {
        ...state,
        tablesState: {
          ...state.tablesState,
          ...roleIsEmpty,
          [action.role]: {
            ...state.tablesState[action.role],
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}

export const usersReducer = reduceReducers(users);
