import {
  REQUEST_AUTH,
  SUCCEED_AUTH,
  FAIL_AUTH,
  UNCONFIRMED_AUTH,
  INIT_AUTH,
  AUTH_COMPLETED,
  AUTH_ERROR,
} from "../constants/Signin";

import { VALIDATE_FORM } from "../constants/App";

const initialState = {
  status: null,
  error: null,
  form: null,
  authToken: null,
  authMethod: null,
  refreshToken: null,
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_FORM:
      return {
        ...state,
        status: null,
        error: null,
      };
    case REQUEST_AUTH:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SUCCEED_AUTH:
      console.log(action);
      return {
        ...state,
        status: AUTH_COMPLETED,
        authToken: action.token,
        refreshToken: action.refreshToken,
        authMethod: "Email",
      };
    case FAIL_AUTH:
      return {
        ...state,
        status: AUTH_ERROR,
        error: action.error.message,
      };

    case UNCONFIRMED_AUTH:
      return {
        ...state,
        status: UNCONFIRMED_AUTH,
      };

    case INIT_AUTH:
      return initialState;
    default:
      return state;
  }
}
