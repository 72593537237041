// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link__link__hDCfUA {
  cursor: pointer;
  box-shadow: none;
  color: #1e88e5;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
}

.link__link__hDCfUA:active {
  outline: none !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `link__link__hDCfUA`
};
export default ___CSS_LOADER_EXPORT___;
