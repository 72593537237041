import React, { Component } from "react";
import Textarea from "react-textarea-autosize";
// eslint-disable-next-line no-unused-vars
import area from "./css/area.css";

const getAreaStyleName = type => {
  const styleName = {
    inputNewOrder: "area.inputNewOrder",
    default: "area.input",
  };

  return styleName[type] || styleName["default"];
};

export default class SourceTextareaAutoresize extends Component {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();
    this.state = {
      value: "",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { placeholder, value, borderColor, isUploadFilePopoverOpen } = this.props;
    return (
      placeholder !== nextProps.placeholder ||
      value !== nextProps.value ||
      borderColor !== nextProps.borderColor ||
      this.props.onFocus !== nextProps.onFocus ||
      this.state.value !== nextState.value ||
      this.props.initValue !== nextProps.initValue ||
      this.state.minRows !== nextState.minRows ||
      isUploadFilePopoverOpen !== nextProps.isUploadFilePopoverOpen
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initValue && this.props.initValue) {
      this.setState({
        value: this.props.initValue,
      });
    }
    if (this.props.onFocus && !prevProps.onFocus && this.props.onFocus !== prevProps.onFocus) {
      this.textarea.current.focus();
    }
  }

  updateAutosizeTextArea = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.autoFocus && this.textarea.current.focus();

    if (this.props.initValue) {
      this.setState({
        value: this.props.initValue,
      });
    }
  }

  onFocus = () => {
    this.props.onFocusTextArea && this.props.onFocusTextArea(true);
  };

  onBlur = () => {
    this.props.onFocusTextArea && this.props.onFocusTextArea(false);
  };

  onChange = e => {
    this.props.onChange(e);
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const { placeholder, textAreaStyle, styleClass, areaStyleName, customPlaceholder, tabIndex } = this.props;

    const styleName = areaStyleName ? getAreaStyleName(areaStyleName) : styleClass ? "area.input2" : "area.input";

    return (
      <div style={{ width: "100%" }}>
        {customPlaceholder && !this.state.value && <div>{customPlaceholder}</div>}
        <Textarea
          dir="auto"
          tabIndex={tabIndex}
          ref={this.textarea}
          styleName={styleName}
          style={textAreaStyle}
          minRows={textAreaStyle.minRows}
          placeholder={placeholder && placeholder}
          value={this.state.value}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          id="uploadImage"
        />
      </div>
    );
  }
}
