export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["CREATE_PAYMENT_ADMIN_REQUEST"] = "CREATE_PAYMENT_ADMIN_REQUEST";
    ActionTypes["CREATE_PAYMENT_ADMIN_SUCCESS"] = "CREATE_PAYMENT_ADMIN_SUCCESS";
    ActionTypes["CREATE_PAYMENT_USER_REQUEST"] = "CREATE_PAYMENT_USER_REQUEST";
    ActionTypes["CREATE_PAYMENT_USER_SUCCESS"] = "CREATE_PAYMENT_USER_SUCCESS";
    ActionTypes["GET_PAYMENTS_REQUEST"] = "GET_PAYMENTS_REQUEST";
    ActionTypes["GET_PAYMENTS_SUCCESS"] = "GET_PAYMENTS_SUCCESS";
    ActionTypes["GET_PAYMENT_BY_ID_REQUEST"] = "GET_PAYMENT_BY_ID_REQUEST";
    ActionTypes["GET_PAYMENT_BY_ID_SUCCESS"] = "GET_PAYMENT_BY_ID_SUCCESS";
    ActionTypes["CREATE_PAYMENT_INVOICE_REQUEST"] = "CREATE_PAYMENT_INVOICE_REQUEST";
    ActionTypes["CREATE_PAYMENT_INVOICE_SUCCESS"] = "CREATE_PAYMENT_INVOICE_SUCCESS";
    ActionTypes["GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_REQUEST"] = "GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_REQUEST";
    ActionTypes["GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_SUCCESS"] = "GET_PAYMENT_INVOICE_RECEIPT_BY_UUID_SUCCESS";
    ActionTypes["PROCESS_PAYPAL_REQUEST"] = "PROCESS_PAYPAL_REQUEST";
    ActionTypes["PROCESS_PAYPAL_SUCCESS"] = "PROCESS_PAYPAL_SUCCESS";
    ActionTypes["CONFIRM_PAYMENT_REQUEST"] = "CONFIRM_PAYMENT_REQUEST";
    ActionTypes["CONFIRM_PAYMENT_SUCCESS"] = "CONFIRM_PAYMENT_SUCCESS";
    ActionTypes["EXCHANGE_RATE_REQUEST"] = "EXCHANGE_RATE_REQUEST";
    ActionTypes["EXCHANGE_RATE_SUCCESS"] = "EXCHANGE_RATE_SUCCESS";
    ActionTypes["PERSONAL_EXCHANGE_RATE_REQUEST"] = "PERSONAL_EXCHANGE_RATE_REQUEST";
    ActionTypes["PERSONAL_EXCHANGE_RATE_SUCCESS"] = "PERSONAL_EXCHANGE_RATE_SUCCESS";
    ActionTypes["REDEEM_COUPON_REQUEST"] = "REDEEM_COUPON_REQUEST";
    ActionTypes["REDEEM_COUPON_SUCCESS"] = "REDEEM_COUPON_SUCCESS";
})(ActionTypes || (ActionTypes = {}));
