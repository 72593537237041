export function scrollToSettingsBlock(itemId) {
  return {
    type: "SCROLL_TO_ITEM_FAVOURITE_LANGUAGE",
    itemId,
  };
}

export function addNewApiKeysReducer(apiKey) {
  return {
    type: "ADD_NEW_API_KEY",
    apiKey,
  };
}
