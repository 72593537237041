import { defineAction } from "redux-define";
import { ERROR, REQUEST, SUCCESS } from "../constants/RequestStatuses";

const initialState = () => {
  return {};
};

export const createThunkReducer =
  (payloadReducerFieldName, thunkName, { handleSuccessAction, handleErrorAction }) =>
  (state = initialState(payloadReducerFieldName), action) => {
    const thunkAction = defineAction(thunkName, [ERROR, SUCCESS, REQUEST]);

    switch (action.type) {
      case thunkAction.REQUEST:
        return {
          ...state,
          [payloadReducerFieldName]: {
            ...state[payloadReducerFieldName],
            status: thunkAction.REQUEST,
          },
        };
      case thunkAction.SUCCESS:
        const successResult = handleSuccessAction ? handleSuccessAction(state, action.payload) : {};
        return {
          ...state,
          [payloadReducerFieldName]: {
            ...successResult.requestState,
            status: thunkAction.SUCCESS,
          },
          ...successResult.reducerState,
        };
      case thunkAction.ERROR:
        const errorResult = handleErrorAction ? handleErrorAction(state, action.payload) : {};
        return {
          ...state,
          [payloadReducerFieldName]: {
            ...state[payloadReducerFieldName],
            ...errorResult.requestState,
            status: thunkAction.ERROR,
          },
          ...errorResult.reducerState,
        };
      default:
        return state;
    }
  };
