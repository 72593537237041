import {
  requestCreatePaymentAdmin,
  successCreatePaymentAdmin,
  requestCreatePaymentUser,
  successCreatePaymentUser,
  requestListPayments,
  successListPayments,
  requestGetPaymentById,
  successGetPaymentById,
  requestCreatePaymentInvoice,
  successCreatePaymentInvoice,
  requestGetPaymentInvoice,
  successGetPaymentInvoice,
  requestProcessPayPal,
  successProcessPayPal,
  requestConfirmPayment,
  successConfirmPayment,
  requestGetExchangeRate,
  successGetExchangeRate,
  requestRedeemCoupon,
  successRedeemCoupon,
} from "./paymentActionCreators";
import {
  createPaymentAdminRequest,
  createPaymentUserRequest,
  listPaymentsAdminRequest,
  listPaymentsCustomerRequest,
  getPaymentAdminRequest,
  getPaymentCustomerRequest,
  createPaymentInvoiceAdminRequest,
  createPaymentInvoiceCustomerRequest,
  getPaymentInvoiceRequest,
  processPayPalRequest,
  confirmPaymentRequest,
  getExchangeRateRequest,
  redeemCouponRequest,
} from "../../../services/PaymentService/requests";

export const createPaymentAdminAction =
  ({ userId, amount, method, details, workspacename }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreatePaymentAdmin());

    const data = await createPaymentAdminRequest({
      userId,
      amount,
      method,
      details,
      workspacename,
      token,
    });

    dispatch(successCreatePaymentAdmin(data));
    return data;
  };

export const createPaymentUserAction =
  ({ locale, amount, sourceAmount, sourceCurrency, method, cid, invoiceDetailsCurrencyList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreatePaymentUser());

    const data = await createPaymentUserRequest({
      locale,
      amount,
      sourceAmount,
      sourceCurrency,
      method,
      cid,
      invoiceDetailsCurrencyList,
      token,
    });

    dispatch(successCreatePaymentUser(data));

    return data;
  };

export const listPaymentsAction =
  ({ offset, limit, search = "", sortField, sortOrder, methodsList, status, workspace, isAdmin }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListPayments());

    const fetchRequest = isAdmin ? listPaymentsAdminRequest : listPaymentsCustomerRequest;

    const data = await fetchRequest({
      offset,
      limit,
      search,
      sortOrder,
      sortField,
      methodsList,
      status,
      workspace,
      token,
      isAdmin,
    });

    dispatch(successListPayments(data));

    return data;
  };

export const getPaymentByIdAction =
  ({ paymentId, isAdmin }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetPaymentById());

    const fetchRequest = isAdmin ? getPaymentAdminRequest : getPaymentCustomerRequest;
    const data = await fetchRequest({ paymentId, isAdmin, token });

    dispatch(successGetPaymentById(data));

    return data;
  };

export const createPaymentInvoiceAction =
  ({ paymentId, isAdmin }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreatePaymentInvoice());

    const fetchRequest = isAdmin ? createPaymentInvoiceAdminRequest : createPaymentInvoiceCustomerRequest;
    const data = await fetchRequest({ paymentId, token, isAdmin });

    dispatch(successCreatePaymentInvoice(data));

    return data;
  };

export const getPaymentInvoiceAction =
  ({ uuid }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetPaymentInvoice());

    const data = await getPaymentInvoiceRequest({ uuid, token });

    dispatch(successGetPaymentInvoice(data));

    return data;
  };

export const processPayPalAction =
  ({ paymentIds }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestProcessPayPal());

    const data = await processPayPalRequest({ paymentIds, token });
    dispatch(successProcessPayPal(data));
    return data;
  };

export const confirmPaymentAction =
  ({ paymentId }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestConfirmPayment());

    const data = await confirmPaymentRequest({ paymentId, token });
    dispatch(successConfirmPayment(data));
    return data;
  };

export const getExchangeRateAction =
  ({ to, isPersonal }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetExchangeRate(isPersonal));

    const data = await getExchangeRateRequest({ to, token });
    dispatch(successGetExchangeRate(data, isPersonal));
    return data;
  };

export const redeemCouponAction =
  ({ code }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestRedeemCoupon());

    const data = await redeemCouponRequest({ code, token });
    dispatch(successRedeemCoupon(data));
    return data;
  };
