// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table__more__lGltz6 {
  display: flex;
  justify-content: center;
}

.table__container__A7Jl5Q {
  /*width: 1014px;*/
}

.table__table__rC9LHQ {
  margin-bottom: 0 !important;
}

.table__bottom-padding__pXOcOk {
  min-height: 36px;
  max-height: 36px;
}

@media (max-width: 658px) {
  .table__container__A7Jl5Q {
    width: 100%;
  }
  .table__scroll-wrapper__Cdh995 {
    /*overflow-x: auto*/
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"more": `table__more__lGltz6`,
	"container": `table__container__A7Jl5Q`,
	"table": `table__table__rC9LHQ table table-striped`,
	"bottom-padding": `table__bottom-padding__pXOcOk`,
	"scroll-wrapper": `table__scroll-wrapper__Cdh995`
};
export default ___CSS_LOADER_EXPORT___;
