// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-navigator__container__MFZSQF {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scroll-navigator__row-reverse__V9MOGA {
  flex-direction: row-reverse !important;
}

.scroll-navigator__row-column__g_V08M {
  flex-direction: column !important;
}

.scroll-navigator__sidebar__FhoVPW {
  margin: 0 16px 0 0;
}

.scroll-navigator__adaptive-hidden__BEUBBo {
}

.scroll-navigator__sidebarMobile__N2dBiZ {
  margin: 0 16px 0 0;
}

.scroll-navigator__blocksMobile__24abwx {
}

.scroll-navigator__additionalMobileBlock__kPd5tC {
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
}

.scroll-navigator__adaptive-hidden-revers__LRfnCK {
  margin: 0 0 0 16px;
  z-index: 3;
}

@media (max-width: 780px) {
  .scroll-navigator__container__MFZSQF {
    display: flex;
    /*flex-direction: column !important;*/
    justify-content: center;
  }

  .scroll-navigator__sidebarMobile__N2dBiZ {
    width: 112px;
    margin-right: 16px;
  }

  .scroll-navigator__blocksMobile__24abwx {
    padding-bottom: 0px !important;
    width: 100%;
  }

  .scroll-navigator__adaptive-hidden__BEUBBo {
  }

  .scroll-navigator__adaptive-hidden-revers__LRfnCK {
    width: 112px;
    margin-left: 16px;
  }
}
@media (max-width: 658px){
  .scroll-navigator__blocksMobile__24abwx {
    padding-top: 48px;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `scroll-navigator__container__MFZSQF`,
	"row-reverse": `scroll-navigator__row-reverse__V9MOGA`,
	"row-column": `scroll-navigator__row-column__g_V08M`,
	"sidebar": `scroll-navigator__sidebar__FhoVPW`,
	"adaptive-hidden": `scroll-navigator__adaptive-hidden__BEUBBo scroll-navigator__sidebar__FhoVPW hidden-xs hidden-sm scroll-navigator__sidebarMobile__N2dBiZ hidden-xs hidden-sm`,
	"sidebarMobile": `scroll-navigator__sidebarMobile__N2dBiZ`,
	"blocksMobile": `scroll-navigator__blocksMobile__24abwx`,
	"additionalMobileBlock": `scroll-navigator__additionalMobileBlock__kPd5tC`,
	"adaptive-hidden-revers": `scroll-navigator__adaptive-hidden-revers__LRfnCK hidden-xs hidden-sm hidden-xs hidden-sm`
};
export default ___CSS_LOADER_EXPORT___;
