import React, { Component } from "react";
import { HandySvg } from "handy-svg";
import { findDOMNode } from "react-dom";
import questionIcon from "../../../../images/svg/question.svg";
import iIcon from "../../../../images/svg/i.svg";
// eslint-disable-next-line no-unused-vars
import question from "./css/question.css";
import { PopoverTest } from "../../../Popover/PopoverTest";

export default class Question extends Component {
  state = {
    isPopoverOpen: false,
  };

  static defaultProps = {
    placement: "bottom-start",
  };

  componentDidMount() {
    this.props.isShow &&
      this.setState({
        isPopoverOpen: true,
      });
  }

  handlePopover = () => {
    if (this.props.title && !this.state.isPopoverOpen) {
      woopra.track("click to word Export", { env: process.env.SERVER_NAME });
    }
    this.setState(prevState => ({
      isPopoverOpen: !prevState.isPopoverOpen,
    }));
  };

  handleBlur = () => {
    this.setState({
      isPopoverOpen: false,
    });
  };

  render() {
    const {
      question,
      isWhite,
      placement,
      popperStyleName,
      questionCustomStyleName,
      label,
      info,
      isNotShowQuestionIcon,
      title,
      wrapperStyle,
    } = this.props;
    const questionLink = this.props.questionLink && this.props.questionLink.link;
    const target = this.props.questionLink && this.props.questionLink.target;
    let questionStyleName = label ? "question.label" : isWhite ? "question.questionWhite" : "question.question";
    questionStyleName = questionCustomStyleName ? questionCustomStyleName : questionStyleName;

    const titleBlock = title ? <span> {title} </span> : null;
    return (
      <React.Fragment>
        <PopoverTest
          wrapperStyle={wrapperStyle}
          handleBlur={this.handleBlur}
          popperStyleName={popperStyleName}
          placement={placement}
          isShowPopover={this.state.isPopoverOpen}
          referenceElement={
            <div
              ref={c => {
                this.target = findDOMNode(c);
              }}
              styleName={`${questionStyleName} ${title ? "question.title" : ""}`}
              onClick={this.handlePopover}
            >
              {titleBlock}
              {!isNotShowQuestionIcon && <HandySvg src={questionIcon} width="15" height="15" />}
              {isNotShowQuestionIcon && info && <HandySvg src={iIcon} width="15" height="15" />}
              {label}
            </div>
          }
          popperElement={
            this.props.questionLink && (
              <div styleName={"question.questionLinkContainer"}>
                <a
                  tabIndex={0}
                  onBlur={this.handleBlur}
                  href={questionLink}
                  target={target}
                  styleName={"question.questionLink"}
                >
                  {this.props.questionLink.text}
                </a>
              </div>
            )
          }
          mainText={question}
        />
      </React.Fragment>
    );
  }
}
