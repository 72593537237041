import { I18n } from "react-redux-i18n";
import img1 from "../../images/screens/1-tag-select.jpg";
import img2 from "../../images/screens/2-copy-btn.jpg";

const imagesFAQ = {
  TRANSLATOR: {
    BLOCK_4: {
      IMAGES: [img1, img2],
    },
  },
  CUSTOMER: {},
  SUPPROTED_FORMATS: {},
};

const getDescriptionWithImages = (description, images = []) => {
  if (images.length === 0) {
    return [
      {
        description,
        image: null,
      },
    ];
  } else {
    const descriptionArr = description.split("%{img}");
    return descriptionArr.reduce((returnedArr, element, index) => {
      return [
        ...returnedArr,
        {
          description: element,
          image: images[index],
        },
      ];
    }, []);
  }
};

export const blocksArray = (appName, blocks) => {
  return blocks.map(block => {
    const title = I18n.t(appName + "." + block + ".TITLE");
    const desc = appName + "." + block + ".DESCRIPTION";
    const description = I18n.t(desc);
    const images = imagesFAQ[appName][block] && imagesFAQ[appName][block]["IMAGES"];
    return {
      title,
      descriptions: getDescriptionWithImages(description, images),
    };
  });
};
