import { DiscountTypes } from "./descountActionType";
export function requestGenerateDiscounts() {
    return {
        type: DiscountTypes.GENERATE_DISCOUNTS_REQUEST,
    };
}
export function successGenerateDiscounts(payload) {
    return {
        type: DiscountTypes.GENERATE_DISCOUNTS_SUCCESS,
        payload: payload,
    };
}
export function requestGetDiscountInfo() {
    return {
        type: DiscountTypes.GET_DISCOUNT_INFO_REQUEST,
    };
}
export function successGetDiscountInfo(payload) {
    return {
        type: DiscountTypes.GET_DISCOUNT_INFO_SUCCESS,
        payload: payload,
    };
}
export function requestGetCustomerDiscountInfo() {
    return {
        type: DiscountTypes.GET_CUSTOMER_DISCOUNT_INFO_REQUEST,
    };
}
export function successGetCustomerDiscountInfo(payload) {
    return {
        type: DiscountTypes.GET_CUSTOMER_DISCOUNT_INFO_SUCCESS,
        payload: payload,
    };
}
export function requestGetAdminDiscountInfo() {
    return {
        type: DiscountTypes.GET_ADMIN_DISCOUNT_INFO_REQUEST,
    };
}
export function successGetAdminDiscountInfo(payload) {
    return {
        type: DiscountTypes.GET_ADMIN_DISCOUNT_INFO_SUCCESS,
        payload: payload,
    };
}
export function requestListDiscounts() {
    return {
        type: DiscountTypes.LIST_DISCOUNTS_REQUEST,
    };
}
export function successListDiscounts(payload) {
    return {
        type: DiscountTypes.LIST_DISCOUNTS_SUCCESS,
        payload: payload,
    };
}
export function requestListDiscountUsers() {
    return {
        type: DiscountTypes.LIST_DISCOUNT_USERS_REQUEST,
    };
}
export function successListDiscountUsers(payload) {
    return {
        type: DiscountTypes.LIST_DISCOUNT_USERS_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateDiscount() {
    return {
        type: DiscountTypes.UPDATE_DISCOUNT_REQUEST,
    };
}
export function successUpdateDiscount(payload) {
    return {
        type: DiscountTypes.UPDATE_DISCOUNT_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateDiscountBlocked() {
    return {
        type: DiscountTypes.UPDATE_DISCOUNT_BLOCKED_REQUEST,
    };
}
export function successUpdateDiscountBlocked(payload) {
    return {
        type: DiscountTypes.UPDATE_DISCOUNT_BLOCKED_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteDiscount() {
    return {
        type: DiscountTypes.DELETE_DISCOUNT_REQUEST,
    };
}
export function successDeleteDiscount(payload) {
    return {
        type: DiscountTypes.DELETE_DISCOUNT_SUCCESS,
        payload: payload,
    };
}
