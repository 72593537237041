// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-translate__text__CFYuxd {
  white-space: pre-wrap;
}

.html-translate__text__CFYuxd a {
    color: #1e88e5;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    border-bottom: none;
  }

.html-translate__text__CFYuxd a:hover {
      color: #1e88e5;
      -webkit-text-decoration: underline;
      text-decoration: underline;
      border-bottom: none;
    }

.html-translate__whiteSpaceInitial__Mnfx7D {
  white-space: initial;
}

.html-translate__moreTitle__LvNx8D {
  white-space: nowrap;
}

.html-translate__targetLanguage__B5rJl_ span {
    color: #43a047;
  }

.html-translate__targetLanguageIsDisabled__b0FWnK span {
    color: #2121214d;
  }

.html-translate__discount__XYBPzw {
  display: flex;
}

.html-translate__discount__XYBPzw div {
    margin-right: 4px;
    -webkit-text-decoration: line-through;
    text-decoration: line-through;
  }

.html-translate__discountJPY__uGgPim {
  display: flex;
}

.html-translate__discountJPY__uGgPim div {
    margin-right: 4px;
    cursor: pointer;
  }
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `html-translate__text__CFYuxd`,
	"whiteSpaceInitial": `html-translate__whiteSpaceInitial__Mnfx7D`,
	"moreTitle": `html-translate__moreTitle__LvNx8D`,
	"targetLanguage": `html-translate__targetLanguage__B5rJl_`,
	"targetLanguageIsDisabled": `html-translate__targetLanguageIsDisabled__b0FWnK`,
	"discount": `html-translate__discount__XYBPzw`,
	"discountJPY": `html-translate__discountJPY__uGgPim`
};
export default ___CSS_LOADER_EXPORT___;
