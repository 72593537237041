// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languages__container__6b2YzK {
  display: flex;
  font-family: Roboto, sans-serif;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.languages__header__oVRA5D {
  display: flex;
  height: 40px;
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  border: 1px solid #e0e0e0;
  margin-left: 24px;
}

.languages__title__YjZW55 {
}

.languages__customer-app-header__VEZQrD {
  display: flex;
  height: 40px;
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  border: 1px solid #e0e0e0;
}

.languages__source-title__YjIjEc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 447px;
  padding: 2px 0 2px 33px;
  background-color: #f5f5f5;
}

.languages__target-title__RUH_re {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 423px;
  padding: 2px 0 2px 16px;
  border-left: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}

.languages__box__ORNgWJ {
  display: flex;
  flex-direction: column;
  min-height: 37px;
  white-space: initial;
  word-wrap: break-word;
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  border-top: 0;
  width: 870px;
}

.languages__source__dJhQSr {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.languages__sourceSelectNone__HZDdYP {
  width: 480px;
  display: flex;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

.languages__source-number__bamxma {
  height: 100%;
  min-width: 25px;
}

.languages__source-number__bamxma:after {
  content: attr(data-number);
  height: 100%;
  min-width: 24px;
  padding-top: 8px;
  color: #9e9e9e;
  /*font-size: 14px;*/
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

.languages__source-content__mzXpOq {
  width: 422px;
  flex-grow: 1;
  min-height: 37px;
  padding: 8px 12px 8px 8px;
  display: flex;
  justify-content: space-between;
}

.languages__source-content__mzXpOq .languages__copyIcon__BualYK {
    opacity: 0;
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 12px;
    margin-top: 2px;
    cursor: pointer;
  }

.languages__source-content__mzXpOq .languages__copyIcon__BualYK:hover {
      cursor: pointer;
    }

.languages__source-content__mzXpOq:hover .languages__copyIcon__BualYK {
      opacity: 1;
    }

.languages__target__lCyjU8 {
  width: 423px;
  padding: 8px 12px 8px 16px;
  border-left: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
}

.languages__target__lCyjU8 .languages__copyIcon__BualYK {
    opacity: 0;
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 12px;
    margin-top: 2px;
    cursor: pointer;
  }

.languages__target__lCyjU8 .languages__copyIcon__BualYK:hover {
      cursor: pointer;
    }

.languages__target__lCyjU8:hover .languages__copyIcon__BualYK {
      opacity: 1;
    }

.languages__targetSelectNone__byP0dv {
  width: 480px;
  display: flex;
  padding: 8px 16px 8px 16px;
  border-left: 1px solid #e0e0e0;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

.languages__end__hQKfkj {
  display: flex;
  flex-grow: 1;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.languages__end-source__DBMH6U {
  display: flex;
  min-height: 112px;
  width: 447px;
}

.languages__end-target__pzwoUn {
  width: 422px;
}

.languages__section__bMXCqM {
  background-color: #e0e0e0;
  width: 25px;
  min-height: 112px;
}

.languages__fileName__vYpKkA {
  border-bottom: none;
  text-overflow: ellipsis;
  max-width: 250px;
  color: rgb(33, 33, 33);
  overflow: hidden;
  white-space: nowrap;
}

.languages__fileName__vYpKkA:hover,
  .languages__fileName__vYpKkA:focus {
    border-bottom: none;
    color: rgb(33, 33, 33);
  }

.languages__base-container__I9APIR {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;

}
.languages__base-container__I9APIR a {
  -webkit-text-decoration: underline;
  text-decoration: underline;
  color: #1e88e5;
  border-bottom: none;
  cursor: pointer;
}

.languages__base-container__I9APIR a:hover,
.languages__base-container__I9APIR a:focus{
  -webkit-text-decoration: underline;
  text-decoration: underline;
  color: #1e88e5;
  border-bottom: none;
}

.languages__loading-container__SdpB6O {
  margin-top: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.languages__loading__jtX8KP {
  align-self: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.languages__before-scroll-navigator-placement__HMuut4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  bottom: 0;
  margin-top: -56px;
  padding: 16px 20px 0px;
}
.languages__plurals__vghFro{
  width: 64px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #9E9E9E;
  padding: 8px 0 8px 16px;
  border-right: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  margin-right: 16px;
  height: 100%;
}
.languages__pluralSegment__O7x_x4{
  padding: 0;
  align-items: center;
  padding-right: 12px;
}
.languages__pluralSegment__O7x_x4.languages__active__yvkYji{
  background-color: #F5F5F5;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `languages__container__6b2YzK`,
	"header": `languages__header__oVRA5D`,
	"title": `languages__title__YjZW55`,
	"customer-app-header": `languages__customer-app-header__VEZQrD`,
	"source-title": `languages__source-title__YjIjEc`,
	"target-title": `languages__target-title__RUH_re`,
	"box": `languages__box__ORNgWJ`,
	"source": `languages__source__dJhQSr`,
	"sourceSelectNone": `languages__sourceSelectNone__HZDdYP`,
	"source-number": `languages__source-number__bamxma`,
	"source-content": `languages__source-content__mzXpOq`,
	"copyIcon": `languages__copyIcon__BualYK`,
	"target": `languages__target__lCyjU8`,
	"targetSelectNone": `languages__targetSelectNone__byP0dv`,
	"end": `languages__end__hQKfkj`,
	"end-source": `languages__end-source__DBMH6U`,
	"end-target": `languages__end-target__pzwoUn`,
	"section": `languages__section__bMXCqM`,
	"fileName": `languages__fileName__vYpKkA`,
	"base-container": `languages__base-container__I9APIR`,
	"loading-container": `languages__loading-container__SdpB6O`,
	"loading": `languages__loading__jtX8KP`,
	"before-scroll-navigator-placement": `languages__before-scroll-navigator-placement__HMuut4`,
	"plurals": `languages__plurals__vghFro`,
	"pluralSegment": `languages__pluralSegment__O7x_x4`,
	"active": `languages__active__yvkYji`
};
export default ___CSS_LOADER_EXPORT___;
