import reduceReducers from "reduce-reducers";
import { DESC } from "../../constants/Sort";

const initialState = {
  search: "",
  searchByUser: "",
  scrollPosition: {
    x: 0,
    y: 0,
  },
  loadLimit: 20,
  loadLimitMemories: 20,
  offset: 0,
  sort: {
    id: "TRANSLATION_MEMORIES_USER_ID",
    type: DESC,
  },
  selectedMemories: [],
  currentId: "",
  editingBlock: {
    isOpen: false,
  },
};

function translationMemories(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_TRANSLATION_MEMORIES_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
}

export const translationMemoriesTableReducer = reduceReducers(translationMemories);
