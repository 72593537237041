import { UserActionTypes } from "./userActionTypes";
export function requestCreateTranslator() {
    return {
        type: UserActionTypes.CREATE_TRANSLATOR_REQUEST,
    };
}
export function successCreateTranslator(payload) {
    return {
        type: UserActionTypes.CREATE_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestGetMe() {
    return {
        type: UserActionTypes.GET_ME_REQUEST,
    };
}
export function successGetMe(payload) {
    return {
        type: UserActionTypes.GET_ME_SUCCESS,
        payload: payload,
    };
}
export function requestGetApiKeys() {
    return {
        type: UserActionTypes.GET_API_KEYS_REQUEST,
    };
}
export function successGetApiKeys(payload) {
    return {
        type: UserActionTypes.GET_API_KEYS_SUCCESS,
        payload: payload,
    };
}
export function requestGenerateApiKey() {
    return {
        type: UserActionTypes.GENERATE_API_KEY_REQUEST,
    };
}
export function successGenerateApiKey(payload) {
    return {
        type: UserActionTypes.GENERATE_API_KEY_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateApiKeyName() {
    return {
        type: UserActionTypes.UPDATE_API_KEY_NAME_REQUEST,
    };
}
export function successUpdateApiKeyName(payload) {
    return {
        type: UserActionTypes.UPDATE_API_KEY_NAME_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteApiKey() {
    return {
        type: UserActionTypes.DELETE_API_KEY_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление API-ключей
export function successDeleteApiKey(payload) {
    return {
        type: UserActionTypes.DELETE_API_KEY_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteMe() {
    return {
        type: UserActionTypes.DELETE_ME_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление аккаунта пользователя
export function successDeleteMe(payload) {
    return {
        type: UserActionTypes.DELETE_ME_SUCCESS,
        payload: payload,
    };
}
export function requestGetUser() {
    return {
        type: UserActionTypes.GET_USER_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение информации о пользователе
export function successGetUser(payload) {
    return {
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: payload,
    };
}
export function requestUpdatePassword() {
    return {
        type: UserActionTypes.UPDATE_PASSWORD_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление пароля пользователя
export function successUpdatePassword(payload) {
    return {
        type: UserActionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: payload,
    };
}
// ActionCreator для отправки запроса на удаление недавно использованных пар пользователем
export function requestRemoveRecentPair() {
    return {
        type: UserActionTypes.REMOVE_RECENT_PAIR_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление недавно использованных пар пользователем
export function successRemoveRecentPair(payload) {
    return {
        type: UserActionTypes.REMOVE_RECENT_PAIR_SUCCESS,
        payload: payload,
    };
}
export function requestSetCustomerCategory() {
    return {
        type: UserActionTypes.SET_CUSTOMER_CATEGORY_REQUEST,
    };
}
export function successSetCustomerCategory(payload) {
    return {
        type: UserActionTypes.SET_CUSTOMER_CATEGORY_SUCCESS,
        payload: payload,
    };
}
export function requestRemoveRoleUser() {
    return {
        type: UserActionTypes.REMOVE_ROLE_USER_REQUEST,
    };
}
export function successRemoveRoleUser(payload) {
    return {
        type: UserActionTypes.REMOVE_ROLE_USER_SUCCESS,
        payload: payload,
    };
}
export function requestDeleteUser() {
    return {
        type: UserActionTypes.DELETE_USER_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление пользователя
export function successDeleteUser(payload) {
    return {
        type: UserActionTypes.DELETE_USER_SUCCESS,
        payload: payload,
    };
}
export function requestGetSubscribeStatus() {
    return {
        type: UserActionTypes.GET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение статуса подписки
export function successGetSubscribeStatus(payload) {
    return {
        type: UserActionTypes.GET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestSetSubscribeStatus() {
    return {
        type: UserActionTypes.SET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на установку статуса подписки
export function successSetSubscribeStatus(payload) {
    return {
        type: UserActionTypes.SET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestListUsers(role) {
    var actionTypesByRole = {
        CUSTOMER: UserActionTypes.GET_CUSTOMERS_REQUEST,
        ADMIN: UserActionTypes.GET_ADMINS_REQUEST,
        TRANSLATOR: UserActionTypes.GET_TRANSLATORS_REQUEST,
        USERS: UserActionTypes.GET_USERS_REQUEST,
    };
    return {
        type: actionTypesByRole[role],
    };
}
// ActionCreator для успешного завершения запроса на получение списка пользователей
export function successListUsers(payload, role) {
    var actionTypesByRole = {
        CUSTOMER: UserActionTypes.GET_CUSTOMERS_SUCCESS,
        ADMIN: UserActionTypes.GET_ADMINS_SUCCESS,
        TRANSLATOR: UserActionTypes.GET_TRANSLATORS_SUCCESS,
        USERS: UserActionTypes.GET_USERS_SUCCESS,
    };
    return {
        type: actionTypesByRole[role],
        payload: payload,
    };
}
//_______________________________________________
export function requestListTranslators() {
    return {
        type: UserActionTypes.GET_TRANSLATORS_BY_LANGUAGE_PAIRS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение списка переводчиков
export function successListTranslators(payload) {
    return {
        type: UserActionTypes.GET_TRANSLATORS_BY_LANGUAGE_PAIRS_SUCCESS,
        payload: payload,
    };
}
//=============================
export function requestGetLanguagePairStatistic() {
    return {
        type: UserActionTypes.GET_LANGUAGE_PAIR_STATISTIC_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение статистики языковой пары пользователя
export function successGetLanguagePairStatistic(payload) {
    return {
        type: UserActionTypes.GET_LANGUAGE_PAIR_STATISTIC_SUCCESS,
        payload: payload,
    };
}
export function requestGetSendersEmails() {
    return {
        type: UserActionTypes.GET_SENDERS_EMAILS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение списка отправителей электронных писем
export function successGetSendersEmails(payload) {
    return {
        type: UserActionTypes.GET_SENDERS_EMAILS_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateUser() {
    return {
        type: UserActionTypes.UPDATE_USER_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление информации о пользователе
export function successUpdateUser(payload) {
    return {
        type: UserActionTypes.UPDATE_USER_SUCCESS,
        payload: payload,
    };
}
export function requestUpdatePaymentMethod() {
    return {
        type: UserActionTypes.UPDATE_PAYMENT_METHOD_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление метода оплаты
export function successUpdatePaymentMethod(payload) {
    return {
        type: UserActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateEmailNotifications() {
    return {
        type: UserActionTypes.UPDATE_EMAIL_NOTIFICATIONS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление настроек электронных уведомлений
export function successUpdateEmailNotifications(payload) {
    return {
        type: UserActionTypes.UPDATE_EMAIL_NOTIFICATIONS_SUCCESS,
        payload: payload,
    };
}
export function requestSetSpellCheckerStatus() {
    return {
        type: UserActionTypes.SET_SPELLCHECKER_STATUS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на установку статуса проверки орфографии
export function successSetSpellCheckerStatus(payload) {
    return {
        type: UserActionTypes.SET_SPELLCHECKER_STATUS_SUCCESS,
        payload: payload,
    };
}
export function requestUpdatePersonalSettings() {
    return {
        type: UserActionTypes.UPDATE_PERSONAL_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление персональных настроек пользователя
export function successUpdatePersonalSettings(payload) {
    return {
        type: UserActionTypes.UPDATE_PERSONAL_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateBillTo() {
    return {
        type: UserActionTypes.UPDATE_BILL_TO_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на обновление информации об оплате
export function successUpdateBillTo(payload) {
    return {
        type: UserActionTypes.UPDATE_BILL_TO_SUCCESS,
        payload: payload,
    };
}
export function requestAcceptTranslatorPopup() {
    return {
        type: UserActionTypes.ACCEPT_TRANSLATOR_POPUP_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на принятие запроса от переводчика
export function successAcceptTranslatorPopup(payload) {
    return {
        type: UserActionTypes.ACCEPT_TRANSLATOR_POPUP_SUCCESS,
        payload: payload,
    };
}
export function requestSaveCustomerReview() {
    return {
        type: UserActionTypes.SAVE_CUSTOMER_REVIEW_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на сохранение отзыва от клиента
export function successSaveCustomerReview(payload) {
    return {
        type: UserActionTypes.SAVE_CUSTOMER_REVIEW_SUCCESS,
        payload: payload,
    };
}
export function requestSaveVacationPeriodTranslator() {
    return {
        type: UserActionTypes.SAVE_VACATION_PERIOD_TRANSLATOR_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на сохранение периода отпуска переводчика
export function successSaveVacationPeriodTranslator(payload) {
    return {
        type: UserActionTypes.SAVE_VACATION_PERIOD_TRANSLATOR_SUCCESS,
        payload: payload,
    };
}
export function requestSaveVacationPeriodAdmin() {
    return {
        type: UserActionTypes.SAVE_VACATION_PERIOD_ADMIN_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на сохранение периода отпуска администратором
export function successSaveVacationPeriodAdmin(payload) {
    return {
        type: UserActionTypes.SAVE_VACATION_PERIOD_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestListLiteUsers() {
    return {
        type: UserActionTypes.LIST_LITE_USERS_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на получение списка пользователей
export function successListLiteUsers(payload) {
    return {
        type: UserActionTypes.LIST_LITE_USERS_SUCCESS,
        payload: payload,
    };
}
