import React from "react";
import BadgeMenuItem from "../BadgeMenuItem/BadgeMenuItem";
// eslint-disable-next-line no-unused-vars
import badge from "../../css/side-menu.css";

export default function MenuItemDesktopCount(props) {
  const { link, pageHeading, content, active, unreadStatus, iconIsWhite } = props;
  const contentValue = content > 99 ? "99+" : content;
  const bsStyle = {
    badgeContainer: iconIsWhite ? "badge.white-round-badge" : "badge.red-round-badge",
    content: "badge.round-badge-content",
  };
  return (
    <BadgeMenuItem
      link={link}
      pageHeading={pageHeading}
      content={contentValue}
      bsStyle={bsStyle}
      active={active}
      unreadStatus={unreadStatus}
    />
  );
}
