import { REQUEST_ORDER, SUCCEED_ORDER, FAIL_ORDER, UNSET_ORDER } from "../constants/AuthorizedActionTypes";

import { ORDER_FETCHED, ORDER_FETCHING, ORDER_ERROR } from "../constants/AuthorizedStatusTypes";

const initialState = {
  item: {},
  status: "",
  error: null,
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ORDER:
      return {
        ...state,
        status: ORDER_FETCHING,
        error: null,
      };
    case SUCCEED_ORDER:
      return {
        ...state,
        status: ORDER_FETCHED,
        item: action.order,
      };
    case FAIL_ORDER:
      return {
        ...state,
        status: ORDER_ERROR,
        error: action.error,
      };
    case UNSET_ORDER:
      return initialState;
    default:
      return state;
  }
}
