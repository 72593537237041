export const REQUEST_ME = "REQUEST_ME";
export const SUCCEED_ME = "SUCCEED_ME";
export const FAIL_ME = "FAIL_ME";

export const REQUEST_BALANCE = "REQUEST_BALANCE";
export const SUCCEED_BALANCE = "SUCCEED_BALANCE";
export const FAIL_BALANCE = "FAIL_BALANCE";

export const ME_FETCHED = "ME_FETCHED";
export const ME_ERROR = "ME_ERROR";

export const GET_ME_SUCCESS = "GET_ME_SUCCESS";

export const BALANCE_FETCHED = "BALANCE_FETCHED";
export const BALANCE_ERROR = "BALANCE_ERROR";

export const REQUEST_USERS = "REQUEST_USERS";
export const FAIL_USERS = "FAIL_USERS";
export const SUCCEED_USERS = "SUCCEED_USERS";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_ADMINS = "GET_ADMINS";
export const GET_TRANSLATORS = "GET_TRANSLATORS";
