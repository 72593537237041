//@ts-nocheck
import { invokeHttpThunk } from "../../helpers/invokeThunk";
export var uploadImage = function (image, type, name, width, height, cursorWatch) {
    var requestName = "UPLOAD_FILE";
    var imagesJson = {
        type: "image/png",
        content: image,
        width: width,
        height: height,
        filename: name,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/attachments/upload",
            method: token ? "post" : "put",
            type: "image/png",
            body: JSON.stringify(imagesJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch, false, false);
    };
};
export var deleteImage = function (id, cursorWatch, notAuth) {
    var requestName = "DELETE_FILE";
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: token && !notAuth ? "/attachments/delete/" + id : "/attachments/remove/" + id,
            method: "DELETE",
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch, false, false);
    };
};
export var uploadGoogleFile = function (url, orderId) {
    var requestName = "UPLOAD_GOOGLE_FILE";
    console.log("uploadGoogleFile url", url);
    console.log("uploadGoogleFile orderId", orderId);
    //TODO type подкидывать в соответствии с прототипами
    var fileDataJson = {
        type: "GOOGLE_DOC",
        order_id: orderId ? orderId : "",
        file_url: url,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, false);
    };
};
export var uploadGoogleSheets = function (url, orderId) {
    var requestName = "GET_COLUMNS_GOOGLE_SEHHETS";
    console.log("uploadGoogleSEHHETS url", url);
    console.log("uploadGoogleSEHHETS orderId", orderId);
    var fileDataJson = {
        type: "GOOGLE_SPREADSHEET",
        //order_id: orderId ? orderId : "",
        file_url: url,
    };
    if (orderId) {
        fileDataJson["order_id"] = orderId;
    }
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/select",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, false);
    };
};
export var createOrderPopup = function (payload) {
    var requestName = "UPLOAD_FILE_POPUP";
    var body = JSON.stringify(payload);
    console.log({ body: body });
    var requestOptions = {
        url: "/files/select",
        method: "post",
        body: body,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true);
    };
};
export var uploadGoogleSheetsTable = function (url, arrData, uuid) {
    var requestName = "UPLOAD_GOOGLE_SEHHETS";
    console.log("uploadGoogleSheetsTable", url);
    console.log("uploadGoogleSheetsTable", arrData);
    var fileDataJson = {
        file_url: url,
        type: "GOOGLE_SPREADSHEET",
        selected: arrData,
        uuid: uuid && "",
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, false);
    };
};
export var uploadGoogleFileToTarget = function (url, orderId, docsType) {
    var requestName = "UPLOAD_GOOGLE_FILE_TO_TARGET";
    console.log("uploadGoogleFile url", url);
    console.log("uploadGoogleFile orderId", orderId);
    //TODO type подкидывать в соответствии с прототипами
    var fileDataJson = {
        type: docsType,
        order_id: orderId ? orderId : "",
        file_url: url,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/target",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true);
    };
};
export var downloadGoogleFile = function (orderId) {
    var requestName = "DOWNLOAD_GOOGLE_FILE";
    console.log("DOWNLOAD_GOOGLE_FILE", orderId);
    var fileDataJson = {
        order_id: orderId,
        is_source_file: false,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true);
    };
};
export var deleteGoogleFile = function (id) {
    var requestName = "DELETE_GOOGLE_FILE";
    console.log("deleteGoogleFile id", id);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/".concat(id),
            method: "delete",
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true);
    };
};
export var uploadFileFromComputer = function (file, orderId, toTarget, cursorWatch) {
    if (cursorWatch === void 0) { cursorWatch = false; }
    var requestName = "UPLOAD_FILE_FROM_COMPUTER";
    var fileDataJson = {
        type: file.type,
        filename: file.name,
        content: file.content,
        uuid: file.uuid || undefined, //for PopupPreview
        encoding: file.encoding || undefined, //for PopupPreview
        parser_info: file.parserInfo || undefined, //for .csv,  PopupCSVSelector
    };
    if (orderId) {
        fileDataJson["order_id"] = orderId;
    }
    if (file.selected) {
        fileDataJson["selected"] = file.selected;
    }
    //todo: log for testing
    console.log({ secondPayload: fileDataJson });
    var json = JSON.stringify(fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: toTarget ? "/files/target" : "/files",
            method: "post",
            body: json,
        };
        console.log("requestOptions", requestOptions);
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch);
    };
};
export var downloadOrderFile = function (orderId, isSourceFile, fileUuid) {
    if (isSourceFile === void 0) { isSourceFile = false; }
    if (fileUuid === void 0) { fileUuid = null; }
    var requestName = "DOWNLOAD_ORDER_FILE";
    // console.log("DOWNLOAD_ORDER_FILE orderId", orderId);
    // console.log("DOWNLOAD_ORDER_FILE isSourceFile", isSourceFile);
    var fileDataJson = {
        order_id: orderId,
        is_source_file: isSourceFile,
        uuid: fileUuid,
    };
    // console.log("DOWNLOAD_ORDER_FILE fileDataJson", fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true);
    };
};
export var downloadInvoiceFile = function (paymentId, is_invoice) {
    var requestName = "DOWNLOAD_INVOICE_FILE";
    var fileDataJson = {
        payment_id: paymentId,
        is_invoice: is_invoice,
    };
    console.log("DOWNLOAD_INVOICE_FILE fileDataJson", fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export/payment",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true, false);
    };
};
