import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import faq from "./css/support-faq.css";
import ImageLocal from "../../ImageLocal/ImageLocal";
import Lightbox from "react-18-image-lightbox";
import { getHtml } from "../../../helpers/dangerouslySetInnerHTML";

export default function SupportFAQ(props) {
  const { blocks, styleData, firstOpen, textAfterTitle, openIndex, textSuggestions } = props;

  const [isOpenImage, setIsOpenImage] = useState(false);
  const [imageOpened, setImageOpened] = useState("");

  const customStyles = {
    content: {
      cursor: "zoom-out",
      backgroundColor: `rgba(0, 0, 0, 0.3)`,
    },
  };

  const handleClick = event => {
    setIsOpenImage(true);
    setImageOpened(event.target.src);
  };

  const container = styleData ? `faq.${styleData}` : "faq.container";

  return (
    <div styleName={container}>
      {textAfterTitle && <div styleName={"faq.textAfterTitle"}>{textAfterTitle}</div>}
      {blocks.map((block, index) => {
        const { title, descriptions } = block;

        return (
          <details key={index} open={(index === 0 && firstOpen) || index == openIndex - 1}>
            <summary>{title}</summary>
            {descriptions.map(({ description, image }, index) => (
              <div key={index} styleName={"faq.description"}>
                {description && getHtml(description)}
                {image && <ImageLocal image={image} onClick={handleClick} />}
              </div>
            ))}
          </details>
        );
      })}
      {isOpenImage && (
        <div onClick={() => setIsOpenImage(false)}>
          <Lightbox
            mainSrc={imageOpened}
            enableZoom={false}
            onCloseRequest={() => setIsOpenImage(false)}
            reactModalStyle={customStyles}
          />
        </div>
      )}
      {textSuggestions && <div styleName={"faq.textSuggestion"}>{textSuggestions && getHtml(textSuggestions)}</div>}
    </div>
  );
}
