export var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["CREATE_TRANSLATOR_REQUEST"] = "CREATE_TRANSLATOR_REQUEST";
    UserActionTypes["CREATE_TRANSLATOR_SUCCESS"] = "CREATE_TRANSLATOR_SUCCESS";
    UserActionTypes["GET_ME_REQUEST"] = "GET_ME_REQUEST";
    UserActionTypes["GET_ME_SUCCESS"] = "GET_ME_SUCCESS";
    UserActionTypes["GET_API_KEYS_REQUEST"] = "GET_API_KEYS_REQUEST";
    UserActionTypes["GET_API_KEYS_SUCCESS"] = "GET_API_KEYS_SUCCESS";
    UserActionTypes["GENERATE_API_KEY_REQUEST"] = "GENERATE_API_KEY_REQUEST";
    UserActionTypes["GENERATE_API_KEY_SUCCESS"] = "GENERATE_API_KEY_SUCCESS";
    UserActionTypes["UPDATE_API_KEY_NAME_REQUEST"] = "UPDATE_API_KEY_NAME_REQUEST";
    UserActionTypes["UPDATE_API_KEY_NAME_SUCCESS"] = "UPDATE_API_KEY_NAME_SUCCESS";
    UserActionTypes["DELETE_API_KEY_REQUEST"] = "DELETE_API_KEY_REQUEST";
    UserActionTypes["DELETE_API_KEY_SUCCESS"] = "DELETE_API_KEY_SUCCESS";
    UserActionTypes["DELETE_ME_REQUEST"] = "DELETE_ME_REQUEST";
    UserActionTypes["DELETE_ME_SUCCESS"] = "DELETE_ME_SUCCESS";
    UserActionTypes["GET_USER_REQUEST"] = "GET_USER_REQUEST";
    UserActionTypes["GET_USER_SUCCESS"] = "GET_USER_SUCCESS";
    UserActionTypes["UPDATE_PASSWORD_REQUEST"] = "UPDATE_PASSWORD_REQUEST";
    UserActionTypes["UPDATE_PASSWORD_SUCCESS"] = "UPDATE_PASSWORD_SUCCESS";
    UserActionTypes["REMOVE_RECENT_PAIR_REQUEST"] = "REMOVE_RECENT_PAIR_REQUEST";
    UserActionTypes["REMOVE_RECENT_PAIR_SUCCESS"] = "REMOVE_RECENT_PAIR_SUCCESS";
    UserActionTypes["DELETE_USER_REQUEST"] = "DELETE_USER_REQUEST";
    UserActionTypes["DELETE_USER_SUCCESS"] = "DELETE_USER_SUCCESS";
    UserActionTypes["REMOVE_ROLE_USER_REQUEST"] = "REMOVE_ROLE_USER_REQUEST";
    UserActionTypes["REMOVE_ROLE_USER_SUCCESS"] = "REMOVE_ROLE_USER_SUCCESS";
    UserActionTypes["GET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST"] = "GET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST";
    UserActionTypes["GET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS"] = "GET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS";
    UserActionTypes["SET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST"] = "SET_CUSTOMER_SUBSCRIBE_STATUS_REQUEST";
    UserActionTypes["SET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS"] = "SET_CUSTOMER_SUBSCRIBE_STATUS_SUCCESS";
    UserActionTypes["GET_CUSTOMERS_REQUEST"] = "GET_CUSTOMERS_REQUEST";
    UserActionTypes["GET_CUSTOMERS_SUCCESS"] = "GET_CUSTOMERS_SUCCESS";
    UserActionTypes["GET_ADMINS_REQUEST"] = "GET_ADMINS_REQUEST";
    UserActionTypes["GET_ADMINS_SUCCESS"] = "GET_ADMINS_SUCCESS";
    UserActionTypes["GET_TRANSLATORS_REQUEST"] = "GET_TRANSLATORS_REQUEST";
    UserActionTypes["GET_TRANSLATORS_SUCCESS"] = "GET_TRANSLATORS_SUCCESS";
    UserActionTypes["GET_USERS_REQUEST"] = "GET_USERS_REQUEST";
    UserActionTypes["GET_USERS_SUCCESS"] = "GET_USERS_SUCCESS";
    UserActionTypes["GET_TRANSLATORS_BY_LANGUAGE_PAIRS_REQUEST"] = "GET_TRANSLATORS_BY_LANGUAGE_PAIRS_REQUEST";
    UserActionTypes["GET_TRANSLATORS_BY_LANGUAGE_PAIRS_SUCCESS"] = "GET_TRANSLATORS_BY_LANGUAGE_PAIRS_SUCCESS";
    UserActionTypes["GET_LANGUAGE_PAIR_STATISTIC_REQUEST"] = "GET_LANGUAGE_PAIR_STATISTIC_REQUEST";
    UserActionTypes["GET_LANGUAGE_PAIR_STATISTIC_SUCCESS"] = "GET_LANGUAGE_PAIR_STATISTIC_SUCCESS";
    UserActionTypes["GET_SENDERS_EMAILS_REQUEST"] = "GET_SENDERS_EMAILS_REQUEST";
    UserActionTypes["GET_SENDERS_EMAILS_SUCCESS"] = "GET_SENDERS_EMAILS_SUCCESS";
    UserActionTypes["UPDATE_USER_REQUEST"] = "UPDATE_USER_REQUEST";
    UserActionTypes["UPDATE_USER_SUCCESS"] = "UPDATE_USER_SUCCESS";
    UserActionTypes["UPDATE_PAYMENT_METHOD_REQUEST"] = "UPDATE_PAYMENT_METHOD_REQUEST";
    UserActionTypes["UPDATE_PAYMENT_METHOD_SUCCESS"] = "UPDATE_PAYMENT_METHOD_SUCCESS";
    UserActionTypes["UPDATE_EMAIL_NOTIFICATIONS_REQUEST"] = "UPDATE_EMAIL_NOTIFICATIONS_REQUEST";
    UserActionTypes["UPDATE_EMAIL_NOTIFICATIONS_SUCCESS"] = "UPDATE_EMAIL_NOTIFICATIONS_SUCCESS";
    UserActionTypes["SET_SPELLCHECKER_STATUS_REQUEST"] = "SET_SPELLCHECKER_STATUS_REQUEST";
    UserActionTypes["SET_SPELLCHECKER_STATUS_SUCCESS"] = "SET_SPELLCHECKER_STATUS_SUCCESS";
    UserActionTypes["UPDATE_PERSONAL_REQUEST"] = "UPDATE_PERSONAL_REQUEST";
    UserActionTypes["UPDATE_PERSONAL_SUCCESS"] = "UPDATE_PERSONAL_SUCCESS";
    UserActionTypes["UPDATE_BILL_TO_REQUEST"] = "UPDATE_BILL_TO_REQUEST";
    UserActionTypes["UPDATE_BILL_TO_SUCCESS"] = "UPDATE_BILL_TO_SUCCESS";
    UserActionTypes["ACCEPT_TRANSLATOR_POPUP_REQUEST"] = "ACCEPT_TRANSLATOR_POPUP_REQUEST";
    UserActionTypes["ACCEPT_TRANSLATOR_POPUP_SUCCESS"] = "ACCEPT_TRANSLATOR_POPUP_SUCCESS";
    UserActionTypes["SAVE_CUSTOMER_REVIEW_REQUEST"] = "SAVE_CUSTOMER_REVIEW_REQUEST";
    UserActionTypes["SAVE_CUSTOMER_REVIEW_SUCCESS"] = "SAVE_CUSTOMER_REVIEW_SUCCESS";
    UserActionTypes["SAVE_VACATION_PERIOD_TRANSLATOR_REQUEST"] = "SAVE_VACATION_PERIOD_TRANSLATOR_REQUEST";
    UserActionTypes["SAVE_VACATION_PERIOD_TRANSLATOR_SUCCESS"] = "SAVE_VACATION_PERIOD_TRANSLATOR_SUCCESS";
    UserActionTypes["SAVE_VACATION_PERIOD_ADMIN_REQUEST"] = "SAVE_VACATION_PERIOD_ADMIN_REQUEST";
    UserActionTypes["SAVE_VACATION_PERIOD_ADMIN_SUCCESS"] = "SAVE_VACATION_PERIOD_ADMIN_SUCCESS";
    UserActionTypes["LIST_LITE_USERS_REQUEST"] = "LIST_LITE_USERS_REQUEST";
    UserActionTypes["LIST_LITE_USERS_SUCCESS"] = "LIST_LITE_USERS_SUCCESS";
    UserActionTypes["SET_CUSTOMER_CATEGORY_REQUEST"] = "SET_CUSTOMER_CATEGORY_REQUEST";
    UserActionTypes["SET_CUSTOMER_CATEGORY_SUCCESS"] = "SET_CUSTOMER_CATEGORY_SUCCESS";
})(UserActionTypes || (UserActionTypes = {}));
