// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.priceTable__tableContainer__aoqOF4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "source target price";
  padding-top: 16px;
}

.priceTable__source__hdCryJ {
  grid-area: source;
}

.priceTable__target__MjYrtX {
  grid-area: target;
}

.priceTable__price__zrpkMB {
  grid-area: price;
}

.priceTable__emptyCell__Juodbb {
  grid-area: emptyCell;
}

.priceTable__tableHeader__oPsd4H {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.priceTable__tableRow__Xhg_tC {
  color: #212121;
  min-width: 115px;
}

.priceTable__tableHeaderFile__eEN6ye.priceTable__tableHeaderFileRus__afYXQd {
  height: 72px;
}

.priceTable__tableHeaderFile__eEN6ye {
  height: 51px;
  display: flex;
  align-items: flex-end;
}

.priceTable__tableHeaderFileRight__bHGTDS {
  justify-content: flex-end;
}

.priceTable__tablePriceHeader__xkB4sC {
  text-align: right;
  justify-content: flex-end;
}

.priceTable__tableEmptyText__q7t695 {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  grid-column: 1/5;
  padding: 40px 0;
}

.priceTable__tableContainerFile__kl60FT .priceTable__tableEmptyText__q7t695 {
  grid-column: 1/6;
}

.priceTable__tableContainerFile__kl60FT .priceTable__totalPrice__aKmpA5 {
  grid-column: 1/6;
}

.priceTable__tableText__OR9N0m {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.priceTable__tableContainerFile__kl60FT .priceTable__tableText__OR9N0m {
  /*width: 118px;*/
}

.priceTable__tableRowVolumeGoogle__HCysXh .priceTable__volumeTitle__fNFvlt,
.priceTable__tableRowVolumeGoogle__HCysXh .priceTable__tableVolumeText__mmRnA7 {
  width: 121px;
}

.priceTable__tableContainerFile__kl60FT .priceTable__volumeTitle__fNFvlt {
  margin: 0;
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableContainerFile__kl60FT .priceTable__tableVolumeText__mmRnA7 {
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableContainerGoogleFile__SCnDub .priceTable__tableVolumeText__mmRnA7 {
  text-align: right;
  padding-right: 20px;
}

.priceTable__priceText__bIG5WY {
  display: flex;
  justify-content: flex-end;
}

.priceTable__tableVolumeText__mmRnA7 {
  text-align: right;
  padding-right: 20px;
}

.priceTable__tableTMText__b3KYzf {
  text-align: right;
  vertical-align: middle;
  min-height: 25px;
  max-height: 25px;
}

.priceTable__totalPrice__aKmpA5 {
  grid-column: 1/5;
  font-weight: bold;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #212121;
}

.priceTable__spinnerContainer___VH4Qr {
  padding-top: 3px;
}

.priceTable__tableTragetLangRowMobile__GcbXx2 {
  display: none;
}

.priceTable__pmQuestion__UDNVRC {
  margin-bottom: 3px;
}

.priceTable__pmQuestionUp__wK8EUV {
  position: relative;
  top: -23px;
}

@media (max-width: 725px) {
  .priceTable__tableContainer__aoqOF4 {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-template-areas:
      "source target price"
  }
  .priceTable__tableHeader__oPsd4H {
    display: flex;
    align-items: flex-end;
    height: 51px;
  }

  .priceTable__tableHeaderFile__eEN6ye.priceTable__tableHeaderFileRus__afYXQd {
    height: 51px;
  }

  .priceTable__tableTragetLangRowMobile__GcbXx2 {
    display: block;
  }

  .priceTable__tableRowVolumeFile__Oj5fE_ {
    grid-column: 2/4;
  }
  .priceTable__tableRowVolumeGoogle__HCysXh {
    grid-column: 2/4;
  }

  .priceTable__volumeTitle__fNFvlt {
    padding-right: 0;
  }

  .priceTable__tableContainerFile__kl60FT .priceTable__volumeTitle__fNFvlt {
    margin: 0;
    text-align: right;
    padding-right: 20px;
  }

  .priceTable__tableContainerGoogleFile__SCnDub .priceTable__volumeTitle__fNFvlt {
    margin: 0;
    text-align: right;
    padding-right: 20px;
  }

  .priceTable__tableVolumeText__mmRnA7 {
    padding-right: 0;
  }

  .priceTable__tableVolumeRow__BntLx7 {
    grid-column: 2/4;
  }

  .priceTable__tableTargetRow__HjKhHb .priceTable__tableText__OR9N0m {
    /*  */
    max-width: 88px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .priceTable__tableText__OR9N0m {
    width: auto;
  }

  .priceTable__tableContainerFile__kl60FT .priceTable__tableEmptyText__q7t695 {
    grid-column: 1/4;
  }

  .priceTable__tableAuthEmptyText__Fd7ZuJ {
    grid-column: 1/4;
  }

  .priceTable__tableTargetText__uRyPmj {
    padding-left: 0;
  }

  .priceTable__totalPrice__aKmpA5 {
    grid-column: 1/3;
  }

  .priceTable__tableContainerFile__kl60FT .priceTable__totalPrice__aKmpA5 {
    grid-column: 1/4;
  }

  .priceTable__tableTMText__b3KYzf {
    /*padding-right: 33px;*/
  }
}

@media (max-width: 425px) {
  .priceTable__tableTMText__b3KYzf {
    /*padding-right: 20px;*/
  }
}

@media screen and (max-width: 380px) {
    .priceTable__tableRow__Xhg_tC{
        min-width: auto;
    }
}


@media (max-width: 325px) {
  .priceTable__tableTMText__b3KYzf {
    /*padding-right: 18px;*/
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `priceTable__tableContainer__aoqOF4`,
	"source": `priceTable__source__hdCryJ`,
	"target": `priceTable__target__MjYrtX`,
	"price": `priceTable__price__zrpkMB`,
	"emptyCell": `priceTable__emptyCell__Juodbb`,
	"tableHeader": `priceTable__tableHeader__oPsd4H`,
	"tableRow": `priceTable__tableRow__Xhg_tC`,
	"tableHeaderFile": `priceTable__tableHeaderFile__eEN6ye`,
	"tableHeaderFileRus": `priceTable__tableHeaderFileRus__afYXQd`,
	"tableHeaderFileRight": `priceTable__tableHeaderFileRight__bHGTDS`,
	"tablePriceHeader": `priceTable__tablePriceHeader__xkB4sC`,
	"tableEmptyText": `priceTable__tableEmptyText__q7t695`,
	"tableContainerFile": `priceTable__tableContainerFile__kl60FT`,
	"totalPrice": `priceTable__totalPrice__aKmpA5`,
	"tableText": `priceTable__tableText__OR9N0m`,
	"tableRowVolumeGoogle": `priceTable__tableRowVolumeGoogle__HCysXh`,
	"volumeTitle": `priceTable__volumeTitle__fNFvlt`,
	"tableVolumeText": `priceTable__tableVolumeText__mmRnA7`,
	"tableContainerGoogleFile": `priceTable__tableContainerGoogleFile__SCnDub`,
	"priceText": `priceTable__priceText__bIG5WY`,
	"tableTMText": `priceTable__tableTMText__b3KYzf`,
	"spinnerContainer": `priceTable__spinnerContainer___VH4Qr`,
	"tableTragetLangRowMobile": `priceTable__tableTragetLangRowMobile__GcbXx2`,
	"pmQuestion": `priceTable__pmQuestion__UDNVRC`,
	"pmQuestionUp": `priceTable__pmQuestionUp__wK8EUV`,
	"tableRowVolumeFile": `priceTable__tableRowVolumeFile__Oj5fE_`,
	"tableVolumeRow": `priceTable__tableVolumeRow__BntLx7`,
	"tableTargetRow": `priceTable__tableTargetRow__HjKhHb`,
	"tableAuthEmptyText": `priceTable__tableAuthEmptyText__Fd7ZuJ`,
	"tableTargetText": `priceTable__tableTargetText__uRyPmj`
};
export default ___CSS_LOADER_EXPORT___;
