import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import link from "./css/link.css";
import * as _ from "lodash";

export default class LinkWrapper extends Component {
  debounceOnClick = _.debounce(() => {
    this.props.onClick();
  }, 200);

  handleClick = () => {
    this.link.blur();
    this.debounceOnClick();
  };

  render() {
    const { textContent, style, tabIndex } = this.props;
    return (
      <span
        ref={link => (this.link = link)}
        tabIndex={tabIndex}
        style={style}
        styleName={"link.link"}
        onClick={this.handleClick}
      >
        {textContent}
      </span>
    );
  }
}
