var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { PaymentServiceClient } from "@alconost/nitro-services-api/src/proto/payment_service_grpc_web_pb";
import { CreatePaymentAdminRequest, CreatePaymentUserRequest, ListPaymentsRequest, SortPaymentField, PaymentId, PaymentUuid, ProcessPayPalRequest, ConfirmPaymentRequest, GetExchangeRateRequest, RedeemCouponRequest, } from "@alconost/nitro-services-api/src/proto/payment_service_pb";
import { PaymentMethod, SortOrder } from "@alconost/nitro-services-api/src/proto/base_pb";
import { Page } from "@alconost/nitro-services-api/src/proto/base_pb";
export var createPaymentAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var userId = _b.userId, amount = _b.amount, method = _b.method, details = _b.details, workspacename = _b.workspacename, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreatePaymentAdminRequest();
                request.setUserId(userId);
                request.setAmount(amount);
                request.setMethod(method);
                request.setDetails(details);
                workspacename && request.setWorkspacename(workspacename);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                console.log(request.toObject(), "request");
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "createPaymentAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createPaymentUserRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var locale = _b.locale, amount = _b.amount, sourceAmount = _b.sourceAmount, sourceCurrency = _b.sourceCurrency, method = _b.method, cid = _b.cid, invoiceDetailsCurrencyList = _b.invoiceDetailsCurrencyList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreatePaymentUserRequest();
                request.setLocale(locale);
                request.setAmount(amount);
                request.setSourceAmount(sourceAmount);
                request.setSourceCurrency(sourceCurrency);
                request.setMethod(PaymentMethod[method]);
                request.setCid(cid);
                request.setInvoiceDetailsCurrencyList(invoiceDetailsCurrencyList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "createPaymentUser",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listPaymentsAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var offset = _b.offset, limit = _b.limit, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, methodsList = _b.methodsList, status = _b.status, workspace = _b.workspace, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListPaymentsRequest();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                request.setPage(page);
                request.setSearch(search);
                request.setField(SortPaymentField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setMethodsList(methodsList);
                request.setStatus(status);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                console.log(request.toObject(), "request");
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "listPaymentsAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listPaymentsCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var offset = _b.offset, limit = _b.limit, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, methodsList = _b.methodsList, status = _b.status, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListPaymentsRequest();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                request.setPage(page);
                request.setSearch(search);
                request.setField(SortPaymentField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setMethodsList(methodsList);
                request.setStatus(status);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "listPaymentsCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getPaymentAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentId = _b.paymentId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentId();
                request.setPaymentId(paymentId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "getPaymentAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getPaymentCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentId = _b.paymentId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentId();
                request.setPaymentId(paymentId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "getPaymentCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createPaymentInvoiceAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentId = _b.paymentId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentId();
                request.setPaymentId(paymentId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "createPaymentInvoiceAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createPaymentInvoiceCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentId = _b.paymentId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentId();
                request.setPaymentId(paymentId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "createPaymentInvoice",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getPaymentInvoiceRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var uuid = _b.uuid, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new PaymentUuid();
                request.setUuid(uuid);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "getPaymentInvoice",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var processPayPalRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentIds = _b.paymentIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ProcessPayPalRequest();
                request.setPaymentIdsList(paymentIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "processPayPal",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var confirmPaymentRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var paymentId = _b.paymentId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ConfirmPaymentRequest();
                request.setPaymentId(paymentId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "confirmPayment",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getExchangeRateRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var to = _b.to, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetExchangeRateRequest();
                request.setTo(to);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "getExchangeRate",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var redeemCouponRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var code = _b.code, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RedeemCouponRequest();
                request.setCode(code);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: PaymentServiceClient,
                        methodName: "redeemCoupon",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
