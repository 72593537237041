import { DESC } from "../../constants/Sort";

const initialState = {
  tableState: {
    search: "",
    scrollPosition: {
      x: 0,
      y: 0,
    },
    loadDiscountsLimit: 10,
    selectedDiscounts: [],
    selectedDiscountsUses: [],
    sortDiscounts: {
      id: "DISCOUNT_ID",
      type: DESC,
    },
    editingBlocks: {
      isOpen: false,
      rate: {},
    },
    users: {
      search: "",
      scrollPosition: {
        x: 0,
        y: 0,
      },
      loadLimit: 10,
      selected: [],
      sort: {
        id: "USER_ID",
        type: DESC,
      },
      editingBlocks: {
        isOpen: false,
        rate: {},
      },
    },
    generatedDiscounts: {},
  },
};

export default function discountsTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_DISCOUNTS_TABLE_STATE":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.state,
        },
      };
    case "GENERATE_DISCOUNTS":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          generatedDiscounts: {
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}
