// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover__popoverQuestion__Gk6o3p {
  border-radius: 0 !important;
  border: 1px solid #bdbdbd !important;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 16px;
}

.popover__popper__kUTaJ_[data-x-out-of-boundaries] {
  display: none;
}

.popover__questionHint__CXa5r6 {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  align-self: center;
  text-align: left;
  box-shadow: none;
  white-space: pre-line;
  max-width: 240px;
  min-width: 240px;
  word-wrap: break-word;
}

.popover__questionLink___nsztk {
  color: #1e88e5 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.popover__questionLinkContainer__oDyLOg {
  padding-top: 8px;
}

.popover__popper__kUTaJ_ {
  display: flex;
  flex-direction: column;
  z-index: 15;
  padding: 15px;
  background: white;
  max-width: 270px;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  min-height: 30px !important;

}
.popover__popper-languageBox__AIZSw4{
  z-index: 13;
}
.popover__popper-addFiles__Z6o1Z1, .popover__popper-export__oojNJO{
  padding: 0;
}
.popover__popper-orderContext__KYA3UT,
.popover__popper-addFiles__Z6o1Z1,
.popover__popper-customerOrderContext__kaHPez,
.popover__popper-translatorOrderContext___dXAMl,
.popover__popper-export__oojNJO {
}

.popover__popper__kUTaJ_ .popover__popper__arrow__cbNhVG {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin: 5px;
}

.popover__popper__kUTaJ_[data-placement^="top"] {
  margin-bottom: 12px;
  left: -10px !important;
}

.popover__popper__kUTaJ_[data-placement^="top"] .popover__popper__arrow__cbNhVG {
  content: "";
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.popover__popper__kUTaJ_[data-placement^="top"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-placement^="top"] .popover__popper__arrow__cbNhVG::after {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top: 9px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-placement^="top"] .popover__popper__arrow__cbNhVG::after {
  border-top: 9px solid white;
  bottom: -17px;
}
/*data-popper-placement = top-start*/
.popover__popper__kUTaJ_[data-popper-placement^="top-start"] {
  margin-bottom: 10px;
  left: -16px!important;
}
.popover__popper__kUTaJ_[data-popper-placement^="top-start"] .popover__popper__arrow__cbNhVG {
  pointer-events: none;
  content: "";
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  transform: rotate(180deg);
}
.popover__popper-languageBox__AIZSw4.popover__popper__kUTaJ_[data-popper-placement^="top-start"]{
  left: 0!important;
}
.popover__popper__kUTaJ_[data-popper-placement^="top-start"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-popper-placement^="top-start"] .popover__popper__arrow__cbNhVG::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-popper-placement^="top-start"] .popover__popper__arrow__cbNhVG::after {
    border-top: 10px solid white;
    bottom: -19px;
}
/*data-popper-placement = bottom-start*/
.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] {
  margin-top: 10px;
  left: -16px!important;
}
.popover__popper-addFiles__Z6o1Z1.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"]{
  margin-top: 0;
  left: 0!important;
  top: -3px!important;
}

.popover__popper-languageBox__AIZSw4.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"]{
  left: 0!important;
}

.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG {
  pointer-events: none;
  content: "";
  top: -20px;
  margin-top: 0;
  margin-bottom: 0;
}

.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG::after {
    border-bottom: 10px solid white;
    bottom: -21px;
}

@-moz-document url-prefix() {
  .popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-popper-placement^="bottom-start"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    /*margin-top: -24px;*/
  }
}


.popover__popper__kUTaJ_[data-placement^="right"] {
  margin-left: 13px;
  top: -10px !important;
}

.popover__popper__kUTaJ_[data-placement^="right"] .popover__popper__arrow__cbNhVG {
  content: "";
  left: -21px;
  top: calc(2%) !important;
  margin-left: 0;
  margin-right: 0;
}

.popover__popper__kUTaJ_[data-placement^="right"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-placement^="right"] .popover__popper__arrow__cbNhVG::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-right: 10px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-placement^="right"] .popover__popper__arrow__cbNhVG::after {
  border-right: 10px solid white;
  right: -21px;
}

.popover__popper__kUTaJ_[data-placement^="left"] {
  margin-right: 12px;
  top: -10px !important;
}

.popover__popper__kUTaJ_[data-placement^="left"] .popover__popper__arrow__cbNhVG {
  content: "";
  left: 100%;
  top: calc(2%) !important;
  margin-left: 0;
  margin-right: 0;
}

.popover__popper__kUTaJ_[data-placement^="left"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-placement^="left"] .popover__popper__arrow__cbNhVG::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-left: 10px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-placement^="left"] .popover__popper__arrow__cbNhVG::after {
  border-left: 10px solid white;
  left: -1px;
}

.popover__popper__kUTaJ_[data-x-out-of-boundaries] {
  display: none;
}

.popover__container-popover__ppMkJs {
  padding: 8px 11px 8px 9px;
}

.popover__popper-table-menu__Kjq8qb,
.popover__detail-button-container-popover__pKEzbY {
  border-radius: 0 !important;
  border: 1px solid #bdbdbd !important;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 0;
}

/* bottom-end*/

.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] {
    margin-top: 10px;
    right: -10px !important;
}

.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
    pointer-events: none;
    content: "";
    top: -20px;
    margin-top: 0;
    margin-bottom: 0;
    left: -26px !important;
}

.popover__popper-export__oojNJO[data-popper-placement^="bottom-end"]{
    top: 0 !important;
    right: 0 !important;
    bottom: auto !important;
    left: auto !important;
    box-shadow: 0px 2px 3px 0px #00000026 !important;
    border: 1px solid #E0E0E0 !important;
    bottom: 10px;
}


.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
}

.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
    content: "";
    top: -20px;
    margin-top: 0;
    margin-bottom: 0;
    /*left: calc(85%) !important;*/
}


.popover__popper__kUTaJ_[data-popper-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    border-bottom: 10px solid white;
    bottom: -21px;
}

.popover__popper-customerOrderContext__kaHPez[data-placement^="bottom-end"] {
  margin-top: 13px;
  left: 11px !important;
}

.popover__popper-translatorOrderContext___dXAMl[data-placement^="bottom-end"] {
  margin-top: 13px;
  /*min-width: 270px;*/
  /*max-width: 270px;*/
  left: 10px !important;
}

.popover__popper-table-menu__Kjq8qb[data-placement^="bottom-end"],
.popover__detail-button-container-popover__pKEzbY[data-placement^="bottom-end"] {
  left: auto !important;
  right: -8px;
  top: 100% !important;
  transform: none !important;
  width: -webkit-max-content;
  width: max-content;
}

.popover__estimated-time-container-popover__BNKglq[data-placement^="bottom-end"] {
  margin-top: 20px;
  left: 20px !important;
}



.popover__popper-translatorOrderContext___dXAMl[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  top: -20px;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(88%) !important;
}

.popover__popper-customerOrderContext__kaHPez[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  top: -20px;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(88%) !important;
}

.popover__detail-button-container-popover__pKEzbY[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  top: -21px;
  margin: 0;
  left: calc(85%) !important;
}

.popover__popper-table-menu__Kjq8qb[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  top: -21px;
  right: 33px;
  margin: 0;
  left: auto !important;
}



@-moz-document url-prefix() {
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    margin-top: -24px;
  }

  .popover__popper-orderContext__KYA3UT[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    margin-top: -18px;
  }

  .popover__popper-addFiles__Z6o1Z1[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    margin-top: -18px;
    z-index: 3;
  }

  .popover__popper-translatorOrderContext___dXAMl[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    margin-top: -18px;
  }

  .popover__popper-customerOrderContext__kaHPez[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::before,
  .popover__popper__kUTaJ_[data-placement^="bottom-end"] .popover__popper__arrow__cbNhVG::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #bdbdbd;
    margin-top: -18px;
  }
}


.popover__popper__kUTaJ_[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 0 !important;
}

.popover__popper-export__oojNJO.popover__popper__kUTaJ_[data-popper-placement^="top-end"] {
    margin-bottom: 10px;
}

.popover__popper-orderContext__KYA3UT[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 19px !important;
}

.popover__popper-addFiles__Z6o1Z1[data-placement^="top"] {
  margin-bottom: 5px;
  left: 62px !important;
  padding: 0;
  z-index: 3;
}

.popover__popper-addFiles__Z6o1Z1[data-placement^="bottom"] {
  margin-top: 5px;
  left: auto !important;
  padding: 0;
  z-index: 3;
  opacity: 1;
  transition: opacity 0.01s ease-in;
  will-change: unset !important;
}

.popover__popper-translatorOrderContext___dXAMl[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 19px !important;
  z-index: 10;
}

.popover__popper-customerOrderContext__kaHPez[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 19px !important;
}

.popover__popper__kUTaJ_[data-placement^="top-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(86%) !important;
}

.popover__detail-button-container-popover__pKEzbY[data-placement^="top-end"] .popover__popper__arrow__cbNhVG {
  content: "";
  bottom: -1px;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(86%) !important;
}

.popover__item__vKF00L {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #424242;
  border: 0;
  display: block;
  padding: 0 !important;
  cursor: pointer;
}

.popover__item__vKF00L:hover {
  background-color: #eeeeee;
}

.popover__exit-container__dPbhtl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0 14px;
  cursor: pointer;
}

.popover__something-wrong-btn__qQS7cu {
  text-align: center !important;
  font-size: 14px;
  border-radius: 3px;
  line-height: 16px !important;
  margin-right: 16px;
}

.popover__detailItem-container__RMNUtJ {
  margin: 0;
  padding: 0;
}

.popover__addFiles__tUhaYH .popover__popover__popper___1zF0g__ASqTyp[data-placement^="bottom"] {
  margin-top: 5px;
  left: 62px !important;
}

.popover__underheader-title__vCeW7L {
  transition: all 0.5s;
  transform: translate3d(31px, 45px, -14px) !important;
}

.popover__underHeaderTitleArrow__Kl2Gup {
  position: absolute;
  z-index: 100;
  top: -8px !important;
  left: 15px !important;
  /*box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);*/
  /*border: 1px solid black;*/

  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;

  border-bottom: 9px solid white;
  transition: all 0.5s;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverQuestion": `popover__popoverQuestion__Gk6o3p`,
	"popper": `popover__popper__kUTaJ_`,
	"questionHint": `popover__questionHint__CXa5r6`,
	"questionLink": `popover__questionLink___nsztk`,
	"questionLinkContainer": `popover__questionLinkContainer__oDyLOg`,
	"popper-languageBox": `popover__popper-languageBox__AIZSw4`,
	"popper-addFiles": `popover__popper-addFiles__Z6o1Z1 popover__popper__kUTaJ_`,
	"popper-export": `popover__popper-export__oojNJO popover__popper__kUTaJ_`,
	"popper-orderContext": `popover__popper-orderContext__KYA3UT popover__popper__kUTaJ_`,
	"popper-customerOrderContext": `popover__popper-customerOrderContext__kaHPez popover__popper__kUTaJ_`,
	"popper-translatorOrderContext": `popover__popper-translatorOrderContext___dXAMl popover__popper__kUTaJ_`,
	"popper__arrow": `popover__popper__arrow__cbNhVG`,
	"container-popover": `popover__container-popover__ppMkJs popover__popper__kUTaJ_`,
	"popper-table-menu": `popover__popper-table-menu__Kjq8qb popover__popper__kUTaJ_`,
	"detail-button-container-popover": `popover__detail-button-container-popover__pKEzbY popover__popper__kUTaJ_`,
	"estimated-time-container-popover": `popover__estimated-time-container-popover__BNKglq`,
	"item": `popover__item__vKF00L`,
	"exit-container": `popover__exit-container__dPbhtl`,
	"something-wrong-btn": `popover__something-wrong-btn__qQS7cu`,
	"detailItem-container": `popover__detailItem-container__RMNUtJ`,
	"addFiles": `popover__addFiles__tUhaYH`,
	"popover__popper___1zF0g": `popover__popover__popper___1zF0g__ASqTyp`,
	"underheader-title": `popover__underheader-title__vCeW7L`,
	"underHeaderTitleArrow": `popover__underHeaderTitleArrow__Kl2Gup`
};
export default ___CSS_LOADER_EXPORT___;
