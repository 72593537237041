import React from "react";
// eslint-disable-next-line no-unused-vars
import editBlock from "../components/refactored/PaymentEditBlock/css/payment-edit-block.css";
import { formatLastSignIn } from "./formatLastSignIn";
import { I18n } from "react-redux-i18n";
import SaveBtn from "../components/elements/SaveBtn";

export const formatCompletedPayout = (payout, processPayout) => {
  if (payout.completed && payout.status === "COMPLETED") {
    return formatLastSignIn(payout.completed);
  } else if (!payout.completed && payout.paymentId !== 0) {
    return "Processing";
  } else if (payout.status === "FAILED") {
    return "Failed";
  } else {
    return (
      <SaveBtn
        tabIndex={0}
        styleName={"editBlock.btn"}
        text={I18n.t("ADMIN_APP.PAYMENTS_PAGE.EDIT_PAYOUT_SECTION.BUTTONS.PROCESS")}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          return processPayout();
        }}
      />
    );
  }
};
