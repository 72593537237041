import { getCookie, setCookie } from "../../../helpers/common";
import * as jose from "jose";
import { setToken } from "../../../actions/App";
import { generateAccessToken } from "../../../services/AuthServices/requests";

function getDefaultHeaders() {
  return {
    "Content-Type": "application/json",
  };
}

function getHeaders() {
  const token = getCookie("auth_token") || false;

  if (token) {
    return {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token}`,
    };
  }

  return {
    ...getDefaultHeaders(),
  };
}

export const checkToken = async options => {
  let newToken = options.token; // const {token: newToken, dispatch} = options
  let dispatch = options.dispatch;
  const savedRefreshToken = getCookie("refresh_token");

  if (newToken && getCookie("auth_token") && savedRefreshToken) {
    const claims = jose.decodeJwt(newToken);
    const currentDate = Math.round(Date.now() / 1000);
    if ((claims.exp && claims.exp <= currentDate) || !claims.exp) {
      await generateAccessToken(savedRefreshToken).then(accessToken => {
        setCookie("auth_token", accessToken.accessToken, 3650);
        newToken = accessToken.accessToken;
      });
      //uncomment while switch to new proto gen
      // .catch((e) => {
      //   console.log(e, "ERROR CODE!!");
      //   if (e.code !== 0) {
      //     window.location.href = "/login";
      //     setCookie("auth_token", "", -10);
      //     setCookie("refresh_token", "", -10);
      //   }
      // });
      dispatch(setToken(getCookie("auth_token")));
    }
  }
  return newToken;
};

export async function resolver(endpoint, options, otherOptions) {
  const result = {
    data: null,
    error: null,
    status: null,
  };
  let URL = `${process.env.SERVICES_URL}/${endpoint}`;

  try {
    if (otherOptions) {
      await checkToken(otherOptions);
    }

    const response = await fetch(URL, {
      headers: getHeaders(),
      ...options,
    });

    result.status = response.status;
    //todo: add error handler
    if (endpoint === "stripe/pay" && response.status === 403) {
      return result;
    }

    if (endpoint === "stripe/pay" && response.status === 400) {
      result.error = await response.text();
      return result;
    }

    if ((endpoint === "stripe/delete" || endpoint === "stripe/pay") && response.status === 200) {
      return result;
    }

    //RC Cleanup
    if (endpoint === "stripe/add" && response.status === 200) {
      result.data = await response.text();
      return result;
    } else {
      result.data = await response.json();
    }
  } catch (error) {
    console.log(error);
    result.error = error;
  }

  return result;
}
