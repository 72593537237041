import { RefreshTokenRequest, } from "@alconost/nitro-services-api/src/proto/auth_service_pb";
import { grpcRequest } from "../newGrpcUtils";
import { AuthServiceClient } from "@alconost/nitro-services-api/src/proto/auth_service_grpc_web_pb";
export var generateAccessToken = function (refreshToken) {
    var protoRequest = new RefreshTokenRequest();
    protoRequest.setRefreshToken(refreshToken);
    var metadata = undefined;
    return grpcRequest({
        clientService: AuthServiceClient,
        methodName: "refreshToken",
        protoRequest: protoRequest,
        metadata: metadata,
    });
};
