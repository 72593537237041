import React from "react";
import Select, { components } from "react-select";
import { I18n } from "react-redux-i18n";
import { HandySvg } from "handy-svg";
import addNewCardIcon from "../../../images/svg/payment-methods/add-new-card.svg";
// eslint-disable-next-line no-unused-vars
import defaultSelect from "./css/defaultSelect.css";

export default class DefaultSelect extends React.Component {
  constructor(props) {
    super(props);
    this.divEl = [];
    this.state = {
      isFocus: false,
    };
  }

  componentDidMount() {
    this.calculateContentHint();
  }

  componentDidUpdate() {
    this.calculateContentHint();
  }

  calculateContentHint = () => {
    const { divEl } = this;
    for (let key in divEl) {
      if (divEl[key] && divEl[key].scrollWidth - divEl[key].clientWidth) {
        divEl[key].title = key;
      }
    }
  };

  styles = {
    control: base => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: 264,
      border: "1px solid #CECECE",
      borderRadius: 1,
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #1E88E5",
      },
    }),

    placeholder: base => ({
      ...base,
      color: "#9E9E9E",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 16,
    }),

    input: base => ({
      ...base,
    }),

    valueContainer: base => ({
      ...base,
      height: 30,
    }),

    singleValue: base => ({
      ...base,
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "16px",
    }),

    dropdownIndicator: () => ({
      backgroundImage:
        "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODUzNTUgNi44NTM1NUwxMC42NDY0IDEwLjY0NjRDMTAuODQxNyAxMC44NDE3IDExLjE1ODMgMTAuODQxNyAxMS4zNTM2IDEwLjY0NjRMMTUuMTQ2NCA2Ljg1MzU1QzE1LjQ2MTQgNi41Mzg1NyAxNS4yMzgzIDYgMTQuNzkyOSA2SDcuMjA3MTFDNi43NjE2NSA2IDYuNTM4NTcgNi41Mzg1NyA2Ljg1MzU1IDYuODUzNTVaIiBmaWxsPSIjOUU5RTlFIi8+Cjwvc3ZnPgo=) center no-repeat",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    clearIndicator: base => ({
      ...base,
      display: "none",
    }),

    menu: base => ({
      ...base,
      minHeight: this.props.autoHeight ? "" : 270,
      marginTop: 0,
      borderRadius: 1,
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "::-webkit-scrollbar": { width: "0 !important" },
    }),

    menuList: base => ({
      ...base,
      minHeight: this.props.autoHeight ? "" : 270,
      padding: 0,
      boxShadow: "0 3px 4px 0 rgba(0,0,0,0.19)",
      overflow: "-moz-scrollbars-none",
      "-ms-overflow-style": "none",
      "::-webkit-scrollbar": { width: "0 !important" },
    }),

    option: base => ({
      ...base,
      width: 264,
      padding: 0,
      backgroundColor: "#FFFFFF",
      color: "#424242",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E3F2FD",
        color: "#1E88E5",
      },
    }),
  };

  onFocus = () => {
    this.setState({
      isFocus: true,
    });
  };

  onBlur = () => {
    this.state.isFocus &&
      this.setState({
        isFocus: false,
      });
  };

  onAddNewCardClick = () => {
    this.props.handleAddNewPaymentMethodClick && this.props.handleAddNewPaymentMethodClick();
  };

  render() {
    const {
      onChange,
      onInputChange,
      value,
      options,
      customOption,
      selectStyle,
      selectOptionStyle,
      status,
      placeholder,
    } = this.props;

    let validationMessage;
    if (status === "INPUT_REQUIRED") {
      validationMessage = "INPUT_VALIDATIONS.REQUIRED";
    } else if (status === "INPUT_INCORRECT_EMAIL") {
      validationMessage = "INPUT_VALIDATIONS.INCORRECT_EMAIL_ERROR";
    } else if (status === "INVALID_VALUE") {
      validationMessage = "INPUT_VALIDATIONS.INVALID_VALUE";
    }

    const DropdownIndicator = props => {
      const colorArrow = props.selectProps.error
        ? "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTggKDEwMTAxMCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+MzJBQjI2NkUtNjkwMi00Mjc5LUI0MEMtMzY2NzkxREY2MDU5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxnIGlkPSLwn5G38J+PvC1uaXRyby10cmFuc2xhdG9yIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iVUktS0lULURFTU8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NTguMDAwMDAwLCAtMTMxNy4wMDAwMDApIiBmaWxsPSJyZWQiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iVHJpYW5nbGUtZ3JleSIgcG9pbnRzPSI0NjMgMTMyMyA0NTggMTMxNyA0NjggMTMxNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+') center no-repeat"
        : "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODUzNTUgNi44NTM1NUwxMC42NDY0IDEwLjY0NjRDMTAuODQxNyAxMC44NDE3IDExLjE1ODMgMTAuODQxNyAxMS4zNTM2IDEwLjY0NjRMMTUuMTQ2NCA2Ljg1MzU1QzE1LjQ2MTQgNi41Mzg1NyAxNS4yMzgzIDYgMTQuNzkyOSA2SDcuMjA3MTFDNi43NjE2NSA2IDYuNTM4NTcgNi41Mzg1NyA2Ljg1MzU1IDYuODUzNTVaIiBmaWxsPSIjOUU5RTlFIi8+Cjwvc3ZnPgo=) center no-repeat";
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <div
              style={{
                width: 24,
                height: 24,
                background: colorArrow,
              }}
            />
          </components.DropdownIndicator>
        )
      );
    };

    const Option = props => {
      const styleContainer = {
        display: "flex",
        width: 264,
        whiteSpace: "nowrap",
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "normal",
        padding: 8,
        backgroundColor: props.isSelected && "#E3F2FD",
        color: props.isSelected && "#424242",
        cursor: !props.isSelected ? "pointer" : "default",
      };

      const optionStyle = selectOptionStyle ? selectOptionStyle(props) : styleContainer;

      const optionTextStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
      };

      return (
        <components.Option classNamePrefix="react-select" {...props}>
          <div style={optionStyle}>
            <div
              ref={node => {
                this.divEl[props.children] = node;
              }}
              style={optionTextStyle}
            >
              {props.children}
            </div>
          </div>
        </components.Option>
      );
    };

    const CustomOption = props => {
      const optionTextStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
      };

      const styleContainer = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        whiteSpace: "nowrap",
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: "16px",
        padding: 8,
        backgroundColor: props.isSelected && "#E3F2FD",
        color: props.isSelected && "#424242",
        cursor: !props.isSelected ? "pointer" : "default",
      };

      const styleIcon = {
        height: 9,
        width: 12,
        background:
          props.isSelected &&
          "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxMiA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTggKDEwMTAxMCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+QTk0MTY3NTktMjFCNi00OTk5LUFCMTYtMzMxRDM3NjM0Nzg0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxnIGlkPSLwn5G38J+PvC1uaXRyby10cmFuc2xhdG9yIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iVUktS0lULURFTU8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MzcuMDAwMDAwLCAtMTMxNy4wMDAwMDApIiBmaWxsPSIjMUU4OEU1Ij4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImNoZWNrLTEiIHBvaW50cz0iNTQ5IDEzMTcgNTQ3IDEzMTcgNTQxIDEzMjMgNTM5IDEzMjEgNTM3IDEzMjEgNTM3IDEzMjIgNTQxIDEzMjYgNTQ5IDEzMTgiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')",
        // : props.isSelected && "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxMiA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkU0OTFDREUzLUFFQzAtNDc4QS1BRDFDLTk0MkE5QjlBMjE0MTwvdGl0bGU+CiAgICA8ZyBpZD0iVUktS0lUIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iVUktS0lULURFTU8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MzcuMDAwMDAwLCAtMTMxNy4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImNoZWNrIiBwb2ludHM9IjU0OSAxMzE3IDU0NyAxMzE3IDU0MSAxMzIzIDUzOSAxMzIxIDUzNyAxMzIxIDUzNyAxMzIyIDU0MSAxMzI2IDU0OSAxMzE4Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=)"
      };

      return (
        <components.Option classNamePrefix="react-select" {...props}>
          <div style={styleContainer} styleName="defaultSelect.container">
            <div styleName="defaultSelect.text">
              {props.data.icon && (
                <HandySvg
                  styleName={props.isSelected ? "defaultSelect.iconActive" : "defaultSelect.icon"}
                  width="20"
                  height="20"
                  src={props.data.icon}
                />
              )}
              <div
                ref={node => {
                  this.divEl[props.children] = node;
                }}
                style={optionTextStyle}
              >
                {props.children}
              </div>
            </div>
            <div style={styleIcon} />
          </div>
        </components.Option>
      );
    };

    const Menu = props => {
      const { id, handleAddNewPaymentMethodClick } = this.props;

      if (id === "paymentMethod" && handleAddNewPaymentMethodClick) {
        return (
          <components.Menu {...props}>
            <div style={{ paddingBottom: 40 }}>{props.children}</div>
            <div styleName={"defaultSelect.add-card-container"}>
              <button
                styleName={"defaultSelect.add-card-btn"}
                onClick={this.onAddNewCardClick}
                onTouchEnd={this.onAddNewCardClick}
              >
                <HandySvg src={addNewCardIcon} height={20} width={20} />
                <span>{I18n.t("CUSTOMER_APP.SETTINGS_PAGE.PAYMENT_CARD.ADD_PAYMENT_METHOD_BUTTON")}</span>
              </button>
            </div>
          </components.Menu>
        );
      }

      return <components.Menu {...props} />;
    };

    const isFocusedMenu = this.state.isFocus;
    const styles = selectStyle ? selectStyle(isFocusedMenu, validationMessage) : this.styles;
    return (
      <div>
        <Select
          classNamePrefix="react-select"
          placeholder={placeholder}
          value={value}
          //menuIsOpen={true}
          onChange={onChange}
          onInputChange={onInputChange}
          styles={styles}
          options={options}
          openMenuOnFocus={true}
          openMenuOnClick={true}
          isSearchable={false}
          isSelected={true}
          components={{
            Menu,
            DropdownIndicator,
            Option: customOption ? CustomOption : Option,
          }}
          onFocus={this.onFocus}
          onClick={this.onFocus}
          onBlur={this.onBlur}
          error={validationMessage}
        />
        {validationMessage && (
          <p
            style={{
              marginTop: 8,
              color: "#EF5350",
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: 200,
            }}
          >
            {I18n.t(validationMessage)}
          </p>
        )}
      </div>
    );
  }
}
