import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
// eslint-disable-next-line no-unused-vars
import box from "./css/box.css";
import HtmlTranslate from "../../../../HtmlTranslate";
import { PopoverTest } from "../../../../Popover/PopoverTest";
import { findDOMNode } from "react-dom";
import iconSet from "../../../../../images/selection.json";
import IcomoonReact from "icomoon-react";

export default class LanguageCheckbox extends Component {
  state = {
    isTitle: false,
    onHover: false,
    isPopoverOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { selected, isDisabled, isRecent, sourceLanguage, language, boxWidth, item } = this.props;
    const { isTitle } = this.state;

    return (
      selected !== nextProps.selected ||
      !_.isEqual(item, nextProps) ||
      isRecent !== nextProps.isRecent ||
      isDisabled !== nextProps.isDisabled ||
      sourceLanguage !== nextProps.sourceLanguage ||
      boxWidth !== nextProps.boxWidth ||
      (isTitle !== nextState.isTitle && language.value === nextProps.language.value)
    );
  }

  componentDidMount() {
    this.doubleSetStateHintAfterCalculate();
  }

  doubleSetStateHintAfterCalculate = () => {
    this.setState(
      {
        isTitle: !!this.defineNeedTitle(),
      },
      () => {
        this.setState({
          isTitle: !!this.defineNeedTitle(),
        });
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sourceLanguage !== prevProps.sourceLanguage || this.state.isTitle !== prevState.isTitle) {
      this.doubleSetStateHintAfterCalculate();
    }
  }

  handleClick = () => {
    const { language, handleClick, isDisabled, sourceLanguage, clickDisabledLanguage } = this.props;
    this.doubleSetStateHintAfterCalculate();
    if (!isDisabled || !sourceLanguage.value) {
      handleClick(language);
    } else {
      if (sourceLanguage.value && isDisabled) {
        this.setState({ isPopoverOpen: true });
        clickDisabledLanguage(language);
      }
    }
  };

  defineNeedTitle = () => {
    return !!(this.divEl && this.divEl.scrollWidth > this.divEl.clientWidth);
  };

  onHover = () => {
    this.setState({
      onHover: true,
    });
  };

  onBlur = () => {
    this.setState({
      onHover: false,
    });
  };

  handlePopover = () => {
    this.props.sourceLanguage.value &&
      this.props.isDisabled &&
      this.setState(prevState => ({
        isPopoverOpen: !prevState.isPopoverOpen,
      }));
  };

  handleBlur = () => {
    this.props.sourceLanguage.value &&
      this.props.isDisabled &&
      this.setState({
        isPopoverOpen: false,
      });
  };

  openPopover = () => {
    this.props.sourceLanguage.value &&
      this.props.isDisabled &&
      this.setState({
        isPopoverOpen: true,
      });
  };

  render() {
    const { isDisabled, selected, isRecent, sourceLanguage, language, boxWidth, selectLanguage } = this.props;
    const { onHover } = this.state;
    const { label } = this.props.language;
    const proofreadingStyleName = isDisabled ? "translate.targetLanguageIsDisabled" : "translate.targetLanguage";
    const itemValue =
      language.value === sourceLanguage.value ? (
        <HtmlTranslate
          innerStyleName={proofreadingStyleName}
          value={"LANGUAGE_PROOFREADING"}
          target={I18n.t("LANGUAGES." + label)}
        />
      ) : (
        I18n.t("LANGUAGES." + label)
      );
    const divTitle =
      language.value === sourceLanguage.value
        ? I18n.t("LANGUAGE_PROOFREADING", { target: I18n.t("LANGUAGES." + label) })
            .replace("<span>", "")
            .replace("</span>", "")
        : I18n.t("LANGUAGES." + label);
    const iconTitle = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TITLE_TARGET_LANGUAGES_CLOCK");
    const title = this.defineNeedTitle() ? divTitle : undefined;
    const containerStyle = {
      position: "relative",
      width: boxWidth,
      ...(selected && { background: "#E3F2FD", color: "#212121" }),
    };
    const labelColor =
      sourceLanguage.value && isDisabled
        ? { color: "rgba(33, 33, 33, 0.3)" }
        : selected
          ? { color: "#212121" }
          : this.state.onHover
            ? { color: "#1E88E5" }
            : { color: "#212121" };
    const iconRecentStyle = selected ? "box.iconIsRecentBlack" : "box.iconIsRecentGrey";
    const iconSelectedStyle = selected ? "box.iconIsChecked" : "box.icon";
    const isRecentLabel = !isRecent && { marginLeft: 24 };
    const labelStyle = _.assign(isRecentLabel, labelColor);
    const containerStyleName = sourceLanguage.value && isDisabled ? "box.containerDisabled" : "box.container";
    let textPopover;
    if (selectLanguage) {
      const message = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.NEED_LANGUAGES_TO_BE_ADDED_CHAT_MESSAGE");
      textPopover = I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.TOOLTIP_NOT_SUPPORTED", {
        source: selectLanguage.label,
        target: itemValue,
        message,
      });
    }

    return (
      <div
        styleName={containerStyleName}
        style={containerStyle}
        title={divTitle}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onBlur}
        ref={c => {
          this.target = findDOMNode(c);
        }}
        onClick={this.openPopover}
      >
        {isRecent && (
          <div styleName={iconRecentStyle} title={iconTitle} onClick={this.handleClick}>
            {sourceLanguage.value && isDisabled ? (
              <IcomoonReact iconSet={iconSet} color={"#BDBDBD"} size={16} icon={"recent"} />
            ) : selected ? (
              <IcomoonReact iconSet={iconSet} color={"#424242"} size={16} icon={"recent"} />
            ) : (
              <IcomoonReact iconSet={iconSet} color={onHover ? "#1E88E5" : "#BDBDBD"} size={16} icon={"recent"} />
            )}
          </div>
        )}
        {this.state.isPopoverOpen && sourceLanguage.value && isDisabled && (
          <PopoverTest
            placement={"bottom-start"}
            isShowPopover={this.state.isPopoverOpen}
            popperStyleName={"languageBox"}
            handleBlur={this.handleBlur}
            referenceElement={<div styleName={"box.leftEmptyBlock"}></div>}
            wrapperStyle={{ position: "absolute", left: "0" }}
            popperElement={
              <span styleName={"box.textPopover"} dangerouslySetInnerHTML={{ __html: textPopover }}></span>
            }
          />
        )}
        <div
          ref={node => {
            this.divEl = node;
          }}
          styleName={"box.label"}
          style={labelStyle}
          onClick={this.handleClick}
          title={this.state.isTitle ? title : undefined}
        >
          {itemValue}
        </div>
        {selected && (
          <div styleName={"box.checkedDisabledIconContainer"}>
            <div styleName={iconSelectedStyle} onClick={this.handleClick}>
              {selected && <IcomoonReact iconSet={iconSet} color={"#1E88E5"} size={12} icon={"check"} />}
            </div>
          </div>
        )}
        <div styleName={"box.padding"} />
      </div>
    );
  }
}
