import React from "react";
// eslint-disable-next-line no-unused-vars
import badge from "../../refactored/SideMenu/css/side-menu.css";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
const Badge = props => {
  const { bsStyle, content, handleAddFunds } = props;

  return (
    <div styleName={`${bsStyle.badgeContainer}`}>
      <span styleName={`${bsStyle.content}`}>{content}</span>
      {handleAddFunds && (
        <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={15} icon={"add_circle"} onClick={handleAddFunds} />
      )}
    </div>
  );
};

export default Badge;
