import React, { useState } from "react";
import iconStyles from "./css/icon.css";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
import classnames from "classnames";

export default function IconView(props) {
  const { icon, size, colors, cursor = "default", styleContainer, onClick, title, wrapperClassName } = props;
  const [isHover, setIsHover] = useState(false);

  const style = { ...styleContainer, cursor };

  return (
    <div className={classnames(iconStyles.container, wrapperClassName)} style={style} onClick={onClick} title={title}>
      <IcomoonReact
        iconSet={iconSet}
        color={isHover ? colors[0] : colors[1]}
        size={size}
        icon={icon}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />
    </div>
  );
}
