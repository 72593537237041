import reduceReducers from "reduce-reducers";
import { createThunkReducer } from "../../helpers/createThunkReducer";
import {
  CREATE_TRANSLATOR,
  DELETE_USER,
  GET_ADMINS,
  GET_CUSTOMERS,
  GET_TRANSLATORS,
  UPDATE_USER,
  GET_USERS,
  REMOVE_ROLE_USER,
} from "../../constants/Users";

const initialState = {
  ["translator"]: { status: "", data: { hasmore: false } },
  ["admin"]: { status: "", data: { hasmore: false } },
  ["customer"]: { status: "", data: { hasmore: false } },
  ["workspace"]: { status: "", data: { hasmore: false } },
  ["users"]: { status: "", data: { hasmore: false } },
  ["newTranslator"]: { status: "", exist: false },
  ["updatedUser"]: { status: "" },
  ["removeUsers"]: { status: "", data: {} },
  ["translatorsByLanguagePairs"]: { status: "", data: { hasmore: false } },
};

const customersReducer = createThunkReducer("customer", GET_CUSTOMERS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const adminsReducer = createThunkReducer("admin", GET_ADMINS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const translatorsReducer = createThunkReducer("translator", GET_TRANSLATORS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});
const workspaceReducer = createThunkReducer("workspace", "LIST_WORKSPACES_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});
const usersFullReducer = createThunkReducer("users", GET_USERS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const translatorsByPairsReducer = createThunkReducer(
  "translatorsByLanguagePairs",
  "GET_TRANSLATORS_BY_LANGUAGE_PAIRS",
  {
    handleSuccessAction: (state, payload) => {
      return { requestState: { data: payload } };
    },
  }
);

const newTranslatorReducer = createThunkReducer("newTranslator", CREATE_TRANSLATOR, {
  handleSuccessAction: () => {
    return { requestState: { exist: false } };
  },
});

const updatedUser = createThunkReducer("updatedUser", UPDATE_USER, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const deleteUserRequest = createThunkReducer("removeUsers", DELETE_USER, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const removeRoleUserRequest = createThunkReducer("removeRoleUser", REMOVE_ROLE_USER, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const usersReducer = reduceReducers(
  initialReducer,
  adminsReducer,
  customersReducer,
  translatorsReducer,
  usersFullReducer,
  newTranslatorReducer,
  deleteUserRequest,
  removeRoleUserRequest,
  updatedUser,
  translatorsByPairsReducer,
  workspaceReducer
);
