import React, { useEffect, useState } from "react";
import DefaultSelect from "../Select/DefaultSelect";
import FormInput from "../FormInput/FormInput";
import { I18n } from "react-redux-i18n";
// eslint-disable-next-line no-unused-vars
import advancedOptions from "./css/advanced-options.css";
import { categoryCodeList, translationTypeList } from "../AdvancedOptionsResult/AdvancedOptionsResult";

export const AdvancedOptions = ({
  setMaxLength,
  handleChangeAdvancedSettings,
  advancedSettingsList,
  localeFormSelectStyle,
  advancedSettingsValue,
  savedValue,
  categorySettingsList,
  handleChangeCategorySettings,
  advancedCategoryValue,
}) => {
  const [inputValue, setInputValue] = useState(savedValue || "");

  useEffect(() => {
    setInputValue(savedValue || "");
  }, [setMaxLength]);

  const handleChangeValue = e => {
    const result = e.target.value.replace(/^[\D0]+|\D/g, "");
    setInputValue(result);
    return setMaxLength(result);
  };

  return (
    <div style={{ marginTop: 16 }}>
      <div styleName={"advancedOptions.advansedWrapper"}>
        <div styleName={"advancedOptions.advansedLabel"}>
          {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY")}
        </div>
        <DefaultSelect
          id="category"
          selectStyle={localeFormSelectStyle}
          customOption={true}
          value={{
            value: advancedCategoryValue,
            label: I18n.t(
              "CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.CATEGORY_MENU." + categoryCodeList[advancedCategoryValue]
            ),
          }}
          onChange={handleChangeCategorySettings}
          options={categorySettingsList}
        />
      </div>

      <div styleName={"advancedOptions.advansedLabel"}>
        {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.LABEL")}
      </div>
      <DefaultSelect
        id="advancedSettings"
        selectStyle={localeFormSelectStyle}
        customOption={true}
        value={{
          value: advancedSettingsValue,
          label:
            typeof advancedSettingsValue === "number"
              ? I18n.t(
                  "CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU." + translationTypeList[advancedSettingsValue]
                )
              : I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.MENU." + advancedSettingsValue),
        }}
        onChange={handleChangeAdvancedSettings}
        options={advancedSettingsList}
      />
      <div styleName={"advancedOptions.advancedSettingsWrapper"}>
        <FormInput
          value={inputValue}
          type="text"
          labelStyle={{ fontWeight: 400 }}
          styleContainer={{ marginTop: 16 }}
          label={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.TRANSLATION")}
          styleInput={{ width: 142 }}
          onChange={e => handleChangeValue(e)}
        />
        {inputValue.length > 0 ? (
          <div styleName={"advancedOptions.validationText"}>
            {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.ADVANCED_SETTINGS.VALIDATION_TRANSLATION")}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
