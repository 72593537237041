import PropTypes from "prop-types";
import React, { Component } from "react";
import { setCookie } from "../helpers/common";
import "../css/deprecated/footer.deprecated.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import IcomoonReact from "icomoon-react";
import iconSet from "../images/selection.json";

export default class LanguageSwitcher extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
  };

  handleClick = (e, locale) => {
    e.preventDefault();
    setCookie("nitro_lang", locale, 3650);
    this.props.handleSaveLocale && this.props.handleSaveLocale(locale);
    woopra.track("changed language", {
      locale: locale,
      env: process.env.SERVER_NAME,
    });
    window.history.replaceState(null, null, window.location);
    !this.props.handleSaveLocale && window.location.reload();
  };

  handleRussianClick = e => {
    this.handleClick(e, "ru");
  };

  handleEnglishClick = e => {
    this.handleClick(e, "en");
  };

  handleGermanClick = e => {
    this.handleClick(e, "de");
  };

  handleJapaneseClick = e => {
    this.handleClick(e, "ja");
  };

  render() {
    const { locale, german, japanese, russian = true } = this.props;
    const dev = process.env.SERVER_NAME === "dev";
    const containerStyle = { position: "relative", width: 158 };
    const popover = (
      <Popover className={locale + " nitro-popover"} id="popover-positioned-top">
        <ul className="language-list">
          {russian && (
            <li>
              <a className="footer-gray" onClick={this.handleRussianClick}>
                Русский
              </a>
            </li>
          )}
          <li>
            <a className="footer-gray" onClick={this.handleEnglishClick}>
              English
            </a>
          </li>
          {german && dev && (
            <li>
              <a className="footer-gray" onClick={this.handleGermanClick}>
                Deutsch
              </a>
            </li>
          )}
          {japanese && (
            <li>
              <a className="footer-gray" onClick={this.handleJapaneseClick}>
                日本語
              </a>
            </li>
          )}
        </ul>
      </Popover>
    );

    if (russian && locale === "ru") {
      return (
        <div style={containerStyle} ref={container => (this.container = container)}>
          <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover} container={this.container}>
            <span className="switcher-container">
              <IcomoonReact className="earth-icon" iconSet={iconSet} color={"#868686"} size={20} icon={"earth"} />
              &nbsp;&nbsp;
              <a className="footer-gray inner">Русский</a>
            </span>
          </OverlayTrigger>
        </div>
      );
    } else if (german && dev && locale === "de") {
      return (
        <span style={containerStyle} ref={container => (this.container = container)}>
          <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover} container={this.container}>
            <span className="switcher-container">
              <IcomoonReact className="earth-icon" iconSet={iconSet} color={"#868686"} size={20} icon={"earth"} />
              &nbsp;&nbsp;
              <a className="footer-gray inner">Deutsch</a>
            </span>
          </OverlayTrigger>
        </span>
      );
    } else if (japanese && locale === "ja") {
      return (
        <span style={containerStyle} ref={container => (this.container = container)}>
          <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover} container={this.container}>
            <span className="switcher-container">
              <IcomoonReact className="earth-icon" iconSet={iconSet} color={"#868686"} size={20} icon={"earth"} />
              &nbsp;&nbsp;
              <a className="footer-gray inner">日本語</a>
            </span>
          </OverlayTrigger>
        </span>
      );
    } else {
      return (
        <span style={containerStyle} ref={container => (this.container = container)}>
          <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover} container={this.container}>
            <span className="switcher-container">
              <IcomoonReact className="earth-icon" iconSet={iconSet} color={"#868686"} size={20} icon={"earth"} />
              &nbsp;&nbsp;
              <a className="footer-gray inner">English</a>
            </span>
          </OverlayTrigger>
        </span>
      );
    }
  }
}

LanguageSwitcher.defaultProps = {
  locale: "en",
};
