import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import checkbox from "./css/checkbox.css";
import { isSpace } from "../../../constants/KeyboardKeys";

class CheckBox extends Component {
  static defaultProps = {
    isFocusingByKeybord: false,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.isChecked !== this.props.isChecked;
  }

  componentDidMount() {
    const { isFocusingByKeybord } = this.props;
    isFocusingByKeybord && this.checkmark.addEventListener("keydown", this.handleSpacePressed, true);
  }

  handleSpacePressed = e => {
    const { handleChange } = this.props;
    if (isSpace(e)) {
      handleChange(e);
    }
  };

  handleChange = e => {
    if (e.target.nodeName === "LABEL" || e.target.nodeName === "SPAN") {
      const { handleChange } = this.props;
      this.checkmark.blur();
      let isShiftClick = false;
      if (e.shiftKey) {
        isShiftClick = true;
      }

      handleChange(e, this.props.isChecked, isShiftClick);
    }
  };

  componentWillUnmount() {
    this.checkmark.removeEventListener("keydown", this.handleSpacePressed, true);
  }

  render() {
    const { isFocusingByKeybord, containerStyle } = this.props;
    const tabIndex = isFocusingByKeybord ? 0 : undefined;
    const labelStyleShit = isFocusingByKeybord ? "checkbox.containerKeyBoardFocus" : "checkbox.container";

    return (
      <label styleName={labelStyleShit} onClick={this.handleChange} style={containerStyle}>
        <input onChange={() => {}} checked={this.props.isChecked} type="checkbox" styleName={"checkbox.input"} />
        <span ref={node => (this.checkmark = node)} tabIndex={tabIndex} styleName={"checkbox.checkmark"} />
      </label>
    );
  }
}

export default CheckBox;
