// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.defaultSelect__container__YSTdrl:hover .defaultSelect__icon__YfDtHf {
  fill: #1e88e5;
}

.defaultSelect__text__CSR5JQ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultSelect__icon__YfDtHf {
  fill: #bdbdbd;
  margin-right: 12px;
}

.defaultSelect__iconActive__I373HI {
  fill: #1e88e5;
  margin-right: 12px;
}

.defaultSelect__options-container__gnwzv0 {
  position: relative;
  padding-bottom: 40px;
}

.defaultSelect__add-card-container__DBkV0j {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  height: 40px;
  background-color: white;
  padding: 8px 5px 9px;
}

.defaultSelect__add-card-btn__SR9aNT {
  background-color: transparent;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.defaultSelect__add-card-btn__SR9aNT > span {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0;
  color: #1e88e5;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  margin-left: 8px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `defaultSelect__container__YSTdrl`,
	"icon": `defaultSelect__icon__YfDtHf`,
	"text": `defaultSelect__text__CSR5JQ`,
	"iconActive": `defaultSelect__iconActive__I373HI`,
	"options-container": `defaultSelect__options-container__gnwzv0`,
	"add-card-container": `defaultSelect__add-card-container__DBkV0j`,
	"add-card-btn": `defaultSelect__add-card-btn__SR9aNT`
};
export default ___CSS_LOADER_EXPORT___;
