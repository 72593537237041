export const REQUEST_TRANSACTIONS = "REQUEST_TRANSACTIONS";
export const REFRESH_TRANSACTIONS = "REFRESH_TRANSACTIONS";
export const SUCCEED_TRANSACTIONS = "SUCCEED_TRANSACTIONS";
export const SUCCEED_REFRESH_TRANSACTIONS = "SUCCEED_REFRESH_TRANSACTIONS";
export const FAIL_TRANSACTIONS = "FAIL_TRANSACTIONS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const TRANSACTIONS_FETCHED = "TRANSACTIONS_FETCHED";
export const TRANSACTIONS_FETCHING = "TRANSACTIONS_FETCHING";
export const TRANSACTIONS_REFRESHING = "TRANSACTIONS_REFRESHING";
export const TRANSACTIONS_ERROR = "TRANSACTIONS_ERROR";
