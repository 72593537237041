import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";
import { DESC } from "../../constants/Sort";

const initialState = {
  search: "",
  scrollPosition: {
    x: 0,
    y: 0,
  },
  loadLimit: 20,
  sort: {
    id: "GLOSSARY_LAST_MODIFIED",
    type: DESC,
  },
  termLoadLimit: 20,
  selectedGlossaries: [],
  termsSearch: "",
  currentId: "",
  sourceLanguages: {},
  actualLanguages: [],
  selectedRows: [],
  ["customerTerms"]: { status: "", termsentriesList: [], hasmore: false },
  ["statistic"]: { status: "", glossaries: "", termEntries: "", terms: "" },
  ["languagesList"]: { status: "", data: "" },

  glossaryInfo: {
    id: "",
    name: "",
    lastModified: undefined,
    terms: 0,
    languages: 0,
    languagesList: [],
  },
  glossaries: {
    status: "",
    search: "",
    userId: "",
    loadLimit: 10,
    glossariesList: [],
    hasmore: false,
    sort: {
      id: "GLOSSARY_LAST_MODIFIED",
      type: DESC,
    },
    selectedGlossaries: [],
    selectedGlossariesObj: [],
  },
  usersGlossaries: {
    status: "",
    search: "",
    loadLimit: 10,
    glossariesList: [],
    hasmore: false,
    sort: {
      id: "GLOSSARY_LAST_MODIFIED",
      type: DESC,
    },
  },
  tablesState: {
    search: "",
    name: "",
    termsLimit: 10,
    scrollPosition: {
      x: 0,
      y: 0,
    },

    terms: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObjects: [],
      amountLoaded: 10,
      filter: {
        languages: "",
      },
    },
    glossary: {
      id: "",
      name: "",
      lastModified: undefined,
      terms: 0,
      languages: 0,
    },
    hasmore: false,
    languagesList: [],
  },
};

const getGlossariesRequest = createThunkReducer("glossaries", "GET_USER_GLOSSARIES_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { ...state.glossaries, ...payload } };
  },
});

const getUsersGlossariesRequest = createThunkReducer("usersGlossaries", "GET_ADMIN_GLOSSARIES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { ...state.usersGlossaries, ...payload } };
  },
});

const searchTermsRequest = createThunkReducer("customerTerms", "GET_LIST_TERMS_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        termsentriesList: payload.termsentriesList,
        hasmore: payload.hasmore,
      },
    };
  },
});

const getGlossaryById = createThunkReducer("glossaryInfo", "DELETE_GLOSSARY_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        ...state.glossaryInfo,
        ...payload,
      },
    };
  },
});

function glossary(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_GLOSSARY_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    case "CHANGE_GLOSSARIES_TABLE_STATE":
      return {
        ...state,
        glossaries: {
          ...state.glossaries,
          ...action.state,
        },
      };
    case "GET_LIST_TERMS_ADMIN_SUCCESS":
      return {
        ...state,
        tablesState: {
          ...state.tablesState,
          info: {
            ...state.tablesState.info,
            ...action.payload,
          },
        },
      };
    case "CHANGE_GLOSSARY_INFO": //RC
      return {
        ...state,
        glossaryInfo: {
          ...state.glossaryInfo,
          ...action.state,
        },
      };
    case "CHANGE_TERMS_STATE":
      return {
        ...state,
        customerTerms: {
          ...state.customerTerms,
          ...action.state,
        },
      };
    case "GET_GLOSSARY_INFO_ADMIN_SUCCESS":
      return {
        ...state,
        glossaryInfo: {
          ...state.glossaryInfo,
          ...action.payload,
        },
      };

    case "CHANGE_USERS_GLOSSARIES":
      return {
        ...state,
        usersGlossaries: {
          ...state.usersGlossaries,
          ...action.state,
        },
      };
    default:
      return state;
  }
}

export const adminGlossaryReducer = reduceReducers(
  glossary,
  getGlossariesRequest,
  getGlossaryById,
  searchTermsRequest,
  getUsersGlossariesRequest
);
