//@ts-nocheck
import { showErrorNotification, showNewSourceVersionNotification, showSuccessNotification, showWarningErrorNotification, showWarningNotification, showWarningNotificationAutoHide, } from "../actions/NotificationActions";
export var showWarning = function (message) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showWarningNotification(message, newId));
        return newId;
    };
};
export var showWarningAutoHide = function (message) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showWarningNotificationAutoHide(message, newId));
        return newId;
    };
};
export var showNotification = function (message) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showSuccessNotification(message, newId));
        return newId;
    };
};
export var showWarningError = function (message) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showWarningErrorNotification(message, newId));
        return newId;
    };
};
export var showError = function (message) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showErrorNotification(message, newId));
        return newId;
    };
};
export var showNewSourceVersion = function (message, handleRefresh) {
    return function (dispatch, getState) {
        var _a = getState(), token = _a.app.token, id = _a.notification.id;
        var newId = id + 1;
        dispatch(showNewSourceVersionNotification(message, newId));
        return newId;
    };
};
