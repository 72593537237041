// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-label__container__4ubvgB {
  text-align: center;
  font-size: 14px;
  margin-top: 35px;
  height: 19px;
  margin-bottom: 18px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `empty-label__container__4ubvgB`
};
export default ___CSS_LOADER_EXPORT___;
