import React from "react";
import HtmlTranslate from "../components/HtmlTranslate";
import { useClearCache } from "./reactClearCache";

export default function ClearCacheRefresh() {
  const { emptyCacheStorage } = useClearCache();
  const style = {
    display: "inline-block",
    cursor: "pointer",
    border: "1px solid #fff",
    color: "#fff",
    borderRadius: 4,
    padding: "2px 10px",
    marginLeft: 24,
    marginRight: 16,
  };

  return (
    <div style={style} onClick={emptyCacheStorage}>
      <HtmlTranslate value="ERRORS.COMMON_REFRESH_BUTTON" />
    </div>
  );
}
