import {
  FAIL_CONNECTION_OPEN,
  FAIL_CONNECTION_CLOSE,
  FAIL_POLLING_OPEN,
  FAIL_POLLING_CLOSE,
} from "../constants/AuthorizedActionTypes";

const initialState = {
  visiable: false,
  feedbackSuccess: false,
  connectionFail: false,
  pollingFail: false,
};

export default function errors(state = initialState, action) {
  switch (action.type) {
    case FAIL_CONNECTION_OPEN:
      return {
        ...state,
        visiable: true,
        connectionFail: true,
      };
    case FAIL_CONNECTION_CLOSE:
      return {
        ...state,
        visiable: false,
        connectionFail: false,
      };
    case FAIL_POLLING_OPEN:
      return {
        ...state,
        visiable: true,
        pollingFail: true,
      };
    case FAIL_POLLING_CLOSE:
      return {
        ...state,
        visiable: false,
        pollingFail: false,
      };
    default:
      return state;
  }
}
