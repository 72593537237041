var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CouponPolicy, Page, SortDiscountField, SortOrder, } from "@alconost/nitro-services-api/src/proto/base_pb";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { DiscountServiceClient } from "@alconost/nitro-services-api/src/proto/discount_service_grpc_web_pb";
import { DiscountCode, DiscountCustomerCode, DiscountId, GenerateDiscountsRequest, ListDiscountUsersRequest, ListDiscountsRequest, SortDiscountUserField, UpdateDiscountRequest, UpdateDiscountBlockedRequest, DeleteDiscountRequest, } from "@alconost/nitro-services-api/src/proto/discount_service_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
export var generateDiscountsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, expiresTimestamp, metadata;
    var count = _b.count, percent = _b.percent, details = _b.details, expires = _b.expires, policy = _b.policy, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GenerateDiscountsRequest();
                request.setCount(count);
                request.setPercent(percent);
                request.setDetails(details);
                expiresTimestamp = new Timestamp();
                expiresTimestamp.fromDate(expires);
                request.setExpires(expiresTimestamp);
                request.setPolicy(CouponPolicy[policy]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "generateDiscounts",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getDiscountInfoRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var code = _b.code, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DiscountCode();
                request.setCode(code);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "getInfo",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCustomerDiscountInfoRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var code = _b.code, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DiscountCustomerCode();
                request.setCode(code);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "getDiscountCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getAdminDiscountInfoRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var id = _b.id, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DiscountId();
                request.setId(id);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "getDiscountAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listDiscountsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var limit = _b.limit, offset = _b.offset, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListDiscountsRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                if (search)
                    request.setSearch(search);
                request.setField(SortDiscountField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "listDiscounts",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listDiscountUsersRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var id = _b.id, limit = _b.limit, offset = _b.offset, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListDiscountUsersRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                request.setId(id);
                request.setPage(page);
                request.setField(SortDiscountUserField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "listDiscountUsers",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateDiscountRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, timestampExpired, time, metadata;
    var id = _b.id, percent = _b.percent, code = _b.code, name = _b.name, expires = _b.expires, policy = _b.policy, blocked = _b.blocked, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateDiscountRequest();
                timestampExpired = new Timestamp();
                time = new Date(expires);
                timestampExpired.fromDate(time);
                request.setId(id);
                request.setPercent(percent);
                request.setCode(code);
                request.setName(name);
                request.setExpires(timestampExpired);
                request.setPolicy(CouponPolicy[policy]);
                request.setBlocked(blocked);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "updateDiscount",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateDiscountBlockedRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var ids = _b.ids, isBlocked = _b.isBlocked, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateDiscountBlockedRequest();
                request.setIdsList(ids);
                request.setIsBlocked(isBlocked);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "updateDiscountBlocked",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteDiscountRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var ids = _b.ids, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DeleteDiscountRequest();
                request.setIdsList(ids);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DiscountServiceClient,
                        methodName: "deleteDiscount",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
