import * as types from "../constants/NotificationActionTypes";
import { ERROR, NEW_SOURCE_VERSION, NOTIFICATION, WARNING, WARNING_ERROR } from "../constants/Notifications";

export function stopShowNotification() {
  return {
    type: types.STOP_NOTIFICATION,
  };
}

export function stopShowNotificationById(id) {
  return {
    id: id,
    type: types.STOP_NOTIFICATION_BY_ID,
  };
}

export function showSuccessNotification(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: NOTIFICATION,
    message,
  };
}

export function showWarningNotification(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: WARNING,
    message,
  };
}

export function showWarningNotificationAutoHide(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: WARNING,
    autoHide: true,
    message,
  };
}

export function showWarningNotificationNotDisconnect(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: WARNING,
    notDisconnect: true,
    message,
  };
}

export function showErrorNotification(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: ERROR,
    message,
  };
}

export function showWarningErrorNotificationWithClose(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: WARNING_ERROR,
    message,
    withClose: true,
  };
}

export function showWarningErrorNotification(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: WARNING_ERROR,
    autoHide: true,
    message,
  };
}

export function showNewSourceVersionNotification(message, id) {
  return {
    id: id,
    type: types.RUN_NOTIFICATION,
    notificationType: NEW_SOURCE_VERSION,
    message,
  };
}
