import {
  REQUEST_PASSWORD_RESET,
  SUCCEED_REQUEST_PASSWORD_RESET,
  FAIL_REQUEST_PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET,
  SUCCEED_CONFIRM_PASSWORD_RESET,
  FAIL_CONFIRM_PASSWORD_RESET,
  RESET_PASSWORD,
  INIT_PASSWORD_RESET,
  PASSWORD_RESET_USER_NOT_ACTIVE_ERROR,
  SET_STATUS,
  PASSWORD_RESET_USER_NOT_ACTIVE_STATUS,
} from "../constants/Signup";

import { VALIDATE_FORM } from "../constants/App";

import {
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_CONFIRMED,
  PASSWORD_RESET_COMPLETED,
  PASSWORD_RESET_ERROR,
} from "../constants/Signup";
import { SignupTypes } from "../thunks/newThunks/signup/signupActionTypes";

const initialState = {
  status: null,
  error: null,
  email: null,
  token: null,
  authToken: null,
};

export default function resetPassword(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_FORM:
      return {
        ...state,
        status: null,
        error: null,
      };
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        email: action.email,
        status: null,
        error: null,
      };
    case SUCCEED_REQUEST_PASSWORD_RESET:
      return {
        ...state,
        status: PASSWORD_RESET_REQUESTED,
      };
    case FAIL_REQUEST_PASSWORD_RESET:
      return {
        ...state,
        status: PASSWORD_RESET_ERROR,
        error: action.error.message,
      };
    case PASSWORD_RESET_USER_NOT_ACTIVE_ERROR:
      return {
        ...state,
        status: PASSWORD_RESET_USER_NOT_ACTIVE_STATUS,
        error: action.error.message,
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case CONFIRM_PASSWORD_RESET:
      return {
        ...state,
        status: null,
        error: null,
        email: action.form.email,
        token: action.form.code,
      };
    case SUCCEED_CONFIRM_PASSWORD_RESET:
      return {
        ...state,
        status: PASSWORD_RESET_CONFIRMED,
      };
    case FAIL_CONFIRM_PASSWORD_RESET:
      return {
        ...state,
        error: action.error.message,
        status: PASSWORD_RESET_ERROR,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        status: null,
        error: null,
      };
    case SignupTypes.CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: PASSWORD_RESET_COMPLETED,
        authToken: action.token,
      };
    case SignupTypes.CONFIRM_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        status: PASSWORD_RESET_ERROR,
        error: action.error.message,
      };
    case INIT_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
}
