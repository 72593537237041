import { disableApp, enableApp } from "../actions/App";

import { requestFacebookAuth, succeedRequestFacebookAuth } from "../actions/Facebook";
import { cursorNoneEvent } from "../settings/cursorWait";

export function fetchRequestFacebookAuth() {
  return dispatch => {
    cursorNoneEvent(true);
    dispatch(disableApp("facebook"));
    dispatch(requestFacebookAuth());
    window?.FB?.getLoginStatus(function (response) {
      if (response.status !== "connected") {
        dispatch(enableApp());
        window?.FB?.login(
          function (response) {
            response.authResponse && dispatch(succeedRequestFacebookAuth(response.authResponse.accessToken));
          },
          { scope: "email" }
        );
      } else {
        dispatch(succeedRequestFacebookAuth(response.authResponse.accessToken));
      }
      cursorNoneEvent(false);
    });
  };
}
