import React, { Component, createRef } from "react";
// eslint-disable-next-line no-unused-vars
import column from "./css/table-column.css";
import iconSet from "../../../../images/selection.json";
import IcomoonReact from "icomoon-react";

export default class TableColumn extends Component {
  constructor(props) {
    super(props);
    this.columnRef = createRef();
  }
  componentDidMount() {
    const { divEl } = this;
    const { column } = this.props;

    divEl && divEl.scrollWidth - divEl.clientWidth ? (divEl.title = column) : null;
  }

  render() {
    const { id, column, icon, handleSortTable, thStyle, filter, textStyle, iconStyle, thClass, onHeaderClick } =
      this.props;

    const setIcon = icon => {
      if (icon === "ASC") {
        return "column.title-up";
      }

      if (icon === "DESC") {
        return "column.title-down";
      }

      return "";
    };

    return (
      <th
        ref={this.columnRef}
        style={thStyle}
        styleName={`${setIcon(icon)} ${thClass ? thClass : "column.title"} ${
          handleSortTable ? "column.column-status" : ""
        } `}
        onClick={() => onHeaderClick && onHeaderClick(id)}
      >
        {column && (
          <div
            ref={node => {
              this.divEl = node;
            }}
            styleName={"column.content"}
          >
            <div style={textStyle} onClick={handleSortTable}>
              {column}
            </div>
            {filter && (
              <div style={iconStyle} onClick={filter.onFilter} styleName={"column.filter-block"}>
                <IcomoonReact
                  iconSet={iconSet}
                  color={filter.filter || filter.appliedFilter ? "#1E88E5" : "#616161"}
                  size={14}
                  icon={"filter"}
                />
              </div>
            )}
          </div>
        )}
        {filter && filter.filter && filter.component}
      </th>
    );
  }
}
