import reduceReducers from "reduce-reducers";
import { createThunkReducer } from "../../helpers/createThunkReducer";

const initialState = {
  ["queue"]: { status: "", data: { hasmore: false } },
  ["progress"]: { status: "", data: { hasmore: false } },
  ["done"]: { status: "", data: { hasmore: false } },
  ["priority"]: { status: "", data: { hasmore: false } },
  ["incomplete"]: { status: "", data: { hasmore: false } },
  ["draft"]: { status: "", data: { hasmore: false } },
  ["customer_draft"]: { status: "", data: { hasmore: false } },
  ["archived"]: { status: "", data: { hasmore: false } },
  ["queueOrdersStatus"]: { status: "", data: { hasmore: false } },
  ["order"]: { status: "", data: { hasmore: false, targetFile: "", sourceFile: "" } },
  ["unreadOrdersStatus"]: {},
};

const queueOrdersReducer = createThunkReducer("queue", "GET_QUEUE_ORDERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const priorityOrdersReducer = createThunkReducer("progress", "GET_PROGRESS_ORDERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const progressOrdersReducer = createThunkReducer("priority", "LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const draftOrdersAdminReducer = createThunkReducer("draft", "LIST_ORDERS_WITH_DRAFT_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const draftOrdersTranslatorReducer = createThunkReducer("draft", "LIST_ORDERS_WITH_DRAFT_TRANSLATOR", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});
const customerDraftOrdersReducer = createThunkReducer("customer_draft", "LIST_DRAFT_CUSTOMER", {
  handleSuccessAction: (state, payload) => {
    const ordersList = payload.draftsList.map(item => {
      return {
        ...item,
        id: item.draftId,
        advancedOptions: {
          ...item.advancedOptions,
          attachmentCount: item.attachmentCount,
          messageText: item.messageText,
        },
      };
    });
    return {
      requestState: {
        data: {
          ordersList,
          hasmore: payload.hasmore,
        },
      },
    };
  },
});
const adminDraftOrdersReducer = createThunkReducer("customer_draft", "LIST_DRAFT_ADMIN", {
  handleSuccessAction: (state, payload) => {
    const ordersList = payload.draftsList.map(item => {
      return {
        ...item,
        id: item.draftId,
        advancedOptions: {
          ...item.advancedOptions,
          attachmentCount: item.attachmentCount,
          messageText: item.messageText,
        },
      };
    });
    return {
      requestState: {
        data: {
          ordersList,
          hasmore: payload.hasmore,
        },
      },
    };
  },
});
const doneOrdersReducer = createThunkReducer("done", "GET_DONE_ORDERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const incompleteOrdersReducer = createThunkReducer("incomplete", "GET_INCOMPLETE_ORDERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const archivedOrdersReducer = createThunkReducer("archived", "GET_ARCHIVED_ORDERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const queueOrdersStatusReducer = createThunkReducer("queueOrdersStatus", "GET_ADMIN_QUEUE_STATUS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getOrderAdmin = createThunkReducer("order", "GET_ORDER_ADMIN", {
  handleSuccessAction: handleOrderSuccessAction,
});

const getOrderCustomer = createThunkReducer("order", "GET_ORDER_CUSTOMER", {
  handleSuccessAction: handleOrderSuccessAction,
});
function handleOrderSuccessAction(state, payload) {
  const originMessagesList = [...payload.comments.messagesList];
  const orderHistory = [...payload.comments.messagesList, ...payload.comments.statusesList];
  payload.comments.messagesList = orderHistory.sort((element1, element2) => {
    if (element1.time.seconds < element2.time.seconds) {
      return -1;
    }
    if (element1.time.seconds > element2.time.seconds) {
      return 1;
    }
    return 0;
  });
  payload.originMessagesList = originMessagesList;
  return { requestState: { data: payload } };
}

const getDraftAdmin = createThunkReducer("order", "GET_DRAFT_ADMIN", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        data: {
          order: payload.draft,
          comments: {
            messagesList: [
              {
                authorName: "",
                time: payload.draft.createdTime,
                attachmentsList: payload.attachmentsList,
                author: 2,
                isCoCreated: false,
                message: payload.draft.messageText,
              },
            ],
          },
        },
      },
    };
  },
});
const getDraftCustomer = createThunkReducer("order", "GET_DRAFT_CUSTOMER", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        data: {
          order: payload.draft,
          comments: {
            messagesList: [
              {
                authorName: "",
                time: payload.draft.createdTime,
                attachmentsList: payload.attachmentsList,
                author: 2,
                isCoCreated: false,
                message: payload.draft.messageText,
              },
            ],
          },
        },
      },
    };
  },
});
const getOrderNextPrevAdmin = createThunkReducer("order", "GET_NEXT_ADMIN_ORDER_NEW", {
  handleSuccessAction: handleOrderSuccessAction,
});

const getOrderNextPrevCustomer = createThunkReducer("order", "GET_NEXT_CUSTOMER_ORDER_NEW", {
  handleSuccessAction: handleOrderSuccessAction,
});

const getOrderNextPrevTranslator = createThunkReducer("order", "GET_NEXT_TRANSLATOR_ORDER_NEW", {
  handleSuccessAction: handleOrderSuccessAction,
});
const getOrderFile = createThunkReducer("order", "GET_ORDER_FILE", {
  handleSuccessAction: (state, payload) => {
    return {
      requestState: {
        ...state.order,
        data: {
          ...state.order.data,
          sourceFile: payload.sourceFileMetadata || "",
          targetFile: payload.targetFileMetadata || "",
        },
      },
    };
  },
});

const unreadOrdersStatusReducer = createThunkReducer("unreadOrdersStatus", "GET_UNREAD_STATUS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: payload };
  },
});

const updateAdvancedOptions = createThunkReducer("order", "UPDATE_ADVANCED_OPTIONS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const ordersReducer = reduceReducers(
  initialReducer,
  queueOrdersReducer,
  progressOrdersReducer,
  draftOrdersAdminReducer,
  draftOrdersTranslatorReducer,
  customerDraftOrdersReducer,
  doneOrdersReducer,
  incompleteOrdersReducer,
  queueOrdersStatusReducer,
  archivedOrdersReducer,
  getOrderAdmin,
  getOrderNextPrevAdmin,
  getOrderNextPrevCustomer,
  getOrderNextPrevTranslator,
  getOrderFile,
  getDraftCustomer,
  getDraftAdmin,
  adminDraftOrdersReducer,
  unreadOrdersStatusReducer,
  priorityOrdersReducer,
  updateAdvancedOptions,
  getOrderCustomer
);
