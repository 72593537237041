import React from "react";
import { HandySvg } from "handy-svg";
import dots from "../../../images/svg/dots.svg";
// eslint-disable-next-line no-unused-vars
import popover from "../DetailButton/css/popover.css";
// eslint-disable-next-line no-unused-vars
import message from "../ContextOrderMessages/css/message.css";

const OptionButton = React.forwardRef(({ divStyleName, svgStyleName, onClick }, ref) => {
  return (
    <div ref={ref} styleName={divStyleName} onClick={onClick}>
      <HandySvg src={dots} width={32} height={32} styleName={svgStyleName} />
    </div>
  );
});
export default OptionButton;
