import settingsDecimal from "./settingsDecimal";
import { I18n } from "react-redux-i18n";
const currencyOptions = currency => {
  const digits = currency === "JPY" ? 0 : 2;
  return {
    style: "currency",
    currency: currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
};
export const formatCurrency = ({ value, currency, showIsZero }) => {
  const currencyOptionsWithSymbol = currencyOptions(currency);
  const currencyOptionsWithOutSymbol = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const balanceNum = calculateSum(value);

  return I18n.l(
    balanceNum ? +balanceNum : showIsZero ? 0.0 : "",
    currency ? currencyOptionsWithSymbol : currencyOptionsWithOutSymbol
  );
};

export const calculateSum = value => {
  return (
    value &&
    (value.integer < 0 || value.decimal < 0
      ? -Math.abs(Number(`${value.integer}.${settingsDecimal(Math.abs(value.decimal))}`))
      : Number(`${value.integer}.${settingsDecimal(Math.abs(value.decimal))}`))
  );
};

export const formatNumAsCurrency = ({ value, currency, showIsZero }) => {
  const currencyOptionsWithSymbol = currencyOptions(currency);
  const currencyOptionsWithOutSymbol = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return I18n.l(
    value ? +value : showIsZero ? 0.0 : "",
    currency ? currencyOptionsWithSymbol : currencyOptionsWithOutSymbol
  );
};

export const formatCouponAsCurrency = ({ value, currency, showIsZero }) => {
  const currencyOptionsWithSymbol = currencyOptions(currency);

  currencyOptionsWithSymbol.minimumFractionDigits = 0;
  currencyOptionsWithSymbol.maximumFractionDigits = 0;

  const currencyOptionsWithOutSymbol = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return I18n.l(
    value ? +value : showIsZero ? 0.0 : "",
    currency ? currencyOptionsWithSymbol : currencyOptionsWithOutSymbol
  );
};
