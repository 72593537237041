import { I18n } from "react-redux-i18n";

const IMAGE_FILE_TYPE = "image/";

export const checkImage = (file, limitImageSize, width, height) => {
  if (!file.type.includes(IMAGE_FILE_TYPE)) {
    return I18n.t("ERRORS.IMAGE_UPLOAD_FORMAT");
  }

  if (file && file.size > limitImageSize) {
    return I18n.t("ERRORS.IMAGE_UPLOAD_FORMAT");
  }

  if (width < 48 && height < 48) {
    return I18n.t("ERRORS.IMAGE_UPLOAD_FORMAT");
  }

  return "";
};
