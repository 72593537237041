import PropTypes from "prop-types";
import React, { Component } from "react";
import "../css/deprecated/settings-section.css";
import Title from "./refactored/Title";

export default class SettingsSection extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    question: PropTypes.any,
  };

  static defaultProps = {
    question: false,
  };

  componentDidMount() {
    this.props.getRefTitle && this.props.getRefTitle(this.title);
  }

  render() {
    const {
      title,
      question,
      questionLink,
      titleColor,
      counter,
      toolbar,
      handlerDragLeave,
      handlerDragEnter,
      getRefTitle,
      getSidebarInfo,
      handleDrop,
      isShowPopover,
      id,
      rightTooltip,
      createReport,
      disableReport,
      handleReportRequest,
    } = this.props;
    const style = this.props.width ? { width: this.props.width } : {};
    return (
      <section
        onDragLeave={handlerDragLeave && handlerDragLeave}
        onDragOver={handlerDragEnter}
        onDragEnter={handlerDragEnter && handlerDragEnter}
        onDrop={handleDrop && handleDrop}
        style={style}
        ref={this.props.sectionRef}
        className="settings-section"
      >
        <div className={createReport ? "section-position" : ""}>
          <Title
            id={id}
            text={title}
            ref={sourceInput => (this.title = sourceInput)}
            getRefTitle={getRefTitle}
            getSidebarInfo={getSidebarInfo}
            titleColor={titleColor}
            question={question}
            questionLink={questionLink}
            counter={counter}
            isShowPopover={isShowPopover}
            toolbar={toolbar}
            rightTooltip={rightTooltip && rightTooltip}
          />
          {createReport && (
            <button disabled={disableReport} className="create-report" onClick={() => handleReportRequest(id)}>
              {createReport}
            </button>
          )}
        </div>

        {this.props.children}
      </section>
    );
  }
}
