import React from "react";
// eslint-disable-next-line no-unused-vars
import toolbar from "./css/loading.css";
import colorIconSet from "../../../images/color_icons.json";
import IcomoonReact from "icomoon-react";

export default function Loading(props) {
  let loaderClasses = "toolbar.load-more";
  if (props.className) {
    loaderClasses = `${loaderClasses} ${props.className}`;
  }
  return (
    <span styleName={loaderClasses}>
      <IcomoonReact
        iconSet={colorIconSet}
        size={props.size ? props.size : 24}
        color={props.color ? props.color : ""}
        icon={"spinner"}
      />
    </span>
  );
}
