import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import HtmlTranslate from "../../../../HtmlTranslate";
// eslint-disable-next-line no-unused-vars
import menu from "./../../css/side-menu.css";

export default class MenuItem extends Component {
  onClick = () => {
    const { scrollTo, setAnchor } = this.props;
    scrollTo && scrollTo(setAnchor);
  };

  render() {
    const { link, pageHeading, active } = this.props;

    return (
      <li styleName={active} onClick={this.onClick}>
        <NavLink to={link}>
          <HtmlTranslate value={pageHeading} />
        </NavLink>
      </li>
    );
  }
}
