import React, { useEffect, useState } from "react";
import Popup from "../../../components/elements/Popup";
import sheets from "./css/style-sheets.css";
import { I18n } from "react-redux-i18n";
import FormCheckbox from "../../../components/refactored/FormCheckbox";
import Loading from "../../../components/refactored/Loading";

const popupStyles = {
  stylePopup: { overflow: "scroll", padding: "120px 0 50px" },
  style: { bottom: "initial" },
  containerStyle: { overflowY: "initial" },
};

const checkboxStyles = {
  marginLeft: 15,
  marginBottom: 0,
  padding: "4px 0",
};

export default function CheckBoxPopup({
  isOpen,
  onClose,
  googleSheetsData,
  changeTableState,
  googleSheetsLink,
  handleUploadGoogleFile,
  googleSheetsDataFileName = "",
  googleDriveUrl,
  loadingView,
}) {
  let newData = "";

  if (googleSheetsData.elements) {
    console.log(JSON.parse(googleSheetsData.elements), "JSON.parse(googleSheetsData.elements");
    newData = JSON.parse(googleSheetsData.elements).map(elem => {
      return {
        ...elem,
        selected: true,
        partial: false,
        columns: elem.columns.map(col => {
          return {
            ...col,
            selected: true,
            label: `${I18n.t("SHEETS_POPUP.COLUMN_NAME", { column: col.name })}`,
            id: elem.name + col.name,
          };
        }),
      };
    });
  } else {
    newData = googleSheetsData;
  }
  const [sheetsData, setSheetsData] = useState(newData);
  const [currentChars, setCurrentChars] = useState(0);

  useEffect(() => {
    if (sheetsData.length > 0) {
      setCurrentChars(calculateSheetsCharNew(sheetsData));
    }
  }, [sheetsData]);

  const calculateSheetsCharNew = data => {
    let sum = 0;

    data.forEach(listItem => {
      let selectedMergedItems = [];
      let selectedUsed = [];
      listItem.columns.forEach(mainItem => {
        if (mainItem.selected && mainItem.mergedColumns.length > 0) {
          selectedMergedItems = [...selectedMergedItems, mainItem];
          sum += parseInt(mainItem.length);
          mainItem.mergedColumns.forEach(selectedMergedItem => {
            listItem.columns.forEach(validateItems => {
              if (
                listItem.name + selectedMergedItem.name === validateItems.id &&
                !validateItems.selected &&
                !selectedUsed.find(
                  elem => elem.name === selectedMergedItem.name && elem.numberRow === selectedMergedItem.numberRow
                )
              ) {
                sum += parseInt(selectedMergedItem.length);
                selectedUsed = [...selectedUsed, selectedMergedItem];
              }
            });
          });
        } else if (mainItem.selected) {
          sum += parseInt(mainItem.length);
        }
      });
    });
    return sum;
  };

  const handleChangeSheetsData = (columnName, status, listName) => {
    const curData = _.cloneDeep(sheetsData).map(elem => {
      let numberChecked = 0;
      if (elem.name === listName) {
        elem.columns = elem.columns.map(column => {
          if (elem.name + column.name === columnName) {
            column.selected = status;
          }
          column.selected && numberChecked++;
          return column;
        });
        const length = elem.columns.length;
        elem.selected = numberChecked === length;
        elem.partial = numberChecked > 0 && numberChecked < length;
      }
      return elem;
    });
    return setSheetsData(curData);
  };

  const handleSelectAllSheetsData = (listName, status) => {
    const curData = _.cloneDeep(sheetsData).map(elem => {
      if (elem.name === listName) {
        elem.selected = status;
        elem.partial = false;
        elem.columns = elem.columns.map(column => {
          return { ...column, selected: status };
        });
      }
      return elem;
    });
    return setSheetsData(curData);
  };

  const handleAcceptOrder = data => {
    changeTableState({
      sheetsData: data,
      googleSheetsLink: googleSheetsLink,
      googleDriveUrl: googleDriveUrl,
      googleSheetsDataFileName: googleSheetsDataFileName,
    });
    let result;
    result = data.map(elem => {
      if (elem.columns.map(column => column.selected === true)) {
        return {
          name: elem.name,
          values: elem.columns.map(column => column.selected === true && column.name).filter(elem => elem !== false),
        };
      }
    });
    return result.filter(elem => elem.values.length > 0);
  };

  const handleOrderRquest = () => {
    handleUploadGoogleFile(googleSheetsLink, handleAcceptOrder(sheetsData), googleDriveUrl);
  };

  const selectAll = (listName, status) => {
    const curData = _.cloneDeep(sheetsData).map(elem => {
      elem.selected = !status;
      elem.partial = false;
      elem.columns.forEach(column => (column.selected = !status));
      return elem;
    });
    return setSheetsData(curData);
  };

  const selectedCount = sheetsData.reduce((prev, item) => (item.selected ? ++prev : prev), 0);
  const partialCount = selectedCount === 0 && sheetsData.reduce((prev, item) => (item.partial ? ++prev : prev), 0);

  const isPartial = (selectedCount > 0 && selectedCount < sheetsData.length) || partialCount > 0;
  const isAll = selectedCount === sheetsData.length;

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      className={sheets.container}
      {...popupStyles}
      isHeader={
        <div styleName="sheets.wrapper">
          <p styleName="sheets.title">{I18n.t("SHEETS_POPUP.TITLE")}</p>
          <p styleName="sheets.text">{I18n.t("SHEETS_POPUP.MAIN_TEXT")}</p>
          <p styleName="sheets.file-name">{googleSheetsDataFileName}</p>
        </div>
      }
      isFooter={
        <div styleName="sheets.wrapper" style={{ paddingBottom: "24px" }}>
          <div styleName="sheets.total-chars">{I18n.t("SHEETS_POPUP.CURRENT_CHARS", { chars: currentChars })}</div>
          <div styleName="sheets.buttons-list">
            <button onClick={handleOrderRquest} styleName="sheets.buttons-submit">
              {loadingView ? <Loading display={true} size={16} color={"white"} /> : I18n.t("SHEETS_POPUP.EDIT")}
            </button>
            <button onClick={onClose} styleName="sheets.buttons-cancel">
              {I18n.t("SHEETS_POPUP.CANCEL")}
            </button>
          </div>
        </div>
      }
      isGoogleSheetContainer={true}
    >
      <ul styleName="sheets.checkbox-list">
        <li styleName="sheets.checkbox-item">
          {
            <FormCheckbox
              id={"selectAll"}
              label={<span className={sheets.checkboxLabel}>{I18n.t("SHEETS_POPUP.SELECT_ALL")}</span>}
              value={isPartial ? "intermediate" : isAll}
              onChange={e => selectAll(e, isAll)}
              containerStyle={checkboxStyles}
            />
          }
        </li>
        {sheetsData.map(listItem => {
          const selectedItemsCount = listItem.columns.reduce((prev, item) => (item.selected ? ++prev : prev), 0);
          const itemsCount = listItem.columns.length;

          const isPartialSelected = selectedItemsCount > 0 && selectedItemsCount < itemsCount;
          const isAllSelected = selectedItemsCount === itemsCount;

          return (
            <CheckBoxList
              onClick={handleChangeSheetsData}
              onSelectAll={handleSelectAllSheetsData}
              list={listItem.columns}
              listLabel={listItem.name}
              key={listItem.name}
              parentItem={true}
              isAllSelected={isAllSelected}
              isPartialSelected={isPartialSelected}
            />
          );
        })}
      </ul>
    </Popup>
  );
}

export const CheckBoxList = ({
  list,
  listLabel,
  onClick,
  onSelectAll,
  parentItem,
  isPartialSelected,
  isAllSelected,
  itemContainerStyles,
}) => {
  const handleClick = e => {
    const { id, checked } = e.target;
    onClick(id, checked, listLabel);
  };

  const handleSelectAll = e => {
    const { checked } = e.target;
    onSelectAll(listLabel, checked);
  };

  const containerStyles = {
    ...checkboxStyles,
    ...itemContainerStyles?.container,
  };

  return (
    <li styleName="sheets.checkbox-item">
      <div styleName="sheets.column-list" style={parentItem ? { marginLeft: 29 } : {}}>
        {parentItem && (
          <FormCheckbox
            id={listLabel}
            label={<span className={sheets.checkboxLabel}>{listLabel}</span>}
            value={isPartialSelected ? "intermediate" : isAllSelected}
            onChange={handleSelectAll}
            containerStyle={checkboxStyles}
          />
        )}

        <div styleName="sheets.column-list" style={parentItem ? { marginLeft: 29 } : {}}>
          {list.map(listItem => {
            return (
              <FormCheckbox
                id={listItem.id}
                label={
                  <span className={sheets.checkboxLabel} style={itemContainerStyles?.label}>
                    {listItem.label}
                  </span>
                }
                value={listItem.selected}
                onChange={handleClick}
                containerStyle={containerStyles}
              />
            );
          })}
        </div>
      </div>
    </li>
  );
};

export const CheckBoxItem = ({ onClick, id, isChecked, label, checkboxClass = "", itemContainerStyles }) => {
  return (
    <label styleName="sheets.checkbox-item-label" style={itemContainerStyles}>
      <input id={id} onChange={onClick} checked={isChecked} styleName="sheets.checkbox-item-input" type="checkbox" />
      <span styleName={"sheets.checkbox-item-text" + checkboxClass}>
        <span styleName="sheets.checkbox-item-text-width">{label}</span>
      </span>
    </label>
  );
};
