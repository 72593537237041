import React from "react";
import { Button } from "react-bootstrap";
import classnames from "classnames";
// eslint-disable-next-line no-unused-vars
import leaveBtn from "../../css/deprecated/leave-btn.css";

export default function LeaveBtn(props) {
  let classes = classnames("leave-btn", props.className);
  return (
    <Button onClick={props.onClick} className={classes} style={props.style}>
      {props.text}
    </Button>
  );
}
