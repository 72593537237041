import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import bar from "./css/toolbar.css";
import DetailButton from "../DetailButton/DetailButton";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";

export default function ToolBar(props) {
  const {
    resetSelected,
    text,
    detail,
    handleDelete,
    titleBasket,
    textIcon,
    containerStyle,
    containerStyleName = "bar.container",
    basketStyle,
    isSelect,
    iconBtns,
  } = props;
  const nonSelect = !isSelect ? "non-select-container" : "";

  return (
    <Fragment>
      {isSelect && (
        <div style={containerStyle} styleName={`${containerStyleName} ${nonSelect}`}>
          <div styleName={"bar.exit-container"} onClick={resetSelected}>
            <IcomoonReact iconSet={iconSet} color="#424242" size={14} icon={"exit"} />
          </div>
          <div styleName={"bar.text-container"}>
            <div styleName={"bar.text"}>{text}</div>
          </div>
          {handleDelete && (
            <div styleName={"bar.basket-container"} onClick={handleDelete} title={titleBasket}>
              <IcomoonReact iconSet={iconSet} color={basketStyle ? "#BDBDBD" : "#424242"} size={20} icon={"trash"} />
            </div>
          )}
          {textIcon && textIcon}
          {iconBtns &&
            iconBtns.length > 0 &&
            iconBtns.map(item => {
              return item?.component ? (
                item.component({ key: item.icon })
              ) : (
                <Fragment key={item.icon}>
                  <button
                    styleName={"bar.btn-container"}
                    onClick={item.method}
                    disabled={item.disabled}
                    title={item.title}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      color={item.disabled ? "#BDBDBD" : "#424242"}
                      size={20}
                      icon={item.icon}
                    />
                  </button>
                </Fragment>
              );
            })}
          {detail && <DetailButton detail={detail} isSelect={isSelect} popoverName={"tableMenu"} />}
        </div>
      )}
    </Fragment>
  );
}
