import { CustomerRatesTypes, } from "./customerRateActionTypes";
export function requestDeleteCustomerRates() {
    return {
        type: CustomerRatesTypes.DELETE_CUSTOMER_RATES_REQUEST,
    };
}
export function successDeleteCustomerRates(payload) {
    return {
        type: CustomerRatesTypes.DELETE_CUSTOMER_RATES_SUCCESS,
        payload: payload,
    };
}
export function requestUpdateCustomerRate() {
    return {
        type: CustomerRatesTypes.UPDATE_CUSTOMER_RATE_REQUEST,
    };
}
export function successUpdateCustomerRate(payload) {
    return {
        type: CustomerRatesTypes.UPDATE_CUSTOMER_RATE_SUCCESS,
        payload: payload,
    };
}
export function requestGetCustomerRateById() {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATE_BY_ID_REQUEST,
    };
}
export function successGetCustomerRateById(payload) {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATE_BY_ID_SUCCESS,
        payload: payload,
    };
}
export function requestCreateCustomerRate() {
    return {
        type: CustomerRatesTypes.CREATE_CUSTOMER_RATE_REQUEST,
    };
}
export function successCreateCustomerRate(payload) {
    return {
        type: CustomerRatesTypes.CREATE_CUSTOMER_RATE_SUCCESS,
        payload: payload,
    };
}
export function requestGetCustomerListRates() {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATES_LIST_REQUEST,
    };
}
export function successGetCustomerListRates(payload) {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATES_LIST_SUCCESS,
        payload: payload,
    };
}
export function requestAllGetCustomerListRates() {
    return {
        type: CustomerRatesTypes.GET_ALL_CUSTOMER_RATES_REQUEST,
    };
}
export function successAllGetCustomerListRates(payload) {
    return {
        type: CustomerRatesTypes.GET_ALL_CUSTOMER_RATES_SUCCESS,
        payload: payload,
    };
}
export function requestGetCustomerRates() {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATES_REQUEST,
    };
}
export function successGetCustomerRates(payload) {
    return {
        type: CustomerRatesTypes.GET_CUSTOMER_RATES_SUCCESS,
        payload: payload,
    };
}
export function requestGetLanguagePairs() {
    return {
        type: CustomerRatesTypes.GET_LANGUAGE_PAIRS_REQUEST,
    };
}
export function successGetLanguagePairs(payload) {
    return {
        type: CustomerRatesTypes.GET_LANGUAGE_PAIRS_SUCCESS,
        payload: payload,
    };
}
