import reduceReducers from "reduce-reducers";

const initialState = {
  search: "",
  scrollPosition: {
    x: 0,
    y: 0,
  },
  filter: {
    languages: [],
  },
  loadLimitMemories: 20,
  offset: 0,
};

function translationMemories(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_TRANSLATION_MEMORIES_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
}

export const translationMemoryReducer = reduceReducers(translationMemories);
