import { I18n } from "react-redux-i18n";

export const filesColumns = ({
  onCellClick,
  docsType,
  onClickEditFile,
  mainCharsCounter,
  subCharsCounter,
  charsCounter,
  isDraft,
}) => {
  return [
    {
      id: "filename",
      name: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.FILES_TABLE.COLUMNS.FILE"),
      style: { cursor: "default" },
      thStyle: {
        paddingLeft: 0,
      },
      tdStyle: () => {
        return { cursor: "default" };
      },
      optionStyleCell: () => {
        return {
          paddingTop: 8,
          paddingLeft: 0,
        };
      },
      onClickEditFile: onClickEditFile,
      mainCharsCounter: mainCharsCounter,
      subCharsCounter: subCharsCounter,
      charsCounter: charsCounter,
      type: docsType,
      draft: isDraft,
    },
    {
      id: "deleteIcon",
      name: "",
      thStyle: {
        maxWidth: 40,
        width: 40,
        height: 20,
        alignItems: "center",
        justifyItems: "center",
      },
      style: { maxWidth: 40, width: 40 },
      tdStyle: () => {
        return {
          maxWidth: 40,
          width: 40,
          //verticalAlign: "middle",
          display: "flex",
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: "auto",
        };
      },
      optionStyleCell: () => {
        return {
          display: "flex",
          width: 40,
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: 10,
          paddingRight: 0,
        };
      },
      onCellClick,
    },
  ];
};
