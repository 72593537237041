// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box__container__kuYWb8 {
  height: 32px;
  width: 199px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /*border: 1px solid grey;*/
  border: 1px solid #e3f2fd;
  border-right: none;
  background: white;
  margin-bottom: -1px;
  margin-left: -1px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.box__container__kuYWb8:hover {
    background: #e3f2fd;
  }

.box__container__kuYWb8 .box__iconIsRecentGrey__OEn7wr {
    height: 22px;
    width: 17px;
    min-width: 17px;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.box__container__kuYWb8:hover .box__iconIsRecentGrey__OEn7wr {
      height: 22px;
      width: 17px;
      min-width: 17px;
      margin-bottom: 4px;
      margin-top: 4px;
      margin-left: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

.box__container__kuYWb8 .box__iconIsRecentBlack__NQ6SWp {
    height: 22px;
    width: 17px;
    min-width: 17px;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.box__leftEmptyBlock__rvDbqX {
  height: 22px;
  width: 17px;
  min-width: 17px;
  margin: 4px -24px 4px 7px;
}

.box__textPopover__EFj7Q6 {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

.box__containerDisabled__UbvRDH {
  height: 32px;
  width: 199px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3f2fd;
  border-right: none;
  background: white;
  margin-bottom: -1px;
  margin-left: -1px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.box__containerDisabled__UbvRDH .box__iconIsRecentGrey__OEn7wr {
    height: 22px;
    width: 17px;
    min-width: 17px;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.box__label__Wk4EAN {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #212121;
  padding: 6px 8px;
  width: 99%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.box__icon__ZlJ6o0 {
}

.box__iconIsChecked__yOYFzI {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box__checkedDisabledIconContainer__eBII92 {
  width: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border: none;
}

.box__padding__Gw1Xz1 {
  background: #e3f2fd;
  width: 1px;
  height: 32px;
  margin-right: -0.5px;
}

@keyframes box__bloom__chgxd0 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .box__container__kuYWb8 {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #e3f2fd;
    margin-bottom: -1px;
    margin-right: -1px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .box__containerDisabled__UbvRDH {
    border: 1px solid #e3f2fd;
  }

  .box__padding__Gw1Xz1 {
    background: none;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `box__container__kuYWb8`,
	"iconIsRecentGrey": `box__iconIsRecentGrey__OEn7wr`,
	"iconIsRecentBlack": `box__iconIsRecentBlack__NQ6SWp`,
	"leftEmptyBlock": `box__leftEmptyBlock__rvDbqX`,
	"textPopover": `box__textPopover__EFj7Q6`,
	"containerDisabled": `box__containerDisabled__UbvRDH`,
	"label": `box__label__Wk4EAN`,
	"icon": `box__icon__ZlJ6o0`,
	"iconIsChecked": `box__iconIsChecked__yOYFzI`,
	"checkedDisabledIconContainer": `box__checkedDisabledIconContainer__eBII92`,
	"padding": `box__padding__Gw1Xz1`,
	"bloom": `box__bloom__chgxd0`
};
export default ___CSS_LOADER_EXPORT___;
