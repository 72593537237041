// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__container__vQU8xk {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer__content__BbUfN4 {
  margin: auto;
}

@media screen and (max-width: 540px) {
  .footer__content__BbUfN4 {
    margin-top: 35px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `footer__container__vQU8xk`,
	"content": `footer__content__BbUfN4`
};
export default ___CSS_LOADER_EXPORT___;
