// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leave-btn {
  padding: 7px 16px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-weight: 300;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 18px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.leave-btn:focus,
  .leave-btn:hover,
  .leave-btn:active:hover,
  .leave-btn:active,
  .leave-btn:active:focus {
    color: #9e9e9e;
    background-color: transparent;
    border: none;
    box-shadow: none;
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
.leave-btn:hover {
    color: #616161;
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
.leave-btn:active {
    outline: none !important;
  }
.leave-btn.settings-btn {
    margin-top: 24px;
  }
`, "",{"version":3,"sources":["webpack://./src/css/deprecated/leave-btn.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kCAA0B;EAA1B,0BAA0B;AAsB5B;AArBE;;;;;IAKE,cAAc;IACd,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;IAChB,kCAA0B;IAA1B,0BAA0B;EAC5B;AACA;IACE,cAAc;IACd,kCAA0B;IAA1B,0BAA0B;EAC5B;AACA;IACE,wBAAwB;EAC1B;AACA;IACE,gBAAgB;EAClB","sourcesContent":[".leave-btn {\r\n  padding: 7px 16px;\r\n  border-radius: 4px;\r\n  background-color: transparent;\r\n  border: none;\r\n  font-family: Roboto;\r\n  font-weight: 300;\r\n  color: #9e9e9e;\r\n  font-size: 14px;\r\n  line-height: 18px;\r\n  text-decoration: underline;\r\n  &:focus,\r\n  &:hover,\r\n  &:active:hover,\r\n  &:active,\r\n  &:active:focus {\r\n    color: #9e9e9e;\r\n    background-color: transparent;\r\n    border: none;\r\n    box-shadow: none;\r\n    text-decoration: underline;\r\n  }\r\n  &:hover {\r\n    color: #616161;\r\n    text-decoration: underline;\r\n  }\r\n  &:active {\r\n    outline: none !important;\r\n  }\r\n  &.settings-btn {\r\n    margin-top: 24px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
