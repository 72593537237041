import { I18n } from "react-redux-i18n";
import { NEW_SOURCE_VERSION } from "../constants/Notifications";
import { useClearCache } from "../helpers/reactClearCache";

const ClearCache = props => {
  const { showNewSourceVersion, notification, isFirstLoading, setFirstLoading } = props;
  const propsObj = {
    jsonPathFrom: __dirname + "static/meta.json",
    isFirstLoading: isFirstLoading,
    setFirstLoading: setFirstLoading,
  };
  const { isLatestVersion } = useClearCache(propsObj);

  !isLatestVersion &&
    notification.notificationType !== NEW_SOURCE_VERSION &&
    showNewSourceVersion(I18n.t("ERRORS.CLEAR_CACHE_REFRESH"));

  return null;
};

export default ClearCache;
