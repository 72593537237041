var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { CouponServiceClient } from "@alconost/nitro-services-api/src/proto/coupon_service_grpc_web_pb";
import { GenerateCouponsRequest, CouponCode, CouponId, ListCouponsRequest, ListCouponUsersRequest, UpdateCouponRequest, UpdateBlockedRequest, DeleteCouponRequest, SortCouponUserField, } from "@alconost/nitro-services-api/src/proto/coupon_service_pb";
import { Page, SortCouponField, SortOrder } from "@alconost/nitro-services-api/src/proto/base_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
var policyEnum = {
    UNKNOWN_POLICY: 0,
    SINGLE: 1,
    MULTI: 2,
    MULTI_NEW_ACCOUNTS: 3,
    SINGLE_NEW_ACCOUNTS: 4,
};
export var getCouponsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, getListCouponsRequest, page;
    var offset = _b.offset, limit = _b.limit, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                getListCouponsRequest = new ListCouponsRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                getListCouponsRequest.setPage(page);
                getListCouponsRequest.setSearch(search);
                getListCouponsRequest.setField(SortCouponField[sortField]);
                getListCouponsRequest.setOrder(SortOrder[sortOrder]);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "listCoupons",
                        protoRequest: getListCouponsRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getListCouponUsersRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, listCouponUsersRequest, page;
    var id = _b.id, offset = _b.offset, limit = _b.limit, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                listCouponUsersRequest = new ListCouponUsersRequest();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                listCouponUsersRequest.setPage(page);
                listCouponUsersRequest.setId(id);
                listCouponUsersRequest.setField(SortCouponUserField[sortField]);
                listCouponUsersRequest.setOrder(SortOrder[sortOrder]);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "listCouponUsers",
                        protoRequest: listCouponUsersRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteCouponRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, deleteCouponRequest;
    var ids = _b.ids, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                deleteCouponRequest = new DeleteCouponRequest();
                deleteCouponRequest.setIdsList(ids);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "deleteCoupon",
                        protoRequest: deleteCouponRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var blockCouponRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, updateBlockedRequest;
    var ids = _b.ids, isBlocked = _b.isBlocked, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                updateBlockedRequest = new UpdateBlockedRequest();
                updateBlockedRequest.setIdsList(ids);
                updateBlockedRequest.setIsBlocked(isBlocked);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "updateBlocked",
                        protoRequest: updateBlockedRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateCouponRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, updateCouponRequest, timestampExpired, time;
    var id = _b.id, amount = _b.amount, code = _b.code, description = _b.description, expired = _b.expired, policy = _b.policy, blocked = _b.blocked, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                updateCouponRequest = new UpdateCouponRequest();
                timestampExpired = new Timestamp();
                time = new Date(expired);
                timestampExpired.fromDate(time);
                updateCouponRequest.setId(id);
                updateCouponRequest.setAmount(amount);
                updateCouponRequest.setCode(code);
                updateCouponRequest.setName(description);
                updateCouponRequest.setExpires(timestampExpired);
                updateCouponRequest.setPolicy(policyEnum[policy]);
                updateCouponRequest.setBlocked(blocked);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "updateCoupon",
                        protoRequest: updateCouponRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCouponByIdRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, getCouponRequest;
    var id = _b.id, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                getCouponRequest = new CouponId();
                getCouponRequest.setId(id);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "getCoupon",
                        protoRequest: getCouponRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getCouponInfoRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, request;
    var code = _b.code, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                request = new CouponCode();
                request.setCode(code);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "getInfo",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var generateCouponsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var metadata, generateCouponsRequest, timestampExpired, time;
    var description = _b.description, amount = _b.amount, policy = _b.policy, quantity = _b.quantity, expired = _b.expired, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                generateCouponsRequest = new GenerateCouponsRequest();
                timestampExpired = new Timestamp();
                time = new Date(expired);
                timestampExpired.fromDate(time);
                generateCouponsRequest.setDetails(description);
                generateCouponsRequest.setAmount(amount);
                generateCouponsRequest.setPolicy(policyEnum[policy]);
                generateCouponsRequest.setCount(quantity);
                generateCouponsRequest.setExpires(timestampExpired);
                return [2 /*return*/, grpcRequest({
                        clientService: CouponServiceClient,
                        methodName: "generateCoupons",
                        protoRequest: generateCouponsRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
