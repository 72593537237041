import * as types from "../constants/Signin";

export function validateFacebookAuth() {
  return {
    type: types.VALIDATE_FACEBOOK_AUTH,
  };
}

export function succeedValidateFacebookAuth(token, refreshToken, created = false) {
  return {
    type: types.SUCCEED_VALIDATE_FACEBOOK_AUTH,
    token,
    refreshToken,
    created,
  };
}

export function failValidateFacebookAuth(error) {
  return {
    type: types.FAIL_VALIDATE_FACEBOOK_AUTH,
    error,
  };
}

export function validateGoogleAuth() {
  return {
    type: types.VALIDATE_GOOGLE_AUTH,
  };
}

export function succeedValidateGoogleAuth(token, refreshToken, created = false) {
  return {
    type: types.SUCCEED_VALIDATE_GOOGLE_AUTH,
    token,
    refreshToken,
    created,
  };
}

export function failValidateGoogleAuth(error) {
  return {
    type: types.FAIL_VALIDATE_GOOGLE_AUTH,
    error,
  };
}

export function fallbackToEmailSignup(error, name) {
  return {
    type: types.FALLBACK_TO_EMAIL_SIGNUP,
    error,
    name,
  };
}

export function requestAuth() {
  return {
    type: types.REQUEST_AUTH,
  };
}

export function succeedAuth(token, refreshToken) {
  return {
    type: types.SUCCEED_AUTH,
    token,
    refreshToken,
  };
}

export function failAuth(error) {
  return {
    type: types.FAIL_AUTH,
    error,
  };
}

export function unconfirmedAuth() {
  return {
    type: types.UNCONFIRMED_AUTH,
  };
}

export function initAuth() {
  return {
    type: types.INIT_AUTH,
  };
}
