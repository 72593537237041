var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var formattedNumberWithSpaces = function (x) {
    if (x === void 0) { x = ""; }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
var getIsSelectedFlag = function (element, flag) {
    if (flag !== undefined)
        return flag;
    if ("isSelected" in element)
        return element.isSelected;
    return true;
};
export var getElementWithUpdatedIsSelectFlag = function (elements, flag) {
    return elements.map(function (element) {
        return __assign(__assign({}, element), { isSelected: getIsSelectedFlag(element, flag) });
    });
};
export var getAmountOfSelectedElements = function (elements) {
    return elements.reduce(function (acc, element) { return (element.isSelected ? ++acc : acc); }, 0);
};
export var getTotalOfCharsInSelectedElements = function (elements, shouldTranslateFirstRow) {
    if (shouldTranslateFirstRow === void 0) { shouldTranslateFirstRow = true; }
    return elements.reduce(function (acc, element) {
        if (!element.isSelected)
            return acc;
        if (shouldTranslateFirstRow)
            return acc + element.countChars;
        return acc + element.countChars - element.countCharsOnFirstRow;
    }, 0);
};
export var getSelectedValues = function (elements) {
    return elements.reduce(function (acc, element) { return (element.isSelected ? __spreadArray(__spreadArray([], acc, true), [element.nameColumn], false) : acc); }, []);
};
export var getValueForSelectAllCheckbox = function (elements) {
    var selectAllValue = false;
    if (!elements.length) {
        return selectAllValue;
    }
    var amountOfSelected = getAmountOfSelectedElements(elements);
    var isEvery = amountOfSelected === elements.length;
    var isIntermediate = !isEvery && amountOfSelected !== 0;
    if (isEvery)
        selectAllValue = true;
    if (isIntermediate)
        selectAllValue = "intermediate";
    return selectAllValue;
};
