import { applyMiddleware, compose, createStore } from "redux";
import { persistState } from "@redux-devtools/core";
import { thunk } from "redux-thunk";
import { adminReducer, formsReducer, rolesReducer, translatorReducer } from "../reducers";
import { customerReducer } from "../reducers/index";

const enhancer = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  persistState(window.location.href.match(/[?&]debug_session=([^&#]+)\b/))
);

export function configureRolesStore() {
  return createStore(rolesReducer, enhancer);
}

export function configureFormsStore() {
  return createStore(formsReducer, enhancer);
}

export function configureTranslatorStore() {
  return createStore(translatorReducer, enhancer);
}

export function configureAdminStore() {
  return createStore(adminReducer, enhancer);
}

export function configureCustomerStore() {
  return createStore(customerReducer, enhancer);
}
