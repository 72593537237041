import reduceReducers from "reduce-reducers";
import { createThunkReducer } from "../../helpers/createThunkReducer";

const initialState = {
  tableState: {
    search: "",
    termsLimit: 0,
    scrollPosition: {
      x: 0,
      y: 0,
    },
  },
  ["orderGlossary"]: { status: "", data: { hasmore: false } },
};

const getOrderGlossary = createThunkReducer("orderGlossary", "GET_ORDER_GLOSSARY", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function glossary(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_ORDER_GLOSSARY_TABLE_STATE":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
}

export const translatorGlossaryReducer = reduceReducers(glossary, getOrderGlossary);
