// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-image-button__container__U4r2Ar {
  display: flex;
  align-items: center;
  text-align: center;
}

.add-image-button__imageLabel__QEISjJ {
  display: flex;
  height: 32px;
  cursor: pointer;
  align-items: center;
  margin: 0 0 -4px;
}

.add-image-button__imageLabel__QEISjJ:hover,
  .add-image-button__imageLabel__QEISjJ:active:hover,
  .add-image-button__imageLabel__QEISjJ:active,
  .add-image-button__imageLabel__QEISjJ:active:focus {
    outline: none !important;
  }

.add-image-button__text__kvJOti {
  color: #1e88e5;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 300;
  margin-right: 8px;
}

.add-image-button__icon__wHxEZA {
  margin-left: 6px;
  color: rgb(84, 84, 84);
}

.add-image-button__icon__wHxEZA:hover {
  color: #1e88e5;
}

.add-image-button__iconPlus__yk69Ke {
  height: 8px;
  width: 8px;
  margin-right: 8px;
}

.add-image-button__fileInput__CO5JCs {
  display: none !important;
  outline: none !important;
}

.add-image-button__fileInput__CO5JCs:active {
  display: none !important;
  outline: none !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `add-image-button__container__U4r2Ar`,
	"imageLabel": `add-image-button__imageLabel__QEISjJ`,
	"text": `add-image-button__text__kvJOti`,
	"icon": `add-image-button__icon__wHxEZA`,
	"iconPlus": `add-image-button__iconPlus__yk69Ke`,
	"fileInput": `add-image-button__fileInput__CO5JCs`
};
export default ___CSS_LOADER_EXPORT___;
