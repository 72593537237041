import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classnames from "classnames";
// eslint-disable-next-line no-unused-vars
import popup from "../../css/deprecated/popup.css";
import { Button } from "react-bootstrap";

export default class Popup extends Component {
  constructor(props) {
    super(props);
    this.popupRef = React.createRef();
    this.contentRef = React.createRef();
    this.headerRef = React.createRef();
    this.footerRef = React.createRef();

    this.state = {
      isResponsive: false,
    };
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  static propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    popupTitle: PropTypes.string,
    isHeader: PropTypes.element,
    isFooter: PropTypes.element,
    isGoogleSheetContainer: PropTypes.bool,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeydown);
    if (
      this.popupRef.current &&
      this.contentRef.current &&
      this.contentRef.current.clientHeight + 140 >= this.popupRef.current.clientHeight
    ) {
      this.setState({
        isResponsive: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.isOpen && !this.props.isOpen) || (prevProps.isOpen && this.props.isOpen)) {
      document.getElementById("app-container").style.overflowY = "visible";
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.popup.focus();
    }
    if (
      this.popupRef.current &&
      this.contentRef.current &&
      this.contentRef.current.clientHeight + 140 >= this.popupRef.current.clientHeight &&
      !this.state.isResponsive
    ) {
      this.setState({
        isResponsive: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.props.outsideClickCallBack &&
      this.contentRef &&
      this.contentRef.current &&
      !this.contentRef.current.contains(event.target)
    ) {
      this.props.outsideClickCallBack();
    }
  };

  handleKeydown = e => {
    if (e.key === "Escape" && this.props.isOpen) {
      this.props.onClose();
    }
  };
  getScrollHeight = () => {
    if (
      this.headerRef.current &&
      this.headerRef.current.clientHeight &&
      this.footerRef.current &&
      this.footerRef.current.clientHeight
    ) {
      return this.headerRef.current.clientHeight + this.footerRef.current.clientHeight + 20;
    } else if (this.headerRef.current && this.headerRef.current.clientHeight) {
      return this.headerRef.current.clientHeight + 20;
    }
  };

  render() {
    const container = document.getElementById("app-container");
    const { isResponsive } = this.state;
    let popupInnerClasses = classnames("popup__inner", this.props.className);
    if (!this.props.isOpen) {
      return null;
    }
    return ReactDOM.createPortal(
      <div className="popup" style={this.props.stylePopup} ref={this.popupRef}>
        <div
          className={popupInnerClasses + (isResponsive ? " popup__responsive" : "")}
          style={this.props.style}
          ref={this.contentRef}
        >
          <Button
            style={{
              overflow: "hidden",
              clip: "rect(0 0 0 0)",
              position: "absolute",
              width: "1px",
              height: "1px",
              margin: "-1px",
              padding: "0",
              border: "0",
              whiteSpace: "nowrap",
              clipPath: "inset(100%)",
            }}
            ref={popup => (this.popup = popup)}
          />
          {this.props.isHeader && (
            <div className="popup__header" ref={this.headerRef}>
              <button onClick={this.props.onClose} className="button-close" />
              {this.props.isHeader}
            </div>
          )}
          <div
            className={`popup__container ${this.props.isGoogleSheetContainer ? "popup__container-border" : ""} ${
              this.props.hideContainerScroll ? "popup__container__noScroll" : ""
            }`}
            style={{ height: `calc(100% - ${this.getScrollHeight()}px)`, ...(this.props.containerStyle || {}) }}
          >
            {this.props.children}
          </div>
          {this.props.isFooter && <div ref={this.footerRef}>{this.props.isFooter}</div>}
        </div>
      </div>,
      container
    );
  }
}
