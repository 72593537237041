import { SigninStatus } from "@alconost/nitro-services-api/src/proto/signin_service_pb";
import { emailRequest, facebookRequest, googleRequest } from "../../../services/SigninServices/requests";
import {
  failValidateFacebookAuth,
  failValidateGoogleAuth,
  fallbackToEmailSignup,
  succeedAuth,
  succeedValidateFacebookAuth,
  succeedValidateGoogleAuth,
  unconfirmedAuth,
  validateFacebookAuth,
  validateGoogleAuth,
} from "../../../actions/Signin";
import { saveForm } from "../../../actions/Signup";
import { requestEmail, successEmail } from "./signinActionCreators";
import { I18n } from "react-redux-i18n";
import { disableApp, enableApp } from "../../../actions/App";

export const googleAction =
  ({ serviceToken, locale, coupon }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    dispatch(disableApp("google"));
    dispatch(validateGoogleAuth());
    const data = await googleRequest({ serviceToken, locale, coupon, token });
    const serviceMessage = data.status;
    if (serviceMessage === SigninStatus.OK) {
      dispatch(succeedValidateGoogleAuth(data.token, data.refreshToken));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.CREATED) {
      dispatch(succeedValidateGoogleAuth(data.token, data.refreshToken, true));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.EMAIL_NOT_EXISTS) {
      let formError = {
        code: serviceMessage,
        message: I18n._translate("SIGNUP_PAGE.ERRORS.NO_SOCIAL_EMAIL"),
      };
      let name = data.name;
      dispatch(fallbackToEmailSignup(formError, name));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.INVALID_TOKEN) {
      let formError = {
        code: serviceMessage,
        message: I18n._translate("ERRORS.COMMON_REFRESH"),
      };
      dispatch(failValidateGoogleAuth(formError));
      dispatch(enableApp());
      return data;
    }
    let formError = {
      code: serviceMessage,
      message: I18n._translate("ERRORS.COMMON"),
    };
    dispatch(failValidateGoogleAuth(formError));
    dispatch(enableApp());
    return data;
  };

export const facebookAction =
  ({ serviceToken, locale, coupon }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    dispatch(disableApp("facebook"));
    dispatch(validateFacebookAuth());
    const data = await facebookRequest({ serviceToken, locale, coupon, token });
    const serviceMessage = data.status;
    if (serviceMessage === SigninStatus.OK) {
      dispatch(succeedValidateFacebookAuth(data.token, data.refreshToken));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.CREATED) {
      dispatch(succeedValidateFacebookAuth(data.token, data.refreshToken, true));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.EMAIL_NOT_EXISTS) {
      let formError = {
        code: serviceMessage,
        message: I18n._translate("SIGNUP_PAGE.ERRORS.NO_SOCIAL_EMAIL"),
      };
      dispatch(fallbackToEmailSignup(formError));
      dispatch(enableApp());
      return data;
    }
    if (serviceMessage === SigninStatus.INVALID_TOKEN) {
      let formError = {
        code: serviceMessage,
        message: I18n._translate("ERRORS.COMMON_REFRESH"),
      };
      dispatch(failValidateFacebookAuth(formError));
      dispatch(enableApp());
      return data;
    }
    let formError = {
      code: serviceMessage,
      message: I18n._translate("ERRORS.COMMON"),
    };
    dispatch(failValidateFacebookAuth(formError));
    dispatch(enableApp());
    return data;
  };

export const emailAction =
  ({ email, password, coupon }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    dispatch(requestEmail());
    const data = await emailRequest({ email, password, coupon, token });
    if (data.status === SigninStatus.UNCONFIRMED_EMAIL) {
      dispatch(saveForm({ email, password }));
      dispatch(unconfirmedAuth());
      return data;
    }
    dispatch(succeedAuth(data.token, data.refreshToken));
    dispatch(successEmail(data));
    return data;
  };
