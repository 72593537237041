import { DESC } from "../../constants/Sort";

const initialState = {
  tableState: {
    search: "",
    scrollPosition: {
      x: 0,
      y: 0,
    },
    loadCouponsLimit: 10,
    selectedCoupons: [],
    selectedCouponsUses: [],
    sortCoupons: {
      id: "COUPON_ID",
      type: DESC,
    },
    editingBlocks: {
      isOpen: false,
      rate: {},
    },
    users: {
      search: "",
      scrollPosition: {
        x: 0,
        y: 0,
      },
      loadLimit: 10,
      selected: [],
      sort: {
        id: "USER_ID",
        type: DESC,
      },
      editingBlocks: {
        isOpen: false,
        rate: {},
      },
    },
    generatedCoupons: {},
  },
};

export default function couponsTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_COUPONS_TABLE_STATE":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.state,
        },
      };
    case "GENERATE_COUPONS":
      return {
        ...state,
        tableState: {
          ...state.tableState,
          generatedCoupons: {
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}
