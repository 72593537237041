// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+MEZCODZFRjMtQTRDMy00NTc4LTg1ODEtQkZGQ0YwRUIzMDMyPC90aXRsZT4KICAgIDxnIGlkPSJVSS1LSVQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJVSS1LSVQtREVNTyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwOTcuMDAwMDAwLCAtMTIyNS4wMDAwMDApIiBmaWxsPSIjRkY5ODAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMTEyMSwxMjMzIEwxMTIxLDEyMzYgQzExMjEsMTI0MC40MTgyOCAxMTE3LjQxODI4LDEyNDQgMTExMywxMjQ0IEwxMTAzLDEyNDQgTDExMDMsMTI0NCBMMTA5Ny44MjAwOSwxMjQ4LjMxNjU5IEMxMDk3LjYwNzk1LDEyNDguNDkzMzcgMTA5Ny4yOTI2NywxMjQ4LjQ2NDcxIDEwOTcuMTE1ODksMTI0OC4yNTI1NyBDMTA5Ny4wNDEwMSwxMjQ4LjE2MjcxIDEwOTcsMTI0OC4wNDk0NSAxMDk3LDEyNDcuOTMyNDggTDEwOTcsMTIzMyBDMTA5NywxMjI4LjU4MTcyIDExMDAuNTgxNzIsMTIyNSAxMTA1LDEyMjUgTDExMTMsMTIyNSBDMTExNy40MTgyOCwxMjI1IDExMjEsMTIyOC41ODE3MiAxMTIxLDEyMzMgWiIgaWQ9ImJpZy1jaGF0LWljb24iPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge__messageIcon__b7_wVy {
  height: 24px;
  width: 24px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    no-repeat;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding-top: 3px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageIcon": `badge__messageIcon__b7_wVy`
};
export default ___CSS_LOADER_EXPORT___;
