import React from "react";
import IcomoonReact from "icomoon-react";
import colorIconSet from "../images/color_icons.json";

export default function Loading(props) {
  const display = props.display ? "flex" : "none";
  let loaderClasses = "more-orders-loader";
  if (props.className) {
    loaderClasses = `${loaderClasses} ${props.className}`;
  }
  return (
    <span className={loaderClasses} style={{ display: display, ...props.style }}>
      <IcomoonReact iconSet={colorIconSet} size={24} icon={"spinner"} />
    </span>
  );
}
