// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../UnderHeader/css/under-header.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refresh__refresh-icon__XeyJy9 {
  cursor: pointer;
  display: flex;
  border: 0;
  padding-left: 8px;
  width: 30px;
  height: 48px;
  align-items: center;
}
.refresh__refresh-icon__XeyJy9:active {
  opacity: 0.75;
}

.refresh__refresh-icon__XeyJy9:hover {
  border: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refresh-icon": `refresh__refresh-icon__XeyJy9 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["base-refresh-trigger"]}`
};
export default ___CSS_LOADER_EXPORT___;
