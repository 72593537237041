// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-order__container__KNb94y {
  display: flex;
  flex-direction: column;
  width: 308px;
  /*padding: 24px;*/
  font-family: Roboto;
}

.new-order__menu-container__nMRuIO {
  flex-direction: column;
  align-items: flex-start;
}

.new-order__amount-container___Atqej {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.new-order__menu-label__odMcay {
  margin-top: 15px;
  margin-bottom: 8px;
  color: #424242;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.new-order__button-container__UecYtz {
  margin-top: 24px;
}

.new-order__couponDescription__IeaKiZ {
  width: 390px;
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  margin-top: 24px;
}

.new-order__equivalent__L4xKHI {
  color: #9e9e9e;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 6px;
  margin-left: 8px;
}

.new-order__advancedBtn__YijHg3 {
  color: #1e88e5;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid #1e88e5;
  cursor: pointer;
}

.new-order__advancedLabel__C1jj6E {
  margin-top: 25px;
  margin-bottom: 8px;
  color: #212121;
  font-family: Roboto;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: normal;
}

.new-order__headerButtons__TAymbt {
  text-align: right;
  display: flex;
  width: 33%;
  justify-content: flex-end;
}

.new-order__imageList__OJwyl6 {
  display: grid;
  grid-template-columns: repeat(3, 188px);
  column-gap: 16px;
  row-gap: 16px;
  margin-top: 24px;
}

@media screen and (max-width: 720px) {
  .new-order__imageList__OJwyl6 {
    grid-template-columns: repeat(2, 188px);
  }
}

@media screen and (max-width: 480px) {
  .new-order__imageList__OJwyl6 {
    display: grid;
    grid-template-columns: repeat(2, 136px);
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 14px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `new-order__container__KNb94y`,
	"menu-container": `new-order__menu-container__nMRuIO`,
	"amount-container": `new-order__amount-container___Atqej`,
	"menu-label": `new-order__menu-label__odMcay`,
	"button-container": `new-order__button-container__UecYtz`,
	"couponDescription": `new-order__couponDescription__IeaKiZ`,
	"equivalent": `new-order__equivalent__L4xKHI`,
	"advancedBtn": `new-order__advancedBtn__YijHg3`,
	"advancedLabel": `new-order__advancedLabel__C1jj6E`,
	"headerButtons": `new-order__headerButtons__TAymbt`,
	"imageList": `new-order__imageList__OJwyl6`
};
export default ___CSS_LOADER_EXPORT___;
