import React, { useState } from "react";
import NitroButton from "../GreenButton";
import { FormControl } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import discount from "./css/discount.css";

export const Discount = ({ inputValue, buttonText, placeholder, applyDiscount, validationMessage }) => {
  const [discount, setDiscount] = useState(inputValue);
  const onChange = e => {
    setDiscount(e.target.value);
  };

  return (
    <div styleName={"discount.container"}>
      <div>
        <FormControl
          componentclass={"input"}
          styleName={"discount.input"}
          onChange={onChange}
          placeholder={placeholder}
          value={discount}
        />
        {validationMessage && <p styleName={"discount.validation-message"}>{validationMessage}</p>}
      </div>
      <NitroButton
        disabled={!discount}
        clickHandler={() => {
          applyDiscount(discount);
        }}
        buttonStyleName={"button.save-button"}
        title={buttonText}
      />
    </div>
  );
};
