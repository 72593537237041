import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// eslint-disable-next-line no-unused-vars
import cell from "./css/table-cell.css";
import { Link } from "react-router-dom";
import smallPen from "../../../../images/svg/small-pen.svg";
import { HandySvg } from "handy-svg";
import sheetsIcon from "../../../../images/svg/sheets-icon.svg";
import docsIcon from "../../../../images/svg/docs-icon.svg";
import otherDocsIcon from "../../../../images/svg/other-docs-icon.svg";
import { I18n } from "react-redux-i18n";

export default class TableCell extends Component {
  static propTypes = {
    editPenProps: PropTypes.object,
    pausedProps: PropTypes.object,
  };

  static defaultProps = {
    editPenProps: {},
    pausedProps: {},
    tdStyleName: "cell.cell",
    cellStyleName: "cell.container",
  };

  shouldComponentUpdate(nextProps) {
    //todo: should we remove shouldComponentUpdate
    return (
      !_.isEqual(nextProps.content, this.props.content) ||
      !_.isEqual(nextProps.tdStyleName, this.props.tdStyleName) ||
      !_.isEqual(nextProps.id, this.props.id) ||
      !_.isEqual(nextProps.highlighted, this.props.highlighted) ||
      //todo: are sheetCounter && columnCounter required?
      !_.isEqual(nextProps.sheetCounter, this.props.sheetCounter) ||
      !_.isEqual(nextProps.columnCounter, this.props.columnCounter) ||
      !_.isEqual(nextProps.mainCharsCounter, this.props.mainCharsCounter) ||
      !_.isEqual(nextProps.subCharsCounter, this.props.subCharsCounter) ||
      !_.isEqual(nextProps.charsCounter, this.props.charsCounter)
    );
  }

  componentDidMount() {
    this.calculateContentHint();
  }

  componentDidUpdate() {
    this.calculateContentHint();
  }

  calculateContentHint = () => {
    const { divEl } = this;
    const { content } = this.props;

    divEl && typeof content !== "object" && divEl.scrollWidth - divEl.clientWidth ? (divEl.title = content) : null;
  };
  getSubCharsText = ({ documentType }) => {
    const { mainCharsCounter, subCharsCounter, charsCounter } = this.props;

    const subCharsText = {
      XML: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.COMPUTER_FILES_DATA", {
        nodeCounter: mainCharsCounter,
        attributesCounter: subCharsCounter,
        charsCounter: charsCounter,
      }),
      GOOGLE_SPREADSHEET: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.GOOGLE_FILES_DATA", {
        sheetCounter: mainCharsCounter,
        columnCounter: subCharsCounter,
        charsCounter: charsCounter,
      }),
      CSV: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.CSV_FILES_DATA", {
        columnCounter: subCharsCounter,
        charsCounter: charsCounter,
      }),
    };
    return subCharsText[documentType] || "";
  };
  render() {
    const {
      content,
      style,
      tdStyle,
      tdClass,
      onCellClick,
      tdStyleName,
      cellStyleName,
      optionsFormat,
      tooltipFormat,
      origin,
      isFirst,
      id,
      rowIsLink,
      isDiscount,
      onClickEditFile,
      mainCharsCounter,
      subCharsCounter,
      charsCounter,
      title,
      docsType,
      onClickFileTitle,
    } = this.props;
    let tooltip = tooltipFormat ? tooltipFormat(content) : typeof content === "object" ? "" : content;
    const dataNotificaton = `${isFirst ? "1st" : isDiscount ? "%" : origin ? "API" : ""}`;
    const viewContent = optionsFormat ? optionsFormat(content) : content;
    return (
      <td
        style={tdStyle}
        styleName={tdClass ? `${tdClass} ${tdStyleName}` : tdStyleName}
        onClick={onCellClick && onCellClick}
      >
        {rowIsLink ? (
          <Link to={rowIsLink + id} style={style} styleName={cellStyleName}>
            <div
              styleName={cellStyleName}
              ref={node => {
                this.divEl = node;
              }}
              title={tooltip}
            >
              <span data-notificaton={dataNotificaton}>{viewContent}</span>
            </div>
          </Link>
        ) : (
          <div
            ref={node => {
              this.divEl = node;
            }}
            style={{ paddingRight: 8, ...style }}
            styleName={cellStyleName}
            title={!!tooltip ? tooltip : title}
          >
            <div styleName="cell.cell-container" style={{ cursor: "pointer" }} onClick={onClickFileTitle}>
              {/*[WIP] Return and investigate into this onClick(above) and prev conditions*/}
              {docsType && typeof content === "string" && (
                <HandySvg
                  width="16"
                  height="16"
                  src={
                    docsType === "GOOGLE_SPREADSHEET"
                      ? sheetsIcon
                      : docsType === "GOOGLE_DOC"
                        ? docsIcon
                        : otherDocsIcon
                  }
                />
              )}
              <span data-notificaton={dataNotificaton}>{viewContent}</span>
            </div>
            {mainCharsCounter >= 0 && subCharsCounter >= 0 && charsCounter ? (
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <p style={{ margin: 0, fontWeight: 300, color: "#9E9E9E", fontSize: "14px" }}>
                  {this.getSubCharsText({ documentType: docsType })}
                </p>
                <HandySvg
                  width="10"
                  height="10"
                  src={smallPen}
                  onClick={onClickEditFile}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </td>
    );
  }
}
