// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge__container__vl6r_t {
  border-radius: 100px;
  height: 25px;
  padding: 5px 5px 5px 10px;
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  background-color: #d6ecd6;
  display: flex;
  margin-right: 8px;
}

.badge__content__KW4_Tz {
  margin-right: 10px;
}

.badge__icon-green__xwKeIB {
  display: inline-flex;
  cursor: pointer;
}
.badge__loading__bM0PxI{
  display: flex;
  margin-right: 6px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `badge__container__vl6r_t`,
	"content": `badge__content__KW4_Tz`,
	"icon-green": `badge__icon-green__xwKeIB`,
	"loading": `badge__loading__bM0PxI`
};
export default ___CSS_LOADER_EXPORT___;
