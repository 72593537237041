// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.container {*/
/*    max-width: 575px;*/
/*    padding: 0 24px 24px;*/
/*    font-family: Roboto, sans-serif;*/
/*    font-style: normal;*/
/*    color: #212121;*/
/*}*/

.styles__container__lRhYEp {
    display: flex;
    flex-direction: column;
    max-width: 575px;
    padding: 0 24px 24px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    color: #212121;
    /*border-radius: 8px;*/
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    /*position: relative !important;*/
    /*top: initial !important;*/
    bottom: initial !important;
}

.styles__title__eDjepO {
    position: relative;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
}

.styles__content__oLwFBC {
    overflow-y: scroll;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.styles__content__oLwFBC::-webkit-scrollbar {
    display: none;
}

.styles__description__WvVXlA {
    padding-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.styles__fieldWrapper__dVNDxT {
    padding-top: 14px;
}

.styles__fieldWrapper__dVNDxT:first-child {
    padding-top: 5px;
}

.styles__buttonsWrapper__Bn4iJV {
    padding-top: 24px;
    font-size: 14px !important;
}

.styles__mainBtn__ny0uo4 {
    min-width: 85px;
    height: 32px;
    width: 93px;
    font-weight: 300;
    font-size: 14px;
}

.styles__clearButton__ZqrZR5 {
    cursor: pointer;
    height: 24px;
    min-width: 65px;
    color: #9E9E9E;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    display: inline-block;
    margin-left: 5px;
    border: none;
    background-color: transparent;
}

.styles__clearButton__ZqrZR5:active {
    outline: none !important;
}

.styles__clearButton__ZqrZR5:focus {
    box-shadow: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container__lRhYEp`,
	"title": `styles__title__eDjepO`,
	"content": `styles__content__oLwFBC`,
	"description": `styles__description__WvVXlA`,
	"fieldWrapper": `styles__fieldWrapper__dVNDxT`,
	"buttonsWrapper": `styles__buttonsWrapper__Bn4iJV`,
	"mainBtn": `styles__mainBtn__ny0uo4`,
	"clearButton": `styles__clearButton__ZqrZR5`
};
export default ___CSS_LOADER_EXPORT___;
