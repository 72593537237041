import PropTypes from "prop-types";
import React, { Component } from "react";
import { clearErrors, setFocusOnFirst, showErrors, validate } from "../../helpers/common";

import HtmlTranslate from "../HtmlTranslate";

export default class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    handler: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    setFocusOnFirst();
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.validator();
    clearErrors();

    const form = {
      email: e.target.email.value,
    };

    const errors = validate(form);

    if (!errors) {
      if (!this.props.disabled) this.props.handler(form);
    } else {
      showErrors(errors);
    }
  }

  render() {
    return (
      <form
        className="nitro-form forgot-password"
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        style={this.props.styleForm}
      >
        <div className="form-group">
          <label htmlFor="email" style={{ fontSize: 14, fontWeight: 300 }}>
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.FORGOT_PASSWORD_FORM.EMAIL_FIELD_LABEL" />
          </label>
          <input className="form-control" name="email" type="text" id="email" value={this.state.email} />
          <p className="error-message">
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.FORGOT_PASSWORD_FORM.EMAIL_FIELD_ERROR" />
          </p>
          <p className="error-message custom-error">
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.FORGOT_PASSWORD_FORM.INCORRECT_EMAIL_ERROR" />
          </p>
        </div>
        <div className="form-group button-group">
          <button type="submit" className="btn btn-block btn-nitro">
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.FORGOT_PASSWORD_FORM.SUBMIT_BUTTON_TEXT" />
          </button>
        </div>
        {this.state.error && <p className="text-danger">{this.state.error}</p>}
      </form>
    );
  }
}
