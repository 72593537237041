// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.greenArrow__popoverQuestion__C5QOL7 {
  border-radius: 0 !important;
  border: 1px solid #66bb6a !important;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 16px;
}

.greenArrow__popper__vtWe5q[data-x-out-of-boundaries] {
  display: none;
}

.greenArrow__questionHint___ZMw7G {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  align-self: center;
  box-shadow: none;
  white-space: pre-line;
  max-width: 240px;
  min-width: 240px;
  text-align: left;
  word-wrap: break-word;
  /*padding: 16px;*/
}

.greenArrow__questionLink__aLzOcy {
  color: #1e88e5 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.greenArrow__questionLinkContainer__M5S8EM {
  padding-top: 8px;
}

.greenArrow__popper__vtWe5q {
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 15px;
  background: #e8f5e9;
  border-radius: 2px;
  border: 1px solid #66bb6a;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  min-height: 30px !important;
}

.greenArrow__popper-orderContext__hrrc4P {
}

.greenArrow__popper__vtWe5q .greenArrow__popper__arrow__lfc3bw {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin: 5px;
}

.greenArrow__popper__vtWe5q[data-placement^="top"] {
  margin-bottom: 12px;
  left: -10px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="top"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.greenArrow__popper__vtWe5q[data-placement^="top"] .greenArrow__popper__arrow__lfc3bw::before,
.greenArrow__popper__vtWe5q[data-placement^="top"] .greenArrow__popper__arrow__lfc3bw::after {
  content: "";
  position: absolute;
  border: 9px solid transparent;
  border-top: 9px solid #66bb6a;
}

.greenArrow__popper__vtWe5q[data-placement^="top"] .greenArrow__popper__arrow__lfc3bw::after {
  border-top: 9px solid #e8f5e9;
  bottom: -17px;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom"] {
  margin-top: 13px;
  left: -10px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  top: -20px;
  margin-top: 0;
  margin-bottom: 0;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw::before,
.greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #66bb6a;
}

@-moz-document url-prefix() {
  .greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw::before,
  .greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #66bb6a;
    /*margin-top: -24px;*/
  }
}

.greenArrow__popper__vtWe5q[data-placement^="bottom"] .greenArrow__popper__arrow__lfc3bw::after {
  border-bottom: 10px solid #e8f5e9;
  bottom: -21px;
}

.greenArrow__popper__vtWe5q[data-placement^="right"] {
  margin-left: 13px;
  top: -10px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="right"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  left: -21px;
  top: calc(2%) !important;
  margin-left: 0;
  margin-right: 0;
}

.greenArrow__popper__vtWe5q[data-placement^="right"] .greenArrow__popper__arrow__lfc3bw::before,
.greenArrow__popper__vtWe5q[data-placement^="right"] .greenArrow__popper__arrow__lfc3bw::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-right: 10px solid #66bb6a;
}

.greenArrow__popper__vtWe5q[data-placement^="right"] .greenArrow__popper__arrow__lfc3bw::after {
  border-right: 10px solid #e8f5e9;
  right: -21px;
}

.greenArrow__popper__vtWe5q[data-placement^="left"] {
  margin-right: 12px;
  top: -10px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="left"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  left: 100%;
  top: calc(2%) !important;
  margin-left: 0;
  margin-right: 0;
}

.greenArrow__popper__vtWe5q[data-placement^="left"] .greenArrow__popper__arrow__lfc3bw::before,
.greenArrow__popper__vtWe5q[data-placement^="left"] .greenArrow__popper__arrow__lfc3bw::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-left: 10px solid #66bb6a;
}

.greenArrow__popper__vtWe5q[data-placement^="left"] .greenArrow__popper__arrow__lfc3bw::after {
  border-left: 10px solid #e8f5e9;
  left: -1px;
}

.greenArrow__popper__vtWe5q[data-x-out-of-boundaries] {
  display: none;
}

.greenArrow__container-popover__AghWJk {
  padding: 8px 11px 8px 9px;
}

.greenArrow__detail-button-container-popover__Av_7sm {
  padding: 0;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom-end"] {
  margin-top: 13px;
  min-width: 190px;
  left: 0px !important;
}

.greenArrow__popper-orderContext__hrrc4P[data-placement^="bottom-end"] {
  margin-top: 13px;
  min-width: 190px;
  left: 19px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  top: -20px;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(85%) !important;
}

.greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::before,
.greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #66bb6a;
}

@-moz-document url-prefix() {
  .greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::before,
  .greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #66bb6a;
    margin-top: -24px;
  }

  .greenArrow__popper-orderContext__hrrc4P[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::before,
  .greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #66bb6a;
    margin-top: -18px;
  }
}

.greenArrow__popper__vtWe5q[data-placement^="bottom-end"] .greenArrow__popper__arrow__lfc3bw::after {
  border-bottom: 10px solid #e8f5e9;
  bottom: -21px;
}

.greenArrow__popper__vtWe5q[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 0 !important;
}

.greenArrow__popper-orderContext__hrrc4P[data-placement^="top-end"] {
  margin-bottom: 12px;
  min-width: 190px;
  text-align: center;
  left: 19px !important;
}

.greenArrow__popper__vtWe5q[data-placement^="top-end"] .greenArrow__popper__arrow__lfc3bw {
  content: "";
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  left: calc(86%) !important;
}

.greenArrow__item__DjRXG_ {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #424242;
  border: 0;
  display: block;
  padding: 0 !important;
  cursor: pointer;
}

.greenArrow__item__DjRXG_:hover {
    background-color: #eeeeee;
  }

.greenArrow__exit-container__NehG3q {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0 14px;
  cursor: pointer;
}

.greenArrow__something-wrong-btn__e8XLLu {
  text-align: center !important;
  font-size: 14px;
  border-radius: 3px;
  line-height: 16px !important;
  margin-right: 16px;
}

.greenArrow__detailItem-container__yFDzgV {
  margin: 0;
  padding: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverQuestion": `greenArrow__popoverQuestion__C5QOL7`,
	"popper": `greenArrow__popper__vtWe5q`,
	"questionHint": `greenArrow__questionHint___ZMw7G`,
	"questionLink": `greenArrow__questionLink__aLzOcy`,
	"questionLinkContainer": `greenArrow__questionLinkContainer__M5S8EM`,
	"popper-orderContext": `greenArrow__popper-orderContext__hrrc4P greenArrow__popper__vtWe5q`,
	"popper__arrow": `greenArrow__popper__arrow__lfc3bw`,
	"container-popover": `greenArrow__container-popover__AghWJk greenArrow__popper__vtWe5q`,
	"detail-button-container-popover": `greenArrow__detail-button-container-popover__Av_7sm greenArrow__popper__vtWe5q`,
	"item": `greenArrow__item__DjRXG_`,
	"exit-container": `greenArrow__exit-container__NehG3q`,
	"something-wrong-btn": `greenArrow__something-wrong-btn__e8XLLu`,
	"detailItem-container": `greenArrow__detailItem-container__yFDzgV`
};
export default ___CSS_LOADER_EXPORT___;
