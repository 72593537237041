import { createThunkReducer } from "../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  coupon: { status: "", data: {} },
};

const getCouponReducer = createThunkReducer("coupon", "GET_COUPON_INFO", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const couponReducer = reduceReducers(initialReducer, getCouponReducer);
