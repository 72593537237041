import { I18n } from "react-redux-i18n";
import {
  confirmResetPasswordRequest,
  confirmSignupRequest,
  resendConfirmEmailRequest,
  resetPasswordRequest,
  signupRequest,
} from "../../../services/SignupServices/requests";
import { showNotification } from "../../Notification";
import {
  failureConfirmResetPassword,
  requestConfirmSignup,
  requestResendConfirmEmail,
  successConfirmResetPassword,
  successConfirmSignup,
  successResendConfirmEmail,
} from "./signupActionCreators";
import { disableApp, enableApp } from "../../../actions/App";
import { SignupStatus } from "@alconost/nitro-services-api/src/proto/signup_service_pb";
import {
  failRequestPasswordReset,
  failRequestRegistration,
  requestPasswordReset,
  requestRegistration,
  setStatus,
  setUserNotActiveError,
  succeedRequestPasswordReset,
  succeedRequestRegistration,
} from "../../../actions/Signup";
import { PASSWORD_RESET_USER_NOT_FOUND_STATUS } from "../../../constants/Signup";

export const resendConfirmEmailAction =
  ({ email }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestResendConfirmEmail());
    const data = await resendConfirmEmailRequest({ email, token });
    dispatch(successResendConfirmEmail(data));
    dispatch(showNotification(I18n._translate("LOGIN_PAGE.NOTIFICATION_SUCCESS_RESEND_CONFIRM_EMAIL")));
    return data;
  };

export const confirmSignupAction =
  ({ email, code }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestConfirmSignup());
    dispatch(disableApp());
    const data = await confirmSignupRequest({ email, code, token });
    dispatch(enableApp());
    dispatch(successConfirmSignup(data));
    return data;
  };

export const confirmResetPasswordAction =
  ({ email, code, password }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(disableApp());
    const data = await confirmResetPasswordRequest({ email, code, password, token });
    dispatch(enableApp());
    if (data.status === SignupStatus.SIGNUP_OK) {
      dispatch(successConfirmResetPassword(data.token));
      return data;
    }
    if (data.status === SignupStatus.INVALID_SECURITY_TOKEN) {
      const errorMessage = {
        code: data.status,
        message: I18n._translate("FORGOT_CONFIRM_ERROR_PAGE.ERRORS.INCORRECT_CONFIRMATION_CODE_RESET"),
      };
      dispatch(failureConfirmResetPassword(errorMessage));
      return data;
    }
    const errorMessage = {
      code: data.status,
      message: I18n._translate("ERRORS.COMMON"),
    };
    dispatch(failureConfirmResetPassword(errorMessage));
    return data;
  };

export const resetPasswordAction =
  ({ email }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    dispatch(disableApp());
    dispatch(requestPasswordReset({ email }));
    const data = await resetPasswordRequest({ email, token });
    dispatch(enableApp());
    const messageStatus = data.status;

    if (messageStatus === SignupStatus.SIGNUP_OK) {
      dispatch(succeedRequestPasswordReset());
      dispatch(showNotification(I18n._translate("LOGIN_PAGE.NOTIFICATION_SUCCESS_RESEND_CONFIRM_EMAIL")));

      return data;
    } else if (messageStatus === SignupStatus.USER_NOT_FOUND) {
      dispatch(setStatus(PASSWORD_RESET_USER_NOT_FOUND_STATUS));
    } else if (messageStatus === SignupStatus.SIGNUP_USER_NOT_ACTIVE) {
      let error = {
        code: messageStatus,
        message: I18n._translate("FORGOT_PASSWORD_PAGE.ERRORS.USER_NOT_ACTIVE", { email: form.email }),
      };
      dispatch(setUserNotActiveError(error));
      return data;
    } else {
      let error = {
        code: messageStatus,
        message: I18n._translate("ERRORS.COMMON"),
      };
      dispatch(failRequestPasswordReset(error));
      return data;
    }
  };

export const signupAction =
  ({ email, password, fullname, locale, referer, coupon }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    dispatch(disableApp());
    dispatch(requestRegistration({ email, password, fullname, locale, referer, coupon }));
    const data = await signupRequest({
      email,
      password,
      fullname,
      locale,
      referer,
      coupon,
      token,
    });
    dispatch(enableApp());
    const messageStatus = data.status;
    if (messageStatus === SignupStatus.SIGNUP_OK) {
      dispatch(succeedRequestRegistration({ email, password, fullname, locale, referer, coupon }));
      return data;
    }
    if (messageStatus === SignupStatus.USER_ALREADY_EXISTS) {
      let error = {
        code: messageStatus,
        message: I18n._translate("SIGNUP_PAGE.ERRORS.USER_ALREADY_EXISTS"),
      };
      dispatch(failRequestRegistration(error));
      return data;
    }

    let error = {
      code: messageStatus,
      message: I18n._translate("ERRORS.COMMON"),
    };
    dispatch(failRequestRegistration(error));
    return data;
  };
