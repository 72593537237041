// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-item__container__Jltd8d {
  display: flex;
  justify-content: space-between;
  padding: 14px 12px 14px 21px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #212121;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
}

.sidebar-item__container__Jltd8d:hover {
    cursor: pointer;
  }

.sidebar-item__container__Jltd8d:first-child {
    border-top: none;
  }

.sidebar-item__active__oLFB8d {
  border-left: 3px solid #1e88e5;
  color: #1e88e5;
  padding-left: 18px;
}

.sidebar-item__left-block__VsY_RX {
  display: flex;
  align-items: center;
}

.sidebar-item__number-block__DVFfhv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-left: -5px;
  color: #212121;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  height: 20px;
  width: 20px;
  border-radius: 1px;
}

.sidebar-item__number-block-active__c9Tpl7 {
  color: #1e88e5;
}

.sidebar-item__text__UQRztm {
  font-family: Roboto, sans-serif;
  line-height: 16px;
  font-weight: 300;
}

.sidebar-item__additional__fIxVOY {
  display: flex;
  align-items: center;
  color: #9e9e9e;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: right;
  line-height: 16px;
}

.sidebar-item__marginCount__khlfKD {
  display: flex;
  align-items: center;
  color: rgba(255, 152, 0, 1);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sidebar-item__container__Jltd8d`,
	"active": `sidebar-item__active__oLFB8d sidebar-item__container__Jltd8d`,
	"left-block": `sidebar-item__left-block__VsY_RX`,
	"number-block": `sidebar-item__number-block__DVFfhv`,
	"number-block-active": `sidebar-item__number-block-active__c9Tpl7`,
	"text": `sidebar-item__text__UQRztm`,
	"additional": `sidebar-item__additional__fIxVOY`,
	"marginCount": `sidebar-item__marginCount__khlfKD`
};
export default ___CSS_LOADER_EXPORT___;
