var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
export function useClearCache(props) {
    var _this = this;
    if (props === void 0) { props = {}; }
    var _a = props.duration, duration = _a === void 0 ? 300 * 1000 : _a, _b = props.auto, auto = _b === void 0 ? false : _b, _c = props.jsonPathFrom, jsonPathFrom = _c === void 0 ? "static/meta.json" : _c, setFirstLoading = props.setFirstLoading;
    var _d = React.useState(true), loading = _d[0], setLoading = _d[1];
    var _e = React.useState(true), isLatestVersion = _e[0], setIsLatestVersion = _e[1];
    var useSessionStorage = function (name) {
        var getSessionStorage = function () {
            var sessionStorage = window.sessionStorage.getItem(name);
            if (sessionStorage === null) {
                return null;
            }
            return JSON.parse(sessionStorage);
        };
        var setSressionStorage = function (item) {
            window.sessionStorage.setItem(name, JSON.stringify(item));
        };
        return [getSessionStorage(), setSressionStorage];
    };
    var _f = useSessionStorage("appVersion"), appVersion = _f[0], setAppVersion = _f[1];
    var _g = React.useState(appVersion), latestVersion = _g[0], setLatestVersion = _g[1];
    function setVersion(version) {
        return __awaiter(this, void 0, void 0, function () {
            var newVersion;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newVersion = typeof version === "string" && version ? version : latestVersion;
                        return [4 /*yield*/, setAppVersion(newVersion)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    var emptyCacheStorage = function (version) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Clearing cache and hard reloading...");
                    if ("caches" in window) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
                                var name_1 = names_1[_i];
                                caches.delete(name_1);
                            }
                        });
                    }
                    // clear browser cache and reload page
                    return [4 /*yield*/, setVersion(version).then(function () {
                            window.location.reload();
                        })];
                case 1:
                    // clear browser cache and reload page
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    function fetchMeta() {
        fetch(jsonPathFrom, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                cache: "no-store",
            },
        })
            .then(function (response) {
            if (response.ok) {
                return response
                    .json()
                    .then(function (meta) {
                    var newVersion = meta.version;
                    var isUpdated = newVersion === appVersion;
                    if (props.isFirstLoading) {
                        setFirstLoading && setFirstLoading();
                        setAppVersion(newVersion);
                        setLoading(false);
                        setIsLatestVersion(true);
                        setLatestVersion(newVersion);
                    }
                    else if (!isUpdated && !auto && !appVersion) {
                        setAppVersion(newVersion);
                    }
                    else if (!isUpdated && !auto && appVersion) {
                        console.log("An update is available!");
                        setLatestVersion(newVersion);
                        setLoading(false);
                        setIsLatestVersion(false);
                    }
                    else if (!isUpdated && auto) {
                        setAppVersion(newVersion);
                    }
                    else {
                        setIsLatestVersion(true);
                        setLoading(false);
                    }
                })
                    .catch(function (e) {
                    console.log("Error parsing JSON: ", e);
                });
            }
            else {
                throw new Error("Error getting JSON: ");
            }
        })
            .catch(function (e) {
            console.log(e);
        });
    }
    React.useEffect(function () {
        var fetchCacheTimeout = setInterval(function () { return fetchMeta(); }, duration);
        return function () {
            clearInterval(fetchCacheTimeout);
        };
    }, [loading]);
    React.useEffect(function () {
        fetchMeta();
    }, []);
    return {
        loading: loading,
        isLatestVersion: isLatestVersion,
        emptyCacheStorage: emptyCacheStorage,
        latestVersion: latestVersion,
    };
}
var ClearCache = function (props) {
    var _a = useClearCache(props), loading = _a.loading, isLatestVersion = _a.isLatestVersion, emptyCacheStorage = _a.emptyCacheStorage;
    var children = props.children;
    return children({
        loading: loading,
        isLatestVersion: isLatestVersion,
        emptyCacheStorage: emptyCacheStorage,
    });
};
export default ClearCache;
