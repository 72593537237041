import { I18n } from "react-redux-i18n";
import { formatDate } from "./formatDate";

export const getStatusOrderString = orderStatus => {
  const status = {
    1: "queue",
    2: "progress",
    3: "done",
    4: "edit",
    5: "revision",
    6: "revision_progress",
    7: "editor_progress",
  };
  return status[orderStatus];
};

export const convertIntStatusToString = status => {
  const statuses = {
    1: "queue",
    2: "incomplete",
    3: "done",
    4: "incomplete",
    5: "queue",
    6: "incomplete",
    7: "incomplete",
    default: "",
  };

  return statuses[status] || statuses["default"];
};

export const definePenStyle = (status, editTime) => {
  const style = {
    1: {
      isAddPen: false,
      editPenColor: false,
      editPenTitle: false,
    },
    2: {
      isAddPen: false,
      editPenColor: false,
      editPenTitle: false,
    },
    3: editTime
      ? {
          isAddPen: true,
          editPenColor: "row.edit-pen-grey",
          editPenTitle: I18n.t("ORDER_PAGE.EDITED_HINT", {
            data: formatDate(editTime).format("DD/MM/YYYY HH:mm:ss"),
          }),
        }
      : {
          isAddPen: false,
          editPenColor: false,
          editPenTitle: false,
        },
    4: {
      isAddPen: true,
      editPenColor: "row.edit-pen-orange",
      editPenTitle: I18n.t("ORDER_PAGE.EDITING_HINT"),
    },
    5: {
      isAddPen: true,
      editPenColor: "row.edit-pen-red",
      editPenTitle: I18n.t("ORDER_PAGE.NEED_REVISION_HINT"),
    },
    6: {
      isAddPen: true,
      editPenColor: "row.edit-pen-orange",
      editPenTitle: I18n.t("ORDER_PAGE.EDITING_HINT"),
    },
    7: {
      isAddPen: true,
      editPenColor: "row.edit-pen-blue",
      editPenTitle: I18n.t("ORDER_PAGE.EDITOR_PROGRESS_HINT"),
    },
    8: {
      isAddPen: true,
      editPenColor: "row.pause",
      editPenTitle: I18n.t("ORDER_PAGE.QUEUE_HINT"),
    },
  };
  return style[status] || {};
};
