import { createThunkReducer } from "../../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["discounts"]: { status: "", data: { hasmore: false } },
  ["users"]: { status: "", data: { hasmore: false } },
};

const getDiscountsRequest = createThunkReducer("discounts", "LIST_DISCOUNTS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getDiscountUsers = createThunkReducer("users", "LIST_DISCOUNT_USERS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const discountsReducer = reduceReducers(initialReducer, getDiscountsRequest, getDiscountUsers);
