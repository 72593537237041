// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4xOTQ5OCAyLjc1NDc2QzAuOTM1MDEyIDMuMDE0NzIgMC45MzUwMTIgMy40MzQ2NiAxLjE5NDk4IDMuNjk0NjNMMi40MTQ4MSA0LjkxNDQ2TDQuOTE0NDYgMi40MTQ4TDMuNjk0NjMgMS4xOTQ5N0MzLjQzNDY2IDAuOTM1MDA5IDMuMDE0NzIgMC45MzUwMDkgMi43NTQ3NiAxLjE5NDk3TDEuMTk0OTggMi43NTQ3NlpNMTMgMTNWMTAuNTAwM0w1LjYyNzY5IDMuMTI4MDRMMy4xMjgwNCA1LjYyNzY5TDEwLjUwMDMgMTNIMTNaIiBmaWxsPSIjRkY5ODAwIi8+DQo8L3N2Zz4NCg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4xOTQ5OCAyLjc1NDc2QzAuOTM1MDEyIDMuMDE0NzIgMC45MzUwMTIgMy40MzQ2NiAxLjE5NDk4IDMuNjk0NjNMMi40MTQ4MSA0LjkxNDQ2TDQuOTE0NDYgMi40MTQ4TDMuNjk0NjMgMS4xOTQ5N0MzLjQzNDY2IDAuOTM1MDA5IDMuMDE0NzIgMC45MzUwMDkgMi43NTQ3NiAxLjE5NDk3TDEuMTk0OTggMi43NTQ3NlpNMTMgMTNWMTAuNTAwM0w1LjYyNzY5IDMuMTI4MDRMMy4xMjgwNCA1LjYyNzY5TDEwLjUwMDMgMTNIMTNaIiBmaWxsPSIjQkRCREJEIi8+DQo8L3N2Zz4NCg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4xOTQ5OCAyLjc1NDc2QzAuOTM1MDEyIDMuMDE0NzIgMC45MzUwMTIgMy40MzQ2NiAxLjE5NDk4IDMuNjk0NjNMMi40MTQ4MSA0LjkxNDQ2TDQuOTE0NDYgMi40MTQ4TDMuNjk0NjMgMS4xOTQ5N0MzLjQzNDY2IDAuOTM1MDA5IDMuMDE0NzIgMC45MzUwMDkgMi43NTQ3NiAxLjE5NDk3TDEuMTk0OTggMi43NTQ3NlpNMTMgMTNWMTAuNTAwM0w1LjYyNzY5IDMuMTI4MDRMMy4xMjgwNCA1LjYyNzY5TDEwLjUwMDMgMTNIMTNaIiBmaWxsPSIjRUY1MzUwIi8+DQo8L3N2Zz4NCg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4xOTQ5OCAyLjc1NDc2QzAuOTM1MDEyIDMuMDE0NzIgMC45MzUwMTIgMy40MzQ2NiAxLjE5NDk4IDMuNjk0NjNMMi40MTQ4MSA0LjkxNDQ2TDQuOTE0NDYgMi40MTQ4TDMuNjk0NjMgMS4xOTQ5N0MzLjQzNDY2IDAuOTM1MDA5IDMuMDE0NzIgMC45MzUwMDkgMi43NTQ3NiAxLjE5NDk3TDEuMTk0OTggMi43NTQ3NlpNMTMgMTNWMTAuNTAwM0w1LjYyNzY5IDMuMTI4MDRMMy4xMjgwNCA1LjYyNzY5TDEwLjUwMDMgMTNIMTNaIiBmaWxsPSIjMUU4OEU1Ii8+DQo8L3N2Zz4NCg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row__base__My0bKI {
  cursor: pointer;
}

.table-row__base__My0bKI:nth-of-type(odd) {
    background-color: #ffffff !important;
  }

.table-row__base__My0bKI:nth-of-type(even) {
    background-color: #fafafa !important;
  }

.table-row__base__My0bKI .table-row__deleteFileIcon___YpBHJ {
    display: flex;
    min-height: 10px;
    min-width: 10px;
    /*margin-right: 16px;*/
    margin-left: 6px;
    cursor: pointer;
    display: flex;
  }

.table-row__base-green__wpWsPU{
  cursor: pointer;
  background-color: #E8F5E9 !important;
}

.table-row__base-white__letWF6 {
  cursor: pointer;
}

.table-row__base-white__letWF6:nth-of-type(odd),
  .table-row__base-white__letWF6:nth-of-type(even) {
    background-color: #ffffff !important;
  }

.table-row__base-whiteIsOneRow__f_f7Ps {
  cursor: pointer;
  height: 52px;
}

.table-row__base-whiteIsOneRow__f_f7Ps:nth-of-type(odd),
  .table-row__base-whiteIsOneRow__f_f7Ps:nth-of-type(even) {
    background-color: #ffffff !important;
  }

.table-row__row-checked__Bih0U7 {
}

.table-row__row-checked__Bih0U7:nth-of-type(odd),
  .table-row__row-checked__Bih0U7:nth-of-type(even) {
    background-color: #e3f2fd !important;
  }

.table-row__row-support-checked__pJkQGk {
}

.table-row__row-support-checked__pJkQGk:nth-of-type(odd),
  .table-row__row-support-checked__pJkQGk:nth-of-type(even) {
    background-color: rgba(224, 224, 224, 0.5) !important;
  }

.table-row__rowGreen__dB4rE7 {
}

.table-row__rowGreen__dB4rE7:nth-of-type(odd),
  .table-row__rowGreen__dB4rE7:nth-of-type(even) {
    background-color: rgb(232, 245, 233) !important;
  }

.table-row__edit-pen__iEDWoM {
  height: 14px;
  width: 14px;
}

.table-row__edit-pen-orange__FNC9lL {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.table-row__edit-pen-grey__brESNX {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.table-row__edit-pen-red__P16Wy9 {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.table-row__edit-pen-blue__cNztWF {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.table-row__pen-container__U89LZG {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-row__unread-container__Kk5h2Q {
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `table-row__base__My0bKI`,
	"deleteFileIcon": `table-row__deleteFileIcon___YpBHJ`,
	"base-green": `table-row__base-green__wpWsPU`,
	"base-white": `table-row__base-white__letWF6`,
	"base-whiteIsOneRow": `table-row__base-whiteIsOneRow__f_f7Ps`,
	"row-checked": `table-row__row-checked__Bih0U7 table-row__base__My0bKI`,
	"row-support-checked": `table-row__row-support-checked__pJkQGk table-row__base__My0bKI`,
	"rowGreen": `table-row__rowGreen__dB4rE7 table-row__base__My0bKI`,
	"edit-pen": `table-row__edit-pen__iEDWoM`,
	"edit-pen-orange": `table-row__edit-pen-orange__FNC9lL table-row__edit-pen__iEDWoM`,
	"edit-pen-grey": `table-row__edit-pen-grey__brESNX table-row__edit-pen__iEDWoM`,
	"edit-pen-red": `table-row__edit-pen-red__P16Wy9 table-row__edit-pen__iEDWoM`,
	"edit-pen-blue": `table-row__edit-pen-blue__cNztWF table-row__edit-pen__iEDWoM`,
	"pen-container": `table-row__pen-container__U89LZG`,
	"unread-container": `table-row__unread-container__Kk5h2Q table-row__pen-container__U89LZG`
};
export default ___CSS_LOADER_EXPORT___;
