export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

export const REQUEST_ORDER = "REQUEST_ORDER";
export const SUCCEED_ORDER = "SUCCEED_ORDER";
export const FAIL_ORDER = "FAIL_ORDER";
export const UNSET_ORDER = "UNSET_ORDER";

export const SUCCEED_SETTINGS = "SUCCEED_SETTINGS";
export const SUCCEED_RATES = "SUCCEED_RATES";
export const UPDATED_SETTINGS = "UPDATED_SETTINGS";
export const FAIL_SETTINGS = "FAIL_SETTINGS";
export const HIDE_NOTIFICATIONS_SETTINGS = "HIDE_NOTIFICATIONS_SETTINGS";
export const FAIL_PASSWORD = "FAIL_PASSWORD";
export const SUCCEEED_PASSWORD = "SUCCEEED_PASSWORD";

export const LOADING_ORDER_STATUS = "LOADING_ORDER_STATUS";
export const SUCCEED_ORDER_STATUS = "SUCCEED_ORDER_STATUS";
export const SUCCEED_NO_ORDER_STATUS = "SUCCEED_NO_ORDER_STATUS";
export const FAIL_ORDER_STATUS = "FAIL_ORDER_STATUS";

export const SUCCEED_NEXT_ORDER = "SUCCEED_NEXT_ORDER";
export const SUCCEED_ACCEPTED_ORDER = "SUCCEED_ACCEPTED_ORDER";
export const FAIL_NEXT_ORDER = "FAIL_NEXT_ORDER";

export const SUCCEED_SKIP_ORDER = "SUCCEED_SKIP_ORDER";
export const FAIL_SKIP_ORDER = "FAIL_SKIP_ORDER";

export const SUCCEED_COMPLETE_ORDER = "SUCCEED_COMPLETE_ORDER";
export const FAIL_COMPLETE_ORDER = "FAIL_COMPLETE_ORDER";
export const FAIL_CONNECTION = "FAIL_CONNECTION";

export const LOADING_ORDER = "LOADING_ORDER";
export const COMPLETE_WITH_NOTE_ORDER = "COMPLETE_WITH_NOTE_ORDER";
export const UPDATE_COMPLETE_STATUS = "UPDATE_COMPLETE_STATUS";
export const UPDATE_RESULT = "UPDATE_RESULT";

export const ACCEPT_ORDER_SKIP = "ACCEPT_ORDER_SKIP";
export const ACCEPT_ORDER_DONE = "ACCEPT_ORDER_DONE";
export const OPEN_DESKTOP_POPUP = "OPEN_DESKTOP_POPUP";
export const CLOSE_DESKTOP_POPUP = "CLOSE_DESKTOP_POPUP";
export const RESET_DESKTOP_POPUP = "RESET_DESKTOP_POPUP";
export const HIDE_NOTIFICATIONS_DESKTOP = "HIDE_NOTIFICATIONS_DESKTOP";
export const VALIDATE_ORDER = "VALIDATE_ORDER";

export const UPDATE_TIME_TILL_REFRESH = "UPDATE_TIME_TILL_REFRESH";

export const FAIL_CONNECTION_OPEN = "FAIL_CONNECTION_OPEN";
export const FAIL_CONNECTION_CLOSE = "FAIL_CONNECTION_CLOSE";
export const FAIL_POLLING_OPEN = "FAIL_POLLING_OPEN";
export const FAIL_POLLING_CLOSE = "FAIL_POLLING_CLOSE";
