import { createThunkReducer } from "../../helpers/createThunkReducer";
import { GET_TRANSACTIONS } from "../../constants/Transaction";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["transactions"]: { status: "", data: { hasmore: false } },
};

const getTransactions = createThunkReducer("transactions", GET_TRANSACTIONS, {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export const transactionsReducer = reduceReducers(initialReducer, getTransactions);
