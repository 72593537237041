import reduceReducers from "reduce-reducers";
import { ASC, DESC } from "../../constants/Sort";

const initialState = {
  tablesState: {
    search: "",
    openOrderData: {
      type: "",
    },
    scrollPosition: {
      x: 0,
      y: 0,
    },
    draft: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObj: [],
      translatorsByLanguagePairs: [],
      sortOrders: {
        id: "ORDER_CREATED",
        type: ASC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        order: {},
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        format: "",
      },
    },
    customer_draft: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObj: [],
      translatorsByLanguagePairs: [],
      sortOrders: {
        id: "DRAFT_ID",
        type: ASC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        order: {},
      },
      filter: {
        sourceLanguage: "",
        format: "",
      },
    },
    queue: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObj: [],
      translatorsByLanguagePairs: [],
      sortOrders: {
        id: "ORDER_CREATED",
        type: ASC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        order: {},
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        format: "",
      },
    },
    incomplete: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObj: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        order: {},
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        format: "",
      },
    },
    done: {
      loadOrdersLimit: 10,
      selectedOrders: [],
      selectedOrdersObj: [],
      sortOrders: {
        id: "ORDER_LAST_MODIFIED",
        type: DESC,
      },
      search: "",
      editingBlocks: {
        isOpen: false,
        order: {},
      },
      filter: {
        targetLanguage: "",
        sourceLanguage: "",
        format: "",
        createdTime: "",
        completedTime: "",
      },
    },
    images: [],
  },
};

function orders(state = initialState, action) {
  const statusIsEmpty = !action.status && action.state;
  switch (action.type) {
    case "CHANGE_ORDERS_TABLE_STATE":
      return {
        ...state,
        tablesState: {
          ...state.tablesState,
          ...statusIsEmpty,
          [action.status]: {
            ...state.tablesState[action.status],
            ...action.state,
          },
        },
      };
    default:
      return state;
  }
}

export const adminOrdersReducer = reduceReducers(orders);
