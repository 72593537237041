// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-sidebar__fixed__m6_UQx {
  width: 280px;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  /*top: 150px;*/
}

.navigation-sidebar__fixedMobile__X1mR6t {
  width: 280px;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 159px;
}

.navigation-sidebar__item-block__RyLI4a {
  border: 1px solid #e0e0e0;
}

.navigation-sidebar__additional-block__B0avAh {
  margin-top: 25px;
}

@media (max-width: 780px) {
  .navigation-sidebar__fixedMobile__X1mR6t {
    width: 112px !important;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 159px;
  }
}
.navigation-sidebar__error__D8OJVb {
  top: 20.4rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixed": `navigation-sidebar__fixed__m6_UQx`,
	"fixedMobile": `navigation-sidebar__fixedMobile__X1mR6t`,
	"item-block": `navigation-sidebar__item-block__RyLI4a`,
	"additional-block": `navigation-sidebar__additional-block__B0avAh`,
	"error": `navigation-sidebar__error__D8OJVb navigation-sidebar__fixed__m6_UQx`
};
export default ___CSS_LOADER_EXPORT___;
