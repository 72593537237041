import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "history/browser";
import { connect } from "react-redux";

import { signupAction } from "../../thunks/newThunks/signup/signupAsyncActions";
import { fetchRequestFacebookAuth } from "../../thunks/Facebook";
import { facebookAction, googleAction } from "../../thunks/newThunks/signin/signinAsyncActions";
import { fetchMeAction } from "../../thunks/newThunks/user/userAsyncActions";

import { setTitle, validateForm } from "../../actions/App";
import { initRegistration } from "../../actions/Signup";

import { getCookie, setCookie } from "../../helpers/common";

import { APP_DISABLED } from "../../constants/App";

import {
  EMAIL_FALLBACK,
  FACEBOOK_AUTH_REQUESTED,
  FACEBOOK_AUTH_VALIDATED,
  GOOGLE_AUTH_VALIDATED,
  GOOGLE_AUTH_WITH_CODE_REQUESTED,
} from "../../constants/Signin";

import { REGISTRATION_ERROR, REGISTRATION_REQUESTED } from "../../constants/Signup";

import { ME_FETCHED } from "../../constants/User";

import RegisterForm from "../../components/forms/RegisterForm";
import FormError from "../../components/errors/FormError";
import HtmlTranslate from "../../components/HtmlTranslate";

import moment from "moment";
import { getCouponFromUrl, getDiscountFromUrl } from "../../helpers/getCouponFromUrl";
import { withRouter } from "../CustomerApp";

let win;

export class Register extends Component {
  static propTypes = {
    fetchRequestFacebookAuth: PropTypes.func.isRequired,
    fetchValidateFacebookAuth: PropTypes.func.isRequired,
    fetchRequestRegistration: PropTypes.func.isRequired,
    fetchMe: PropTypes.func.isRequired,
    initRegistration: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    signup: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    socialSignin: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
  };
  static defaultProps = {
    relativePath: "",
  };
  state = {
    closeSignUpError: false,
    closeSocialSigninError: false,
  };

  componentDidUpdate(prevProps) {
    const { email, name, id, locale } = this.props.user;
    const { relativePath } = this.props;
    if (this.props.socialSignin.status !== prevProps.socialSignin.status) {
      if (this.props.socialSignin.status === EMAIL_FALLBACK) {
        woopra.track("Facebook error message", { env: process.env.SERVER_NAME });
        history.push("/signup?fallbackName=" + this.props.socialSignin.fallbackName + "&coupon=" + getCouponFromUrl());
      }

      if (this.props.socialSignin.status === FACEBOOK_AUTH_REQUESTED) {
        this.props.fetchValidateFacebookAuth(
          this.props.socialSignin.facebookToken,
          prevProps.i18n.locale,
          getCouponFromUrl()
        );
      }

      if (this.props.socialSignin.status === GOOGLE_AUTH_WITH_CODE_REQUESTED) {
        this.props.fetchValidateGoogleAuthWithCode(
          this.props.socialSignin.googleCode,
          prevProps.i18n.locale,
          getCouponFromUrl()
        );
      }

      if (
        this.props.socialSignin.status === FACEBOOK_AUTH_VALIDATED ||
        this.props.socialSignin.status === GOOGLE_AUTH_VALIDATED
      ) {
        this.props.fetchMe(this.props.socialSignin.authToken);
      }
    }

    if (this.props.signup.status !== prevProps.signup.status) {
      if (this.props.signup.status === REGISTRATION_REQUESTED) {
        woopra
          .identify({
            email: this.props.signup.form.email,
            name: this.props.signup.form.name,
          })
          .push();
        this.props.router.navigate(`${relativePath}/signup/confirm`);
      }
    }

    if (this.props.user.status !== prevProps.user.status) {
      if (this.props.user.status === ME_FETCHED) {
        console.info("Register social user", this.props.user);
        const method = prevProps.signup.authMethod || prevProps.socialSignin.authMethod;
        const authToken = prevProps.signup.authToken || prevProps.socialSignin.authToken;
        const isProd = process.env.NODE_ENV === "production";
        const isNewUserAuthBySocialBtn = this.props.socialSignin.created;
        if (isNewUserAuthBySocialBtn) {
          // temp fix

          let time;
          time = moment(this.props.user.createdTime.seconds * 1000);
          let date = time ? time.format().toString() : "";
          console.log("Register date: ", date);

          // temp fix

          woopra
            .identify({
              email: email,
              name: name,
              locale: locale,
              id: id,
              signed_up: date,
            })
            .push();
        } else {
          woopra
            .identify({
              email: email,
              name: name,
            })
            .push();
          woopra.track("signed in", {
            method: method,
            env: process.env.SERVER_NAME,
          });
        }

        window?.dataLayer?.push({
          event: isNewUserAuthBySocialBtn ? "nitro_signup" : "nitro_signin",
          nitro_user_id: this.props.user.id,
          is_prod: isProd,
        });
        setCookie("auth_token", authToken, 3650);
        window.location = "/";
      }
    }
  }

  closeSignUpErrorMessage = () => {
    this.setState({ closeSignUpError: true });
  };
  closeSocialSigninErrorMessage = () => {
    this.setState({ closeSocialSigninError: true });
  };

  handlerGoogleAuth = () => {
    setCookie("auth_code", "");
    win = window.open(process.env.GOOGLE_AUTH_LINK, "_blank", "height=600,width=400");
    const requestAuth = this.props.fetchValidateGoogleAuthWithCode;
    const locale = this.props.i18n.locale;

    let id = setInterval(() => {
      const authCode = getCookie("auth_code");
      if (win.closed || authCode) {
        clearInterval(id);
        win.close();

        if (authCode) {
          setCookie("auth_code", "");
          requestAuth(authCode, locale, getCouponFromUrl());
        }
      }
    }, 1500);
  };

  render() {
    const { signup, styleFormAlt, styleForm, relativePath } = this.props;
    const formSignUpErrorDisplay = this.props.signup.status === REGISTRATION_ERROR && !this.state.closeSignUpError;
    const formSocialSigninErrorDisplay =
      this.props.socialSignin.status === EMAIL_FALLBACK && !this.state.closeSocialSigninError;
    const signInUrl = getCouponFromUrl()
      ? "/login?coupon=" + getCouponFromUrl()
      : getDiscountFromUrl()
        ? "/login?discount=" + getDiscountFromUrl()
        : "/login";
    return (
      <div style={{ fontSize: 14, fontWeight: 300 }}>
        <FormError
          onClose={this.closeSignUpErrorMessage}
          message={this.props.signup.error}
          display={formSignUpErrorDisplay}
        />
        <FormError
          onClose={this.closeSocialSigninErrorMessage}
          message={this.props.socialSignin.error}
          display={formSocialSigninErrorDisplay}
        />
        <RegisterForm
          disablingTrigger={this.props.app.disablingTrigger}
          disabled={this.props.app.status === APP_DISABLED}
          locale={this.props.i18n.locale}
          validator={this.props.validateForm}
          handler={this.props.fetchRequestRegistration}
          facebookHandler={this.props.fetchRequestFacebookAuth}
          data={signup.form}
          styleForm={styleForm ? styleForm : {}}
          googleHandler={this.handlerGoogleAuth}
        />
        <p className="form-alt" style={styleFormAlt ? styleFormAlt : {}}>
          <HtmlTranslate value="SIGNUP_PAGE.LOGIN_TEXT" />{" "}
          <Link
            to={`${relativePath}${signInUrl}`}
            style={{
              textDecoration: "underline",
              borderBottom: "none",
            }}
          >
            <HtmlTranslate value="SIGNUP_PAGE.LOGIN_LINK" />
          </Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signup: state.signup,
    login: state.login,
    socialSignin: state.socialSignin,
    user: state.user,
    i18n: state.i18n,
    app: state.app,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRequestFacebookAuth: () => {
      dispatch(fetchRequestFacebookAuth());
    },
    fetchValidateGoogleAuthWithCode: (googleCode, locale, coupon) => {
      dispatch(googleAction({ serviceToken: googleCode, locale, coupon }));
    },
    fetchValidateFacebookAuth: (facebookToken, locale, coupon) => {
      dispatch(facebookAction({ serviceToken: facebookToken, locale, coupon }));
    },
    fetchRequestRegistration: form => {
      dispatch(
        signupAction({
          email: form.email,
          password: form.password,
          fullname: form.name,
          locale: form.locale,
          referer: form.referer,
          coupon: form.coupon,
        })
      );
    },
    fetchMe: token => {
      dispatch(fetchMeAction(token));
    },
    initRegistration: () => {
      dispatch(initRegistration());
    },
    validateForm: () => {
      dispatch(validateForm());
    },
    setTitle: title => {
      dispatch(setTitle(title));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
