import * as Sentry from "@sentry/browser";

const setupTrackers = function () {
  woopra.config({ domain: "app.nitrotranslate.com" });

  if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
    Sentry.init({
      dsn: "https://03c191669fdb481ea72bbcee0da9727b@sentry.io/1301558",
      environment: process.env.SENTRY_ENV,
      beforeSend(event, hint) {
        if (hint.originalException === "Timeout") return null;
        return event;
      },
    });
  }
};

export default setupTrackers;
